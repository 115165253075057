<ng-container *transloco="let t; prefix: 'contact'">
<section class="text-green-900 relative z-0">
  <div class="hero-image bg-right-bottom justify-center md:justify-start md:pl-5 bg-cover flex"
       style="background-image: url(https://d3coqd91sswgmf.cloudfront.net/header/contact_header.jpg);">
    <div class="relative container flex justify-center md:justify-start items-end z-10 mb-5">
      <div>
        <div
          class="content md:float-left self-stretch text-center py-4 px-5 my-5 bg-forest-green-800 rounded-md bg-opacity-90">
          <h1 class="heading text-gray-100 mb-3 text-2xl">{{ t('title') }}</h1>
          <h2
            class="text text-base text-gray-300 leading-normal hidden sm:block">{{ t('subtitle') }}</h2>
        </div>
      </div>
    </div>
  </div>
</section>

<section>
  <div class="bg-forest-green-700 text-center py-4 lg:px-4">
    <div class="p-2 bg-forest-green-800 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex"
         role="alert">
      <span class="flex rounded-full bg-forest-green-500 uppercase px-2 py-1 text-xs font-bold mr-3">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
        d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"/>
</svg>
      </span>
      <span class="font-semibold mr-2 text-left flex-auto">{{ t('privateCustomers') }}</span>
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M20.618 5.984A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016zM12 9v2m0 4h.01"/>
      </svg>
    </div>
  </div>
</section>

<div class="bg-gray-100 m-5 p-5 rounded-md">
  <section>
    <div>
      <app-contact-list></app-contact-list>
    </div>
  </section>

  <section id="contact_section">
    <app-contact-form></app-contact-form>
  </section>
</div>


<section class="relative">
  <google-map [options]="options"  height="25em" width="100%"/>
</section>

</ng-container>
