import {Component, OnDestroy, OnInit} from '@angular/core';
import {PlantFiltersComponent} from "../filters/plant-filters/plant-filters.component";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {PlantSlideOverFiltersComponent} from "../filters/plant-slide-over-filters/plant-slide-over-filters.component";
import {PlantGridItemComponent} from "../plant-grid-item/plant-grid-item.component";
import {Page} from "../../../shared/model/page";
import {Plant} from "../../../shared/model/plant";
import {appRoutesLinks} from "../../../app.routes.links";
import {FormGroup, ReactiveFormsModule, UntypedFormBuilder} from "@angular/forms";
import {PlantService, SearchParameters} from "../../../core/repository/plant.service";
import {Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {OptionsService} from "../../../core/util/options.service";
import {GenusService} from "../../../core/repository/genus.service";
import {TranslocoDirective, TranslocoPipe, TranslocoService} from "@jsverse/transloco";
import {Meta, Title} from "@angular/platform-browser";
import {Chip} from "primeng/chip";
import { debounceTime } from 'rxjs/operators';

type FilterType = 'categories' | 'genusIds' | 'lifetimes' | 'flowerMonths' |
  'productTypes' | 'stands' | 'usages' | 'colorIds' | 'special' |
  'provenWinners' | 'beeFriendly' | 'scented' | 'customersFavorite' |
  'summerOnly' | 'spiciness' | 'raesGenetics' | 'geneticallyCompact' |
  'earlyFlowering' | 'autumnSelection' | 'improved' | 'newPlant' | 'name';

@Component({
  selector: 'app-plants',
  standalone: true,
  imports: [
    PlantFiltersComponent,
    NgForOf,
    PlantSlideOverFiltersComponent,
    PlantGridItemComponent,
    ReactiveFormsModule,
    NgClass,
    NgIf,
    TranslocoPipe,
    Chip,
    TranslocoDirective
  ],
  templateUrl: './plants.component.html',
  styles: ``
})
export class PlantsComponent implements OnInit, OnDestroy {

  page: Page<Plant> = new Page<Plant>();
  productsLink = appRoutesLinks.PLANT_OVERVIEW_PAGE;

  form: FormGroup = new FormGroup({})

  animation: string | undefined;
  finished = false;
  loading = false;
  filtersOpen: boolean | undefined;
  url: string | undefined;
  public sortingOptions: any[] | undefined;
  public pageSizeOptions: number[] = [20, 40, 60, 80, 100];

  private possibleColumnLayouts: number[] = [2, 3, 4, 6];
  public amountOfColumns: number = 1;
  private searchParameters: SearchParameters = new SearchParameters();

  private firstTimeLoading = false;
  private subscriptions: Subscription[] = [];


  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private optionsService: OptionsService,
    private genusService: GenusService,
    private plantService: PlantService,
    public translateService: TranslocoService,
    private formBuilder: UntypedFormBuilder,
    private titleService: Title,
    private translocoService: TranslocoService,
    private metaService: Meta
  ) {
    let lang: string = this.translocoService.getActiveLang();

    let title: string;
    let description: string;
    let keywords: string;
    let translationSubscription: Subscription;

    // Use selectTranslate for the first department option
    translationSubscription = this.translocoService.selectTranslate('products.pageTitle').subscribe(value => {
      title = value;
      this.titleService.setTitle(title);
    });
    translationSubscription = this.translocoService.selectTranslate('products.description').subscribe(value => {
      description = value;
      this.metaService.updateTag({name: 'description', content: description});
    });
    translationSubscription = this.translocoService.selectTranslate('products.keywords').subscribe(value => {
      keywords = value;
      if (typeof keywords !== 'undefined') {
        this.metaService.updateTag({name: 'keywords', content: keywords});
      }
    });
    this.subscriptions.push(translationSubscription);
  }


  ngOnInit(): void {
    this.url = this.router.url.split('?')[0];
    this.subscriptions.push(
      this.optionsService.getProductSorting().subscribe(sortingOptions => this.sortingOptions = sortingOptions),
    );

    this.page = new Page<Plant>();
    this.searchParameters = new SearchParameters();
    this.preFillSearchParametersAndInitialise();
  }

  private preFillSearchParametersAndInitialise(): void {
    this.subscriptions.push(
      this.activatedRoute.queryParamMap.pipe().subscribe(queryMap => {
        this.firstTimeLoading = true;

        // Store current form values
        const currentFormValues = this.form.value;

        this.searchParameters = new SearchParameters();

        // Name
        if (queryMap.has('name')) {
          this.searchParameters.name = queryMap.get('name') ?? '';
        }

        // Genus IDs
        if (queryMap.has('genusIds')) {
          const unparsedGenusIds = queryMap.get('genusIds');
          let parsedGenusIds: number[] = [];
          if (unparsedGenusIds) {
            if (unparsedGenusIds.includes(',')) {
              parsedGenusIds = unparsedGenusIds.split(',').map(id => Number.parseInt(id, 10));
            } else {
              parsedGenusIds = [Number.parseInt(unparsedGenusIds, 10)];
            }
          }
          this.searchParameters.genusIds = parsedGenusIds;
        }

        // Categories
        if (queryMap.has('categories')) {
          const unparsedCategories = queryMap.get('categories');
          let parsedCategories: string[] = [];
          if (unparsedCategories) {
            if (unparsedCategories.includes(',')) {
              parsedCategories = unparsedCategories.split(',');
            } else {
              parsedCategories = [unparsedCategories];
            }
          }
          this.searchParameters.categories = parsedCategories;
        }

        // Flower Months
        if (queryMap.has('flowerMonths')) {
          const unparsedMonths = queryMap.get('flowerMonths');
          let parsedMonths: string[] = [];
          if (unparsedMonths) {
            if (unparsedMonths.includes(',')) {
              parsedMonths = unparsedMonths.split(',');
            } else {
              parsedMonths = [unparsedMonths];
            }
          }
          this.searchParameters.flowerMonths = parsedMonths;
        }

        // Product Types
        if (queryMap.has('productTypes')) {
          const unparsedProductTypes = queryMap.get('productTypes');
          let parsedProductTypes: string[] = [];
          if (unparsedProductTypes) {
            if (unparsedProductTypes.includes(',')) {
              parsedProductTypes = unparsedProductTypes.split(',');
            } else {
              parsedProductTypes = [unparsedProductTypes];
            }
          }
          this.searchParameters.productTypes = parsedProductTypes;
        }

        // Lifetimes
        if (queryMap.has('lifetimes')) {
          const unparsedLifetimes = queryMap.get('lifetimes');
          let parsedLifetimes: string[] = [];
          if (unparsedLifetimes) {
            if (unparsedLifetimes.includes(',')) {
              parsedLifetimes = unparsedLifetimes.split(',');
            } else {
              parsedLifetimes = [unparsedLifetimes];
            }
          }
          this.searchParameters.lifetimes = parsedLifetimes;
        }

        // Plant Type
        if (queryMap.has('plantType')) {
          this.searchParameters.plantType = queryMap.get('plantType') ?? '';
        }

        // Stands
        if (queryMap.has('stands')) {
          const unparsedStands = queryMap.get('stands');
          let parsedStands: string[] = [];
          if (unparsedStands) {
            if (unparsedStands.includes(',')) {
              parsedStands = unparsedStands.split(',');
            } else {
              parsedStands = [unparsedStands];
            }
          }
          this.searchParameters.stands = parsedStands;
        }

        // Usages
        if (queryMap.has('usages')) {
          const unparsedUsages = queryMap.get('usages');
          let parsedUsages: string[] = [];
          if (unparsedUsages) {
            if (unparsedUsages.includes(',')) {
              parsedUsages = unparsedUsages.split(',');
            } else {
              parsedUsages = [unparsedUsages];
            }
          }
          this.searchParameters.usages = parsedUsages;
        }

        // Colors
        if (queryMap.has('colors')) {
          const unparsedColors = queryMap.get('colors');
          let parsedColors: number[] = [];
          if (unparsedColors) {
            if (unparsedColors.includes(',')) {
              parsedColors = unparsedColors.split(',').map(id => Number.parseInt(id, 10));
            } else {
              parsedColors = [Number.parseInt(unparsedColors, 10)];
            }
          }
          this.searchParameters.colorIds = parsedColors;
        }

        // Boolean filters
        if (queryMap.has('special')) {
          this.searchParameters.special = JSON.parse(queryMap.get('special') ?? 'false');
        }

        if (queryMap.has('provenWinners')) {
          this.searchParameters.provenWinners = JSON.parse(queryMap.get('provenWinners') ?? 'false');
        }

        if (queryMap.has('beeFriendly')) {
          this.searchParameters.beeFriendly = JSON.parse(queryMap.get('beeFriendly') ?? 'false');
        }

        if (queryMap.has('scented')) {
          this.searchParameters.scented = JSON.parse(queryMap.get('scented') ?? 'false');
        }

        if (queryMap.has('customersFavorite')) {
          this.searchParameters.customersFavorite = JSON.parse(queryMap.get('customersFavorite') ?? 'false');
        }

        if (queryMap.has('summerOnly')) {
          this.searchParameters.summerOnly = JSON.parse(queryMap.get('summerOnly') ?? 'false');
        }

        if (queryMap.has('spiciness')) {
          this.searchParameters.spiciness = JSON.parse(queryMap.get('spiciness') ?? 'false');
        }

        if (queryMap.has('raesGenetics')) {
          this.searchParameters.raesGenetics = JSON.parse(queryMap.get('raesGenetics') ?? 'false');
        }

        if (queryMap.has('geneticallyCompact')) {
          this.searchParameters.geneticallyCompact = JSON.parse(queryMap.get('geneticallyCompact') ?? 'false');
        }

        if (queryMap.has('earlyFlowering')) {
          this.searchParameters.earlyFlowering = JSON.parse(queryMap.get('earlyFlowering') ?? 'false');
        }

        if (queryMap.has('autumnSelection')) {
          this.searchParameters.autumnSelection = JSON.parse(queryMap.get('autumnSelection') ?? 'false');
        }

        if (queryMap.has('improved')) {
          this.searchParameters.improved = JSON.parse(queryMap.get('improved') ?? 'false');
        }

        if (queryMap.has('newPlant')) {
          this.searchParameters.newPlant = JSON.parse(queryMap.get('newPlant') ?? 'false');
        }

        // Sorting and Pagination
        if (queryMap.has('sortAlias')) {
          this.searchParameters.sortAlias = queryMap.get('sortAlias') ?? 'name_asc';
        } else {
          this.searchParameters.sortAlias = 'name_asc';
        }

        if (queryMap.has('page')) {
          this.searchParameters.page = Number.parseInt(queryMap.get('page') ?? '0', 10);
          this.page.pageNumber = Number.parseInt(queryMap.get('page') ?? '0', 10);
        }

        if (queryMap.has('size')) {
          this.searchParameters.size = Number.parseInt(queryMap.get('size') ?? '0', 10);
          this.page.pageSize = Number.parseInt(queryMap.get('size') ?? '0', 10);
        }

        if (queryMap.has('columnLayout')) {
          this.amountOfColumns = Number.parseInt(queryMap.get('columnLayout') ?? '1', 10);
        }

        // Only initialize form if needed
        if (!this.form || this.shouldReinitializeForm(currentFormValues, this.searchParameters)) {
          this.initForm();
        } else {
          // Just update the form values
          this.form.patchValue(this.searchParameters, { emitEvent: false });
        }

        this.initializePlantList();
        this.firstTimeLoading = false;
      })
    );
  }

  private shouldReinitializeForm(currentValues: any, newValues: any): boolean {
    // If there are no current values, we should initialize
    if (!currentValues) {
      return true;
    }

    // Compare structure of both objects
    const currentKeys = Object.keys(currentValues).sort();
    const newKeys = Object.keys(newValues).sort();

    // If the number of properties is different, reinitialize
    if (currentKeys.length !== newKeys.length) {
      return true;
    }

    // Check if all required form controls exist
    const requiredControls = [
      'name', 'categories', 'geni', 'lifetimes', 'plantType',
      'productTypes', 'flowerMonths', 'stands', 'usages', 'colors',
      'special', 'provenWinners', 'beeFriendly', 'scented',
      'customersFavorite', 'summerOnly', 'spiciness', 'raesGenetics',
      'geneticallyCompact', 'earlyFlowering', 'autumnSelection',
      'improved', 'newPlant', 'sortAlias'
    ];

    // Check if any required control is missing
    const hasMissingControls = requiredControls.some(control =>
      !currentValues.hasOwnProperty(control)
    );

    // If any required control is missing, we should reinitialize
    if (hasMissingControls) {
      console.log('Reinitializing form due to missing controls');
      return true;
    }

    // If we reach here, no reinitialization is needed
    return false;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  public resetSearch(): void {
    this.searchParameters = new SearchParameters();
    this.initForm();

    this.initializePlantList();
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      name: [this.searchParameters.name],
      categories: [this.searchParameters.categories],
      geni: [this.searchParameters.genusIds],
      lifetimes: [this.searchParameters.lifetimes],
      plantType: [this.searchParameters.plantType],
      productTypes: [this.searchParameters.productTypes],
      flowerMonths: [this.searchParameters.flowerMonths],
      stands: [this.searchParameters.stands],
      usages: [this.searchParameters.usages],
      colors: [this.searchParameters.colorIds],
      special: [this.searchParameters.special],
      provenWinners: [this.searchParameters.provenWinners],
      beeFriendly: [this.searchParameters.beeFriendly],
      scented: [this.searchParameters.scented],
      customersFavorite: [this.searchParameters.customersFavorite],
      summerOnly: [this.searchParameters.summerOnly],
      spiciness: [this.searchParameters.spiciness],
      raesGenetics: [this.searchParameters.raesGenetics],
      geneticallyCompact: [this.searchParameters.geneticallyCompact],
      earlyFlowering: [this.searchParameters.earlyFlowering],
      autumnSelection: [this.searchParameters.autumnSelection],
      improved: [this.searchParameters.improved],
      newPlant: [this.searchParameters.newPlant],
      sortAlias: [this.searchParameters.sortAlias]
    });

    this.form.valueChanges.pipe(
      debounceTime(1500) // Adjust the debounce time as needed
    ).subscribe(values => {
      console.log('Values:', values);
      if (!this.firstTimeLoading) {
        this.searchParameters.page = 0;
      }

      this.syncSearchParametersAndSend(values);

    });
  }

  private syncSearchParametersAndSend(values: {
    name: string;
    geni: number[];
    colors: number[];
    categories: string[];
    flowerMonths: string[];
    productTypes: string[];
    stands: string[];
    usages: string[];
    lifetimes: string[];
    plantType: string;
    provenWinners: boolean;
    beeFriendly: boolean;
    scented: boolean;
    customersFavorite: boolean;
    summerOnly: boolean;
    spiciness: boolean;
    raesGenetics: boolean;
    geneticallyCompact: boolean;
    earlyFlowering: boolean;
    autumnSelection: boolean;
    special: boolean;
    improved: boolean;
    newPlant: boolean;
    sortAlias: string;
  }): void {
    this.searchParameters.name = values.name;
    this.searchParameters.genusIds = values.geni;
    this.searchParameters.colorIds = values.colors;
    this.searchParameters.categories = values.categories;
    this.searchParameters.flowerMonths = values.flowerMonths;
    this.searchParameters.lifetimes = values.lifetimes;
    this.searchParameters.plantType = values.plantType;
    this.searchParameters.productTypes = values.productTypes;
    this.searchParameters.special = values.special;
    this.searchParameters.improved = values.improved;
    this.searchParameters.newPlant = values.newPlant;
    this.searchParameters.sortAlias = values.sortAlias;

    this.searchParameters.stands = values.stands;
    this.searchParameters.usages = values.usages;
    this.searchParameters.provenWinners = values.provenWinners;
    this.searchParameters.beeFriendly = values.beeFriendly;
    this.searchParameters.scented = values.scented;
    this.searchParameters.customersFavorite = values.customersFavorite;
    this.searchParameters.summerOnly = values.summerOnly;
    this.searchParameters.spiciness = values.spiciness;
    this.searchParameters.raesGenetics = values.raesGenetics;
    this.searchParameters.geneticallyCompact = values.geneticallyCompact;
    this.searchParameters.earlyFlowering = values.earlyFlowering;
    this.searchParameters.autumnSelection = values.autumnSelection;

    this.addQueryParametersToUrl(this.searchParameters, this.amountOfColumns);
    this.initializePlantList();
  }

  public paginate(event: any): void {
    this.searchParameters.page = event.page + 1;
    this.searchParameters.size = event.rows;
    // this.addQueryParametersToUrl(this.searchParameters, this.amountOfColumns);

    this.loading = true;
    this.plantService.fetchPagedPlantList(this.searchParameters).subscribe(page => {
      this.page = page;
      this.loading = false;
    }, (error) => {
      this.loading = false;
    });
  }

  private addQueryParametersToUrl(searchParameters: SearchParameters, amountOfColumns: number): void {
    const queryParams: any = {};

    for (const key of Object.keys(searchParameters)) {
      // @ts-ignore
      const value = searchParameters[key];

      // Skip empty values
      if (!this.isNotEmpty(value)) {
        continue;
      }

      // Handle boolean values - only add if true
      if (typeof value === 'boolean') {
        if (value) {
          queryParams[key] = value;
        }
        continue;
      }

      // Handle arrays
      if (Array.isArray(value)) {
        queryParams[key] = value.join(',');
        continue;
      }

      // Handle all other values
      queryParams[key] = value;
    }

    if (this.possibleColumnLayouts.includes(amountOfColumns)) {
      queryParams.columnLayout = amountOfColumns;
    }

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams,
      queryParamsHandling: null
    });
  }

  public toggleFilters(): void {
    this.filtersOpen = !this.filtersOpen;
  }

  onClose($event: Boolean): void {
    this.filtersOpen = false;
  }

  private fadeIn(): void {
    this.animation = 'fadeIn';
  }

  private fadeOut(): void {
    this.animation = 'fadeOut';
  }

  private initializePlantList(): void {
    this.loading = true;
    this.plantService.fetchPagedPlantList(this.searchParameters).subscribe(page => {
      this.page = page;
      this.loading = false;
    }, (error) => {
      this.loading = false;
    });
  }

  private isNotEmpty(value: any): boolean {
    return value !== null && value !== undefined && value !== '' && (Array.isArray(value) ? value.length !== 0 : true);
  }


  resetFilter(filterType: FilterType): void {
    switch (filterType) {
      case 'categories':
        this.searchParameters.categories = [];
        break;
      case 'lifetimes':
        this.searchParameters.lifetimes = [];
        break;
      case 'flowerMonths':
        this.searchParameters.flowerMonths = [];
        break;
      case 'productTypes':
        this.searchParameters.productTypes = [];
        break;
      case 'stands':
        this.searchParameters.stands = [];
        break;
      case 'usages':
        this.searchParameters.usages = [];
        break;
      case 'special':
        this.searchParameters.special = false;
        break;
      case 'provenWinners':
        this.searchParameters.provenWinners = false;
        break;
      case 'beeFriendly':
        this.searchParameters.beeFriendly = false;
        break;
      case 'scented':
        this.searchParameters.scented = false;
        break;
      case 'customersFavorite':
        this.searchParameters.customersFavorite = false;
        break;
      case 'summerOnly':
        this.searchParameters.summerOnly = false;
        break;
      case 'spiciness':
        this.searchParameters.spiciness = false;
        break;
      case 'raesGenetics':
        this.searchParameters.raesGenetics = false;
        break;
      case 'geneticallyCompact':
        this.searchParameters.geneticallyCompact = false;
        break;
      case 'earlyFlowering':
        this.searchParameters.earlyFlowering = false;
        break;
      case 'autumnSelection':
        this.searchParameters.autumnSelection = false;
        break;
      case 'improved':
        this.searchParameters.improved = false;
        break;
      case 'newPlant':
        this.searchParameters.newPlant = false;
        break;
      case 'name':
        this.searchParameters.name = '';
        break;
    }

    // Update form values
    if (this.form.contains(filterType)) {
      this.form.patchValue({
        [filterType]: this.searchParameters[filterType]
      }, {emitEvent: true});
    }

    // This will trigger the search due to your existing form value changes subscription
  }

  getActiveFilterTypes(): FilterType[] {
    const activeFilters: FilterType[] = [];
    if (this.searchParameters.categories?.length > 0) {
      activeFilters.push('categories');
    }
    if (this.searchParameters.genusIds?.length > 0) {
      activeFilters.push('genusIds');
    }
    if (this.searchParameters.lifetimes?.length > 0) {
      activeFilters.push('lifetimes');
    }
    if (this.searchParameters.flowerMonths?.length > 0) {
      activeFilters.push('flowerMonths');
    }
    if (this.searchParameters.productTypes?.length > 0) {
      activeFilters.push('productTypes');
    }
    if (this.searchParameters.stands?.length > 0) {
      activeFilters.push('stands');
    }
    if (this.searchParameters.usages?.length > 0) {
      activeFilters.push('usages');
    }
    if (this.searchParameters.colorIds?.length > 0) {
      activeFilters.push('colorIds');
    }
    // Boolean filters
    if (this.searchParameters.special) {
      activeFilters.push('special');
    }
    if (this.searchParameters.provenWinners) {
      activeFilters.push('provenWinners');
    }
    if (this.searchParameters.beeFriendly) {
      activeFilters.push('beeFriendly');
    }
    if (this.searchParameters.scented) {
      activeFilters.push('scented');
    }
    if (this.searchParameters.customersFavorite) {
      activeFilters.push('customersFavorite');
    }
    if (this.searchParameters.summerOnly) {
      activeFilters.push('summerOnly');
    }
    if (this.searchParameters.spiciness) {
      activeFilters.push('spiciness');
    }
    if (this.searchParameters.raesGenetics) {
      activeFilters.push('raesGenetics');
    }
    if (this.searchParameters.geneticallyCompact) {
      activeFilters.push('geneticallyCompact');
    }
    if (this.searchParameters.earlyFlowering) {
      activeFilters.push('earlyFlowering');
    }
    if (this.searchParameters.autumnSelection) {
      activeFilters.push('autumnSelection');
    }
    if (this.searchParameters.improved) {
      activeFilters.push('improved');
    }
    if (this.searchParameters.newPlant) {
      activeFilters.push('newPlant');
    }
    if (this.searchParameters.name) {
      activeFilters.push('name');
    }

    return activeFilters;
  }

}
