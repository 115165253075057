<div class="bg-gray-50">
  <div class="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
    <div class="max-w-3xl mx-auto divide-y-2 divide-gray-200">
      <dl class="mt-6 space-y-6 divide-y divide-gray-200">
        <div class="pt-6">
          <dt class="text-lg">
            <button (click)="toggleItem('faq-1')" type="button"
                    class="text-left w-full flex justify-between items-start text-gray-400" aria-controls="faq-0"
                    aria-expanded="false">
              <span class="font-medium text-gray-900">
                {{ 'genetics.culture.technical.title1' | transloco }}
              </span>
              <span class="ml-6 h-7 flex items-center">
                <svg class="h-6 w-6 transform"
                     [ngClass]="{ '-rotate-180': isActive('faq-1'), 'rotate-0': !isActive('faq-1') }"
                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                     aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"/>
                </svg>
              </span>
            </button>
          </dt>
          <dd class="mt-2 pr-12" id="faq-1" [class.hidden]="!isActive('faq-1')">
            <p class="p-5" innerHTML="{{'genetics.culture.technical.description1_1' | transloco}}"></p>
          </dd>
        </div>
        <div class="pt-6">
          <dt class="text-lg">
            <button (click)="toggleItem('faq-2')" type="button"
                    class="text-left w-full flex justify-between items-start text-gray-400" aria-controls="faq-0"
                    aria-expanded="false">
              <span class="font-medium text-gray-900">
                {{ 'genetics.culture.technical.title2' | transloco }}
              </span>
              <span class="ml-6 h-7 flex items-center">
                <svg class="h-6 w-6 transform"
                     [ngClass]="{ '-rotate-180': isActive('faq-2'), 'rotate-0': !isActive('faq-2') }"
                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                     aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"/>
                </svg>
              </span>
            </button>
          </dt>
          <dd class="mt-2 pr-12" id="faq-2" [class.hidden]="!isActive('faq-2')">
            <p class="p-5" innerHTML="{{'genetics.culture.technical.description2_1' | transloco}}"></p>
            <p class="p-5" innerHTML="{{'genetics.culture.technical.description2_2' | transloco}}"></p>
            <p class="p-5" innerHTML="{{'genetics.culture.technical.description2_3' | transloco}}"></p>
            <p class="p-5" innerHTML="{{'genetics.culture.technical.description2_4' | transloco}}"></p>
            <p class="p-5" innerHTML="{{'genetics.culture.technical.description2_5' | transloco}}"></p>
          </dd>
        </div>
        <div class="pt-6">
          <dt class="text-lg">
            <button (click)="toggleItem('faq-3')" type="button"
                    class="text-left w-full flex justify-between items-start text-gray-400" aria-controls="faq-0"
                    aria-expanded="false">
              <span class="font-medium text-gray-900">
                {{ 'genetics.culture.technical.title3' | transloco }}
              </span>
              <span class="ml-6 h-7 flex items-center">
                <svg class="h-6 w-6 transform"
                     [ngClass]="{ '-rotate-180': isActive('faq-3'), 'rotate-0': !isActive('faq-3') }"
                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                     aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"/>
                </svg>
              </span>
            </button>
          </dt>
          <dd class="mt-2 pr-12" id="faq-3" [class.hidden]="!isActive('faq-3')">
            <p class="p-5" innerHTML="{{'genetics.culture.technical.description3' | transloco}}"></p>
          </dd>
        </div>
        <div class="pt-6">
          <dt class="text-lg">
            <button (click)="toggleItem('faq-4')" type="button"
                    class="text-left w-full flex justify-between items-start text-gray-400" aria-controls="faq-0"
                    aria-expanded="false">
              <span class="font-medium text-gray-900">
                {{ 'genetics.culture.technical.title4' | transloco }}
              </span>
              <span class="ml-6 h-7 flex items-center">
                <svg class="h-6 w-6 transform"
                     [ngClass]="{ '-rotate-180': isActive('faq-4'), 'rotate-0': !isActive('faq-4') }"
                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                     aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"/>
                </svg>
              </span>
            </button>
          </dt>
          <dd class="mt-2 pr-12" id="faq-4" [class.hidden]="!isActive('faq-4')">
            <p class="p-5" innerHTML="{{'genetics.culture.technical.description4' | transloco}}"></p>
          </dd>
        </div>
        <div class="pt-6">
          <dt class="text-lg">
            <button (click)="toggleItem('faq-5')" type="button"
                    class="text-left w-full flex justify-between items-start text-gray-400" aria-controls="faq-0"
                    aria-expanded="false">
              <span class="font-medium text-gray-900">
                {{ 'genetics.culture.technical.title5' | transloco }}
              </span>
              <span class="ml-6 h-7 flex items-center">
                <svg class="h-6 w-6 transform"
                     [ngClass]="{ '-rotate-180': isActive('faq-5'), 'rotate-0': !isActive('faq-5') }"
                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                     aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"/>
                </svg>
              </span>
            </button>
          </dt>
          <dd class="mt-2 pr-12" id="faq-5" [class.hidden]="!isActive('faq-5')">
            <p class="p-5" innerHTML="{{'genetics.culture.technical.description5' | transloco}}"></p>
          </dd>
        </div>
        <div class="pt-6">
          <dt class="text-lg">
            <button (click)="toggleItem('faq-6')" type="button"
                    class="text-left w-full flex justify-between items-start text-gray-400" aria-controls="faq-0"
                    aria-expanded="false">
              <span class="font-medium text-gray-900">
                {{ 'genetics.culture.technical.title6' | transloco }}
              </span>
              <span class="ml-6 h-7 flex items-center">
                <svg class="h-6 w-6 transform"
                     [ngClass]="{ '-rotate-180': isActive('faq-6'), 'rotate-0': !isActive('faq-6') }"
                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                     aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"/>
                </svg>
              </span>
            </button>
          </dt>
          <dd class="mt-2 pr-12" id="faq-6" [class.hidden]="!isActive('faq-6')">
            <p class="p-5" innerHTML="{{'genetics.culture.technical.description6_1' | transloco}}"></p>
            <p class="p-5" innerHTML="{{'genetics.culture.technical.description6_2' | transloco}}"></p>
          </dd>
        </div>
        <div class="pt-6">
          <dt class="text-lg">
            <button (click)="toggleItem('faq-7')" type="button"
                    class="text-left w-full flex justify-between items-start text-gray-400" aria-controls="faq-0"
                    aria-expanded="false">
              <span class="font-medium text-gray-900">
                {{ 'genetics.culture.technical.title7' | transloco }}
              </span>
              <span class="ml-6 h-7 flex items-center">
                <svg class="h-6 w-6 transform"
                     [ngClass]="{ '-rotate-180': isActive('faq-7'), 'rotate-0': !isActive('faq-7') }"
                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                     aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"/>
                </svg>
              </span>
            </button>
          </dt>
          <dd class="mt-2 pr-12" id="faq-7" [class.hidden]="!isActive('faq-7')">
            <p class="p-5" innerHTML="{{'genetics.culture.technical.description7' | transloco}}"></p>
          </dd>
        </div>
        <div class="pt-6">
          <dt class="text-lg">
            <button (click)="toggleItem('faq-8')" type="button"
                    class="text-left w-full flex justify-between items-start text-gray-400" aria-controls="faq-0"
                    aria-expanded="false">
              <span class="font-medium text-gray-900">
                {{ 'genetics.culture.technical.title8' | transloco }}
              </span>
              <span class="ml-6 h-7 flex items-center">
                <svg class="h-6 w-6 transform"
                     [ngClass]="{ '-rotate-180 ': isActive('faq-8'), 'rotate-0': !isActive('faq-8') }"
                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                     aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"/>
                </svg>
              </span>
            </button>
          </dt>
          <dd class="mt-2 pr-12" id="faq-8" [class.hidden]="!isActive('faq-8')">
            <p class="p-5" innerHTML="{{'genetics.culture.technical.description8' | transloco}}"></p>
          </dd>
        </div>
        <div class="pt-6">
          <dt class="text-lg">
            <button (click)="toggleItem('faq-9')" type="button"
                    class="text-left w-full flex justify-between items-start text-gray-400" aria-controls="faq-0"
                    aria-expanded="false">
              <span class="font-medium text-gray-900">
                {{ 'genetics.culture.technical.title9' | transloco }}
              </span>
              <span class="ml-6 h-7 flex items-center">
                <svg class="h-6 w-6 transform"
                     [ngClass]="{ '-rotate-180': isActive('faq-9'), 'rotate-0': !isActive('faq-9') }"
                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                     aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"/>
                </svg>
              </span>
            </button>
          </dt>
          <dd class="mt-2 pr-12" id="faq-9" [class.hidden]="!isActive('faq-9')">
            <p class="p-5" innerHTML="{{'genetics.culture.technical.description9' | transloco}}"></p>
          </dd>
        </div>
      </dl>
    </div>
  </div>
</div>
