<ng-container *transloco="let t; prefix: 'plant'">
  <section aria-labelledby="features-heading" class="relative m-5">
    <div class="flex flex-col lg:flex-row">
      <!-- Image Section -->
      <div class="lg:w-1/2 lg:h-[600px] lg:pr-4 xl:pr-16">
        <div class="relative h-full">
          <img
            src="{{plant.publicImage}}"
            alt="{{plant.getFullName(this.translateService.getActiveLang())}}"
            title="{{plant.getFullName(this.translateService.getActiveLang())}}"
            class="w-full h-full object-cover rounded-xl"
          />
          <div class="absolute top-0 right-20 bg-eminence-50 px-1 py-1 m-2 rounded-md" *ngIf="plant.newPlant">
            New
          </div>
          <div class="absolute top-0 right-20 bg-eminence-50 px-1 py-1 m-2 rounded-md" *ngIf="plant.improvedPlant">
            Imp
          </div>
        </div>
      </div>

      <!-- Content Section -->
      <div class="lg:w-1/2 mt-8 lg:mt-0">
        <div class="flex flex-row">
          <app-plant-icon [plantIcon]="plant.lifetime" [category]="'lifetime'" [extension]="'svg'">
          </app-plant-icon>
          <app-plant-icon-list [productIcons]="plant.stands" category="stand" extension="svg"></app-plant-icon-list>
        </div>

        <h2 id="features-heading" class="ml-0 font-medium text-sapphire-700 mt-0">{{ plant.genusBotanicName }}<span
          class="text-md font-thin ml-1 block sm:inline">{{ plant.getGenusName(this.translateService.getActiveLang()) }}</span>
        </h2>

        <p class="mt-4 text-xl text-gray-900 tracking-tight">{{ plant.familyBotanicName }}</p>
        <p class="mt-4 text-gray-500">{{ plant.getGenusDescription(this.translateService.getActiveLang()) }}</p>
        <h3 class="text-gray-600 ml-0">{{ plant.specieBotanicName }}</h3>

        <div>
          <div class="flex flex-row">
            <app-plant-icon *ngIf="plant.hasSeeds()" [plantIcon]="'only_available_as_seeds'" [category]="'productType'"
                            [extension]="'svg'"></app-plant-icon>
            <app-plant-icon *ngIf="plant.hasCuttings()" [plantIcon]="'yougplant_raised_from_cutting'"
                            [category]="'productType'" [extension]="'svg'"></app-plant-icon>
            <app-plant-icon *ngIf="plant.hasSeedlings()" [plantIcon]="'youngplant_raised_from_seeds'"
                            [category]="'productType'" [extension]="'svg'"></app-plant-icon>
            <app-plant-icon-list [productIcons]="plant.usages" category="usage" extension="svg"></app-plant-icon-list>
            <app-plant-height-icon *ngIf="plant.fromHeight !== null || plant.tillHeight !== null"
                                   [fromHeight]="plant.fromHeight"
                                   [tillHeight]="plant.tillHeight"></app-plant-height-icon>
            <app-plant-potsize-icon *ngIf="plant.fromPotSize !== null || plant.tillPotSize !== null"
                                    [fromSize]="plant.fromPotSize"
                                    [tillSize]="plant.tillPotSize"></app-plant-potsize-icon>
          </div>
          <ng-container *ngIf="plant.hasSelectedCultivar">
            <h4 class="ml-0 text-gold-drop-600"><span
              *ngIf="plant.hybrid">F1 </span>{{ plant.selectedCultivarNameEn }}</h4>
            <p class="mt-4 text-gray-500"
               *ngIf="plant.getSelectedCultivarDescription(this.translateService.getActiveLang())?.length">{{ plant.getSelectedCultivarDescription(this.translateService.getActiveLang()) }}</p>
            <p
              *ngIf="plant.hasCuttings() && plant.getSelectedCultivarCuttingsDescription(this.translateService.getActiveLang())?.length"
              class="mt-4 text-gray-500">{{ plant.getSelectedCultivarCuttingsDescription(this.translateService.getActiveLang()) }}</p>
            <p
              *ngIf="plant.hasSeeds() && plant.getSelectedCultivarSeedsDescription(this.translateService.getActiveLang())?.length"
              class="mt-4 text-gray-500">{{ plant.getSelectedCultivarSeedsDescription(this.translateService.getActiveLang()) }}</p>
          </ng-container>
        </div>
        <div class="ml-0 mt-2">
          <h4 class="text-gray-700 font-bold uppercase text-lg p-0 m-0">{{ plant.plantCode }}  {{ plant.fullnameEn }}
            <app-plant-trademark [trademark]="plant.trademark"></app-plant-trademark>
            <span *ngIf="plant.newPlant"
                  class="ml-2 text-eminence-700 font-hk font-bold text-xs uppercase tracking-wide">
                New
              </span>
            <span *ngIf="plant.improvedPlant"
                  class="ml-2 text-eminence-700 font-hk font-bold text-xs uppercase tracking-wide">
                Imp
              </span>
          </h4>
        </div>

        <p class="flex-grow mt-0"
           *ngIf="plant.getDescription(this.translateService.getActiveLang())?.length">{{ plant.getDescription(this.translateService.getActiveLang()) }}</p>
      </div>
    </div>

    <!-- Tabs Section -->
    <div class="mt-16" *ngIf="propertiesAvailable() || periodDataAvailable()">
      <p-tabs value="0">
        <p-tablist>
          <p-tab *ngIf="propertiesAvailable()" value="0">{{ 'plant.taxonomy.product.properties' | transloco }}</p-tab>
          <p-tab *ngIf="periodDataAvailable()" value="1">{{ 'plant.taxonomy.product.timing' | transloco }}</p-tab>
        </p-tablist>
        <p-tabpanels>
          <p-tabpanel value="0">
            <dl class="mt-10 grid grid-cols-1 gap-y-10 gap-x-8 text-sm sm:grid-cols-2" id="properties">
              <!-- Fertilization -->
              <div *ngIf="plant.fertilization !== undefined && plant.fertilization !== null">
                <dt class="font-medium text-gray-900">{{ t('taxonomy.product.fertilization') }}</dt>
                <dd class="mt-2 text-gray-500">
                  {{ 'enum.fertilization.' + plant.fertilization | transloco }}
                </dd>
              </div>

              <!-- Diseases -->
              <div *ngIf="plant.diseases && plant.diseases.length > 0">
                <dt class="font-medium text-gray-900">{{ t('taxonomy.product.diseases') }}</dt>
                <dd class="mt-2 text-gray-500">
                  <ng-container
                    *ngFor="let disease of plant.diseases | orderBy: getOrderProperty(); let lastItem = last">
                    {{ disease.getName(this.translateService.getActiveLang()) }}<span *ngIf="!lastItem">,</span>
                  </ng-container>
                </dd>
              </div>

              <!-- Soils -->
              <div *ngIf="plant.soils && plant.soils.length > 0">
                <dt class="font-medium text-gray-900">{{ t('taxonomy.product.soil') }}</dt>
                <dd class="mt-2 text-gray-500">
                  <ng-container *ngFor="let soil of plant.soils; let lastItem = last">
                    {{ soil.getName(this.translateService.getActiveLang()) }}  <span *ngIf="!lastItem"
                                                                                     class="mt-2 text-base text-indigo-200">,</span>
                  </ng-container>
                </dd>
              </div>

              <!-- Potting Temperature -->
              <div *ngIf="hasValidPottingTemperature()">
                <dt class="font-medium text-gray-900">{{ t('taxonomy.product.temperatures') }}</dt>
                <dd class="mt-2 text-gray-500">
                  {{ t('taxonomy.product.potting-temperature') }} : {{ plant.pottingTemperature }}
                </dd>
              </div>
            </dl>
          </p-tabpanel>
          <p-tabpanel value="1">
            <div *ngIf="periodDataAvailable()" class="mt-10">
              <app-plant-period-grid [plant]="plant"></app-plant-period-grid>
            </div>
          </p-tabpanel>
        </p-tabpanels>
      </p-tabs>
    </div>
  </section>

  <!-- Related plants -->
  <!-- Related plants -->
  <ng-container *ngIf="(relatedPlants$ | async) as relatedPlants">
    <ng-container *ngIf="relatedPlants.length > 0">
      <section aria-labelledby="related-heading" class="mx-5 mt-2">
        <h2 id="related-heading"
            class="text-lg font-medium text-gray-900">{{ 'products.detail.related-products' | transloco }}</h2>

        <div class="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
          <div class="group relative cursor-pointer transition-transform duration-300 ease-in-out hover:scale-105"
               *ngFor="let relatedPlant of relatedPlants"
               [routerLink]="plantDetail(relatedPlant.slug)">
            <div class="relative w-24 h-24 mx-auto overflow-hidden rounded-md shadow-sm transition-all duration-300 ease-in-out group-hover:shadow-lg">
              <img src="{{relatedPlant.publicImage}}"
                   alt="{{relatedPlant.fullnameEn}}"
                   title="{{relatedPlant.fullnameEn}}"
                   class="w-full h-full object-cover transition-transform duration-300 ease-in-out group-hover:scale-110">
            </div>
            <div class="mt-2 text-center">
              <h3 class="text-sm text-gray-700 transition-colors duration-300 ease-in-out group-hover:text-sapphire-600">{{ relatedPlant.genusBotanicName }}</h3>
              <p class="mt-0.5 text-sm text-gray-500">{{ relatedPlant.fullnameEn }}</p>
            </div>
          </div>
        </div>
      </section>
    </ng-container>
  </ng-container>
</ng-container>
