<section class="text-green-900 relative z-0">
  <div class="hero-image bg-right-bottom bg-cover flex sm:bg-none"
       style="background-image: url(https://d3coqd91sswgmf.cloudfront.net/header/culture_header.jpg);">
    <div class="relative container justify-center md:justify-start md:pl-5 flex items-end z-10">
      <div>
        <div
          class="content float-left justify-center md:justify-start py-4 px-5 my-5 bg-christi-700 rounded-md bg-opacity-90">
          <h1 class="heading text-gray-100 mb-3 text-2xl md:text-4xl">{{ 'genetics.culture.title' | transloco }}</h1>
        </div>
      </div>
    </div>
  </div>
</section>

<section>

  <form [formGroup]="form" novalidate class="m-5">
    <div class="sm:hidden">
      <label for="tabs" class="sr-only">Select a tab</label>
      <select id="tabs" name="tabs" formControlName="selectedTab"
              class="block w-full focus:ring-sapphire-500 focus:border-sapphire-500 border-gray-300 rounded-md">
        <option value="production-scheme">{{ 'genetics.culture.production-scheme' | transloco }}</option>
        <option value="technical">{{ 'genetics.culture.technical.title' | transloco }}</option>
      </select>
    </div>
    <div class="hidden sm:block">
      <div class="border-b border-gray-200">
        <nav class="-mb-px flex" aria-label="Tabs">
          <!-- Current: "border-sapphire-500 text-sapphire-600", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" -->
          <a (click)="toggle('production-scheme')"
             [ngClass]="{ 'bg-sapphire-100 text-sapphire-700': isActive('production-scheme'), 'text-gray-500 hover:text-gray-700 cursor-pointer': !isActive('production-scheme') }"
             class="border-transparent text-gray-500 w-1/2 py-4 px-1 text-center border-b-2 font-medium text-sm">
            {{ 'genetics.culture.production-scheme' | transloco }}
          </a>

          <a (click)="toggle('technical')"
             [ngClass]="{ 'bg-sapphire-100 text-sapphire-700': isActive('technical'), 'text-gray-500 hover:text-gray-700 cursor-pointer': !isActive('technical') }"
             class="border-transparent text-gray-500 w-1/2 py-4 px-1 text-center border-b-2 font-medium text-sm">
            {{ 'genetics.culture.technical.title' | transloco }}
          </a>
        </nav>
      </div>
    </div>
  </form>

  <div [class.hidden]="!isActive('production-scheme')">
    <div *ngIf="isBrowser">
      <ngx-extended-pdf-viewer [showPresentationModeButton]="true" [src]="'https://d3coqd91sswgmf.cloudfront.net/genetics/primula-EN-DE.pdf'" [height]="'auto'">
      </ngx-extended-pdf-viewer>
    </div>
  </div>
  <div [class.hidden]="!isActive('technical')">
    <app-genetics-technical-info></app-genetics-technical-info>
  </div>

</section>
