import {isPlatformBrowser, NgClass, NgIf} from "@angular/common";
import {FormBuilder, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {SeoService} from "../../../core/util/seo.service";
import {GeneticsTechnicalInfoComponent} from "../genetics-technical-info/genetics-technical-info.component";
import {Component, Inject, Input, PLATFORM_ID} from '@angular/core';
import {TranslocoPipe, TranslocoService} from "@jsverse/transloco";
import {Meta, Title} from "@angular/platform-browser";
import {Subscription} from "rxjs";
import {NgxExtendedPdfViewerModule} from "ngx-extended-pdf-viewer";


@Component({
  selector: 'app-genetics-culture',
  standalone: true,
  imports: [
    NgClass,
    ReactiveFormsModule,
    GeneticsTechnicalInfoComponent,
    NgIf,
    TranslocoPipe,
    NgxExtendedPdfViewerModule,
  ],
  templateUrl: './genetics-culture.component.html',
  styles: ``
})
export class GeneticsCultureComponent {
  form: FormGroup = this.formBuilder.group({});
  isBrowser: boolean = false;
  @Input() tabToggle = true;

  constructor(private translocoService: TranslocoService,
              private formBuilder: FormBuilder,
              private readonly seoService: SeoService,
              @Inject(PLATFORM_ID) private platformId: Object,
              private titleService: Title,
              private metaService: Meta
  ) {
    let lang: string = this.translocoService.getActiveLang();

    let title: string;
    let description: string;
    let keywords: string;
    let translationSubscription: Subscription;

    // Use selectTranslate for the first department option
    translationSubscription = this.translocoService.selectTranslate('genetics.culture.title').subscribe(value => {
      title = value;
      this.titleService.setTitle(title);
    });
    translationSubscription = this.translocoService.selectTranslate('genetics.culture.description').subscribe(value => {
      description = value;
      this.metaService.updateTag({name: 'description', content: description});
    });
    translationSubscription = this.translocoService.selectTranslate('genetics.culture.keywords').subscribe(value => {
      keywords = value;
      if (typeof keywords !== 'undefined') {
        this.metaService.updateTag({name: 'keywords', content: keywords});
      }    });
    translationSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.createForm();
    this.tabToggle = true;
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      selectedTab: ['production-scheme'],
    });
  }

  toggle(tab: string): void {
    this.form.patchValue({
      selectedTab: tab,
    });
  }

  isActive(tab: string): boolean {
    return tab === this.form.controls['selectedTab'].value;
  }


}
