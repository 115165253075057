import {Injectable} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(private titleService: Title, private metaService: Meta) {
  }

  public setSEOData(title: string, description: string, keywords?: string): void {
    title += ' | Raes Bloemzaden nv';
    this.titleService.setTitle(title);
    this.metaService.updateTag({name: 'description', content: description});
    if (typeof keywords !== 'undefined') {
      this.metaService.updateTag({name: 'keywords', content: keywords});
    }
  }
}
