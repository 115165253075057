<div class="flex-shrink-0">
  <img class="h-48 w-full object-cover" src="{{newsItem?.imageUrl}}" alt="">
</div>
<div class="flex-1 bg-white p-6 flex flex-col justify-between">
  <div class="flex-1">
    <h3 class="text-sm font-medium text-indigo-600">
      <a [routerLink]="newsDetail(newsItem?.id)" class="hover:underline">
        {{newsItem?.getTitle(this.translationService.getActiveLang())}}
      </a>
    </h3>
    <a [routerLink]="newsDetail(newsItem?.id)" class="block mt-2">
      <p class="text-base text-gray-900">
        {{newsItem?.getIntro(this.translationService.getActiveLang())}}
      </p>
    </a>
  </div>
</div>
