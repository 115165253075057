<div class="relative bg-white overflow-hidden">
  <div class="max-w-7xl mx-auto">
    <div class="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
      <svg class="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2" fill="currentColor" viewBox="0 0 100 100" preserveAspectRatio="none" aria-hidden="true">
        <polygon points="50,0 100,0 50,100 0,100" />
      </svg>

      <main class="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
        <div class="relative sm:text-center lg:text-left">
          <h1 class="text-4xl tracking-tight font-extrabold text-my-sin-500 sm:text-5xl md:text-6xl">
            <span class="block xl:inline">{{ this.floweringPeriodTitle }}</span>
          </h1>
          <div class="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
            <div class="rounded-md shadow">
              <a [routerLink]="periodsLink" class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gold-drop-700 hover:bg-gold-drop-800 md:py-4 md:text-lg md:px-10"> {{ 'genetics.type.goto-others' | transloco }} </a>
            </div>
            <div class="mt-3 sm:mt-0 sm:ml-3">
              <a [routerLink]="productCatalogLink"  class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gold-drop-600 hover:bg-gold-drop-800 md:py-4 md:text-lg md:px-10"> {{ 'genetics.type.goto-catalog' | transloco }} </a>
            </div>
          </div>
        </div>
        <p class="my-6">{{'genetics.types.description' | transloco }}</p>
        <div>
          <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
            <a *ngIf="this.flowerPeriod !== 'VERY_EARLY'" (click)="redirect(goToPeriod('VERY_EARLY'))" class="cursor-pointer">
              <div  class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6 hover:bg-gold-drop-700">
                <dt class="text-sm font-medium text-gray-500 truncate">Switch to</dt>
                <dd class="mt-1 text-3xl font-semibold text-gray-900">Very Early</dd>
              </div>
            </a>

            <a *ngIf="this.flowerPeriod !== 'EARLY'" (click)="redirect(goToPeriod('EARLY'))" class="cursor-pointer">
              <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6 hover:bg-gold-drop-700">
                <dt class="text-sm font-medium text-gray-500 truncate">Switch to</dt>
                <dd class="mt-1 text-3xl font-semibold text-gray-900">Early</dd>
              </div>
            </a>

            <a (click)="redirect(goToPeriod('MID_EARLY'))" *ngIf="this.flowerPeriod !== 'MID_EARLY'" class="cursor-pointer">
              <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6 hover:bg-gold-drop-700">
                <dt class="text-sm font-medium text-gray-500 truncate">Switch to</dt>
                <dd class="mt-1 text-3xl font-semibold text-gray-900">Mid Early</dd>
              </div>
            </a>

            <a *ngIf="this.flowerPeriod !== 'MID_LATE'" (click)="redirect(goToPeriod('MID_LATE'))" class="cursor-pointer">
              <div  class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6 hover:bg-gold-drop-700">
                <dt class="text-sm font-medium text-gray-500 truncate">Switch to</dt>
                <dd class="mt-1 text-3xl font-semibold text-gray-900">Mid Late</dd>
              </div>
            </a>

            <a *ngIf="this.flowerPeriod !== 'LATE'" (click)="redirect(goToPeriod('LATE'))" class="cursor-pointer">
              <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6 hover:bg-gold-drop-700">
                <dt class="text-sm font-medium text-gray-500 truncate">Switch to</dt>
                <dd class="mt-1 text-3xl font-semibold text-gray-900">Late</dd>
              </div>
            </a>

            <a (click)="redirect(goToPeriod('VERY_LATE'))" *ngIf="this.flowerPeriod !== 'VERY_LATE'" class="cursor-pointer">
              <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6 hover:bg-gold-drop-700">
                <dt class="text-sm font-medium text-gray-500 truncate">Switch to</dt>
                <dd class="mt-1 text-3xl font-semibold text-gray-900">Very Late</dd>
              </div>
            </a>
          </dl>
        </div>
      </main>
    </div>
  </div>
  <div class="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
    <img class="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full" src="https://s3.eu-west-3.amazonaws.com/raes.cdn/genetics/genetics_1.jpg" alt="">
  </div>
</div>


<div class="bg-white">
  <div class="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
    <h2 class="sr-only">Products</h2>

    <div class="grid grid-cols-1 gap-y-10 sm:grid-cols-2 gap-x-6 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">

      <a [routerLink]="productDetail(product?.slug)" class="group" *ngFor="let product of seriePlants">
        <div class="w-full aspect-w-1 aspect-h-1 bg-gray-200 rounded-lg overflow-hidden xl:aspect-w-7 xl:aspect-h-8">
          <img src="{{product.publicImage}}" alt="{{product.fullnameEn}}" class="w-full h-full object-center object-cover group-hover:opacity-75">
        </div>
        <h3 class="mt-4 text-sm text-gray-700">{{product.fullnameEn}}</h3>
      </a>

    </div>
  </div>
</div>
