import {Component, Input, OnInit} from '@angular/core';
import {TranslocoPipe} from "@jsverse/transloco";

@Component({
  selector: 'app-plant-icon',
  standalone: true,
  imports: [
    TranslocoPipe
  ],
  templateUrl: './plant-icon.component.html'
})
export class PlantIconComponent implements OnInit {

  @Input() plantIcon: string | undefined;
  @Input() category: string | undefined;
  @Input() extension: string | undefined;

  constructor() {
  }

  ngOnInit(): void {
  }

  get icon(): string {
    return 'https://s3.eu-west-3.amazonaws.com/raes.cdn/icon/' + this.plantIcon + '.' + this.extension;
  }
}
