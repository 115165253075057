import { Injectable } from '@angular/core';
import {TranslocoService} from "@jsverse/transloco";

@Injectable({
  providedIn: 'root'
})
export class ValidationUtil {

  constructor(private translocoService: TranslocoService) { }

  public handleError(key: string, data: any): any {
    switch (key) {
      case 'min':
        return this.parseMin(data.min, data.actual);
      case 'max':
        return this.parseMax(data.max, data.actual);
      case 'required':
        return this.parseRequired();
      case 'email':
        return this.parseEmail();
      case 'minlength':
        return this.parseMinLength(data.requiredLength, data.actualLength);
      case 'maxlength':
        return this.parseMaxLength(data.requiredLength, data.actualLength);
      case 'pattern':
        return this.parsePattern(data.requiredPattern, data.actualValue);
      case 'alreadyExists':
        return this.parseAlreadyExists();
      default:
        return this.parseUnknown();
    }
  }

  private parseMin(min: number, actual: number): string {
    return this.translocoService.translate('validation.min', { min });
  }

  private parseMax(max: number, actual: number): string {
    return this.translocoService.translate('validation.max', { max });
  }

  private parseRequired(): string {
    return this.translocoService.translate('validation.required');
  }

  private parseEmail(): string {
    return this.translocoService.translate('validation.email');
  }

  public parseMinLength(minLength: number, actualLength: number): string {
    return this.translocoService.translate('validation.minLength', { minLength });
  }

  public parseMaxLength(maxLength: number, actualLength: number): string {
    return this.translocoService.translate('validation.maxLength', { maxLength });
  }

  public parsePattern(requiredPattern: string, actualValue: string): string {
    return this.translocoService.translate('validation.invalidPattern', {requiredPattern});
  }

  public parseAlreadyExists(): string {
    return this.translocoService.translate('validation.alreadyExists');
  }

  public parseUnknown(): string {
    return this.translocoService.translate('validation.unknown');
  }

}
