<div class="relative mx-auto">
  <div class="text-center" [routerLink]="digitalCatalogLink">


    <h3 class="underline cursor-pointer">{{'dashboard.catalogs.subtitle' | transloco }}</h3>
    <h4 *ngIf="(catalogsSubscription | async)?.length">{{'dashboard.catalogs.title' | transloco }}</h4>
  </div>
</div>

<ul class="space-y-4 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 lg:grid-cols-3 lg:gap-8">
  <app-issuu-item
    *ngFor="let digitalcatalog of catalogsSubscription | async; trackBy:identifyDigitalcatalog"
    [digitalcatalog]="digitalcatalog"  [languageIconActivated]="true">
  </app-issuu-item>
</ul>
<div class="m-5 flex flex-row flex-wrap content-center justify-around">
  <app-cta-button buttonText="{{'cta.gotoCatalogs' | transloco }}" [link]="appRoutesLinks.CATALOG_PAGE"></app-cta-button>
</div>
