export class ContactInfo {
  constructor(public firstName: string = '',
              public lastName: string = '',
              public phone: string = '',
              public email: string = '',
              public street: string = '',
              public streetNumber: string = '',
              public city: string = '',
              public country: string = '',
              public company: string = '',
              public subject: string = '',
              public message: string = '',
              public department: string = '',
              public captcha: string = '') {}
}
