import {Component, OnInit} from '@angular/core';
import {AsyncPipe, NgForOf, NgIf} from "@angular/common";
import {LandingNewsItemComponent} from "../landing-news-item/landing-news-item.component";
import {Observable} from "rxjs";
import {TranslocoPipe} from "@jsverse/transloco";
import {NewsService} from "../../../core/repository/news.service";
import {News} from "../../../shared/model/news";

@Component({
  selector: 'app-landing-news',
  standalone: true,
  imports: [
    AsyncPipe,
    LandingNewsItemComponent,
    NgForOf,
    TranslocoPipe,
    NgIf
  ],
  templateUrl: './landing-news.component.html'
})
export class LandingNewsComponent implements OnInit {

  constructor(private newsService: NewsService) {
  }

  newsSubscription: Observable<News[]> | undefined;

  public newsSlideConfig: any = {
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [{
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  ngOnInit(): void {
    this.newsSubscription = this.newsService.getHighlighted();
  }
}
