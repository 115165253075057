import {ChangeDetectorRef, Component, Input} from '@angular/core';
import {NgClass, NgIf} from "@angular/common";
import {RouterLink, Router} from "@angular/router";
import {appRoutesLinks} from "../../../../app.routes.links";
import {TranslocoPipe, TranslocoService} from "@jsverse/transloco";
import {NavigationUtils} from "../../../../core/util/navigation.utils";
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-mobile-menu',
  standalone: true,
  imports: [
    NgIf,
    RouterLink,
    TranslocoPipe,
    NgClass
  ],
  templateUrl: './mobile-menu.component.html',
  animations: [
    trigger('toggleMenu', [
      state('open', style({
        height: '*',
        opacity: 1,
        transform: 'translateY(0)'
      })),
      state('closed', style({
        height: '0',
        opacity: 0,
        transform: 'translateY(-20px)'
      })),
      transition('open <=> closed', [
        animate('300ms ease-in-out')
      ])
    ])
  ]
})
export class MobileMenuComponent {
  @Input() isMenuOpen = false;
  isSlideOver = false;
  isProductMenu = false;
  isGeneticsMenu = false;
  protected readonly appRoutesLinks = appRoutesLinks;

  constructor(
    private router: Router,
    private translocoService: TranslocoService,
    private changeDetector: ChangeDetectorRef,
    private navigationUtils: NavigationUtils,
  ) {}

  toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
    this.isSlideOver = this.isMenuOpen;
    if (!this.isMenuOpen) {
      this.closeSubmenus();
    }
    this.changeDetector.detectChanges();
  }

  toggleProductMenu(): void {
    this.isProductMenu = !this.isProductMenu;
    if (this.isProductMenu) {
      this.isGeneticsMenu = false;
    }
    this.changeDetector.detectChanges();
  }

  toggleGeneticsMenu(): void {
    this.isGeneticsMenu = !this.isGeneticsMenu;
    if (this.isGeneticsMenu) {
      this.isProductMenu = false;
    }
    this.changeDetector.detectChanges();
  }

  async navigateAndClose(route: string | string[], queryParams: {} = {}): Promise<void> {
    try {
      const routeArray = Array.isArray(route) ? route : [route];
      if (Object.keys(queryParams).length > 0) {
        await this.router.navigate(routeArray, { queryParams });
      } else {
        await this.router.navigate(routeArray);
      }
      this.resetMenu();
    } catch (error) {
      console.error('Navigation error:', error);
    }
  }

  switchLanguage(lang: string): void {
    this.translocoService.setActiveLang(lang);
    this.changeDetector.detectChanges();
  }

  isCurrentLanguage(lang: string): boolean {
    return this.translocoService.getActiveLang() === lang;
  }

  resetMenu(): void {
    this.isMenuOpen = false;
    this.isSlideOver = false;
    this.closeSubmenus();
    this.changeDetector.detectChanges();
  }

  private closeSubmenus(): void {
    this.isProductMenu = false;
    this.isGeneticsMenu = false;
    this.changeDetector.detectChanges();
  }
}
