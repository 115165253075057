<main class="mb-10">
  <div class="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
    <div class="pt-12 text-center">
      <h1>{{'genetics.types.title' | transloco }}</h1>
      <p>{{'genetics.types.description' | transloco }}</p>
    </div>

    <section aria-labelledby="featured-heading" class="relative mt-16 rounded-lg overflow-hidden lg:h-96">
      <div class="absolute inset-0">
        <img src="https://s3.eu-west-3.amazonaws.com/raes.cdn/banner/Primula_Everlast_P581.jpg" alt="Primula Everlast" title="Primula Everlast" class="w-full h-full object-center object-cover">
      </div>
      <div aria-hidden="true" class="relative w-full h-96 lg:hidden"></div>
      <div aria-hidden="true" class="relative w-full h-32 lg:hidden"></div>
      <div class="absolute inset-x-0 bottom-0 bg-gold-drop-700 bg-opacity-75 p-6 rounded-bl-lg rounded-br-lg backdrop-filter backdrop-blur sm:flex sm:items-center sm:justify-between lg:inset-y-0 lg:inset-x-auto lg:w-96 lg:rounded-tl-lg lg:rounded-br-none lg:flex-col lg:items-start">
        <div>
          <h2 id="featured-heading-series" class="text-xl font-bold text-white">{{'genetics.types.standard.title' | transloco}}</h2>
          <p class="mt-1 text-sm text-gray-300">{{'genetics.types.standard.overview' | transloco}}</p>
          <p class="mt-4 text-sm text-gray-300">{{ standardTypeSeries }}</p>
        </div>
        <a (click)="navigate(goToSerie('STANDARD'))" class="mt-6 flex-shrink-0 flex bg-white bg-opacity-0 py-3 px-4 border border-white border-opacity-25 rounded-md items-center justify-center text-base font-medium text-white hover:bg-opacity-10 sm:mt-0 sm:ml-8 lg:ml-0 lg:w-full">{{'genetics.types.GotoSeries' | transloco }}</a>
      </div>
    </section>

    <section aria-labelledby="featured-heading" class="relative mt-16 rounded-lg overflow-hidden lg:h-96">
      <div class="absolute inset-0">
        <img src="https://s3.eu-west-3.amazonaws.com/raes.cdn/banner/Primula_Zebra_Blue.JPG" alt="" class="w-full h-full object-center object-cover">
      </div>
      <div aria-hidden="true" class="relative w-full h-96 lg:hidden"></div>
      <div aria-hidden="true" class="relative w-full h-32 lg:hidden"></div>
      <div class="absolute inset-x-0 bottom-0 bg-my-sin-700 bg-opacity-75 p-6 rounded-bl-lg rounded-br-lg backdrop-filter backdrop-blur sm:flex sm:items-center sm:justify-between lg:inset-y-0 lg:inset-x-auto lg:w-96 lg:rounded-tl-lg lg:rounded-br-none lg:flex-col lg:items-start">
        <div>
          <h2 id="featured-heading-specials" class="text-xl text-center font-bold text-white">{{'genetics.types.specials.title' | transloco}}</h2>
          <p class="mt-1 text-sm text-gray-300">{{'genetics.types.specials.overview' | transloco}}</p>
          <p class="mt-4 text-sm text-gray-300">{{ specialTypeSeries }}</p>
        </div>
        <a (click)="navigate(goToSerie('SPECIALS'))" class="mt-6 flex-shrink-0 flex bg-white bg-opacity-0 py-3 px-4 border border-white border-opacity-25 rounded-md items-center justify-center text-base font-medium text-white hover:bg-opacity-10 sm:mt-0 sm:ml-8 lg:ml-0 lg:w-full">{{'genetics.types.GotoSpecials' | transloco }}</a>
      </div>
    </section>

    <section aria-labelledby="featured-heading-garden-types" class="relative mt-16 rounded-lg overflow-hidden lg:h-96">
      <div class="absolute inset-0">
        <img src="https://s3.eu-west-3.amazonaws.com/raes.cdn/banner/Primula_Everlast_P581.jpg" alt="" class="w-full h-full object-center object-cover">
      </div>
      <div aria-hidden="true" class="relative w-full h-96 lg:hidden"></div>
      <div aria-hidden="true" class="relative w-full h-32 lg:hidden"></div>
      <div class="absolute inset-x-0 bottom-0 bg-christi-700 bg-opacity-75 p-6 rounded-bl-lg rounded-br-lg backdrop-filter backdrop-blur sm:flex sm:items-center sm:justify-between lg:inset-y-0 lg:right-0 lg:w-96 lg:rounded-tl-lg lg:rounded-br-none lg:flex-col lg:items-start">
        <div>
          <h2 id="featured-heading" class="text-xl font-bold text-white">{{'genetics.types.gardentype.title' | transloco}}</h2>
          <p class="mt-1 text-sm text-gray-300">{{'genetics.types.gardentype.overview' | transloco}}</p>
          <p class="mt-4 text-sm text-gray-300">{{gardenTypeSeries}}</p>
        </div>
        <a (click)="navigate(goToSerie('GARDENTYPE'))" class="mt-6 flex-shrink-0 flex bg-white bg-opacity-0 py-3 px-4 border border-white border-opacity-25 rounded-md items-center justify-center text-base font-medium text-white hover:bg-opacity-10 sm:mt-0 sm:ml-8 lg:ml-0 lg:w-full">{{'genetics.types.GotoGardenTypes' | transloco }}</a>
      </div>
    </section>

  </div>
</main>
