import {Component, Input} from '@angular/core';
import {AsyncPipe, NgForOf, NgIf} from "@angular/common";
import {OrderFormItemComponent} from "../orderFormItem/orderFormItem.component";
import {Orderform} from "../../../shared/model/orderform";
import {TranslocoPipe} from "@jsverse/transloco";

@Component({
  selector: 'app-orderFormList',
  standalone: true,
  imports: [
    NgForOf,
    OrderFormItemComponent,
    NgIf,
    AsyncPipe,
    TranslocoPipe
  ],
  templateUrl: './orderFormList.component.html'
})
export class OrderFormListComponent {
  @Input() orderForms: null | Orderform[] = [];

  openInNewTab(url: string | undefined): void {
    const newTab = window.open(url, '_blank');
    newTab?.focus();
  }
}
