import {Component, Input, OnInit, Optional, Self} from '@angular/core';
import {AbstractControl, NgControl, ReactiveFormsModule} from '@angular/forms';
import {AbstractControlDirective} from "../../directives/abstract-control.directive";
import {ValidationUtil} from "../../../core/util/validation-util.service";
import {CommonModule, NgIf} from "@angular/common";

@Component({
  selector: 'app-checkbox',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgIf, CommonModule
  ],
  templateUrl: './checkbox.component.html'
})
export class CheckboxComponent extends AbstractControlDirective implements OnInit  {
  @Input() labelFor: string | undefined;
  inputClasses: "{'border' : control?.dirty && !control?.valid,  'border-red-500' : control?.dirty && !control?.valid}" | undefined;

  constructor(private validationUtil: ValidationUtil,
              @Self() @Optional() public override ngControl: NgControl) {
    super(ngControl);
  }

  ngOnInit(): void {
    this.labelFor = 'input_' + this.id;
  }

  get required(): boolean {
    if (this.control && this.control.validator) {
      const validator = this.control.validator({} as AbstractControl);
      return validator && validator['required'];
    }
    return false;
  }

  get hasErrors(): boolean {
    return this.control ? this.control.dirty && !this.control.valid : false;
  }

  get error(): string | undefined {
    if (this.hasErrors && this.control && this.control.errors && Object.keys(this.control.errors).length !== 0) {
      const firstError = Object.keys(this.control.errors)[0];
      return this.validationUtil.handleError(firstError, this.control.errors[firstError]);
    }
    return undefined;
  }


}
