import {Directive, Input, Optional, Self} from '@angular/core';
import {ControlValueAccessor, FormControl, NgControl} from '@angular/forms';

@Directive()
export abstract class AbstractControlDirective implements ControlValueAccessor {
  @Input() label: string | undefined;
  @Input() id: string | undefined;
  @Input() col = 12;
  @Input() formControlName: string | undefined;

  onChange: ((value: any) => void) | undefined;

  constructor(@Self() @Optional() public ngControl: NgControl) {
    this.ngControl.valueAccessor = this;
  }

  get control(): FormControl {
    return this.ngControl.control as FormControl;
  }

  get isInvalid(): boolean {
    return this.control ? this.control.dirty && !this.control.valid && this.control.touched : false;
  }

  get isValid(): boolean {
    return this.control ? this.control.dirty && this.control.valid : false;
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(obj: any): void {
  }
}
