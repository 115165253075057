import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {OptionsService} from "../../../../core/util/options.service";
import {Subscription} from "rxjs";
import {GenusService} from "../../../../core/repository/genus.service";
import {Genus} from "../../../../shared/model/genus";
import {NgClass, NgForOf, NgIf, NgStyle} from "@angular/common";
import {TranslocoPipe, TranslocoService} from "@jsverse/transloco";
import {DropdownModule} from "primeng/dropdown";
import {MultiSelectModule} from "primeng/multiselect";
import {Tag} from "primeng/tag";
import {Chip} from "primeng/chip";
import {BadgeDirective} from "primeng/badge";
import {InputText} from "primeng/inputtext";
import {FloatLabel} from "primeng/floatlabel";
import {Checkbox} from "primeng/checkbox";

@Component({
  selector: 'app-plant-filters',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgClass,
    NgForOf,
    NgIf,
    TranslocoPipe,
    DropdownModule,
    FormsModule,
    MultiSelectModule,
    NgStyle,
    Tag,
    Chip,
    BadgeDirective,
    InputText,
    FloatLabel,
    Checkbox
  ],
  templateUrl: './plant-filters.component.html',
  styles: ``
})
export class PlantFiltersComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup = new FormGroup({
    geni: new FormControl<[] | null>([])
  });
  @Output() reset: EventEmitter<any> = new EventEmitter<any>();
  genusOptions: any[] = [];
  productTypeOptions: any[] = [];
  categoryOptions: any[] = [];
  lifeTimeOptions: any[] = [];
  standOptions: any[] = [];
  usageOptions: any[] = [];
  flowerMonths: any[] = [];
  lifetimeOptions: any[] = [];

  public language: string;
  public isGenusOptionsOpened = false;

  private subscriptions: Subscription[] = [];

  constructor(private optionsService: OptionsService,
              private genusService: GenusService,
              private translateService: TranslocoService) {
    this.language = this.translateService.getActiveLang();
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.optionsService.getProductTypes().subscribe(productTypes => this.productTypeOptions = productTypes),
      this.optionsService.getCategories().subscribe(categories => this.categoryOptions = categories),
      this.optionsService.getLifetimes().subscribe(lifeTimes => this.lifeTimeOptions = lifeTimes),
      this.optionsService.getStands().subscribe(stands => this.standOptions = stands),
      this.optionsService.getUsages().subscribe(usages => this.usageOptions = usages),
      this.optionsService.getFlowerMonths().subscribe(flowerMonths => this.flowerMonths = flowerMonths.map((month: {
        value: any;
        label: string[];
      }) => ({
        value: month.value,
        label: this.capitalizeFirstLetter(month.label)
      }))),
      this.optionsService.getLifetimes().subscribe(lifetimeOptions => this.lifetimeOptions = lifetimeOptions),
      // todo: Maybe fetch all the (required) geni.
      this.genusService.autocomplete('').subscribe((geni: Genus[]) => this.genusOptions = geni.map((genus: Genus) => ({
        value: genus.id,
        label: genus.botanicName
      })))
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  private capitalizeFirstLetter([first, ...rest]: string[]): string {
    return first.toUpperCase() + rest.join('');
  }

}
