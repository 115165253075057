export enum FruitShape {
  COCKTAIL = 'COCKTAIL',
  LARGE_ROUND_FRUITS = 'LARGE_ROUND_FRUITS',
  HORN_SHAPED = 'HORN_SHAPED',
  HEART_SHAPED = 'HEART_SHAPED',
  OLIVE_SHAPED = 'OLIVE_SHAPED',
  ROUND_FRUITS = 'ROUND_FRUITS',
  MARMANDE = 'MARMANDE',
  COEUR_DE_BOEUF = 'COEUR_DE_BOEUF',
  TRUSS_TOMATOES = 'TRUSS_TOMATOES',
  BLOCKY = 'BLOCKY',
  LONG_POINT = 'LONG_POINT',
  SPECIALS = 'SPECIALS',
  LIGURIA = 'LIGURIA'
}
