import {Component, Input} from '@angular/core';
import {AbstractControlDirective} from "../../directives/abstract-control.directive";
import {InputComponent} from "../input/input.component";
import {CommonModule, NgForOf} from "@angular/common";
import {ReactiveFormsModule} from "@angular/forms";

@Component({
  selector: 'app-dropdown',
  standalone: true,
  imports: [
    InputComponent,ReactiveFormsModule,
    NgForOf, CommonModule
  ],
  templateUrl: './dropdown.component.html'
})
export class DropdownComponent extends AbstractControlDirective {

  @Input('options') dropdownOptions: any[] | undefined;
  @Input() placeholder: string | undefined;
  @Input() optionLabel: (option: any) => string = this.defaultLabel;
  @Input() optionValue: (option: any) => any = this.defaultValue;

  get options(): any {
    if (this.optionLabel && this.dropdownOptions) {
      this.dropdownOptions.map(option => {
        option.label = this.optionLabel(option);
        option.value = this.optionValue(option);
        return option;
      });
    }

    return this.dropdownOptions;
  }

  defaultLabel(option: any): string {
    return option?.label;
  }

  defaultValue(option: any): any {
    return option?.value;
  }

}
