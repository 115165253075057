<ng-container *transloco="let t; prefix: 'privacy'">

<section class="text-green-900 relative z-0">
  <div class="hero-image bg-cover flex sm:bg-none bg-center"
       style="background-image: url(https://d3coqd91sswgmf.cloudfront.net/header/privacy_header.jpg);">
    <div class="relative container mx-auto p-4 flex items-end z-10">
      <div>
        <div class="md:float-left py-4 px-5 my-5 bg-christi-700 rounded-md bg-opacity-80">
          <div class="heading text-gray-100 mb-3 text-2xl md:text-4xl">{{ t('title' ) }}</div>
          <div class="text text-gray-300 leading-normal hidden sm:block">{{ t('subtitle' ) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section>
  <div class="m-5 flex flex-col flex-wrap text-left bg-gray-100 p-5 rounded-md">

    <h1 class="text-xl justify-around">{{ t('statement_1.title' ) }}</h1>

    <p class="px-5">{{ t('statement_1.description_p1' ) }}</p>
    <p class="px-5">{{ t('statement_1.description_p2' ) }}</p>

    <h2 class="text-lg">{{ t('statement_2.title' ) }}</h2>
    <p class="px-5">{{ t('statement_2.description' ) }}</p>

    <h2 class="text-lg">{{ t('statement_3.title' ) }}</h2>
    <p class="px-5">{{ t('statement_3.description' ) }}</p>

    <h2 class="text-lg">{{ t('statement_4.title' ) }}</h2>
    <p class="px-5">{{ t('statement_4.description' ) }}</p>

    <h2 class="text-lg">{{ t('statement_5.title' ) }}</h2>
    <p class="px-5">{{ t('statement_5.description' ) }}</p>

    <h2 class="text-lg">{{ t('statement_6.title' ) }}</h2>
    <p class="px-5">{{ t('statement_6.description' ) }}</p>

    <h2 class="text-lg">{{ t('statement_7.title' ) }}</h2>
    <p class="px-5">{{ t('statement_7.description' ) }}</p>

    <h2 class="text-lg">{{ t('statement_8.title' ) }}</h2>
    <p class="px-5">{{ t('statement_8.description' ) }}</p>

    <h2 class="text-lg">{{ t('statement_9.title' ) }}</h2>
    <p class="px-5">{{ t('statement_9.description' ) }}
      <a [routerLink]="contactLink">{{ t('statement_9.contact_link' ) }}</a>
    </p>

    <h2 class="text-lg">{{ t('statement_10.title' ) }}</h2>
    <p class="px-5">{{ t('statement_10.description' ) }}</p>
  </div>

  <div class="flex flex-row-reverse">
    <p class="text-xs px-5 italic text-gray-600 pr-10 mb-5">{{ t('publicationDate' ) }}</p>
  </div>

</section>

</ng-container>
