<div>
  <input [id]="'input_' + id" type="checkbox" [formControl]="control" [ngClass]="inputClasses"/>
  <label for="{{labelFor}}">
    &nbsp;<ng-content></ng-content><span *ngIf="required">*</span>
  </label>
  <div *ngIf="hasErrors">
    <small class="text-red-700">
      {{ error }}
    </small>
  </div>
</div>
