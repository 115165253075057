<div *ngIf="periodDataAvailable()" class="rounded-sm overflow-hidden bg-gray-100 mt-2 p-4">
  <div class="space-y-2">
    <div>
      <div>
        <app-plant-periods label="months" [showMonths]="true">
        </app-plant-periods>
      </div>

      <div *ngIf="plant?.hasSeeds() && plant?.fromSowingMonth !== null && plant?.tillSowingMonth !== null">
        <app-plant-periods
          [showMonths]="false"
          [from]="plant.fromSowingMonth"
          [till]="plant.tillSowingMonth"
          [fromSecond]="plant.fromSecondSowingMonth"
          [tillSecond]="plant.tillSecondSowingMonth"
          label="sowing">
        </app-plant-periods>
      </div>

      <div *ngIf="plant?.fromPottingMonth !== null && plant?.tillPottingMonth !== null">
        <app-plant-periods
          [showMonths]="false"
          [from]="plant.fromPottingMonth"
          [till]="plant.tillPottingMonth"
          [fromSecond]="plant.fromSecondPottingMonth"
          [tillSecond]="plant.tillSecondPottingMonth"
          label="potting">
        </app-plant-periods>
      </div>

      <div *ngIf="plant?.fromSalesMonth !== null && plant?.tillSalesMonth !== null">
        <app-plant-periods
          [showMonths]="false"
          [from]="plant.fromSalesMonth"
          [till]="plant.tillSalesMonth"
          [fromSecond]="plant.fromSecondSalesMonth"
          [tillSecond]="plant.tillSecondSalesMonth"
          label="selling">
        </app-plant-periods>
      </div>

      <div *ngIf="plant?.flowerMonths">
        <app-flower-periods [flowerMonths]="plant.flowerMonths"></app-flower-periods>
      </div>
    </div>
  </div>
</div>
