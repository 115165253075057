import { Component } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import {AsyncPipe, NgClass, NgIf} from "@angular/common";
import {RouterLink} from "@angular/router";
import {TranslocoDirective, TranslocoPipe} from "@jsverse/transloco";
import {appRoutesLinks} from "../../../../app.routes.links";
import {NavigationUtils} from "../../../../core/util/navigation.utils";
import {FlyoutMenuService} from "../../../../core/util/flyout-menu.service";

@Component({
  selector: 'app-company-menu',
  templateUrl: './company-menu.component.html',
  standalone: true,
  imports: [
    NgIf,
    RouterLink,
    TranslocoPipe,
    TranslocoDirective,
    NgClass,
    AsyncPipe
  ],
  animations: [
    trigger('opacityTranslateXY', [
      transition(':enter', [
        style({opacity: 0, transform: 'translate(-50%,0.25rem)'}),
        animate('200ms ease-out', style({opacity: 1, transform: 'translate(-50%,0)'}))
      ]),
      transition(':leave', [
        style({opacity: 1, transform: 'translate(-50%,0)'}),
        animate('150ms ease-in', style({opacity: 0, transform: 'translate(-50%,0.25rem)'}))
      ])
    ]),
  ]
})
export class CompanyMenuComponent {

  protected readonly appRoutesLinks = appRoutesLinks;

  constructor(private navigationUtils: NavigationUtils, protected flyoutMenuService: FlyoutMenuService) { }

  navigateAndClose(route: string[], queryParams: {} = {}): void {
    if (Object.keys(queryParams).length > 0) {
      this.navigationUtils.navigate(route, {
        queryParams
      });
    } else {
      this.navigationUtils.navigate(route);
    }

    this.resetMenu();
  }

  toggleFlyoutMenu() {
    this.flyoutMenuService.toggleFlyoutMenu('company');
  }

  private resetMenu(): void {
    this.flyoutMenuService.closeCurrentFlyout();
  }
}
