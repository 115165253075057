import { Component } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import {AsyncPipe, NgClass, NgIf} from "@angular/common";
import {appRoutesLinks} from "../../../../app.routes.links";
import {RouterLink} from "@angular/router";
import {TranslocoDirective} from "@jsverse/transloco";
import {NavigationUtils} from "../../../../core/util/navigation.utils";
import {FlyoutMenuService} from "../../../../core/util/flyout-menu.service";
import {Button} from "primeng/button";

@Component({
  selector: 'app-genetics-menu',
  templateUrl: './genetics-menu.component.html',
  standalone: true,
  imports: [
    NgIf,
    RouterLink,
    TranslocoDirective,
    NgClass,
    AsyncPipe,
    Button
  ],
  animations: [
    trigger('AnimationTrigger0', [
      transition(':enter', [
        style({opacity: 0, transform: 'translate(-50%,0.25rem)'}),
        animate('200ms ease-out', style({opacity: 1, transform: 'translate(-50%,0)'}))
      ]),
      transition(':leave', [
        style({opacity: 1, transform: 'translate(-50%,0)'}),
        animate('150ms ease-in', style({opacity: 0, transform: 'translate(-50%,0.25rem)'}))
      ])
    ])
  ]
})
export class GeneticsMenuComponent {

  protected readonly appRoutesLinks = appRoutesLinks;
  geneticsPage = appRoutesLinks.GENETICS_PAGE;
  floweringPeriodsPage = appRoutesLinks.GENETICS_FLOWERINGPERIODS_PAGE;
  newPrimulaPage: any = appRoutesLinks.GENETICS_NEWPRIMULA_PAGE;
  justInTimePage: any = appRoutesLinks.GENETICS_JUSTINTIME_PAGE;
  constructor(private navigationUtils: NavigationUtils, protected flyoutMenuService: FlyoutMenuService) { }

  navigateAndClose(route: string[], queryParams: {} = {}): void {
    if (Object.keys(queryParams).length > 0) {
      this.navigationUtils.navigate(route, {
        queryParams
      });
    } else {
      this.navigationUtils.navigate(route);
    }

    this.resetMenu();
  }

  toggleFlyoutMenu() {
    this.flyoutMenuService.toggleFlyoutMenu('genetics');
  }

  private resetMenu(): void {
    this.flyoutMenuService.closeCurrentFlyout();
  }
}
