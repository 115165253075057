export class Serie {
  constructor(public variety: string = '',
              public growth: string = '',
              public flowerperiod: string = '',
              public potsize: string = '',
              public flowersize: string = '',
              public image: string = '',
              public potweeks: string[] = [],
              public floweringweeks: string[] = []) {}

  static adapt(item: any): Serie {
    return new Serie(
      item.variety,
      item.growth,
      item.flowerperiod,
      item.potsize,
      item.flowersize,
      item.image,
      item.potweeks,
      item.floweringweeks
    );
  }
}
