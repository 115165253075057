import {Component, Input} from '@angular/core';
import {Contact} from "../../../shared/model/contact";
import {NgIf} from "@angular/common";
import {TranslocoDirective, TranslocoPipe} from "@jsverse/transloco";

@Component({
  selector: 'app-contact-item',
  standalone: true,
  imports: [
    NgIf,
    TranslocoDirective,
    TranslocoPipe
  ],
  templateUrl: './contact-item.component.html'
})
export class ContactItemComponent {
  @Input() contact: Contact | undefined;

}
