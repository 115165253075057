<form [formGroup]="form"
      class="flex flex-col lg:flex-row justify-end px-4 lg:px-0 lg:max-w-screen-xl mx-auto py-4 space-y-2 lg:space-y-0 lg:space-x-2">
  <div class="w-full lg:w-56">
    <input type="text" name="codeOrName" id="codeOrName" formControlName="codeOrName"
           [placeholder]="'availability.filter.codeOrName' | transloco"
           class="focus:ring-christi-600 focus:border-christi-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
  </div>

  <div class="w-full lg:w-36">
    <select id="availabilityType" name="availabilityType" formControlName="availabilityType"
            class="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-christi-600 focus:border-christi-600 sm:text-sm rounded-md">
      <option value="">{{ 'availability.filter.noFilter' | transloco }}</option>
      <ng-template ngFor let-availabilityType [ngForOf]="availabilityTypes">
        <option [value]="availabilityType.value">{{ availabilityType.label | transloco }}</option>
      </ng-template>
    </select>
  </div>

  <div class="w-full lg:w-32">
    <label id="selectedWeek" class="sr-only">
      {{'availability.filter.selectWeek' | transloco }}
    </label>
    <div class="relative">
      <button (click)="toggleSelectedWeekDropdown()" type="button"
              class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label">
      <span class="block truncate">
        {{'availability.filter.week' | transloco }} {{ this.form.controls['selectedWeek'].value }}
      </span>
        <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
        <!-- Heroicon name: solid/selector -->
        <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
             aria-hidden="true">
          <path fill-rule="evenodd"
                d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                clip-rule="evenodd"/>
        </svg>
      </span>
      </button>

      <!--
        Select popover, show/hide based on select state.

        Entering: ""
          From: ""
          To: ""
        Leaving: "transition ease-in duration-100"
          From: "opacity-100"
          To: "opacity-0"
      -->
      <ul [class.hidden]="!isSelectedWeekDropdownOpen"
          class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
          role="listbox" aria-labelledby="selected-week-label">
        <!--
          Select option, manage highlight styles based on mouseenter/mouseleave and keyboard navigation.

          Highlighted: "text-white bg-indigo-600", Not Highlighted: "text-gray-900"
        -->
        <ng-template ngFor let-week let-i="index" [ngForOf]="scrollableCols">
          <li (click)="onSelectWeek(week.field)"
              [ngClass]="{ 'text-gray-900': this.form.controls['selectedWeek'].value === week.field }"
              class="cursor-pointer text-gray-60 cursor-default select-none relative py-2 pl-3 pr-9 hover:text-white hover:bg-indigo-600"
              id="selected-week-option-{{week.field}}"
              role="option">
            <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
            <span class="font-normal block truncate">
            {{ week.header }}
          </span>

            <!--
              Checkmark, only display for selected option.

              Highlighted: "text-white", Not Highlighted: "text-indigo-600"
            -->
            <span
              [ngClass]="{ 'text-indigo-600 hover:text-white': this.form.controls['selectedWeek'].value === week.field, 'text-white hover:text-indigo-600': this.form.controls['selectedWeek'].value !== week.field  }"
              class="text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4 hover:text-white">
          <!-- Heroicon name: solid/check -->
          <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
               aria-hidden="true">
            <path fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"/>
          </svg>
        </span>
          </li>

        </ng-template>
      </ul>
    </div>
  </div>
</form>

<div class="flex lg:max-w-screen-xl mx-auto flex-wrap">
  <div class="flex w-full flex-wrap lg:shadow lg:rounded-lg">
    <div class="w-full lg:w-3/5 xl:w-1/2">
      <div class="align-middle inline-block min-w-full">
        <div class="overflow-hidden border-b border-gray-200 sm:rounded-lg sm:rounded-r-none">
          <table class="table-fixed min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
            <tr>
              <th scope="col"
                  class="hidden lg:table-cell px-6 py-3 w-2/5  text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {{ 'availability.stocktable.name' | transloco}}
              </th>
              <th scope="col"
                  class="hidden lg:table-cell px-6 py-3 w-1/5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {{ 'availability.stocktable.code' | transloco}}
              </th>
              <th scope="col"
                  class="hidden lg:table-cell px-6 py-3 w-1/5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {{ 'availability.stocktable.availabilityType' | transloco }}
              </th>
              <th scope="col"
                  class="hidden lg:table-cell px-6 py-3 w-1/5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {{ 'availability.stocktable.plantsPerTray' | transloco}}
              </th>
              <th scope="col"
                  class="hidden px-6 py-3 w-1/5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {{ 'availability.stocktable.plantsPerTray' | transloco}}
              </th>
            </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
            <tr *ngFor="let availability of availabilities">
              <td class="px-6 py-4 whitespace-nowrap">
                <div class="flex items-center">
                  <div #btn class="flex-shrink-0 h-10 w-10 relative" (click)="confirmPopup.confirm(availability, btn)">
                    <div class="relative w-12 h-12">
                      <img class="h-10 w-10 cursor-pointer rounded-full"
                           src="https://d2r5uf6odu3th4.cloudfront.net/{{ availability.getPartOfImagePath() }}/public/{{availability.raescode}}.jpg"
                           alt="">
                      <div class="absolute bottom-0 right-0 h-5 w-5 rounded-full text-gold-drop-600 bg-white z-2">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 p-1" viewBox="0 0 25 25"
                             fill="currentColor">
                          <path
                            d="M5 8a1 1 0 011-1h1V6a1 1 0 012 0v1h1a1 1 0 110 2H9v1a1 1 0 11-2 0V9H6a1 1 0 01-1-1z"/>
                          <path fill-rule="evenodd"
                                d="M2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8zm6-4a4 4 0 100 8 4 4 0 000-8z"
                                clip-rule="evenodd"/>
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="!availability.noProductAvailable"
                       [routerLink]="plantDetail(availability.plantCode)"
                       class="ml-4 w-48 text-sm font-medium text-gray-900 lg:overflow-hidden whitespace-nowrap lg:overflow-ellipsis underline cursor-pointer has-tooltip">
                    <span
                      class='hidden lg:block tooltip rounded shadow-lg p-1 bg-gray-100 text-gold-drop-600 -mt-8'>{{ availability.productName }}</span>
                    {{ availability.productName }}
                  </div>
                  <div *ngIf="availability.noProductAvailable"
                       class="ml-4 w-48 text-sm font-medium text-gray-900 lg:overflow-hidden whitespace-nowrap lg:overflow-ellipsis has-tooltip">
                    <span
                      class='hidden lg:block tooltip rounded shadow-lg p-1 bg-gray-100 text-gold-drop-600 -mt-8'>{{ availability.productName }}</span>
                    {{ availability.productName }}
                  </div>
                </div>
              </td>
              <td class="hidden lg:table-cell px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {{ availability.raescode }}
              </td>
              <td class="hidden lg:table-cell px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {{ 'enum.availabilityType.' + availability.availabilityType | transloco }}
              </td>
              <td class="hidden lg:table-cell px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {{ availability.plantsPerTray }}
              </td>
              <td class="lg:hidden px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                <a class="hover:cursor-pointer"
                   *ngIf="availability.getAvailableAmountForWeek(this.form.controls['selectedWeek'].value)"
                   (click)="initStockOrder(availability, this.form.controls['selectedWeek'].value)">
                  <div class="group">
                      <span
                        class="inline-flex rounded-full items-center py-0.5 pl-2.5 pr-1 text-sm font-medium text-red-600">
                  {{ availability.getAvailableAmountForWeek(this.form.controls['selectedWeek'].value) }}
                        <button *ngIf="isAuthenticated" type="button"
                                class="flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center text-red-600 hover:bg-gold-drop-50 hover:text-my-sin-400 focus:outline-none focus:bg-indigo-500 focus:text-white">
                    <span class="sr-only">beschikbaarheid aantal</span>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                    </svg>
                  </button>
                         </span>
                  </div>
                </a>
              </td>
            </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="hidden lg:block w-2/5 xl:w-1/2">
      <div class="flex flex-col">
        <div class="relative align-middle inline-block min-w-full">
          <div #scrollableTable class="overflow-hidden border-b border-gray-200 sm:rounded-lg sm:rounded-l-none">
            <table class="table-fixed min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
              <tr>
                <th *ngFor="let col of scrollableCols"
                    [ngClass]="{ 'text-gray-50 bg-christi-500': isCurrentWeek(col.field), 'text-gray-500': !isCurrentWeek(col.field) }"
                    class="w-24 py-3 whitespace-nowrap text-center text-left text-xs font-medium uppercase tracking-wider"
                    style="min-width: 6rem">
                  {{ col.header }}
                </th>
              </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
              <tr *ngFor="let availability of availabilities">
                <td *ngFor="let week of scrollableCols"
                    [ngClass]="{ 'text-gray-50 bg-christi-500': isCurrentWeek(week.field), 'text-gray-500': !isCurrentWeek(week.field) }"
                    class="w-24 py-4 text-center leading-10 whitespace-nowrap text-sm hover:bg-my-sin-500 cursor-pointer" style="min-width: 6rem">
                  <a *ngIf="availability.getAvailableAmountForWeek(week.field)"
                     (click)="initStockOrder(availability, week.field)">
                    <div class="group">
                      <span
                        class="inline-flex rounded-full items-center py-0.5 pl-2.5 pr-1 text-sm font-medium text-red-600">
                          {{ availability.getAvailableAmountForWeek(week.field) }}
                        <button *ngIf="isAuthenticated" type="button"
                                class="flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center text-red-600 hover:bg-gold-drop-50 hover:text-my-sin-400 focus:outline-none focus:bg-indigo-500 focus:text-white">
                            <span class="sr-only">beschikbaarheid aantal</span>
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                          </svg>
                          </button>
                      </span>

                    </div>
                  </a>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <button (click)="scrollLeft()" *ngIf="currentScrollPosition > 0"
                  class="absolute top-0 left-0 bg-gray-200 py-2.5 whitespace-nowrap text-left text-xs font-medium uppercase tracking-wider">
            <span class="sr-only">{{'products.paging.previous' | transloco }}</span>
            <!-- Heroicon name: solid/chevron-left -->
            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                 aria-hidden="true">
              <path fill-rule="evenodd"
                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                    clip-rule="evenodd"/>
            </svg>
          </button>
          <button (click)="scrollRight()" *ngIf="currentScrollPosition < maxScrollSize"
                  class="absolute top-0 right-0 bg-gray-200 py-2.5 whitespace-nowrap text-left text-xs font-medium uppercase tracking-wider">
            <span class="sr-only">{{'products.paging.next' | transloco }}</span>
            <!-- Heroicon name: solid/chevron-right -->
            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                 aria-hidden="true">
              <path fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="w-full p-3 flex items-center justify-between">
    <div class="flex-1 flex justify-between sm:hidden">
      <button (click)="paginate({ page: page.pageNumber - 1, rows: page.pageSize})" [disabled]="page.first"
              [ngClass]="{ 'border-gray-100 text-gray-300 cursor-default': page.first, 'border-gray-300 text-gray-700 hover:bg-gray-50': !page.first }"
              class="relative inline-flex items-center px-4 py-2 border text-sm font-medium rounded-md bg-white">
        {{'products.paging.previous' | transloco }}
      </button>
      <button (click)="paginate({ page: page.pageNumber + 1, rows: page.pageSize})" [disabled]="page.last"
              [ngClass]="{ 'border-gray-100 text-gray-300 cursor-default': page.last, 'border-gray-300 text-gray-700 hover:bg-gray-50': !page.last }"
              class="ml-3 relative inline-flex items-center px-4 py-2 border text-sm font-medium rounded-md bg-white ">
        {{'products.paging.next' | transloco }}
      </button>
    </div>
    <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
      <div>
        <p class="text-sm text-gray-700">
          {{'products.paging.showing' | transloco }}
          <span class="font-medium">{{page.pageNumber * page.pageSize}}</span>
          {{'products.paging.to' | transloco }}
          <span class="font-medium">{{(page.pageNumber * page.pageSize) + page.numberOfElementsInPage}}</span>
          {{'products.paging.of' | transloco }}
          <span class="font-medium">{{page.totalElements}}</span>
          {{'products.paging.results' | transloco }}
        </p>
      </div>
      <div>
        <nav class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
          <button (click)="paginate({ page: page.pageNumber - 1, rows: page.pageSize})" [disabled]="page.first"
                  [ngClass]="{ 'border-gray-200 text-gray-300 cursor-default': page.first, 'border-gray-300 text-gray-500 hover:bg-gray-50': !page.first }"
                  class="relative inline-flex items-center px-2 py-2 rounded-l-md border bg-white text-sm font-medium">
            <span class="sr-only">{{'products.paging.previous' | transloco }}</span>
            <!-- Heroicon name: solid/chevron-left -->
            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                 aria-hidden="true">
              <path fill-rule="evenodd"
                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                    clip-rule="evenodd"/>
            </svg>
          </button>
          <ng-template ngFor let-element [ngForOf]="page.pageableElements()">
            <!-- todo Ruben: Temporary fix. The -1 is needed because we use paginate() to navigate to the next page etc. -->
            <button (click)="paginate({ page: element - 1, rows: page.pageSize })"
                    *ngIf="element !== '...'"
                    [ngClass]="{ 'z-10 bg-christi-50 border-christi-600 text-christi-700': element === page.pageNumber + 1, 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50': element !== page.pageNumber + 1  }"
                    class="relative inline-flex items-center px-4 py-2 border text-sm font-medium"
            >
              {{ element }}
            </button>

            <span *ngIf="element === '...'"
                  class="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
                      ...
                    </span>
          </ng-template>


          <button (click)="paginate({ page: page.pageNumber + 1, rows: page.pageSize})" [disabled]="page.last"
                  [ngClass]="{ 'border-gray-200 text-gray-300 cursor-default': page.last, 'border-gray-300 text-gray-500 hover:bg-gray-50': !page.last }"
                  class="relative inline-flex items-center px-2 py-2 rounded-r-md border bg-white text-sm font-medium">
            <span class="sr-only">{{'products.paging.next' | transloco }}</span>
            <!-- Heroicon name: solid/chevron-right -->
            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                 aria-hidden="true">
              <path fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"/>
            </svg>
          </button>
        </nav>
      </div>
    </div>
  </div>
</div>

<p-dialog header="Edit Profile" [modal]="true" [(visible)]="visible" [style]="{ width: '25rem' }">
  <span class="p-text-secondary block mb-5">Update your information.</span>
  <div class="flex align-items-center gap-3 mb-3">
    <label for="username" class="font-semibold w-6rem">Username</label>
    <input pInputText id="username" class="flex-auto" autocomplete="off" />
  </div>
  <div class="flex align-items-center gap-3 mb-5">
    <label for="email" class="font-semibold w-6rem">Email</label>
    <input pInputText id="email" class="flex-auto" autocomplete="off" />
  </div>
  <div class="flex justify-content-end gap-2">
    <p-button label="Cancel" severity="secondary" (click)="visible = false" />
    <p-button label="Save" (click)="visible = false" />
  </div>
</p-dialog>

<app-product-image-popup #confirmPopup></app-product-image-popup>
