import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VersionService {
  getVersionInfo() {
    return {
      version: environment.version,
      gitHash: environment.gitHash
    };
  }
}
