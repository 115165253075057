<main *ngIf="newsItem?.newsType === 'ARTICLE'"  class="lg:relative z-0">
  <div class="mx-auto max-w-7xl w-full pt-16 pb-20 text-center lg:py-48 lg:text-left">
    <div class="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
      <h1 class="text-xl tracking-tight font-extrabold text-gray-900 sm:text-xl xl:text-2xl">
        <span class="block xl:inline">{{newsItem?.getTitle(this.translateService.getActiveLang())}}</span>
      </h1>
      <ng-container *ngIf="newsItem?.introNl">
        <p class="pl-2 text-sm list-disc list-inside" [innerHTML]="newsItem?.getIntro(this.translateService.getActiveLang())"></p>
      </ng-container>
      <ng-container *ngIf="newsItem?.contentNl">
        <p class="pl-2 mt-5 text-sm list-disc list-inside" [innerHTML]="newsItem?.getContent(this.translateService.getActiveLang())"></p>
      </ng-container>
      <div class="mt-10 sm:flex sm:justify-center lg:justify-start">
        <div class="rounded-md shadow">
          <a [routerLink]="appRoutesLinks.NEWS_PAGE" class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10">
            {{'cta.gotoNews' | transloco }}
          </a>
        </div>
        <div class="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
          <a [routerLink]="appRoutesLinks.CONTACT_PAGE"  class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-50 md:py-4 md:text-lg md:px-10">
            {{'cta.gotoContact' | transloco }}
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
    <img class="absolute inset-0 w-full h-full object-cover" src="{{newsItem?.imageUrl}}" alt="">
  </div>
</main>

<div *ngIf="newsItem?.newsType === 'ARTICLE'" class="min-w-screen min-h-screen bg-my-sin-700 flex items-center p-5 lg:p-10 overflow-hidden relative">
  <div class="w-full max-w-6xl rounded bg-white shadow-xl p-10 lg:p-20 mx-auto text-gray-800 relative md:text-left">
    <div class="md:flex items-center -mx-10">
      <div class="w-full md:w-1/2 px-10 mb-10 md:mb-0">
        <div class="relative">
          <img src="{{newsItem?.highlightedImageUrl}}" class="w-full relative z-10" alt="">
        </div>
      </div>
      <div class="w-full md:w-1/2 px-10">
        <div class="mb-10">
          <h1 class="font-bold uppercase text-2xl mb-5">{{ newsItem?.getSubTitle(this.translateService.getActiveLang()) }}</h1>
          <ng-container *ngIf="newsItem?.extraContentNl">
            <p class="pl-2 mt-5 text-sm" [innerHTML]="newsItem?.getExtraContent(this.translateService.getActiveLang())"></p>
          </ng-container>
          <ng-container *ngIf="newsItem?.documentUrl">
            <a class="hover:text-my-sin-400" href="{{newsItem?.documentUrl}}">{{'news.relatedDocument' | transloco }} <span class="ml-2 underline hover:text-my-sin-400">{{newsItem?.getTitle(this.translateService.getActiveLang())}}</span></a>
          </ng-container>
        </div>
        <div>
          <div class="inline-block align-bottom">
            <button [routerLink]="appRoutesLinks.CONTACT_PAGE" class="bg-yellow-300 opacity-75 hover:opacity-100 text-yellow-900 hover:text-gray-900 rounded-full px-10 py-2 font-semibold"> {{'cta.askRelatedQuestion' | transloco }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="newsItem?.newsType === 'PDF'" class="min-w-screen min-h-screen bg-my-sin-700 flex items-center p-5 lg:p-10 overflow-hidden relative">
  <div class="w-full max-w-6xl rounded bg-white shadow-xl p-10 lg:p-20 mx-auto text-gray-800 relative md:text-left">
    <div class="grid place-items-center">
      <h1>
        <span class="mt-1 block text-4xl tracking-tight font-extrabold sm:text-5xl xl:text-6xl">{{newsItem?.getTitle(this.translateService.getActiveLang())}}</span>
      </h1>
      <p class="my-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl" [innerHTML]="newsItem?.getIntro(this.translateService.getActiveLang())"></p>
    </div>
  </div>
</div>

<div *ngIf="newsItem?.newsType === 'VIDEO'" class="min-w-screen min-h-screen bg-my-sin-700 flex items-center p-5 lg:p-10 overflow-hidden relative">
  <div class="w-full max-w-6xl rounded bg-white shadow-xl p-10 lg:p-20 mx-auto text-gray-800 relative md:text-left">
    <div class="grid place-items-center">
      <h1>
        <span class="mt-1 block text-4xl tracking-tight font-extrabold sm:text-5xl xl:text-6xl">{{newsItem?.getTitle(this.translateService.getActiveLang())}}</span>
      </h1>
      <p class="my-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl" [innerHTML]="newsItem?.getIntro(this.translateService.getActiveLang())"></p>
    </div>
    <div class="mt-5 relative mx-auto w-full rounded-lg shadow-lg lg:max-w-md">
      <div class="embed-container rounded-b-md" data-page-width="453" data-page-height="640" id="yp2dzembedcontainer">

      </div>
    </div>
  </div>
</div>

<div *ngIf="newsItem?.newsType === 'CATALOG'" class="min-w-screen min-h-screen bg-my-sin-700 flex items-center p-5 lg:p-10 overflow-hidden relative">

  <div class="w-full max-w-6xl rounded bg-white shadow-xl p-10 lg:p-20 mx-auto text-gray-800 relative md:text-left">
    <div class="flex justify-center items-center">
      <h1>{{newsItem?.getTitle(this.translateService.getActiveLang())}}</h1>
    </div>
    <div class="embed-container rounded-b-md" data-page-width="453" data-page-height="640" id="yp1embedcontainer">

    </div>
  </div>
</div>

<main *ngIf="newsItem?.newsType === 'YOUTUBE'" class="mt-4 mx-auto max-w-7xl px-4 sm:mt-6 sm:px-12 lg:mt-24">
  <div class="lg:grid lg:grid-cols-12 lg:gap-8">
    <div class="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
      <h1>
        <span class="mt-1 block text-4xl tracking-tight font-extrabold sm:text-5xl xl:text-6xl">{{newsItem?.getTitle(this.translateService.getActiveLang())}}</span>
      </h1>
      <p class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl" [innerHTML]="newsItem?.getIntro(this.translateService.getActiveLang())"></p>
      <div class="mt-8 sm:max-w-lg sm:mx-auto sm:text-center lg:text-left lg:mx-0">
        <p class="text-base font-medium text-gray-900" [innerHTML]="newsItem?.getContent(this.translateService.getActiveLang())"></p>
      </div>
    </div>
    <div class="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
      <div class="relative mx-auto w-full rounded-lg shadow-lg lg:max-w-md">
        <div class="embed-container rounded-b-md" data-page-width="453" data-page-height="640" id="yp2zembedcontainer">

        </div>
      </div>
    </div>
  </div>
</main>

<main *ngIf="newsItem?.newsType === 'CASESTUDY'" class="mt-4 mx-auto max-w-7xl px-4 sm:mt-6 sm:px-12 lg:mt-24">
  <div class="py-16 bg-gray-50 overflow-hidden lg:py-24">
    <div class="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
      <svg class="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4" width="404" height="784" fill="none" viewBox="0 0 404 784" aria-hidden="true">
        <defs>
          <pattern id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
            <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
          </pattern>
        </defs>
        <rect width="404" height="784" fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
      </svg>

      <div class="relative">
        <h2 class="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">{{newsItem?.getTitle(this.translateService.getActiveLang())}}</h2>
        <p class="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500" [innerHTML]="newsItem?.getIntro(this.translateService.getActiveLang())"></p>
      </div>

      <div class="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
        <div class="relative">
          <h3 class="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">{{newsItem?.getSubTitle(this.translateService.getActiveLang())}}</h3>
          <p class="mt-3 text-lg text-gray-500" [innerHTML]="newsItem?.getContent(this.translateService.getActiveLang())"></p>
        </div>

        <div class="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
          <svg class="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden" width="784" height="404" fill="none" viewBox="0 0 784 404">
            <defs>
              <pattern id="ca9667ae-9f92-4be7-abcb-9e3d727f2941" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width="784" height="404" fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)" />
          </svg>
          <img class="relative mx-auto" width="490" src="{{newsItem?.highlightedImageUrl}}" alt="">
          <div class="relative mx-auto w-full rounded-lg shadow-lg lg:max-w-md">
            <div class="embed-container rounded-b-md" data-page-width="453" data-page-height="640" id="yp24zembedcontainer">

            </div>
          </div>
        </div>
      </div>

      <svg class="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12" width="404" height="784" fill="none" viewBox="0 0 404 784" aria-hidden="true">
        <defs>
          <pattern id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
            <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
          </pattern>
        </defs>
        <rect width="404" height="784" fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
      </svg>

      <div class="relative mt-12 sm:mt-16 lg:mt-24">
        <div class="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div class="lg:col-start-2">
            <h3 class="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">{{newsItem?.getSubTitle(this.translateService.getActiveLang())}}</h3>
            <p class="mt-3 text-lg text-gray-500" [innerHTML]="newsItem?.getContent(this.translateService.getActiveLang())"></p>
          </div>

          <div class="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
            <svg class="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden" width="784" height="404" fill="none" viewBox="0 0 784 404" aria-hidden="true">
              <defs>
                <pattern id="e80155a9-dfde-425a-b5ea-1f6fadd20131" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="784" height="404" fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)" />
            </svg>
            <img class="relative mx-auto" width="490" src="{{newsItem?.imageUrl}}" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
