import {NewsType} from './enum/newsType';

export class News {
  constructor(public id: number = 0,
              public titleNl: string = '',
              public titleFr: string = '',
              public titleEn: string = '',
              public subitleNl: string = '',
              public subitleFr: string = '',
              public subitleEn: string = '',
              public introNl: string = '',
              public introFr: string = '',
              public introEn: string = '',
              public author: string = '',
              public authorImageUrl: string = '',
              public contentNl: string = '',
              public contentFr: string = '',
              public contentEn: string = '',
              public extraContentNl: string = '',
              public extraContentFr: string = '',
              public extraContentEn: string = '',
              public imageUrl: string = '',
              public documentUrl: string = '',
              public highlightedImageUrl: string = '',
              public datePublished: Date,
              public newsType: NewsType = NewsType.ARTICLE,
              public issuuCode: string = '',
              public youtubeCode: string = '',
              public displayOrder: number = 9999) {}

  static adapt(item: any): News {
    return new News(
      item.id,
      item.titleNl,
      item.titleFr,
      item.titleEn,
      item.subitleNl,
      item.subitleFr,
      item.subitleEn,
      item.introNl,
      item.introFr,
      item.introEn,
      item.author,
      item.authorImageUrl,
      item.contentNl,
      item.contentFr,
      item.contentEn,
      item.extraContentNl,
      item.extraContentFr,
      item.extraContentEn,
      item.imageUrl,
      item.documentUrl,
      item.highlightedImageUrl,
      item.datePublished,
      item.newsType,
      item.issuuCode,
      item.youtubeCode,
      item.displayOrder
    );
  }

  getTitle(language: string): string {
    if (language === null || language === undefined) {
      return this.titleNl;
    }

    switch (language) {
      default:
      case 'nl':
        return this.titleNl;
      case 'fr':
        return this.titleFr;
      case 'en':
        return this.titleEn;
    }
  }

  getSubTitle(language: string): string {
    if (language === null || language === undefined) {
      return this.subitleNl;
    }

    switch (language) {
      default:
      case 'nl':
        return this.subitleNl;
      case 'fr':
        return this.subitleFr;
      case 'en':
        return this.subitleEn;
    }
  }

  getIntro(language: string): string {
    if (language === null || language === undefined) {
      return this.introNl;
    }

    switch (language) {
      default:
      case 'nl':
        return this.introNl;
      case 'fr':
        return this.introFr;
      case 'en':
        return this.introEn;
    }
  }
  getContent(language: string): string {
    if (language === null || language === undefined) {
      return this.contentNl;
    }

    switch (language) {
      default:
      case 'nl':
        return this.contentNl;
      case 'fr':
        return this.contentFr;
      case 'en':
        return this.contentEn;
    }
  }
  getExtraContent(language: string): string {
    if (language === null || language === undefined) {
      return this.extraContentNl;
    }

    switch (language) {
      default:
      case 'nl':
        return this.extraContentNl;
      case 'fr':
        return this.extraContentFr;
      case 'en':
        return this.extraContentEn;
    }
  }

  getYoutubePath(): string {
    return 'https://www.youtube.com/embed/' + this.youtubeCode;
  }

  getIssuuPath(): string {
    return 'https://e.issuu.com/embed.html?d=' + this.issuuCode + '&u=yrr81';
  }

  getVideo(language: string): string {
    if (language === null || language === undefined) {
      return this.documentUrl;
    }

    switch (language) {
      default:
      case 'nl':
        return this.documentUrl + '_NL.mp4';
      case 'fr':
        return this.documentUrl + '_FR.mp4';
      case 'en':
        return this.documentUrl + '_EN.mp4';
    }
  }
}
