import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {NgClass, NgForOf, NgIf, SlicePipe} from "@angular/common";
import {FlowerMonth} from "../../../../shared/model/enum/flowerMonth";
import {TranslocoPipe, TranslocoService} from "@jsverse/transloco";
import {Month} from "../../../../shared/model/enum/month";

@Component({
  selector: 'app-plant-periods',
  standalone: true,
  imports: [
    NgClass,
    SlicePipe,
    NgIf,
    NgForOf,
    TranslocoPipe
  ],
  templateUrl: './plant-periods.component.html',
  styles: ``
})
export class PlantPeriodsComponent implements OnInit, OnChanges {
  @Input() from: FlowerMonth = FlowerMonth.JANUARY;
  @Input() fromSecond: FlowerMonth =  FlowerMonth.JANUARY;
  @Input() till: FlowerMonth =  FlowerMonth.JANUARY;
  @Input() tillSecond: FlowerMonth =  FlowerMonth.JANUARY;
  @Input() label: string | undefined;
  @Input() showMonths: any;

  constructor(public translateService: TranslocoService) {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['from'] || changes['till'] || changes['fromSecond'] || changes['tillSecond']) {
      this.processInputs();
    }
  }

  ngOnInit() {
    this.processInputs();
  }

  private processInputs() {

    // Convert string month names to numbers
    if (typeof this.from === 'string') {
      this.from = this.convertMonthToNumber(this.from);
    }
    if (typeof this.till === 'string') {
      this.till = this.convertMonthToNumber(this.till);
    }
    if (typeof this.fromSecond === 'string' && this.fromSecond !== '') {
      this.fromSecond = this.convertMonthToNumber(this.fromSecond);
    }
    if (typeof this.tillSecond === 'string' && this.tillSecond !== '') {
      this.tillSecond = this.convertMonthToNumber(this.tillSecond);
    }

  }

  private convertMonthToNumber(monthName: string): number {
    // Convert month name to number (1-12)
    const monthIndex = Object.keys(Month).indexOf(monthName);
    const result = monthIndex + 1; // Add 1 because array index starts at 0 but we want 1-12
    return result;
  }

  dataAvailable(): boolean {
    const available = !(this.from === undefined || this.from === null ||
      this.till === undefined || this.till === null);
    return available;
  }

  dataForSecondPeriodAvailable(): boolean {
    const available = !(this.fromSecond === undefined || this.fromSecond === null ||
      this.tillSecond === undefined || this.tillSecond === null);
    return available;
  }

  calculateClasses(month: number): string {
    if (!this.dataAvailable()) {
      return 'bg-forrest-green-400';
    }

    // First period
    if (this.from != null && this.till != null) {
      if (this.isMonthInPeriod(month, this.from, this.till)) {
        return 'bg-gold-drop-800';
      }
    }

    // Second period
    if (this.fromSecond != null && this.tillSecond != null) {
      if (this.isMonthInPeriod(month, this.fromSecond, this.tillSecond)) {
        return 'bg-gold-drop-800';
      }
    }

    return 'bg-forrest-green-400';
  }

  private isMonthInPeriod(month: number, fromMonth: number, tillMonth: number): boolean {
    // Normal period (e.g., March to June)
    if (fromMonth <= tillMonth) {
      return month >= fromMonth && month <= tillMonth;
    }
    // Period spanning year end (e.g., November to February)
    else {
      return month >= fromMonth || month <= tillMonth;
    }
  }
}
