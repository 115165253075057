import {Component, ElementRef, EventEmitter, Input, Output} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {NgIf} from "@angular/common";
import {PlantFiltersComponent} from "../plant-filters/plant-filters.component";

@Component({
  selector: 'app-plant-slide-over-filters',
  standalone: true,
  imports: [
    NgIf,
    PlantFiltersComponent
  ],
  templateUrl: './plant-slide-over-filters.component.html',
  styles: ``
})
export class PlantSlideOverFiltersComponent {
  @Input() form: FormGroup = new FormGroup({})
  @Input() filtersOpen: boolean | undefined;

  @Output() closing = new EventEmitter<boolean>();
  constructor(private eRef: ElementRef) { }

  closeFilters(): void {
    this.closing.emit(true);
  }
}
