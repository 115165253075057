export enum AvailabilityType {
  SEEDLINGS = 'SEEDLINGS',
  CUTTINGS = 'CUTTINGS',
  TISSUECULTURE = 'TISSUECULTURE'
}

export namespace AvailabilityType {
  export function map(value: any): AvailabilityType | null {
    if (value === null || value === undefined) {
      return null;
    }

    switch (value) {
      case 'SEEDLINGS':
        return AvailabilityType.SEEDLINGS;
      case 'CUTTINGS':
        return AvailabilityType.CUTTINGS;
      case 'TISSUECULTURE':
        return AvailabilityType.TISSUECULTURE;
      default:
        return null;
    }
  }
}
