import {Component} from '@angular/core';
import {RouterLink} from "@angular/router";
import {appRoutesLinks} from "../../../app.routes.links";
import {TooltipModule} from "primeng/tooltip";
import {TranslocoPipe} from "@jsverse/transloco";
import {CtaButtonComponent} from "../../../shared/components/cta-button/cta-button.component";

@Component({
  selector: 'app-landing-categories',
  standalone: true,
  imports: [
    RouterLink,
    TooltipModule,
    TranslocoPipe,
    CtaButtonComponent
  ],
  templateUrl: './landing-categories.component.html',
  styleUrls: ['./landing-categories.component.scss']
})
export class LandingCategoriesComponent {

  protected readonly appRoutesLinks = appRoutesLinks;
}
