<section class="text-green-900 relative z-0">
  <div class="hero-image bg-right-bottom bg-cover flex sm:bg-none" style="background-image: url(https://d3coqd91sswgmf.cloudfront.net/header/availability_header.jpg);">
    <div class="relative container justify-center md:justify-start md:pl-5 flex items-end z-10">
      <div class="content float-left justify-center md:justify-start py-4 px-5 my-5 bg-christi-700 rounded-md bg-opacity-90">
        <h1 class="heading text-gray-100 mb-3 text-2xl">{{ 'availability.title' | transloco }}</h1>
        <h2 class="text text-base text-gray-300 leading-normal hidden sm:block">{{ 'availability.subtitle' | transloco }}</h2>
      </div>
    </div>
  </div>
</section>

<section>
  <div class="bg-sapphire-900 text-center py-4 lg:px-4">
    <div class="p-2 bg-sapphire-800 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex"
         role="alert">
      <span class="flex rounded-full bg-indigo-500 uppercase px-2 py-1 text-xs font-bold mr-3">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"/>
        </svg>
      </span>
      <span class="font-semibold mr-2 text-left flex-auto">{{ 'availability.options' | transloco }}</span>
      <svg class="fill-current opacity-75 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z"/>
      </svg>
    </div>
  </div>
</section>

<section>
  <div class="m-5 flex flex-row flex-wrap content-center justify-around">
    <p class="underline text-lg font-bold"><a [href]="activeAvailabilityUpload?.file">
      {{'availability.download' | transloco }} ({{activeAvailabilityUpload?.dateUploaded }})</a>
    </p>
  </div>
</section>

<app-availability-list></app-availability-list>

<section class="m-5 flex flex-row flex-wrap content-center justify-around">
  <app-cta-button buttonText="{{'cta.gotoOrder' | transloco }}" [link]="orderLink"></app-cta-button>
</section>
