export class Orderform {
  constructor(public id: number = 0,
              public titleNl: string = '',
              public titleFr: string = '',
              public titleDe: string = '',
              public titleEn: string = '',
              public country: string = '',
              public productType: string = '',
              public season: string = '',
              public url: string = '',
              public displayOrder: number = 9999) {}

  static adapt(item: any): Orderform {
    return new Orderform(
      item.id,
      item.titleNl,
      item.titleFr,
      item.titleDe,
      item.titleEn,
      item.country,
      item.productType,
      item.season,
      item.url,
      item.displayOrder
    );
  }
}
