import {Component, OnInit} from '@angular/core';
import {ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {Observable, of, Subscription} from "rxjs";
import {SeoService} from "../../../core/util/seo.service";
import {OrderFormService} from "../../../core/repository/orderForm.service";
import {appRoutesLinks} from "../../../app.routes.links";
import {AsyncPipe, CommonModule, NgIf} from "@angular/common";
import {OrderFormListComponent} from "../orderFormList/orderFormList.component";
import {OrderFormFilterPipe} from "../../../shared/pipes/orderForm-filter.pipe";
import {Router, RouterModule} from "@angular/router";
import {map} from 'rxjs/operators';
import {TranslocoPipe, TranslocoService} from "@jsverse/transloco";
import {Orderform} from "../../../shared/model/orderform";
import {Meta, Title} from "@angular/platform-browser";

export function orderFormFilter2(countries: string, season: string) {
  return (source: Observable<Orderform[]>): Observable<Orderform[]> => {
    return source.pipe(
      map(orderForms => {
        if (!orderForms || !countries || !season) {
          return orderForms;
        }

        const countryList: string[] = countries.split('|');

        return orderForms.filter(orderForm => countryList.includes(orderForm.country) && orderForm.season === season);
      })
    );
  };
}

@Component({
  selector: 'app-orderForms',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgIf,
    AsyncPipe,
    OrderFormFilterPipe,
    OrderFormListComponent,
    CommonModule,
    RouterModule,
    TranslocoPipe
  ],
  templateUrl: './orderForms.component.html'
})
export class OrderFormsComponent implements OnInit {
  form: UntypedFormGroup | undefined;

  orderForms$: Observable<Orderform[]> = of([]);
  contactLink = appRoutesLinks.CONTACT_PAGE;

  constructor(
    private orderFormsService: OrderFormService,
    private formBuilder: UntypedFormBuilder,
    public translateService: TranslocoService,
    private titleService: Title,
    private translocoService: TranslocoService,
    private metaService: Meta
  ) {
    let lang: string = this.translocoService.getActiveLang();

    let title: string;
    let description: string;
    let keywords: string;
    let translationSubscription: Subscription;

    // Use selectTranslate for the first department option
    translationSubscription = this.translocoService.selectTranslate('orders.title').subscribe(value => {
      title = value;
      this.titleService.setTitle(title);
    });
    translationSubscription = this.translocoService.selectTranslate('orders.description').subscribe(value => {
      description = value;
      this.metaService.updateTag({name: 'description', content: description});
    });
    translationSubscription = this.translocoService.selectTranslate('orders.keywords').subscribe(value => {
      keywords = value;
      if (typeof keywords !== 'undefined') {
        this.metaService.updateTag({name: 'keywords', content: keywords});
      }
    });
    translationSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.createForm();
    this.orderForms$ = this.orderFormsService.getActive();
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      selectedTab: ['belgium'],
    });
  }

  toggle(tab: string): void {
    if (this.form) {
      this.form.patchValue({
        selectedTab: tab,
      });
    }
  }

  isActive(tab: string): boolean {
    return this.form ? tab === this.form.controls['selectedTab'].value : false;
  }

}
