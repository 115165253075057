<div [routerLink]="appRoutesLinks.NEWS_DETAIL_PAGE(newsItem?.id)" class="cursor-pointer">
  <img class="h-48 w-full object-cover" src="{{newsItem?.highlightedImageUrl}}" alt="">
</div>
<div class="flex-1 bg-white p-6 flex flex-col justify-between">
  <div class="flex-1">
    <p class="text-sm font-medium text-indigo-600">
      <a [routerLink]="appRoutesLinks.NEWS_DETAIL_PAGE(newsItem?.id)" class="hover:underline capitalize">
        {{ newsItem?.getTitle(this.translocoService.getActiveLang()) }}
      </a>
    </p>
    <a [routerLink]="appRoutesLinks.NEWS_DETAIL_PAGE(newsItem?.id)" class="block mt-2">
      <p class="text-base text-gray-900">
        {{ newsItem?.getIntro(this.translocoService.getActiveLang()) }}
      </p>
    </a>
  </div>
</div>
