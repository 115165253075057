<app-input
  [label]="label"
  [labelFor]="'input_' + id"
  [control]="control">
  <select [id]="'input' + id" [name]="'input_' + id" [formControl]="control" [class.is-invalid]="isInvalid" [class.is-valid]="isValid"
          [ngClass]="{'border' : control.dirty && !control.valid,  'border-red-500' : this.control.dirty && !this.control.valid}"
          class="block pb-3 pt-4 mt-1 pl-3 pr-10 w-full focus:ring-christi-500 focus:border-christi-500 shadow-sm sm:text-sm border-gray-300 rounded-md">
    <ng-template ngFor let-option [ngForOf]="options">
      <option [value]="option.value">{{ option.label }}</option>
    </ng-template>
  </select>
</app-input>

