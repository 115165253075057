<main class="py-8">
  <div class="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
    <h1 class="">{{'products.overview.grid.products' | transloco }}</h1>
    <!-- Main 3 column grid -->
    <div class="grid grid-cols-1 gap-4 items-start lg:grid-cols-4 lg:gap-8">
      <!-- Left column -->
      <div class="hidden lg:block grid grid-cols-1 gap-4">
        <section aria-labelledby="section-2-title">
          <h2 class="sr-only" id="section-2-title">{{'products.overview.grid.products' | transloco}}</h2>
          <div class="overflow-hidden">
            <div class="py-6 px-2">
              <app-plant-filters [form]="form" (reset)="resetSearch()"></app-plant-filters>
            </div>
          </div>
        </section>
      </div>

      <!-- Right column -->
      <div class="grid grid-cols-1 gap-4 lg:col-span-3">
        <section aria-labelledby="section-1-title">
          <div class="overflow-hidden">
            <form [formGroup]="form" class="flex lg:py-2 sm:justify-end space-x-2.5 sm:space-x-4 lg:space-x-8">
              <button id="openSlider" (click)="toggleFilters()" class="lg:hidden py-2 px-3 text-base bg-christi-700 text-gray-100 border-christi-700 focus:outline-none focus:ring-christi-600 focus:border-christi-600 sm:text-sm rounded-md">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z" clip-rule="evenodd" />
                </svg>
              </button>

              <select id="size" name="size" #size (change)="paginate({ page: page.pageNumber, rows: size.value })" class="block pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-christi-600 focus:border-christi-600 sm:text-sm rounded-md">
                <ng-template ngFor let-size [ngForOf]="pageSizeOptions">
                  <option [value]="size" [selected]="page.pageSize === size">{{ size }}</option>
                </ng-template>
              </select>
              <select id="sorting" name="sorting" formControlName="sortAlias" class="block pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-christi-600 focus:border-christi-600 sm:text-sm rounded-md">
                <ng-template ngFor let-option [ngForOf]="sortingOptions">
                  <option [value]="option.value">{{ option.label }}</option>
                </ng-template>
              </select>
            </form>
          </div>

          <div *ngIf="getActiveFilterTypes().length > 0" class="flex items-center justify-between bg-amber-500 p-2 rounded-3xl mt-4">
            <div class="flex flex-wrap gap-2">
              <ng-container *transloco="let t; prefix: 'products.filters'">
                <p-chip *ngFor="let filterType of getActiveFilterTypes()"
                        [label]="t(filterType)"
                        [removable]="true" class="text-christi-900"
                        (onRemove)="resetFilter(filterType)">
                  <i *ngIf="filterType === 'beeFriendly'">
                    <img src="assets/icon/bee-friendly.svg" alt="Scented" class="w-4 h-4"/>
                  </i>
                  <i *ngIf="filterType === 'spiciness'">
                    <img src="assets/icon/hot.svg" alt="spiciness" class="w-4 h-4"/>
                  </i>
                  <i *ngIf="filterType === 'autumnSelection'">
                    <img src="assets/icon/autumn-selection.svg" alt="autumnSelection" class="w-4 h-4"/>
                  </i>
                  <i *ngIf="filterType === 'earlyFlowering'">
                    <img src="assets/icon/early-flowering.svg" alt="earlyFlowering" class="w-4 h-4"/>
                  </i>
                  <i *ngIf="filterType === 'geneticallyCompact'">
                    <img src="assets/icon/genetically-compact.svg" alt="geneticallyCompact" class="w-4 h-4"/>
                  </i>
                  <i *ngIf="filterType === 'raesGenetics'">
                    <img src="assets/icon/raes-breeding.svg" alt="raesGenetics" class="w-4 h-4"/>
                  </i>
                  <i *ngIf="filterType === 'scented'">
                    <img src="assets/icon/scented.svg" alt="Scented" class="w-4 h-4"/>
                  </i>
                </p-chip>
              </ng-container>
            </div>
            <div class="ml-auto text-green-950 pr-2">
              {{page.totalElements}}<span class="ml-2">{{ 'products.overview.results' | transloco }}</span>
            </div>
          </div>


          <ul role="list"  class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8 m-10">
            <app-plant-grid-item [plant]="plant" *ngFor="let plant of page.content"></app-plant-grid-item>
          </ul>

          <div class="py-3 flex items-center justify-between">
            <div class="flex-1 flex justify-between sm:hidden">
              <button (click)="paginate({ page: page.pageNumber - 1, rows: page.pageSize})" [disabled]="page.first" [ngClass]="{ 'border-gray-100 text-gray-300 cursor-default': page.first, 'border-gray-300 text-gray-700 hover:bg-gray-50': !page.first }" class="relative inline-flex items-center px-4 py-2 border text-sm font-medium rounded-md bg-white">
                {{'products.paging.previous' | transloco }}
              </button>
              <button (click)="paginate({ page: page.pageNumber + 1, rows: page.pageSize})" [disabled]="page.last" [ngClass]="{ 'border-gray-100 text-gray-300 cursor-default': page.last, 'border-gray-300 text-gray-700 hover:bg-gray-50': !page.last }" class="ml-3 relative inline-flex items-center px-4 py-2 border text-sm font-medium rounded-md bg-white ">
                {{'products.paging.next' | transloco}}
              </button>
            </div>
            <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
              <div>
                <p class="text-sm text-gray-700">
                  {{'products.paging.showing' | transloco }}
                  <span class="font-medium">{{page.pageNumber * page.pageSize}}</span>
                  {{'products.paging.to'  | transloco }}
                  <span class="font-medium">{{(page.pageNumber * page.pageSize) + page.numberOfElementsInPage}}</span>
                  {{'products.paging.of'  | transloco }}
                  <span class="font-medium">{{page.totalElements}}</span>
                  {{'products.paging.results'   | transloco }}
                </p>
              </div>
              <div>
                <nav class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                  <button (click)="paginate({ page: page.pageNumber - 1, rows: page.pageSize})" [disabled]="page.first" [ngClass]="{ 'border-gray-200 text-gray-300 cursor-default': page.first, 'border-gray-300 text-gray-500 hover:bg-gray-50': !page.first }" class="relative inline-flex items-center px-2 py-2 rounded-l-md border bg-white text-sm font-medium">
                    <span class="sr-only">{{'products.paging.previous'| transloco }}</span>
                    <!-- Heroicon name: solid/chevron-left -->
                    <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
                    </svg>
                  </button>
                  <ng-template ngFor let-element [ngForOf]="page.pageableElements()">
                    <!-- todo Ruben: Temporary fix. The -1 is needed because we use paginate() to navigate to the next page etc. -->
                    <button (click)="paginate({ page: element - 1, rows: page.pageSize })"
                            *ngIf="element !== '...'"
                            [ngClass]="{ 'z-10 bg-christi-50 border-christi-600 text-christi-700': element === page.pageNumber + 1, 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50': element !== page.pageNumber + 1  }"
                            class="relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                    >
                      {{ element }}
                    </button>

                    <span *ngIf="element === '...'" class="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
                      ...
                    </span>
                  </ng-template>


                  <button (click)="paginate({ page: page.pageNumber + 1, rows: page.pageSize})" [disabled]="page.last" [ngClass]="{ 'border-gray-200 text-gray-300 cursor-default': page.last, 'border-gray-300 text-gray-500 hover:bg-gray-50': !page.last }" class="relative inline-flex items-center px-2 py-2 rounded-r-md border bg-white text-sm font-medium">
                    <span class="sr-only">{{'products.paging.next' | transloco}}</span>
                    <!-- Heroicon name: solid/chevron-right -->
                    <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                    </svg>
                  </button>
                </nav>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <app-plant-slide-over-filters [form]="form" [filtersOpen]="filtersOpen" (closing)="onClose($event)" (reset)="resetSearch()"></app-plant-slide-over-filters>
  </div>
</main>



