<ng-container *transloco="let t; prefix: 'dashboard'">

<p-carousel *ngIf="slides().length > 0"
  [value]="slides()"
  [numVisible]="1"
  [numScroll]="1"
  [responsiveOptions]="responsiveOptions">
  <ng-template let-slide #item>
    <div class="relative isolate overflow-hidden pt-9 max-h-80">
      <img [src]="getImageUrl(slide.image)"
           alt=""
           class="absolute inset-0 -z-10 h-full w-full object-cover">
      <div class="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80" aria-hidden="true">
        <div class="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"></div>
      </div>
      <div class="mx-auto max-w-2xl py-3 sm:py-4 lg:py-5">
        <div class="hidden sm:mb-8 sm:flex sm:justify-center">
          <div [routerLink]="slide.routerLink" class="relative rounded-full px-3 py-1 text-sm leading-6 text-white ring-4 ring-white hover:ring-white/20">
            {{ t('slider-' + slide.categoryId +'.title-part-1') }}</div>
        </div>
        <div class="text-center">
          <h1 class="text-4xl font-bold tracking-tight text-white sm:text-6xl">Rudy Raes Bloemzaden nv</h1>
          <p class="mt-6 text-lg leading-8 text-white">{{ t('slider-' + slide.categoryId +'.title-part-2') }}</p>
          <div class="mt-10 flex items-center justify-center gap-x-6">
            <a [routerLink]="appRoutesLinks.PLANT_DETAIL_PAGE(slide.raescode)" class="rounded-md bg-forest-green-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400">{{slide.title}}</a>
          </div>
        </div>
      </div>
      <div class="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]" aria-hidden="true">
        <div class="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"></div>
      </div>
    </div>
  </ng-template>
</p-carousel>
</ng-container>
