<ng-container *transloco="let t; prefix: 'job'">
  <p-toast key="myToast" id="myToast"></p-toast>

  <div class="bg-gray-100 m-5 p-5 rounded-md">
    <h2 class="pb-5">{{t('applyTitle') }}</h2>
    <form [formGroup]="jobForm" (ngSubmit)="beforeSubmittingForm()" novalidate>

    <div class="m-5">
      <app-text class="m-5" id="firstname" [label]="'job.form.firstName' | transloco"   formControlName="firstName"></app-text>
      <app-text id="lastname" [label]="'job.form.lastName' | transloco"   formControlName="lastName"></app-text>
    </div>
    <div class="m-5">
      <app-text id="email" [label]="'job.form.email' | transloco"   formControlName="email"></app-text>
      <app-text id="phone" [label]="'job.form.phone' | transloco"   formControlName="phone"></app-text>
    </div>
    <div class="m-5">
      <app-text-area id="motivation" [label]="'job.form.motivation' | transloco"   [rows]="6" formControlName="motivation"></app-text-area>
    </div>
    <div class="m-5">
      <app-text-area id="reference" [label]="'job.form.reference' | transloco"   [rows]="6" formControlName="reference"></app-text-area>
    </div>
      <div class="m-5">
        <div class="flex flex-col items-center justify-center bg-grey-lighter">
          <label class="w-64 flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-christi-500 cursor-pointer hover:bg-christi-500 hover:text-white">
            <svg class="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
            </svg>
            <span class="mt-2 text-base leading-normal">
              {{ selectedFileName || t('form.select_cv') }}
            </span>
            <input
              type='file'
              class="hidden"
              (change)="onFileSelect($event)"
              accept=".pdf,.doc,.docx"
            />
          </label>

          <!-- File Error Message -->
          <div *ngIf="fileError" class="file-error mt-2">
            {{ fileError }}
          </div>

          <!-- Selected File Name -->
          <div *ngIf="selectedFileName" class="mt-2 text-sm text-gray-600 file-name">
            {{ selectedFileName }}
          </div>
        </div>
      </div>
      <div class="m-5">
        <app-checkbox formControlName="termsAccepted">
          {{t('form.agreeTerms') }}
          <a [routerLink]="disclaimerLink"> {{ t('form.terms') }}</a>
        </app-checkbox>
      </div>
      <div class="m-5">
        <re-captcha [lang]="this.translocoService.getActiveLang()" formControlName="captcha" siteKey="6LfhdAoUAAAAADH8OBuxWrrKt68QMjqb4sVnO8MF"></re-captcha>
      </div>
      <!-- Submit Button -->
      <div class="m-5">
        <button
          type="submit"
          [disabled]="isSubmitting"
          class="inline-flex items-center px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed">
          <span *ngIf="!isSubmitting">{{t('form.submit') }}</span>
          <span *ngIf="isSubmitting">
            {{t('form.submitting') }}
          </span>
        </button>
      </div>
    </form>
  </div>
</ng-container>
