import {Component, Input} from '@angular/core';
import {AbstractControlDirective} from "../../directives/abstract-control.directive";
import {InputComponent} from "../input/input.component";
import {ReactiveFormsModule} from "@angular/forms";
import {RouterLink} from "@angular/router";

@Component({
  selector: 'app-text-area',
  standalone: true,
  imports: [
    InputComponent, ReactiveFormsModule, RouterLink
  ],
  templateUrl: './text-area.component.html'
})
export class TextAreaComponent extends AbstractControlDirective {
  @Input() rows: number | undefined;

}
