import {Pipe, PipeTransform} from '@angular/core';
import {Orderform} from "../model/orderform";

@Pipe({standalone: true, name: 'orderFormFilter'})
export class OrderFormFilterPipe implements PipeTransform {
  transform(orderForms: Orderform[], countries: string, season: string): Orderform[] {
    if (!orderForms || !countries || !season) {
      return orderForms;
    }

    const countryList: string[] = countries.split('|');

    return orderForms.filter(orderForm => countryList.includes(orderForm.country) && orderForm.season === season);
  }
}
