<ng-container *transloco="let t; prefix: 'footer'">
  <footer class="bg-white" aria-labelledby="footer-heading">

    <div class="px-6 sm:pt-24 lg:px-8 pt-1">
      <hr class="h-px my-1 bg-christi-700 border-0 dark:bg-christi-700">
      <div class="xl:grid xl:grid-cols-3 xl:gap-8">
        <div class="space-y-8 mt-6">
          <img class="h-7" src="https://d3coqd91sswgmf.cloudfront.net/logo/raes_logo.jpg" alt="Raes Bloemzaden nv">
          <p class="text-sm leading-1 text-gray-600">Perfection In Selection - Selection Means Perfection</p>
          <p class="text-xs leading-1 text-gray-500"></p>
          <div class="flex space-x-6">
            <a href="https://www.facebook.com/raesbloemzaden" class="text-gray-400 hover:text-gray-500">
              <span class="sr-only">Facebook</span>
              <svg class="h-6 w-6 hover:text-christi-500" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path fill-rule="evenodd"
                      d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                      clip-rule="evenodd"/>
              </svg>
            </a>
            <a href="https://www.linkedin.com/company/rbz/" class="text-gray-400 hover:text-gray-500">
              <span class="sr-only">LinkedIn</span>
              <svg class="w-6 h-6 hover:text-christi-500" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                <path fill-rule="evenodd"
                      d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                      clip-rule="evenodd"/>
              </svg>
            </a>
            <a href="tel:093555830" class="text-gray-400 hover:text-gray-500">
              <span class="sr-only">Phone</span>
              <svg class="h-6 w-6 hover:text-christi-500" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24"
                   aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"/>
              </svg>
            </a>
            <a href="https://www.youtube.com/@rudyraesbloemzaden457" class="text-gray-400 hover:text-gray-500">
              <span class="sr-only">YouTube</span>
              <svg class="h-6 w-6 hover:text-christi-500" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path fill-rule="evenodd"
                      d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
                      clip-rule="evenodd"/>
              </svg>
            </a>
          </div>
        </div>
        <div class="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
          <div class="md:grid md:grid-cols-2 md:gap-8">
            <div>
              <h3
                class="ml-0 text-sm font-semibold leading-6 text-gray-900 capitalize">{{ t('productTypes.title') }}</h3>
              <ul role="list" class="mt-6 space-y-4">
                <li>
                  <a (click)="redirectAndClose(productsLink, { productTypes: 'SEEDS' })"
                     class="text-sm leading-6 text-gray-600 hover:text-christi-500 capitalize">{{ t('productTypes.seeds') }}</a>
                </li>
                <li>
                  <a (click)="redirectAndClose(productsLink, { productTypes: 'SEEDLINGS' })"
                     class="text-sm leading-6 text-gray-600 hover:text-christi-500 capitalize">{{ t('productTypes.seedlings') }}
                  </a>
                </li>
                <li>
                  <a (click)="redirectAndClose(productsLink, { productTypes: 'CUTTINGS' })"
                     class="text-sm leading-6 text-gray-600 hover:text-christi-500 capitalize">{{ t('productTypes.cuttings') }}</a>
                </li>
                <li>
                  <a (click)="redirectAndClose(productsLink, { productTypes: 'TISSUECULTURE' })"
                     class="text-sm leading-6 text-gray-600 hover:text-christi-500 capitalize">{{ t('productTypes.tissueCulture') }}</a>
                </li>
              </ul>
            </div>
            <div class="mt-10 md:mt-0">
              <h3 class="ml-0 text-sm font-semibold leading-6 text-gray-900 capitalize">{{ t('categories.title') }}</h3>
              <ul role="list" class="mt-6 space-y-4">
                <li>
                  <a (click)="redirectAndClose(productsLink, { categories: 'GENERAL' })" class="text-sm leading-6 text-gray-600 hover:text-christi-500">General</a>
                </li>
                <li>
                  <a (click)="redirectAndClose(productsLink, { categories: 'GROWEAT' })" class="text-sm leading-6 text-gray-600 hover:text-christi-500">Grow &amp; Eat</a>
                </li>
                <li>
                  <a (click)="redirectAndClose(productsLink, { categories: 'GRASSES' })" class="text-sm leading-6 text-gray-600 hover:text-christi-500">More than Grasses</a>
                </li>
                <li>
                  <a (click)="redirectAndClose(productsLink, { categories: 'FOLIAGE' })" class="text-sm leading-6 text-gray-600 hover:text-christi-500">Fabulous Foliage</a>
                </li>
                <li>
                  <a (click)="redirectAndClose(productsLink, { categories: 'TRIO' })" class="text-sm leading-6 text-gray-600 hover:text-christi-500">Trio</a>
                </li>
                <li>
                  <a (click)="redirectAndClose(productsLink, { categories: 'SASSY' })" class="text-sm leading-6 text-gray-600 hover:text-christi-500">Sassy Succulents</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="md:grid md:grid-cols-2 md:gap-8">
            <div>
              <h3
                class="ml-0 text-sm font-semibold leading-6 text-gray-900 capitalize">{{ t('productRelated.title') }}</h3>
              <ul role="list" class="mt-6 space-y-4">
                <li>
                  <a [routerLink]="availabilitiesLink"
                     class="text-sm leading-6 text-gray-600 hover:text-christi-500 capitalize">{{ t('productRelated.availability') }}</a>
                </li>
                <li>
                  <a [routerLink]="catalogsLink"
                     class="text-sm leading-6 text-gray-600 hover:text-christi-500 capitalize">{{ t('productRelated.catalogs') }}</a>
                </li>
                <li>
                  <a [routerLink]="orderformsLink"
                     class="text-sm leading-6 text-gray-600 hover:text-christi-500 capitalize">{{ t('productRelated.orderforms') }}</a>
                </li>
              </ul>
            </div>
            <div class="mt-10 md:mt-0">
              <h3 class="ml-0 text-sm font-semibold leading-6 text-gray-900 capitalize">{{ t('raesGenetics.title') }}</h3>
              <ul role="list" class="mt-6 space-y-4">
                <li>
                  <a [routerLink]="geneticsCultureLink"
                     class="text-sm leading-6 text-gray-600 hover:text-christi-500 capitalize">{{ t('raesGenetics.culture') }}</a>
                </li>
                <li>
                  <a (click)="redirectAndClose(productsLink, { genusIds: 110 , newPlant: true})"
                     class="text-sm leading-6 text-gray-600 hover:text-christi-500 capitalize">{{ t('raesGenetics.novelties') }}</a>
                </li>
                <li>
                  <a (click)="redirectAndClose(productsLink, { genusIds: 110 , special: true})"
                     class="text-sm leading-6 text-gray-600 hover:text-christi-500 capitalize">{{ t('raesGenetics.specials') }}</a>
                </li>
                <li>
                  <a (click)="redirectAndClose(productsLink, { genusIds: 110 })"
                     class="text-sm leading-6 text-gray-600 hover:text-christi-500 capitalize">{{ t('raesGenetics.series') }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <hr class="h-px my-1 bg-christi-700  border-0 dark:bg-christi-700">
    </div>
    <h2 id="footer-heading" class="sr-only">Footer</h2>
    <div class="mx-auto max-w-7xl pt-4 overflow-hidden px-6 lg:px-8">
      <nav class="-mb-6 columns-2 sm:flex sm:justify-center sm:space-x-12" aria-label="Footer">
        <div class="pb-6">
          <a [routerLink]="appRoutesLinks.COMPANY_PAGE" class="text-sm leading-6 text-gray-600 hover:text-christi-500 capitalize">{{ t('company') }}</a>
        </div>
        <div class="pb-6">
          <a [routerLink]="appRoutesLinks.JOBS_PAGE" class="text-sm leading-6 text-gray-600 hover:text-christi-500 capitalize">{{ t('jobs') }}</a>
        </div>
        <div class="pb-6">
          <a [routerLink]="appRoutesLinks.NEWS_PAGE" class="text-sm leading-6 text-gray-600 hover:text-christi-500 capitalize">{{ t('news') }}</a>
        </div>
        <div class="pb-6">
          <a [routerLink]="appRoutesLinks.ORDERFORM_PAGE" class="text-sm leading-6 text-gray-600 hover:text-christi-500 capitalize">{{ t('order') }}</a>
        </div>
        <div class="pb-6">
          <a [routerLink]="appRoutesLinks.CONTACT_PAGE" class="text-sm leading-6 text-gray-600 hover:text-christi-500 capitalize">{{ t('contact') }}</a>
        </div>
        <div class="pb-6">
          <a [routerLink]="appRoutesLinks.PRIVACY_PAGE" class="text-sm leading-6 text-gray-600 hover:text-christi-500 capitalize">{{ t('privacy') }}</a>
        </div>
        <div class="pb-6">
          <a [routerLink]="appRoutesLinks.DISCLAIMER_PAGE" class="text-sm leading-6 text-gray-600 hover:text-christi-500 capitalize">{{ t('disclaimer') }}</a>
        </div>
      </nav>
    </div>

    <div class="py-1 flex flex-col lg:flex-row justify-between items-center lg:px-8">
      <div class="text-left">
        <p class="text-xs text-gray-500">&copy;2024 - Rudy Raes Bloemzaden nv - BTW BE 0464.687.309</p>
      </div>
      <div class="text-right">
        <p class="text-xs text-gray-500 capitalize">Haenhoutstraat 204, 9070 Destelbergen, {{ t('belgium') }} - v{{ versionInfo?.version}}</p>
      </div>
    </div>
  </footer>
</ng-container>

