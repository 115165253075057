import {Component, Optional, Self} from '@angular/core';
import {NgControl, ReactiveFormsModule} from '@angular/forms';
import {AbstractControlDirective} from "../../directives/abstract-control.directive";
import {InputComponent} from "../input/input.component";
import {CommonModule} from "@angular/common";

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  standalone: true,
  imports: [
    InputComponent, ReactiveFormsModule, CommonModule
  ],
  styleUrls: ['./text.component.scss']
})
export class TextComponent extends AbstractControlDirective {
  constructor(@Self() @Optional() public override ngControl: NgControl) {
    super(ngControl);
  }
}
