<div class="grid place-items-center min-h-screen mx-auto max-w-7xl mx-auto">
  <div class="p-4 max-w-5-xl grid gap-4 xs:grid-cols-2 xs:p-8 md:grid-cols-4">
    <h1 class="text-4xl font-extrabold xs:col-span-2 xs:grid xs-gap-4 xs:grid-cols-2 md:col-span-3 md:text-5xl md:grid-cols-3 lg-text-6xl items-center">
      <span class="md:col-span-2">{{'genetics.floweringperiods.title' | transloco }}</span></h1>
    <p class="xs:row-start-2 xs:col-start-2 xs:self-center md:col-start-1 md-col-span-2 md-pr-12 md:text-lg">
      {{'genetics.floweringperiods.description.start' | transloco }}</p>
    <div class="hidden xs:block h-32 bg-blue-500 xs:h-auto xs:square" >
      <img src="https://flowerweb.s3.eu-west-1.amazonaws.com/seeds/public/P107.jpg" title="Primula's with multiple flowering periods" alt="Primula's with multiple flowering periods" class="w-full h-full object-center object-cover xl:resize-none">
    </div>
    <div (click)="navigate(goToPeriod('VERY_EARLY'))" class="h-32 bg-blue-500 xs:h-auto xs:square relative overflow-hidden rounded-lg shadow-lg cursor-pointer">
      <img src="https://flowerweb.s3.eu-west-1.amazonaws.com/seeds/public/P778.jpg" title="Very Early Primula" alt="Very Early Primula" class="w-full h-full object-center object-cover">
      <div class="bg-white text-md font-bold text-gold-drop-500 hover:bg-christi-700 hover:bg-opacity-80 hover:text-gold-drop-200 bg-opacity-60 backdrop-filter backdrop-blur w-full h-1/3 xs:h-1/5 absolute top-1/2 left-0 p-4 text-center">Very Early</div>
    </div>
    <div (click)="navigate(goToPeriod('EARLY'))" class="h-32 bg-blue-500 xs:h-auto xs:square container mx-auto relative rounded-lg shadow-lg">
      <img src="https://flowerweb.s3.eu-west-1.amazonaws.com/seeds/public/P617.jpg" title="Early Primula" alt="Early Primula" class="w-full h-full object-center object-cover">
      <div class="bg-white text-md font-bold text-my-sin-700 hover:bg-christi-700 hover:bg-opacity-80 hover:text-gold-drop-200 bg-opacity-60 backdrop-filter backdrop-blur w-full h-1/3 xs:h-1/5 absolute top-1/2 left-0 p-4 text-center">Early</div>
    </div>
    <div (click)="navigate(goToPeriod('MID_EARLY'))" class="h-32 bg-blue-500 xs:h-auto xs:square container mx-auto relative rounded-lg shadow-lg">
      <img src="https://flowerweb.s3.eu-west-1.amazonaws.com/seeds/public/E897.jpg" title="Mid Early Primula" alt="Mid Early Primula" class="w-full h-full object-center object-cover">
      <div class="bg-white text-md font-bold text-gold-drop-500 hover:bg-christi-700 hover:bg-opacity-80 hover:text-gold-drop-200 bg-opacity-60 backdrop-filter backdrop-blur w-full h-1/3 xs:h-1/5 absolute top-1/2 left-0 p-4 text-center">Mid Early</div>
    </div>
    <div (click)="navigate(goToPeriod('MID_LATE'))" class="h-32 bg-blue-500 xs:h-auto xs:square container mx-auto relative rounded-lg shadow-lg">
      <img src="https://flowerweb.s3.eu-west-1.amazonaws.com/seeds/public/E968.jpg" title="Mid Late Primula" alt="Mid Late Primula" class="w-full h-full object-center object-cover">
      <div class="bg-white text-md font-bold text-gold-drop-500 hover:bg-christi-700 hover:bg-opacity-80 hover:text-gold-drop-200 bg-opacity-60 backdrop-filter backdrop-blur w-full h-1/3 xs:h-1/5 absolute top-1/2 left-0 p-4 text-center">Mid Late</div>
    </div>
    <div (click)="navigate(goToPeriod('LATE'))" class="h-32 bg-blue-500 xs:h-auto xs:square container mx-auto relative rounded-lg shadow-lg">
      <img src="https://flowerweb.s3.eu-west-1.amazonaws.com/seeds/public/P160.jpg" title="Late Primula" alt="Late Primula" class="w-full h-full object-center object-cover">
      <div class="bg-white text-md font-bold text-gold-drop-500 hover:bg-christi-700 hover:bg-opacity-80 hover:text-gold-drop-200 bg-opacity-60 backdrop-filter backdrop-blur w-full h-1/3 xs:h-1/5 absolute top-1/2 left-0 p-4 text-center">Late</div>
    </div>
    <div (click)="navigate(goToPeriod('VERY_LATE'))" class="h-32 bg-blue-500 xs:h-auto xs:square container mx-auto relative rounded-lg shadow-lg">
      <img src="https://flowerweb.s3.eu-west-1.amazonaws.com/seeds/public/E919.jpg" title="Very Late Primula" alt="Very Late Primula" class="w-full h-full object-center object-cover">
      <div class="bg-white text-md font-bold text-gold-drop-500 hover:bg-christi-700 hover:bg-opacity-80 hover:text-gold-drop-200 bg-opacity-60 backdrop-filter backdrop-blur w-full h-1/3 xs:h-1/5 absolute top-1/2 left-0 p-4 text-center">Very Late</div>
    </div>
    <div class="hidden xs:block h-32 bg-blue-500 xs:h-auto xs:square">
      <img src="https://flowerweb.s3.eu-west-1.amazonaws.com/seeds/public/F675.jpg" title="Primula's with multiple flowering periods" alt="Primula's with multiple flowering periods" class="w-full h-full object-center object-cover">
    </div>
    <p class="md:text-center md:px-4 self-center md:text-lg md:col-span-4">{{'genetics.floweringperiods.description.end' | transloco }}</p>
  </div>
</div>
