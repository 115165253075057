import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormControl} from '@angular/forms';
import {ValidationUtil} from "../../../core/util/validation-util.service";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  imports: [
    NgIf
  ],
  standalone: true
})
export class InputComponent implements OnInit {
  @Input() label: string | undefined;
  @Input() labelFor: string | undefined;
  @Input() control: UntypedFormControl | undefined;

  constructor(private validationUtil: ValidationUtil) {}

  ngOnInit(): void { }

  get required(): boolean {
    if (this.control?.validator) {
      const validator = this.control.validator({} as AbstractControl);
      return validator && validator['required'];
    }
    return false;
  }

  get hasErrors(): boolean {
    return this.control ? this.control.dirty && !this.control.valid : false;
  }

  get error(): string | undefined {
    if (this.hasErrors && this.control?.errors && Object.keys(this.control.errors).length !== 0) {
      const firstError = Object.keys(this.control.errors)[0];
      return this.validationUtil.handleError(firstError, this.control.errors[firstError]);
    }
    return undefined;
  }

}
