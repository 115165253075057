import {Injectable} from '@angular/core';
import {Observable, ReplaySubject, Subscription} from 'rxjs';
import {Usage} from "../../shared/model/enum/usage";
import {Stand} from "../../shared/model/enum/stand";
import {ProductType} from "../../shared/model/enum/productType";
import {FlowerMonth} from "../../shared/model/enum/flowerMonth";
import {AvailabilityType} from "../../shared/model/enum/availabilityType";
import {Lifetime} from "../../shared/model/enum/lifetime";
import {DepartmentType} from "../../shared/model/enum/departmentType";
import {TranslocoService} from "@jsverse/transloco";
import {Category} from "../../shared/model/enum/category";

@Injectable({
  providedIn: 'root'
})
export class OptionsService {
  private categorySubject = new ReplaySubject(1);
  private categories$ = this.categorySubject.asObservable();
  private productTypeSubject = new ReplaySubject(1);
  private productTypes$ = this.productTypeSubject.asObservable();
  private flowerMonthSubject = new ReplaySubject(1);
  private flowerMonths$ = this.flowerMonthSubject.asObservable();
  private availabilityTypeSubject = new ReplaySubject(1);
  private availabilityTypes$ = this.availabilityTypeSubject.asObservable();
  private lifetimeSubject = new ReplaySubject(1);
  private lifetime$ = this.lifetimeSubject.asObservable();
  private usageSubject = new ReplaySubject(1);
  private usage$ = this.usageSubject.asObservable();
  private standSubject = new ReplaySubject(1);
  private stand$ = this.standSubject.asObservable();
  private productSortingSubject = new ReplaySubject(1);
  private productSorting$ = this.productSortingSubject.asObservable();
  private departmentSubject = new ReplaySubject(1);
  private department$ = this.departmentSubject.asObservable();

  constructor(private translocoService: TranslocoService) {
    this.init();

    this.translocoService.langChanges$.subscribe(() => {
      this.init();
    });
  }

  getCategories(): Observable<any> {
    return this.categories$;
  }

  getProductTypes(): Observable<any> {
    return this.productTypes$;
  }

  getFlowerMonths(): Observable<any> {
    return this.flowerMonths$;
  }

  getAvailabilityTypes(): Observable<any> {
    return this.availabilityTypes$;
  }

  getLifetimes(): Observable<any> {
    return this.lifetime$;
  }

  getStands(): Observable<any> {
    return this.stand$;
  }

  getUsages(): Observable<any> {
    return this.usage$;
  }

  getProductSorting(): Observable<any> {
    return this.productSorting$;
  }

  getDepartments(): Observable<any> {
    return this.department$;
  }

  private init(): void {
    this.initProductTypes();
    this.initFlowerMonths();
    this.initAvailabilityTypes();
    this.initLifetimes();
    this.initUsages();
    this.initCategories();
    this.initStands();
    this.initLifetimes();
    this.initProductSorting();
    this.initDepartment();
  }

  private initProductTypes(): void {
    this.productTypeSubject.next([
      {label: this.translocoService.translate('enum.productType.novalue'), value: ''},
      {label: this.translocoService.translate('enum.productType.SEEDS'), value: ProductType.SEEDS},
      {label: this.translocoService.translate('enum.productType.SEEDLINGS'), value: ProductType.SEEDLINGS},
      {label: this.translocoService.translate('enum.productType.CUTTINGS'), value: ProductType.CUTTINGS},
      {label: this.translocoService.translate('enum.productType.TISSUECULTURE'), value: ProductType.TISSUECULTURE}
    ]);
  }

  private initFlowerMonths(): void {
    this.flowerMonthSubject.next([
      {label: this.translocoService.translate('enum.flowermonth.JANUARY'), value: FlowerMonth.JANUARY},
      {label: this.translocoService.translate('enum.flowermonth.FEBRUARY'), value: FlowerMonth.FEBRUARY},
      {label: this.translocoService.translate('enum.flowermonth.MARCH'), value: FlowerMonth.MARCH},
      {label: this.translocoService.translate('enum.flowermonth.APRIL'), value: FlowerMonth.APRIL},
      {label: this.translocoService.translate('enum.flowermonth.MAY'), value: FlowerMonth.MAY},
      {label: this.translocoService.translate('enum.flowermonth.JUNE'), value: FlowerMonth.JUNE},
      {label: this.translocoService.translate('enum.flowermonth.JULY'), value: FlowerMonth.JULY},
      {label: this.translocoService.translate('enum.flowermonth.AUGUST'), value: FlowerMonth.AUGUST},
      {label: this.translocoService.translate('enum.flowermonth.SEPTEMBER'), value: FlowerMonth.SEPTEMBER},
      {label: this.translocoService.translate('enum.flowermonth.OCTOBER'), value: FlowerMonth.OCTOBER},
      {label: this.translocoService.translate('enum.flowermonth.NOVEMBER'), value: FlowerMonth.NOVEMBER},
      {label: this.translocoService.translate('enum.flowermonth.DECEMBER'), value: FlowerMonth.DECEMBER}
    ]);
  }

  private initAvailabilityTypes(): void {
    this.availabilityTypeSubject.next([
      {label: this.translocoService.translate('enum.availabilityType.SEEDLINGS'), value: AvailabilityType.SEEDLINGS},
      {label: this.translocoService.translate('enum.availabilityType.CUTTINGS'), value: AvailabilityType.CUTTINGS},
      {
        label: this.translocoService.translate('enum.availabilityType.TISSUECULTURE'),
        value: AvailabilityType.TISSUECULTURE
      },
    ]);
  }

  private initLifetimes(): void {
    this.lifetimeSubject.next([
      {label: this.translocoService.translate('enum.lifetime.ANNUAL'), value: Lifetime.ANNUAL},
      {label: this.translocoService.translate('enum.lifetime.BIENNIAL'), value: Lifetime.BIENNIAL},
      {label: this.translocoService.translate('enum.lifetime.PERENNIAL'), value: Lifetime.PERENNIAL}
    ]);
  }

  private initCategories(): void {
    this.categorySubject.next([
      {label: 'General', value: Category.GENERAL},
      {label: 'Grow Eat', value: Category.GROWEAT},
      {label: 'More than Grasses', value: Category.GRASSES},
      {label: 'Fabulous Foliage', value: Category.FOLIAGE},
      {label: 'Trio', value: Category.TRIO},
      {label: 'Sassy Succulents', value: Category.SASSY},
    ]);
  }

  private initStands(): void {
    this.standSubject.next([
      {label: this.translocoService.translate('enum.stand.HALF_SHADOW'), value: Stand.HALF_SHADOW},
      {label: this.translocoService.translate('enum.stand.SHADOW'), value: Stand.SHADOW},
      {label: this.translocoService.translate('enum.stand.SUN'), value: Stand.SUN},
    ]);
  }

  private initUsages(): void {
    this.usageSubject.next([
      {label: this.translocoService.translate('enum.usage.BASKET_PLANT'), value: Usage.BASKET_PLANT},
      {label: this.translocoService.translate('enum.usage.POT_PLANT'), value: Usage.POT_PLANT},
      {label: this.translocoService.translate('enum.usage.BEDDING_PLANT'), value: Usage.BEDDING_PLANT},
      {label: this.translocoService.translate('enum.usage.CUT_FLOWER'), value: Usage.CUT_FLOWER},
      {
        label: this.translocoService.translate('enum.usage.SUITED_FOR_HANGING_BASKET'),
        value: Usage.SUITED_FOR_HANGING_BASKET
      },
    ]);
  }

  private initProductSorting(): void {
    this.productSortingSubject.next([
      {label: this.translocoService.translate('products.overview.grid.sorting.title'), value: ''},
      {label: this.translocoService.translate('products.overview.grid.sorting.productName.asc'), value: 'name_asc'},
      {label: this.translocoService.translate('products.overview.grid.sorting.productName.desc'), value: 'name_desc'}
    ]);
  }

  private initDepartment2(): void {
    this.departmentSubject.next([
      {label: this.translocoService.translate('contact.form.chooseDepartment'), value: ''},
      {label: this.translocoService.translate('contact.form.infoDepartment'), value: DepartmentType.INFO_DEPARTMENT},
      {
        label: this.translocoService.translate('contact.form.customerDepartment'),
        value: DepartmentType.CUSTOMER_DEPARTMENT
      },
      {label: this.translocoService.translate('contact.form.plantsOrder'), value: DepartmentType.PLANTS_ORDER},
      {label: this.translocoService.translate('contact.form.seedsOrder'), value: DepartmentType.SEEDS_ORDER},
    ]);
  }

  private initDepartment(): void {
    const departmentOptions: { label: string, value: DepartmentType }[] = [];

    // Declare a variable to hold the subscription
    let chooseDepartmentSubscription: Subscription;

    // Use selectTranslate for the first department option
    chooseDepartmentSubscription = this.translocoService.selectTranslate('contact.form.chooseDepartment').subscribe(value => {
      departmentOptions.push({ label: value, value: DepartmentType.CUSTOMER_DEPARTMENT });
    });

    // Add other department options (assuming selectTranslate isn't needed)
    departmentOptions.push({ label: this.translocoService.translate('contact.form.infoDepartment'), value: DepartmentType.INFO_DEPARTMENT });
    departmentOptions.push({ label: this.translocoService.translate('contact.form.customerDepartment'), value: DepartmentType.CUSTOMER_DEPARTMENT });
    departmentOptions.push({ label: this.translocoService.translate('contact.form.plantsOrder'), value: DepartmentType.PLANTS_ORDER });
    departmentOptions.push({ label: this.translocoService.translate('contact.form.seedsOrder'), value: DepartmentType.SEEDS_ORDER });

    // Assuming departmentSubject expects a resolved array
    this.departmentSubject.next(departmentOptions);

    // Unsubscribe from the subscription after emitting the data
    chooseDepartmentSubscription.unsubscribe();
  }}
