import {Component, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {News} from "../../../../shared/model/news";
import {appRoutesLinks} from "../../../../app.routes.links";
import {NewsService} from "../../../../core/repository/news.service";
import {AsyncPipe, NgForOf} from "@angular/common";
import {NewsItemComponent} from "../news-item/news-item.component";

@Component({
  selector: 'app-news-list',
  standalone: true,
  imports: [
    AsyncPipe,
    NewsItemComponent,
    NgForOf
  ],
  templateUrl: './news-list.component.html',
  styles: ``
})
export class NewsListComponent implements OnInit {

  newsItems$: Observable<News[]> | undefined;
  goToDetail = appRoutesLinks.NEWS_DETAIL_PAGE ;

  constructor(private newsService: NewsService) { }

  ngOnInit(): void {
    this.newsItems$ = this.newsService.getHighlighted();
  }

}
