import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AppConfigService} from '../providers/app-config.service';
import {Slide} from "../../shared/model/slide";

@Injectable({
  providedIn: 'root'
})
export class ImagesliderService {
  private readonly publicApi: string;

  constructor(private http: HttpClient, private appConfig: AppConfigService) {
    this.publicApi = this.appConfig.config.apiBase;
  }

  getActive(): Observable<Slide[]> {
    const slideshowItemUrl = `${this.publicApi}/slideshowitems`;
    return this.http.get<Slide[]>(slideshowItemUrl).pipe(
      map(data => data.map(Slide.adapt))
    );
  }

  getLandingImages(): Observable<Slide[]> {
    return this.http.get<Slide[]>(this.publicApi).pipe(
      map(data => data.map(Slide.adapt))
    );
  }

}
