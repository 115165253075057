<div class="flex flex-col rounded-lg shadow-lg overflow-hidden h-full">
  <div class="flex-shrink-0">
    <img class="h-48 w-full object-cover" src="{{history.image}}" alt="">
  </div>
  <div class="flex-1 bg-white flex flex-col">
    <div class="flex-1">
      <h3 class="text-sm font-medium text-sapphire-600 capitalize">
        {{ history.name }}
      </h3>
      <h4 class="text-xl font-semibold text-my-sin-600">
        {{ history.designation }}
      </h4>
      <ul class="list-disc m-5 pl-5">
        <li>{{ history.item1 }}</li>
        <li>{{ history.item2 }}</li>
        <li>{{ history.item3 }}</li>
        <li
          *ngIf="!(history.item4 === undefined || history.item4 === null || history.item4?.trim() === '')">{{ history.item4 }}
        </li>
        <li
          *ngIf="!(history.item5 === undefined || history.item5 === null || history.item5?.trim() === '')">{{ history.item5 }}
        </li>
        <li
          *ngIf="!(history.item6 === undefined || history.item6 === null || history.item6?.trim() === '')">{{ history.item6 }}
        </li>
        <li
          *ngIf="!(history.item7 === undefined || history.item7 === null || history.item7?.trim() === '')">{{ history.item7 }}
        </li>
      </ul>
    </div>
  </div>
</div>
