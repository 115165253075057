import {ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {appRoutesLinks} from "../../../app.routes.links";
import {Page} from "../../../shared/model/page";
import {Availability} from "../../../shared/model/availability";
import {first, Subscription} from "rxjs";
import {AvailabilityService, SearchParameters} from "../../../core/repository/availability.service";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {OptionsService} from "../../../core/util/options.service";
import {NotificationService} from "../../../core/util/notification.service";
import {CommonModule, NgClass, NgForOf, NgIf} from "@angular/common";
import {TranslocoPipe, TranslocoService} from "@jsverse/transloco";
import {CartService} from "../../../core/util/cart.service";
import {DialogModule} from "primeng/dialog";
import {ButtonModule} from "primeng/button";
import {PopoverModule} from "primeng/popover";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {ConfirmPopupModule} from "primeng/confirmpopup";
import {ProductImagePopupComponent} from "../product-image-popup/product-image-popup.component";

@Component({
  selector: 'app-availability-list',
  standalone: true,
  imports: [
    NgClass,
    NgForOf,
    ReactiveFormsModule,
    NgIf,
    RouterLink,
    TranslocoPipe,
    CommonModule,
    DialogModule,
    ButtonModule,
    PopoverModule,
    ConfirmDialogModule,
    ConfirmPopupModule,
    ProductImagePopupComponent
  ],
  templateUrl: './availability-list.component.html',
  styles: ``

})
export class AvailabilityListComponent implements OnInit, OnDestroy {
  currentWeekNumber: string | null = '';
  public isSelectedWeekDropdownOpen = false;
  public isAuthenticated: boolean | undefined;

  form: FormGroup = this.formBuilder.group({});
  private searchParameters: SearchParameters = new SearchParameters();
  public plantDetail = appRoutesLinks.PLANT_DETAIL_PAGE;

  page: Page<Availability> = new Page<Availability>();
  availabilities: Availability[] = [];
  selectedAvailability!: Availability;
  selectedAvailabilityForStockOrder: Availability | undefined;
  selectedWeekForStockOrder: number = 0;
  selectedQuantityForStockOrder: number = 1;
  selectedMaxQuantityForStockOrder: number = 0;
  availabilityTypes: any[] = [];
  public pageSizeOptions: number[] = [20, 40, 60, 80, 100];
  private firstTimeLoading = false;
  scrollableCols: any[] = [];
  public firstWeekNumber: number | undefined;
  public lastWeekNumber: number | undefined;
  today = new Date();
  public scrollSize = 96; // 6rem = w-24 in tailwindcss.
  public currentScrollPosition: number = 0;
  public maxScrollSize: number = 0;
  @ViewChild('scrollableTable') private scrollableElement: ElementRef = new ElementRef(null);
  private subscriptions: Subscription[] = [];

  visible: boolean = false;

  showDialog() {
    this.visible = true;
  }

  constructor(private availabilityService: AvailabilityService,
              private formBuilder: FormBuilder,
              private activatedRoute: ActivatedRoute,
              private optionService: OptionsService,
              private router: Router,
              private cartService: CartService,
              private notificationService: NotificationService,
              private changeDetector: ChangeDetectorRef,
              private translationService: TranslocoService) {
  }


  @HostListener('window:resize')
  onResize(): void {
    this.calculateScrollingPositions();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnInit(): void {

    this.currentScrollPosition = 0;
    this.searchParameters = new SearchParameters();
    this.page = new Page<Availability>();
    this.preFillSearchParametersAndInitialise();
    this.subscriptions.push(
      this.optionService.getAvailabilityTypes().subscribe(availabilityTypes => this.availabilityTypes = availabilityTypes)
    );
  }

  private calculateScrollingPositions(): void {
    this.maxScrollSize = this.scrollableElement?.nativeElement.scrollWidth - this.scrollableElement?.nativeElement?.offsetWidth;
  }

  // https://stackoverflow.com/questions/61941291/scrollleft-animation-clean-vanilla-js
  public scrollLeft(): void {
    if (this.currentScrollPosition <= 0) {
      // do nothing since we are on the limit.
    } else {
      this.currentScrollPosition -= this.scrollSize;
      this.scroll(this.currentScrollPosition);
    }
  }

  public scrollRight(): void {
    if (this.currentScrollPosition >= this.maxScrollSize) {
      // do nothing since we are on the limit.
    } else {
      this.currentScrollPosition += this.scrollSize;
      this.scroll(this.currentScrollPosition);
    }
  }

  public selectAvailability(availability: Availability): void {
    this.selectedAvailability = availability;
  }

  public initStockOrder(availability: Availability, week: number): void {
    if (!this.isAuthenticated) {
      return;
    }

    this.selectedAvailabilityForStockOrder = availability;
    this.selectedWeekForStockOrder = week;
    this.selectedMaxQuantityForStockOrder = availability.getAvailableAmountForWeekAsNumber(week);
    this.selectedQuantityForStockOrder = 1;
  }

  public toggleSelectedWeekDropdown(): void {
    this.isSelectedWeekDropdownOpen = !this.isSelectedWeekDropdownOpen;
  }

  public onSelectWeek(selectedWeek: number): void {
    this.form.controls['selectedWeek'].setValue(selectedWeek);
    this.calculateCurrentScrollPosition();
    this.scroll(this.currentScrollPosition);
    this.isSelectedWeekDropdownOpen = false;
  }

  private scroll(scrollFromLeftToRight: number): void {
    this.scrollableElement.nativeElement.scroll({
      left: scrollFromLeftToRight,
      top: 0,
      behaviour: 'smooth'
    });
  }

  private preFillSearchParametersAndInitialise(): void {
    this.activatedRoute.queryParamMap.pipe(first()).subscribe(queryMap => {
      this.firstTimeLoading = true;
      if (queryMap.has('codeOrName')) {
        this.searchParameters.codeOrName = queryMap.get('codeOrName') || '';
      }

      if (queryMap.has('availabilityType')) {
        this.searchParameters.availabilityType = queryMap.get('availabilityType') || '';
      }

      if (queryMap.has('selectedWeek')) {
        const parsedWeek = Number.parseInt(queryMap.get('selectedWeek') || '', 10);
        if (this.isInteger(parsedWeek)) {
          this.searchParameters.selectedWeek = parsedWeek;
        }
      }

      if (queryMap.has('page')) {
        this.searchParameters.page = Number.parseInt(queryMap.get('page') || '', 10);
        this.page.pageNumber = Number.parseInt(queryMap.get('page') || '', 10);
      }

      if (queryMap.has('size')) {
        this.searchParameters.size = Number.parseInt(queryMap.get('size') || '', 10);
        this.page.pageSize = Number.parseInt(queryMap.get('size') || '', 10);
      }
    });
    this.initializeAvailabilityList();
    this.initForm();
    this.firstTimeLoading = false;
  }

  private initForm(): void {
    const foundWeekNumbers: number[] = [];
    this.form = this.formBuilder.group({
      codeOrName: [this.searchParameters.codeOrName],
      availabilityType: [this.searchParameters.availabilityType],
      selectedWeek: [this.searchParameters.selectedWeek],
    });
    this.availabilityService.fetchAvailabilityList(this.searchParameters).subscribe(page => {
      this.availabilities = page.content;
      this.page = page;
      this.availabilities.forEach(availability =>
        availability.availabilityWeeks.forEach(availabilityWeek => foundWeekNumbers.push(availabilityWeek.weekNumber)));

      this.firstWeekNumber = Math.min(...foundWeekNumbers);
      this.lastWeekNumber = Math.max(...foundWeekNumbers);

      this.scrollableCols = [];
      for (let i = this.firstWeekNumber; i <= this.lastWeekNumber; i++) {
        this.scrollableCols.push({
          field: i,
          header: this.translationService.translate('availability.table.week') + ` ${i}`
        });
      }

      let possibleSelectedWeek = this.form.controls['selectedWeek'].value;
      if (possibleSelectedWeek === null) {
        possibleSelectedWeek = this.currentWeekNumber;
      }

      if (possibleSelectedWeek <= this.firstWeekNumber) {
        possibleSelectedWeek = this.firstWeekNumber;
      } else if (possibleSelectedWeek >= this.lastWeekNumber) {
        possibleSelectedWeek = this.lastWeekNumber;
      }

      this.form.controls['selectedWeek'].setValue(possibleSelectedWeek, {emitEvent: false});


    });

    this.form.valueChanges.subscribe(values => {
      if (!this.firstTimeLoading) {
        this.searchParameters.page = 0;
      }

      this.syncSearchParametersAndSend(values);
    });

    this.form.controls['selectedWeek'].valueChanges.subscribe(value => {
      if (this.isInteger(value)) {
        this.searchParameters.selectedWeek = value;
      }
    });
  }

  private calculateCurrentScrollPosition(): void {
    const amountOfColumnsVisible = this.scrollableElement?.nativeElement?.offsetWidth / this.scrollSize;
    const selectedWeek = this.form.controls['selectedWeek'].value;
    const colNumber = selectedWeek - (this.firstWeekNumber || 0);

    if (this.firstWeekNumber === selectedWeek) {
      this.currentScrollPosition = 0;
    } else if (this.lastWeekNumber === selectedWeek) {
      this.currentScrollPosition = this.maxScrollSize;
    } else {
      this.currentScrollPosition = (colNumber - amountOfColumnsVisible + 2) * this.scrollSize;
    }
  }

  private syncSearchParametersAndSend(values: {
    codeOrName: string;
    availabilityType: string;
    selectedWeek: string;
  }): void {
    this.searchParameters.codeOrName = values.codeOrName;
    this.searchParameters.availabilityType = values.availabilityType;
    if (this.isInteger(values.selectedWeek)) {
      this.searchParameters.selectedWeek = Number.parseInt(values.selectedWeek, 10);
      this.form.controls['selectedWeek'].setValue(this.searchParameters.selectedWeek, {emitEvent: false});
    }
    this.addQueryParametersToUrl(this.searchParameters);
    this.paginate({
      page: 0,
      rows: this.page?.pageSize
    });
  }

  public paginate(event: { page: any; rows: any; }): void {
    this.searchParameters.page = event.page + 1;
    this.searchParameters.size = event.rows;
    this.addQueryParametersToUrl(this.searchParameters);

    this.availabilityService.fetchAvailabilityList(this.searchParameters).subscribe(page => {
      this.page = page;
      this.availabilities = page.content;
    }, (error) => {
      console.log(error);
    });
  }

  private initializeAvailabilityList(): void {

  }


  private addQueryParametersToUrl(searchParameters: SearchParameters): void {
    const queryParams: any = {};

    for (const key of Object.keys(searchParameters)) {
      if (this.isNotEmpty(searchParameters[key])) {
        queryParams[key] = searchParameters[key];
      }
    }

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams,
      queryParamsHandling: null
    });
  }

  private isNotEmpty(value: any): boolean {
    return value !== null && value !== undefined && value !== '';
  }

  isCurrentWeek(weekNumber: number): any {
    return this.form.controls['selectedWeek'].value === weekNumber;
  }

  isInteger(value: any): boolean {
    return Number.isInteger(value);
  }


}
