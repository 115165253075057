<li class="relative" [routerLink]="productDetail(plant?.slug)">
  <div class="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
    <img src="{{plant?.publicImage}}" alt="" class="object-cover pointer-events-none group-hover:opacity-75 transition duration-500 hover:scale-125">
    <button type="button" class="absolute inset-0 focus:outline-none">
      <span class="sr-only">View details for {{plant?.fullnameEn}}</span>
    </button>
  </div>
  <div class="mt-4 flex items-center justify-between text-base font-medium text-gray-900">
    <p class="mt-2 mx-2 block text-xs font-medium text-gray-900 overflow-clip pointer-events-none">{{plant?.fullnameEn}}</p>
    <p class="block text-sm font-medium text-gray-500 pointer-events-none w-12 h-12"><img class="w-full h-full object-none" src="{{plant?.lifetimeIconSmall}}" alt=""/></p>
  </div>
  <div *ngIf="plant?.newPlant" class="absolute top-0 right-0 bg-eminence-50 px-1 py-1 m-2 rounded-md">
    <p class="p-0 m-0 text-eminence-700 font-hk font-bold text-xs uppercase tracking-wide">
      New
    </p>
  </div>
  <div *ngIf="plant?.improvedPlant" class="absolute top-0 right-0 bg-eminence-50 px-1 py-1 m-2 rounded-md">
    <p class="p-0 m-0 text-eminence-700 font-hk font-bold text-xs uppercase tracking-wide">
      Imp
    </p>
  </div>
</li>
