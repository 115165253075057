export class Job {
  constructor(public id: number = 0,
              public intro: string = '',
              public imageUrl: string = '',
              public title: string = '',
              public jobFunction: string = '',
              public tasks: string = '',
              public profile: string = '',
              public attitudes: string = '',
              public offer: string = '',
              public displayOrder: number = 9999) {}

  static adapt(item: any): Job {
    return new Job(
      item.id,
      item.intro,
      item.imageUrl,
      item.title,
      item.jobFunction,
      item.tasks,
      item.profile,
      item.attitudes,
      item.offer,
      item.displayOrder
    );
  }
}
