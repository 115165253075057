<section class="text-green-900 relative z-0">
  <div class="hero-image bg-right-bottom bg-cover flex sm:bg-none"
       style="background-image: url(https://d3coqd91sswgmf.cloudfront.net/header/jobs_header.jpg);">
    <div class="relative container mx-auto p-4 flex items-end z-10">
      <div>
        <div class="content float-left py-4 px-5 my-5 bg-christi-700 rounded-md bg-opacity-90">
          <div class="heading text-gray-100 mb-3 text-2xl md:text-4xl">{{'job.applyPrefixTitle' | transloco }}</div>
          <div class="text text-gray-300 leading-normal hidden sm:block">{{this.title}}</div>
        </div>
      </div>
    </div>
  </div>
</section>

<app-job-apply-form [job]="job"></app-job-apply-form>
