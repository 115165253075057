import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {SeoService} from "../../../core/util/seo.service";
import {appRoutesLinks} from "../../../app.routes.links";
import {NavigationUtils} from "../../../core/util/navigation.utils";
import {TranslocoPipe, TranslocoService} from "@jsverse/transloco";
import {Meta, Title} from "@angular/platform-browser";
import {Subscription} from "rxjs";
import {PlantService} from "../../../core/repository/plant.service";
import {isPlatformBrowser} from "@angular/common";
import {ActivatedRoute} from "@angular/router";
import {Plant} from "../../../shared/model/plant";
import {Type} from "../../../shared/model/enum/type";

@Component({
  selector: 'app-genetics-types',
  standalone: true,
  imports: [
    TranslocoPipe
  ],
  templateUrl: './genetics-types.component.html',
  styles: ``
})
export class GeneticsTypesComponent implements OnInit {
  private subscriptions: Subscription[] = [];

  public standardTypeSeries: string = 'Standard Series';
  public specialTypeSeries: string = 'Special Series';
  public gardenTypeSeries: string = 'Garden Series';
  defaultPlants: Plant[] = [];
  constructor(
    private plantService: PlantService,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private navigationUtils: NavigationUtils,
    private translocoService: TranslocoService,
    @Inject(PLATFORM_ID) private platform: any,
    private metaService: Meta
  ) {
    let lang: string = this.translocoService.getActiveLang();

    let title: string;
    let description: string;
    let keywords: string;
    let translationSubscription: Subscription;

    // Use selectTranslate for the first department option
    translationSubscription = this.translocoService.selectTranslate('genetics.news.title').subscribe(value => {
      title = value;
      this.titleService.setTitle(title);
    });
    translationSubscription = this.translocoService.selectTranslate('genetics.news.description').subscribe(value => {
      description = value;
      this.metaService.updateTag({name: 'description', content: description});
    });
    translationSubscription = this.translocoService.selectTranslate('genetics.news.keywords').subscribe(value => {
      keywords = value;
      if (typeof keywords !== 'undefined') {
        this.metaService.updateTag({name: 'keywords', content: keywords});
      }
    });
    translationSubscription.unsubscribe();
  }

  goToSerie = appRoutesLinks.GENETICS_TYPE_PAGE;

  private fetchSeries(): void {
    if (isPlatformBrowser(this.platform)) {
      this.subscriptions.push(
        this.plantService.fetchDefaultsByTypeGeneticsList(Type.STANDARD).subscribe({
          next: (result) => {
            this.defaultPlants = result;
            // Reset the series string before concatenating
            this.standardTypeSeries = '';

            // Process the plants after they're loaded
            this.defaultPlants.forEach((plant, index) => {
              // Add separator if not the first item
              if (index > 0) {
                this.standardTypeSeries += ', ';
              }
              this.standardTypeSeries += plant.selectedCultivarNameEn;
            });
          },
          error: (error) => {
            console.error('Error fetching series:', error);
            // Handle error appropriately - maybe set an error flag or show a message
          }
        }),
        this.plantService.fetchDefaultsByTypeGeneticsList(Type.GARDENTYPE).subscribe({
        next: (result) => {
          this.defaultPlants = result;
          // Reset the series string before concatenating
          this.gardenTypeSeries = '';

          // Process the plants after they're loaded
          this.defaultPlants.forEach((plant, index) => {
            // Add separator if not the first item
            if (index > 0) {
              this.gardenTypeSeries += ', ';
            }
            this.gardenTypeSeries += plant.selectedCultivarNameEn;
          });
        },
        error: (error) => {
          console.error('Error fetching series:', error);
          // Handle error appropriately - maybe set an error flag or show a message
        }
      }),
        this.plantService.fetchDefaultsByTypeGeneticsList(Type.SPECIALS).subscribe({
          next: (result) => {
            this.defaultPlants = result;
            // Reset the series string before concatenating
            this.specialTypeSeries = '';

            // Process the plants after they're loaded
            this.defaultPlants.forEach((plant, index) => {
              // Add separator if not the first item
              if (index > 0) {
                this.specialTypeSeries += ', ';
              }
              this.specialTypeSeries += plant.selectedCultivarNameEn;
            });
          },
          error: (error) => {
            console.error('Error fetching series:', error);
            // Handle error appropriately - maybe set an error flag or show a message
          }
        })
      );
    }
  }
  ngOnInit(): void {
    this.fetchSeries();
  }

  public navigate(route: string[], queryParams: {} = {}): void {
    if (Object.keys(queryParams).length !== 0) {
      this.navigationUtils.navigate(route, {
        queryParams
      });
    } else {
      this.navigationUtils.navigate(route);
    }
  }
}
