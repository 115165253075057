<ng-container *transloco="let t; prefix: 'contact.form'">
  <p-toast key="myToast" id="myToast"></p-toast>

<div class="py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
  <div class="relative mx-auto">
    <div class="text-center">
      <h2 class="text-4xl">
        {{t('title') }}
      </h2>
    </div>

    <div class="mt-12">
      <form [formGroup]="contactForm" (ngSubmit)="beforeSubmittingForm()" novalidate class="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
        <app-text id="firstname" [label]="'contact.form.firstName'| transloco" formControlName="firstName"></app-text>
        <app-text id="lastname" [label]="'contact.form.lastName' | transloco" formControlName="lastName"></app-text>
        <app-text id="email" [label]="'contact.form.email' | transloco" formControlName="email"></app-text>
        <app-text id="phone" [label]="'contact.form.phone' | transloco" formControlName="phone"></app-text>
        <div class="sm:col-span-2">
          <app-text id="company" [label]="'contact.form.company' | transloco" formControlName="company"></app-text>
        </div>
        <app-text id="street" [label]="'contact.form.street' | transloco" formControlName="street"></app-text>
        <app-text id="streetNumber" [label]="'contact.form.number' | transloco"
                  formControlName="streetNumber"></app-text>
        <app-text id="city" [label]="'contact.form.city' | transloco" formControlName="city"></app-text>
        <app-text id="country" [label]="'contact.form.country' | transloco" formControlName="country"></app-text>
        <app-text id="subject" [label]="'contact.form.subject' | transloco" formControlName="subject"></app-text>
        <app-dropdown [label]="t('department')"
                      [options]="departments"
                      id="department"
                      formControlName="department"></app-dropdown>
        <div class="sm:col-span-2">
          <app-text-area id="message" [label]="'contact.form.message' | transloco" [rows]="6"
                         formControlName="message"></app-text-area>
        </div>
        <div class="sm:col-span-2">
          <div class="flex items-stretch">
            <div class="self-center">
              <div class="flex h-6">
                <input id="candidates" formControlName="termsAccepted" name="candidates" type="checkbox" class="focus:ring-christi-500 h-5 w-5 text-christi-600 border-gray-300 rounded">
              </div>
            </div>
            <div class="ml-3">
              <p class="text-base text-gray-500">
                {{t('agreeTerms')}}
                <a [routerLink]="disclaimerLink" class="font-medium text-gray-700 underline">{{t('terms')}}</a>
              </p>
            </div>
          </div>
        </div>
        <div class="sm:col-span-2">
          <re-captcha formControlName="captcha" siteKey="6LfhdAoUAAAAADH8OBuxWrrKt68QMjqb4sVnO8MF" ></re-captcha>
        </div>
        <div class="sm:col-span-2">
          <button type="submit"
                  class="inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-gold-drop-600 hover:bg-christi-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-christi-500">
            {{t('submit')}}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

</ng-container>
