export enum LeafType {
  GREEN_LEAVED= 'GREEN_LEAVED',
  DARK_LEAVED= 'DARK_LEAVED',
  GREEN_AND_DARK_LEAVED= 'GREEN_AND_DARK_LEAVED',
  ROUND_LEAVED= 'ROUND_LEAVED',
  WAVY_LEAVED= 'WAVY_LEAVED',
  FRINGED_LEAVED= 'FRINGED_LEAVED',
  FRILLED_AND_WAY_LEAVED= 'FRILLED_AND_WAY_LEAVED',
  FEATHER_LEAVED= 'FEATHER_LEAVED'
}
