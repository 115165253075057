import {Component, Input} from '@angular/core';
import {ConfirmPopupModule} from "primeng/confirmpopup";
import {ConfirmationService, MessageService, SharedModule} from "primeng/api";
import {Availability} from "../../../shared/model/availability";
import {Router} from "@angular/router";

@Component({
  selector: 'app-product-image-popup',
  standalone: true,
  imports: [
    ConfirmPopupModule,
    SharedModule
  ],
  templateUrl: './product-image-popup.component.html',
  styleUrl: './product-image-popup.component.css',
  providers: [ConfirmationService, MessageService]
})
export class ProductImagePopupComponent {
  availability!: Availability;

  constructor(   private confirmationService: ConfirmationService,
                 private messageService: MessageService,
                 private router: Router) {
  }


  get image(): string {
    return `https://d2r5uf6odu3th4.cloudfront.net/${this.availability?.getPartOfImagePath()}/public/${this.availability?.raescode}.jpg`;
  }

  get productName(): string {
    return <string>this.availability?.productName;
  }

  confirm(event: Availability, availabilityDiv: HTMLDivElement) {
    this.availability = event;
    this.confirmationService.confirm({
      target: availabilityDiv,
      message: 'Meer info.',
      icon: 'pi pi-exclamation-circle',
      rejectButtonProps: {
        icon: 'pi pi-times',
        label: 'terug naar Beschikbaarheid',
        outlined: true,
      },
      acceptButtonProps: {
        icon: 'pi pi-check',
        label: 'Ga naar plant',
      },
      accept: () => {
        this.messageService.add({
          severity: 'info',
          summary: 'Confirmed',
          detail: 'You have accepted',
          life: 3000,
        });
        this.router.navigate(['/products', event.raescode]);
      },
      reject: () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Rejected',
          detail: 'You have rejected',
          life: 3000,
        });
      },
    });
  }
}
