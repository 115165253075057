import {Component, Input} from '@angular/core';
import {TranslocoPipe} from "@jsverse/transloco";
import {Orderform} from "../../../shared/model/orderform";

@Component({
  selector: 'app-orderFormItem',
  standalone: true,
  imports: [
    TranslocoPipe
  ],
  templateUrl: './orderFormItem.component.html'
})
export class OrderFormItemComponent {
  @Input() orderForm?: Orderform = {} as Orderform;

  openInNewTab(url: string | undefined): void {
    const newTab = window.open(url, '_blank');
    newTab?.focus();
  }
}
