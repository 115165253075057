import {Component, OnInit} from '@angular/core';
import {CtaButtonComponent} from "../../../shared/components/cta-button/cta-button.component";
import {AboutHistorySliderComponent} from "./about-history-slider/about-history-slider.component";
import {AboutTestimonialsComponent} from "./about-testimonials/about-testimonials.component";
import {TranslocoDirective, TranslocoService} from "@jsverse/transloco";
import {appRoutesLinks} from "../../../app.routes.links";
import {
  UniqueSellingPointsComponent
} from "../../../shared/components/unique-selling-points/unique-selling-points.component";
import {SeoService} from "../../../core/util/seo.service";
import {Subscription} from "rxjs";
import {DepartmentType} from "../../../shared/model/enum/departmentType";
import {Meta, Title} from "@angular/platform-browser";

@Component({
  selector: 'app-about-us',
  standalone: true,
  imports: [
    CtaButtonComponent,
    AboutHistorySliderComponent,
    AboutTestimonialsComponent,
    TranslocoDirective,
    UniqueSellingPointsComponent
  ],
  templateUrl: './about-us.component.html'
})
export class AboutUsComponent {
  protected readonly appRoutesLinks = appRoutesLinks;

  constructor(private translocoService: TranslocoService,
              private titleService: Title,
              private metaService: Meta
  ) {
    let lang: string = this.translocoService.getActiveLang();

    let title: string;
    let description: string;
    let keywords: string;
    let translationSubscription: Subscription;

    // Use selectTranslate for the first department option
    translationSubscription = this.translocoService.selectTranslate('about.title').subscribe(value => {
      title = value;
      this.titleService.setTitle(title);
    });
    translationSubscription = this.translocoService.selectTranslate('about.description').subscribe(value => {
      description = value;
      this.metaService.updateTag({name: 'description', content: description});
    });
    translationSubscription = this.translocoService.selectTranslate('about.keywords').subscribe(value => {
      keywords = value;
      if (typeof keywords !== 'undefined') {
        this.metaService.updateTag({name: 'keywords', content: keywords});
      }    });
    translationSubscription.unsubscribe();
  }
}
