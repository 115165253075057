import {Component, OnInit} from '@angular/core';
import {Observable, of} from "rxjs";
import {News} from "../../../../shared/model/news";
import {appRoutesLinks} from "../../../../app.routes.links";
import {AsyncPipe, NgForOf, NgIf} from "@angular/common";
import {NewsService} from "../../../../core/repository/news.service";
import {SafePipe} from "../../../../shared/pipes/safe.pipe";
import {TranslocoDirective, TranslocoService} from "@jsverse/transloco";
import {NavigationUtils} from "../../../../core/util/navigation.utils";

@Component({
  selector: 'app-news-history',
  standalone: true,
  imports: [
    AsyncPipe,
    SafePipe,
    NgForOf,
    NgIf,
    TranslocoDirective
  ],
  templateUrl: './news-history.component.html',
  styles: ``
})
export class NewsHistoryComponent implements OnInit {
  newsItems$: Observable<News[]> = of([]);
  goToDetail = appRoutesLinks.NEWS_DETAIL_PAGE;

  constructor(private newsService: NewsService,
              private navigationUtils: NavigationUtils,
              public translocoService: TranslocoService) {
  }

  ngOnInit(): void {
    this.newsItems$ = this.newsService.getHistorical();
  }

  public navigate(route: string[], queryParams: {} = {}): void {
    if (Object.keys(queryParams).length > 0) {
      this.navigationUtils.navigate(route, {
        queryParams
      });
    } else {
      this.navigationUtils.navigate(route);
    }
  }
}
