import {Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {appRoutesLinks} from "../../app.routes.links";
import {AvailabilityType} from "../../shared/model/enum/availabilityType";
import {SeoService} from "../../core/util/seo.service";
import {AvailabilityUpload} from "../../shared/model/availabilityUpload";
import {AvailabilityService} from "../../core/repository/availability.service";
import {CommonModule, DatePipe} from "@angular/common";
import {CtaButtonComponent} from "../../shared/components/cta-button/cta-button.component";
import {AvailabilityListComponent} from "./availability-list/availability-list.component";
import {TranslocoPipe, TranslocoService} from "@jsverse/transloco";
import {OAuthService} from "angular-oauth2-oidc";
import {PlantService} from "../../core/repository/plant.service";
import {Meta, Title} from "@angular/platform-browser";
import {NavigationUtils} from "../../core/util/navigation.utils";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-availability',
  standalone: true,
  imports: [
    DatePipe,
    CtaButtonComponent,
    AvailabilityListComponent,
    TranslocoPipe,
    CommonModule
  ],
  templateUrl: './availability.component.html',
})
export class AvailabilityComponent implements OnInit {
  @ViewChild('gallery', {static: true}) galleryElement: ElementRef | undefined;

  orderLink = appRoutesLinks.ORDERFORM_PAGE;
  productDetail = appRoutesLinks.PLANT_DETAIL_PAGE;


  public lastActiveAvailabilityUploadFilename = 'http://flowerweb.s3.amazonaws.com/availability/STOCKLIST-20210224.xls';
  public lastActiveAvailabilitydateUploaded: Date = new Date();
  activeAvailabilityUpload: AvailabilityUpload | undefined;
  seedlingsAmount: number | undefined;
  cuttingsAmount: number | undefined;
  tissuecultureAmount: number | undefined;
  url: string | undefined;

  constructor(
    private availabilityService: AvailabilityService,
    private translationService: TranslocoService,
    private router: Router,
    private seoService: SeoService,
    private titleService: Title,
    private translocoService: TranslocoService,
    private metaService: Meta
  ) {
    let lang: string = this.translocoService.getActiveLang();

    let title: string;
    let description: string;
    let keywords: string;
    let translationSubscription: Subscription;

    // Use selectTranslate for the first department option
    translationSubscription = this.translocoService.selectTranslate('plant.title').subscribe(value => {
      title = value;
      this.titleService.setTitle(title);
    });
    translationSubscription = this.translocoService.selectTranslate('plant.description').subscribe(value => {
      description = value;
      this.metaService.updateTag({name: 'description', content: description});
    });
    translationSubscription = this.translocoService.selectTranslate('plant.keywords').subscribe(value => {
      keywords = value;
      if (typeof keywords !== 'undefined') {
        this.metaService.updateTag({name: 'keywords', content: keywords});
      }
    });
    translationSubscription.unsubscribe();
  }


  ngOnInit(): void {
    this.url = this.router.url.split('?')[0];
    this.availabilityService.retrieveActiveAvailabilityUploads().subscribe(
      (activeAvailabilityUpload: any) => {
        this.activeAvailabilityUpload = activeAvailabilityUpload;
      });
    this.initProductAmount();
  }


  private initProductAmount(): void {
    this.availabilityService.retrieveActiveAvailabilityAmount().subscribe(
      (availabilityAmount: { numberOfSeedlings: number; numberOfCuttings: number; numberOfTissueCulture: number; }) => {
        this.seedlingsAmount = availabilityAmount.numberOfSeedlings;
        this.cuttingsAmount = availabilityAmount.numberOfCuttings;
        this.tissuecultureAmount = availabilityAmount.numberOfTissueCulture;
      });
  }

  isSeedlingsType(value: { availabilityType: AvailabilityType; }): boolean {
    return value.availabilityType === AvailabilityType.SEEDLINGS;
  }

  isCuttingsType(value: { availabilityType: AvailabilityType; }): boolean {
    return value.availabilityType === AvailabilityType.CUTTINGS;
  }

}
