import {Component, Input} from '@angular/core';
import {TranslocoPipe} from "@jsverse/transloco";

@Component({
  selector: 'app-plant-height-icon',
  standalone: true,
  imports: [
    TranslocoPipe
  ],
  templateUrl: './plant-height-icon.component.html',
  styles: ``
})
export class PlantHeightIconComponent {

  @Input() fromHeight: any;
  @Input() tillHeight: any;

  constructor() { }

  ngOnInit(): void {
  }

  getHeight(): string {
    if (this.tillHeight == null || this.tillHeight === 0) {
      return this.fromHeight + ' cm';
    } else {
      return this.fromHeight + ' - ' + this.tillHeight + ' cm';
    }
  }

}
