import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {ActivatedRoute, ParamMap, Router, RouterLink} from '@angular/router';
import {flatMap} from 'rxjs/operators';
import {Observable, Subscription} from 'rxjs';
import {appRoutesLinks} from "../../app.routes.links";
import {Plant} from "../../shared/model/plant";
import {TranslocoDirective, TranslocoPipe, TranslocoService} from "@jsverse/transloco";
import {NavigationUtils} from "../../core/util/navigation.utils";
import {PlantService} from "../../core/repository/plant.service";
import {PlantIconComponent} from "../catalog/plant-icon/plant-icon.component";
import {PlantIconListComponent} from "../catalog/plant-icon-list/plant-icon-list.component";
import {AsyncPipe, JsonPipe, NgForOf, NgIf} from "@angular/common";
import {PlantHeightIconComponent} from "../plants/icons/plant-height-icon/plant-height-icon.component";
import {PlantPotsizeIconComponent} from "../plants/icons/plant-potsize-icon/plant-potsize-icon.component";
import {PlantPeriodGridComponent} from "../plants/periods/plant-period-grid/plant-period-grid.component";
import {PlantTrademarkComponent} from "../plants/icons/plant-trademark/plant-trademark.component";
import {OrderByPipe} from "../../shared/pipes/order-by.pipe";
import {OptionsService} from "../../core/util/options.service";
import {GenusService} from "../../core/repository/genus.service";
import {UntypedFormBuilder} from "@angular/forms";
import {Meta, Title} from "@angular/platform-browser";
import {Tab, TabList, TabPanel, TabPanels, Tabs} from "primeng/tabs";
@Component({
  selector: 'app-plant',
  standalone: true,
  imports: [
    PlantIconComponent,
    PlantIconListComponent,
    NgIf,
    PlantHeightIconComponent,
    PlantPotsizeIconComponent,
    TranslocoPipe,
    AsyncPipe,
    RouterLink,
    NgForOf,
    PlantPeriodGridComponent,
    OrderByPipe,
    PlantTrademarkComponent,
    TranslocoDirective,
    JsonPipe,
    Tabs,
    TabList,
    Tab,
    TabPanels,
    TabPanel
  ],
  templateUrl: './plant.component.html',
  styles: ``
})
export class PlantComponent implements OnInit, OnDestroy {
  public orderLink = appRoutesLinks.ORDERFORM_PAGE;
  public contactLink = appRoutesLinks.CONTACT_PAGE;
  public plant: Plant = new Plant();
  public relatedPlants$: Observable<Plant[]> = new Observable<Plant[]>();
  public plantDetail = appRoutesLinks.PLANT_DETAIL_PAGE;
  public plantsLink = appRoutesLinks.PLANT_OVERVIEW_PAGE;
  private subscriptions: Subscription[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private optionsService: OptionsService,
    private genusService: GenusService,
    private navigationUtils: NavigationUtils,
    private plantService: PlantService,
    public translateService: TranslocoService,
    private formBuilder: UntypedFormBuilder,
    private titleService: Title,
    private translocoService: TranslocoService,
    private metaService: Meta
  ) {
    let lang: string = this.translocoService.getActiveLang();

    let title: string;
    let description: string;
    let keywords: string;
    let translationSubscription: Subscription;

    // Use selectTranslate for the first department option
    translationSubscription = this.translocoService.selectTranslate('products.pageTitle').subscribe(value => {
      title = value;
      this.titleService.setTitle(title);
    });
    translationSubscription = this.translocoService.selectTranslate('products.description').subscribe(value => {
      description = value;
      this.metaService.updateTag({name: 'description', content: description});
    });
    translationSubscription = this.translocoService.selectTranslate('products.keywords').subscribe(value => {
      keywords = value;
      if (typeof keywords !== 'undefined') {
        this.metaService.updateTag({name: 'keywords', content: keywords});
      }
    });
    translationSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.activatedRoute.paramMap.pipe(flatMap((params: ParamMap) => this.plantService.findBySlugOrRaesCode(params.get('slugOrRaesCode'))))
        .subscribe(result => {
          this.plant = result;
          this.titleService.setTitle(this.plant.fullnameEn);
          this.relatedPlants$ = this.plantService.fetchRelatedPlants(this.plant.plantCode);
          this.relatedPlants$.subscribe(plants => {
            console.log('Related plants:', plants);
          });
        })
    );

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  navigateToOverview(): void {
    if (this.navigationUtils.getPreviousUrl() !== undefined && this.navigationUtils.getPreviousUrl().includes('/products?')) {
      this.router.navigateByUrl(this.navigationUtils.getPreviousUrl());
    } else {
      this.navigationUtils.navigate(this.plantsLink);
    }
  }

  getOrderProperty(): string {
    switch (this.translateService.getActiveLang().toLowerCase()) {
      default:
      case 'en':
        return 'nameEn';
      case 'nl':
        return 'nameNl';
      case 'fr':
        return 'nameFr';
      case 'de':
        return 'nameDe';
      case 'pl':
        return 'namePl';
      case 'hu':
        return 'nameHu';
    }
  }

  hasValidSowingTemperature(): boolean {
    if (this.plant?.sowingTemperature === undefined) {
      return false;
    }
    if (this.plant?.sowingTemperature === '0 °C') {
      return false;
    }
    if (this.plant?.sowingTemperature === '0 - 0 °C') {
      return false;
    }
    if (this.plant?.sowingTemperature === '') {
      return false;
    }
    return true;
  }

  hasValidPottingTemperature(): boolean {
    if (this.plant?.pottingTemperature === undefined) {
      return false;
    }
    if (this.plant?.pottingTemperature === null) {
      return false;
    }
    if (this.plant?.pottingTemperature === '0 °C') {
      return false;
    }
    if (this.plant?.pottingTemperature === '0.0 °C') {
      return false;
    }
    if (this.plant?.pottingTemperature === '') {
      return false;
    }
    return true;
  }

  periodDataAvailable(): boolean {
    return !!(this.plant?.fromPottingMonth
      || this.plant?.fromSowingMonth
      || this.plant?.fromSalesMonth);
  }

  propertiesAvailable() {
    return !!(
      this.plant.fertilization !== undefined && this.plant.fertilization !== null ||
      this.plant.diseases && this.plant.diseases.length > 0 ||
      this.plant.soils && this.plant.soils.length > 0 ||
      this.plant.pottingTemperature !== undefined && this.plant.pottingTemperature !== null
    );
  }

}
