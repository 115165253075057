import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Job} from "../../../../shared/model/job";
import {appRoutesLinks} from "../../../../app.routes.links";
import {RouterLink} from "@angular/router";
import {JobIntroComponent} from "../job-intro/job-intro.component";
import {NgForOf} from "@angular/common";
import {TranslocoPipe} from "@jsverse/transloco";

@Component({
  selector: 'app-job-list',
  standalone: true,
  imports: [
    RouterLink, JobIntroComponent, NgForOf, TranslocoPipe
  ],
  templateUrl: './job-list.component.html',
  styles: ``
})
export class JobListComponent {
  @Input() jobs: Job[] = [];
  @Input() activeJobId: number | undefined;
  active = false;
  nonExistingJobInOrderToDeselect = new Job(-1);
  @Output() selected = new EventEmitter<Job>();
  applyLink = appRoutesLinks.JOBS_APPLY_PAGE;

  constructor() {
  }

  onClick(job: Job): void {
    this.selected.emit(job);
    this.active = false;
  }

  clickOpenApplication(): void {
    this.active = !this.active;
    this.selected.emit(this.nonExistingJobInOrderToDeselect);
  }

}
