export enum Category {
  GENERAL = 'GENERAL',
  GROWEAT = 'GROWEAT',
  GRASSES = 'GRASSES',
  FOLIAGE = 'FOLIAGE',
  SASSY = 'SASSY',
  TRIO = 'TRIO'
}

// tslint:disable-next-line:no-namespace
export namespace Category {
  export function map(value: any): Category | null {
    if (value === null || value === undefined) {
      return null;
    }

    switch (value) {
      case 'GENERAL':
        return Category.GENERAL;
      case 'GROWEAT':
        return Category.GROWEAT;
      case 'GRASSES':
        return Category.GRASSES;
      case 'FOLIAGE':
        return Category.FOLIAGE;
      case 'TRIO':
        return Category.TRIO;
      case 'SASSY':
        return Category.SASSY;
      default:
        return null;
    }
  }
}
