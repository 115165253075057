<ng-container *transloco="let t; prefix: 'news.history'">

  <div class="bg-white pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
    <div class="relative max-w-lg mx-auto divide-y-2 divide-gray-200 lg:max-w-7xl">
      <div>
        <h2>
          {{ t('title') }}
        </h2>
        <h3 class="mt-3 sm:mt-4">
          {{ t('subtitle') }}
        </h3>
      </div>
      <div class="mt-12 grid gap-16 pt-12 lg:grid-cols-3 lg:gap-x-5 lg:gap-y-12">
        <div *ngFor="let newsItem of newsItems$ | async;" (click)="navigate(goToDetail(newsItem.id))"
             class="cursor-pointer hover:bg-gold-drop-200 p-5 rounded-2xl">
          <p class="text-xl font-semibold text-gray-900">
            {{ newsItem?.getTitle(this.translocoService.getActiveLang()) }} </p>
          <p class="mt-3 text-base text-gray-500">
            {{ newsItem?.getIntro(this.translocoService.getActiveLang()) }} </p>
          <div class="mt-6 flex items-center">
            <div class="flex-shrink-0">
              <ng-container *ngIf="newsItem.newsType === 'VIDEO'">
                <iframe [src]="newsItem.getYoutubePath() | safe"
                        frameborder="0"
                        allowfullscreen
                        class="w-16 h-16 object-center object-cover rounded mr-6 ml-4"
                ></iframe>
              </ng-container>
              <ng-container *ngIf="newsItem.newsType !== 'VIDEO'">
                <img src="{{newsItem?.imageUrl}}" alt="{{newsItem?.getTitle(this.translocoService.getActiveLang())}}"
                     class="w-16 h-16 object-center object-cover rounded mr-6 ml-4">
              </ng-container>
            </div>
            <div class="ml-3">
              <p class="text-sm font-medium text-gray-900">
                {{ newsItem?.author }}
              </p>
              <div class="flex space-x-1 text-sm text-gray-500">
                <time datetime="2020-03-16">
                  {{ newsItem?.datePublished }}
                </time>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</ng-container>
