import {Injectable} from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AppConfigService} from '../providers/app-config.service';
import {AvailabilityUpload} from "../../shared/model/availabilityUpload";
import {AvailabilityAmount} from "../../shared/model/availabilityAmount";
import {Availability} from "../../shared/model/availability";
import {Page} from "../../shared/model/page";

@Injectable({
  providedIn: 'root'
})
export class AvailabilityService {
  private readonly publicApi: string;

  constructor(private http: HttpClient, private appConfig: AppConfigService) {
    this.publicApi = this.appConfig.config.apiBase;
  }

  retrieveActiveAvailabilityAmount(): Observable<AvailabilityAmount> {
    const availabilitiesUrl = `${this.publicApi}/availability/amount`;
    return this.http.get<AvailabilityAmount>(availabilitiesUrl).pipe(
      map(data => AvailabilityAmount.adapt(data))
    );
  }

  retrieveActiveAvailabilityUploads(): Observable<AvailabilityUpload> {
    const availabilityUploadUrl = `${this.publicApi}/availabilityupload`;
    return this.http.get<AvailabilityUpload>(availabilityUploadUrl).pipe(
      map(AvailabilityUpload.adapt)
    );
  }

  public fetchAvailabilityList(searchParameters: SearchParameters): Observable<Page<Availability>> {
    if (searchParameters === undefined) {
      searchParameters = new SearchParameters();
    }
    return this.http.get<Page<Availability>>(`${this.publicApi}/availability`, { params: searchParameters.build() }).pipe(
      map(page => Page.adapt(page, page.content.map(Availability.adapt)))
    );
  }

}
export class SearchParameters {
  constructor(
    public codeOrName: string = '',
    public availabilityType: string = '',
    public page: number = 0 ,
    public size: number = 0,
    public selectedWeek: number = 0
  ) {
  }

  build(): HttpParams {
    let params = new HttpParams();

    if (this.codeOrName) { params = params.append('codeOrName', this.codeOrName); }
    if (this.availabilityType) { params = params.append('availabilityType', this.availabilityType); }
    if (this.page) { params = params.append('page' , String(this.page - 1)); }
    if (this.size) { params = params.append('size' , String(this.size)); }

    return params;
  }
  [key: string]: any;
}
