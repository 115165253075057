import { Injectable } from '@angular/core';
import {Router, RoutesRecognized} from '@angular/router';
import {filter, pairwise} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NavigationUtils {
  private previousUrl: string | undefined ;
  private currentUrl: string ;

  constructor(
    private router: Router) {
    this.currentUrl = this.router.url;

    this.router.events
      .pipe(
        filter((evt: any) => evt instanceof RoutesRecognized),
        pairwise()
      ).subscribe((events: RoutesRecognized[]) => {
      this.previousUrl = events[0].urlAfterRedirects;
      this.currentUrl = events[1].urlAfterRedirects;
    });
  }

  public navigate(uri: string[], params: {} = {}): Promise<boolean> {
    return this.router.navigate(uri, params);
  }

  public redirect(uri: string[], params: {} = {}): Promise<boolean> {
    return this.router.navigateByUrl('/', {
      skipLocationChange: true
    }).then(() => {
      return this.router.navigate(uri, params);
    });
  }

  getPreviousUrl(): string {
    return <string>this.previousUrl;
  }

  getCurrentUrl(): string {
    return this.currentUrl;
  }
}
