export class Slide {
  constructor(public image: string = '',
              public title: string  = '',
              public type: string  = '',
              public raescode: string  = '',
              public categoryId: string  = '',
              public routerLink: string  = '') {}

  static adapt(item: any): Slide {
    return new Slide(
      item.image,
      item.title,
      item.type,
      item.raescode,
      item.categoryId,
      item.routerLink
    );
  }

}
