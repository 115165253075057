import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {TranslocoPipe, TranslocoService} from "@jsverse/transloco";
import {Meta, Title} from "@angular/platform-browser";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-genetics-history',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TranslocoPipe
  ],
  templateUrl: './genetics-history.component.html',
  styles: ``
})
export class GeneticsHistoryComponent implements OnInit {

  form: FormGroup = this.formBuilder.group({});

  constructor(private formBuilder: FormBuilder,
              private titleService: Title,
              private translocoService: TranslocoService,
              private metaService: Meta
  ) {
    let lang: string = this.translocoService.getActiveLang();

    let title: string;
    let description: string;
    let keywords: string;
    let translationSubscription: Subscription;

    // Use selectTranslate for the first department option
    translationSubscription = this.translocoService.selectTranslate('genetics.history.title').subscribe(value => {
      title = value;
      this.titleService.setTitle(title);
    });
    translationSubscription = this.translocoService.selectTranslate('genetics.history.description').subscribe(value => {
      description = value;
      this.metaService.updateTag({name: 'description', content: description});
    });
    translationSubscription = this.translocoService.selectTranslate('genetics.history.keywords').subscribe(value => {
      keywords = value;
      if (typeof keywords !== 'undefined') {
        this.metaService.updateTag({name: 'keywords', content: keywords});
      }
    });
    translationSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.createForm();
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      selectedTab: ['knolbegonia'],
    });
  }

  isActive(tab: string): boolean {
    return this.form ? tab === this.form.controls['selectedTab'].value : false;
  }
}
