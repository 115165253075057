import {Component, OnInit} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {Digitalcatalog} from "../../shared/model/digitalcatalog";
import {appRoutesLinks} from "../../app.routes.links";
import {CatalogService} from "../../core/repository/catalog.service";
import {AsyncPipe, NgForOf} from "@angular/common";
import {CtaButtonComponent} from "../../shared/components/cta-button/cta-button.component";
import {TranslocoPipe, TranslocoService} from "@jsverse/transloco";
import {IssuuItemComponent} from "../../shared/components/issuu-item/issuu-item.component";
import {ActivatedRoute} from "@angular/router";
import {Meta, Title} from "@angular/platform-browser";

@Component({
  selector: 'app-catalog',
  standalone: true,
  imports: [
    NgForOf,
    AsyncPipe,
    CtaButtonComponent,
    TranslocoPipe,
    IssuuItemComponent
  ],
  templateUrl: './catalog.component.html'
})
export class CatalogComponent implements OnInit {
  digitalCatalogs$: Observable<Digitalcatalog[]> | undefined;
  orderLink = appRoutesLinks.ORDERFORM_PAGE;

  constructor(private digitalCatalogService: CatalogService,
              private route: ActivatedRoute,
              private translocoService: TranslocoService,
              private titleService: Title,
              private metaService: Meta
  ) {
    let title: string;
    let description: string;
    let keywords: string;
    let translationSubscription: Subscription;

    // Use selectTranslate for the first department option
    translationSubscription = this.translocoService.selectTranslate('catalog.title').subscribe(value => {
      title = value;
      this.titleService.setTitle(title);
    });
    translationSubscription = this.translocoService.selectTranslate('catalog.description').subscribe(value => {
      description = value;
      this.metaService.updateTag({name: 'description', content: description});
    });
    translationSubscription = this.translocoService.selectTranslate('catalog.keywords').subscribe(value => {
      keywords = value;
      if (typeof keywords !== 'undefined') {
        this.metaService.updateTag({name: 'keywords', content: keywords});
      }
    });
    translationSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.digitalCatalogs$ = this.digitalCatalogService.getActive();
  }

}
