import {Component, Input} from '@angular/core';
import {RouterLink} from "@angular/router";
import {News} from "../../../../shared/model/news";
import {appRoutesLinks} from "../../../../app.routes.links";
import {TranslocoService} from "@jsverse/transloco";

@Component({
  selector: 'app-news-item',
  standalone: true,
  imports: [
    RouterLink
  ],
  templateUrl: './news-item.component.html',
  styles: ``
})
export class NewsItemComponent {

  @Input() newsItem: News | undefined;
  newsDetail = appRoutesLinks.NEWS_DETAIL_PAGE;

  constructor(public translationService: TranslocoService) {
  }

}
