import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {appRoutesLinks} from "../../../app.routes.links";
import {Plant} from "../../../shared/model/plant";
import {Serie} from "../../../shared/model/serie";
import {Subscription} from "rxjs";
import {PlantService} from "../../../core/repository/plant.service";
import {ActivatedRoute, RouterLink} from "@angular/router";
import {NavigationUtils} from "../../../core/util/navigation.utils";
import {isPlatformBrowser, NgForOf, NgIf} from "@angular/common";
import {TranslocoPipe, TranslocoService} from "@jsverse/transloco";
import { DialogModule } from 'primeng/dialog';
import {ButtonModule} from "primeng/button";

@Component({
  selector: 'app-genetics-type',
  standalone: true,
  imports: [
    RouterLink,
    NgIf,
    NgForOf,
    TranslocoPipe,
    DialogModule,
    ButtonModule
  ],
  templateUrl: './genetics-type.component.html',
  styles: ``
})
export class GeneticsTypeComponent implements OnInit, OnDestroy {
  goToSerie = appRoutesLinks.GENETICS_TYPE_PAGE;
  public seriePlants: Plant[] = [];
  productDetail = appRoutesLinks.PLANT_DETAIL_PAGE;
  goToPeriod = appRoutesLinks.GENETICS_FLOWERINGPERIOD_PAGE;
  public currentSerie: Serie = new Serie();
  primusVariety: Serie = new Serie();
  evieVariety: Serie = new Serie();
  tinkerbellsVariety: Serie = new Serie();
  rambomidVariety: Serie = new Serie();
  doublerubensVariety: Serie = new Serie();
  charlieVariety: Serie = new Serie();
  rambolateVariety: Serie = new Serie();
  cabaretVariety: Serie = new Serie();
  private subscriptions: Subscription[] = [];
  public typeDescription: string | undefined;
  public typeTableTitle: string | undefined;
  public type: string | undefined;

  constructor(private plantService: PlantService,
              private readonly changeDetection: ChangeDetectorRef,
              private translateService: TranslocoService,
              private activatedRoute: ActivatedRoute,
              private navigationUtils: NavigationUtils,
              @Inject(PLATFORM_ID) private platform: any) {
  }

  visible: boolean = false;

  showDialog() {
    this.visible = true;
  }

  seriesLink = appRoutesLinks.GENETICS_PRIMULA_PAGE;
  productCatalogLink = appRoutesLinks.PLANT_OVERVIEW_PAGE;
  public serietitle: string = '';
  public seriesubtitle: string = '';

  ngOnInit(): void {
    this.type = this.activatedRoute.snapshot.params['type'];
    this.fetchSerieProducts();
    this.initVarieties();
    this.serietitle = 'Standard Series';
    this.seriesubtitle = this.translateService.translate('genetics.type.subtitle.standard');
    this.typeDescription = this.translateService.translate('genetics.type.desc.standard');
    this.typeTableTitle = this.translateService.translate('genetics.type.tableTitle.standard');
    if (this.activatedRoute.snapshot.params['type'] === 'SPECIALS') {
      this.serietitle = 'Specials F1';
      this.seriesubtitle = this.translateService.translate('genetics.type.subtitle.specials');
      this.typeDescription = this.translateService.translate('genetics.type.desc.specials');
      this.typeTableTitle = this.translateService.translate('genetics.type.tableTitle.specials');
    } else if (this.activatedRoute.snapshot.params['type'] === 'GARDENTYPE') {
      this.serietitle = 'Garden Types';
      this.seriesubtitle = this.translateService.translate('genetics.type.subtitle.gardentype');
      this.typeDescription = this.translateService.translate('genetics.type.desc.gardentype');
      this.typeTableTitle = this.translateService.translate('genetics.type.tableTitle.gardentype');
    }
  }

  private fetchSerieProducts(): void {
    if (isPlatformBrowser(this.platform)) {
      this.subscriptions.push(
        this.plantService.fetchByTypeGeneticsList(this.activatedRoute.snapshot.params['type']).subscribe(result => {
          this.seriePlants = result;
        })
      );
    }
  }

  private initVarieties(): void {
    this.primusVariety = new Serie();
    this.primusVariety.potweeks = ['30', '32', '32', '33', '34', '35'];
    this.primusVariety.floweringweeks = ['39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '1'];
    this.primusVariety.variety = 'Primus F1';
    this.primusVariety.growth = 'mc';
    this.primusVariety.potsize = 'm';
    this.primusVariety.flowersize = '10,5';
    this.primusVariety.image = 'https://d2r5uf6odu3th4.cloudfront.net/seeds/public/M034.jpg';

    this.evieVariety = new Serie();
    this.evieVariety.potweeks = ['34', '35', '36', '37', '38', '39', '40'];
    this.evieVariety.floweringweeks = ['48', '49', '50', '51', '52', '1', '2', '3', '4', '5', '6'];
    this.evieVariety.variety = 'Evie F1';
    this.evieVariety.growth = 'c';
    this.evieVariety.potsize = 'l';
    this.evieVariety.flowersize = '9-10,5';
    this.evieVariety.image = 'https://d2r5uf6odu3th4.cloudfront.net/seeds/public/P596.jpg';

    this.tinkerbellsVariety = new Serie();
    this.tinkerbellsVariety.potweeks = ['35', '36', '37', '38', '39'];
    this.tinkerbellsVariety.floweringweeks = ['2', '3', '4', '5', '6', '7', '8', '9'];
    this.tinkerbellsVariety.variety = 'Tinkerbells F1';
    this.tinkerbellsVariety.growth = 'xc';
    this.tinkerbellsVariety.potsize = 'm';
    this.tinkerbellsVariety.flowersize = '9';
    this.tinkerbellsVariety.image = 'https://d2r5uf6odu3th4.cloudfront.net/seeds/public/P739.jpg';

    this.rambomidVariety = new Serie();
    this.rambomidVariety.potweeks = ['36', '37', '38', '39', '40'];
    this.rambomidVariety.floweringweeks = ['2', '3', '4', '5', '6', '7', '8', '9'];
    this.rambomidVariety.variety = 'Rambo Mid F1';
    this.rambomidVariety.growth = 'xv';
    this.rambomidVariety.potsize = 'xl';
    this.rambomidVariety.flowersize = '12-14';
    this.rambomidVariety.image = 'https://d2r5uf6odu3th4.cloudfront.net/seeds/public/F158.jpg';

    this.doublerubensVariety = new Serie();
    this.doublerubensVariety.potweeks = ['36', '37', '38', '39', '40'];
    this.doublerubensVariety.floweringweeks = ['6', '7', '8', '9', '10', '11', '12'];
    this.doublerubensVariety.variety = 'Double Rubens F1';
    this.doublerubensVariety.growth = 'xv';
    this.doublerubensVariety.potsize = 'm';
    this.doublerubensVariety.flowersize = '12 - 14';
    this.doublerubensVariety.image = 'https://d2r5uf6odu3th4.cloudfront.net/seeds/public/X993.jpg';

    this.charlieVariety = new Serie();
    this.charlieVariety.potweeks = ['37', '38', '39', '40'];
    this.charlieVariety.floweringweeks = ['6', '7', '8', '9', '10', '11'];
    this.charlieVariety.variety = 'Charlie F1';
    this.charlieVariety.growth = 'c';
    this.charlieVariety.potsize = 'm';
    this.charlieVariety.flowersize = '9-10,5';
    this.charlieVariety.image = 'https://d2r5uf6odu3th4.cloudfront.net/seeds/public/P608.jpg';

    this.rambolateVariety = new Serie();
    this.rambolateVariety.potweeks = ['37', '38', '39', '40'];
    this.rambolateVariety.floweringweeks = ['6', '7', '8', '9', '10', '11', '12'];
    this.rambolateVariety.variety = 'Rambo Late F1';
    this.rambolateVariety.growth = 'xv';
    this.rambolateVariety.potsize = 'xl';
    this.rambolateVariety.flowersize = '12-14';
    this.rambolateVariety.image = 'https://d2r5uf6odu3th4.cloudfront.net/seeds/public/P593.jpg';

    this.cabaretVariety = new Serie();
    this.cabaretVariety.potweeks = ['37', '38', '39', '40'];
    this.cabaretVariety.floweringweeks = ['9', '10', '11', '12', '13', '14'];
    this.cabaretVariety.variety = 'Cabaret F1';
    this.cabaretVariety.growth = 'mc';
    this.cabaretVariety.potsize = '10,5';
    this.cabaretVariety.flowersize = 'l';
    this.cabaretVariety.image = 'https://d2r5uf6odu3th4.cloudfront.net/seeds/public/E204.jpg';

  }


  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public navigate(route: string[], queryParams: {} = {}): void {
    if (Object.keys(queryParams).length !== 0) {
      this.navigationUtils.navigate(route, {
        queryParams
      });
    } else {
      this.navigationUtils.navigate(route);
    }
  }

  public redirect(route: string[], queryParams: {} = {}): void {
    if (Object.keys(queryParams).length !== 0) {
      this.navigationUtils.navigate(route, {
        queryParams
      });
    } else {
      this.navigationUtils.navigate(route);
    }
  }

  initWeekCalendars(serie: Serie): void {
    this.visible = true;
    this.currentSerie = serie;
  }
}
