import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AppConfigService} from '../providers/app-config.service';
import {Contact} from "../../shared/model/contact";
import {ContactInfo} from "../../shared/model/contactInfo";

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  private readonly publicApi: string;

  constructor(private http: HttpClient, private appConfig: AppConfigService) {
    this.publicApi = this.appConfig.config.apiBase;

  }

  getActive(): Observable<Contact[]> {
    const contactUrl = `${this.publicApi}/representatives`;
    return this.http.get<Contact[]>(contactUrl).pipe(
      map(data => data.map(Contact.adapt))
    );
  }

  createContactInfoRequest(contactInfo: ContactInfo): Observable<ContactInfo> {
    const url = `${this.publicApi}/contactinfo`;
    return this.http.post<ContactInfo>(url, contactInfo);
  }

}
