import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AppConfigService} from '../providers/app-config.service';
import {News} from "../../shared/model/news";

@Injectable({
  providedIn: 'root'
})
export class NewsService {
  private readonly publicApi: string;

  constructor(private http: HttpClient, private appConfig: AppConfigService) {
    this.publicApi = this.appConfig.config.apiBase;
  }

  getHighlighted(): Observable<News[]> {
    const newsUrl = `${this.publicApi}/newsitems?highlighted=true`;
    return this.http.get<any>(newsUrl).pipe(
      map(data => data.content.map(News.adapt))
    );
  }

  getHistorical(): Observable<News[]> {
    const newsUrl = `${this.publicApi}/newsitems?historical=true`;
    return this.http.get<any>(newsUrl).pipe(
      map(data => data.content.map(News.adapt))
    );
  }

  show(id: any): Observable<News> {
    const newsUrl = `${this.publicApi}/newsitems/${id}`;

    return this.http.get<News>(newsUrl).pipe(
      map(News.adapt)
    );
  }
}
