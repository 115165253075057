import {Component, Input} from '@angular/core';
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-about-history-item',
  standalone: true,
  imports: [
    NgIf
  ],
  templateUrl: './about-history-item.component.html'
})
export class AboutHistoryItemComponent {
  @Input() history: any;

}
