<section class="text-green-900 relative z-0">
  <div class="hero-image bg-right-bottom bg-cover flex sm:bg-none"
       style="background-image: url(https://d3coqd91sswgmf.cloudfront.net/header/justintime_header.jpg);">
    <div class="relative container justify-center md:justify-start md:pl-5 flex items-end z-10">
      <div>
        <div class="content float-left justify-center md:justify-start py-4 px-5 my-5 bg-christi-700 rounded-md bg-opacity-90">
          <h1 class="heading text-gray-100 mb-3 text-2xl">{{'genetics.justintime.title' | transloco }}</h1>
          <h2 class="text text-base text-gray-300 leading-normal hidden sm:block">{{'genetics.justintime.subtitle1' | transloco }}</h2>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="py-12 bg-white">
  <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <div class="md:grid md:grid-cols-4 md:items-center">
      <img class="hidden md:block" src="/assets/images/justintime_thumb.png" alt="Primula Series Just in Time Thumb" title="Primula Series Just in Time Thumb">
      <div class="lg:text-center col-span-2">
        <p class="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">{{ 'genetics.justintime.description' | transloco}}</p>
      </div>
      <img class="hidden md:block ml-10" src="/assets/images/justintime_circle.png" alt="Primula Series Just in Time Overview" title="Primula Series Just in Time Overview">
    </div>
    <div class="grid grid-cols-6 gap-4 mt-5 justify-items-stretch">
      <div><a title="Primula Primus F1" class="text-forest-green-700 text-left underline hover:text-gold-drop-400" [routerLink]="productDetail('M034')">Primus F1</a></div>
      <div><a title="Primula Evie F1" class="text-forest-green-700 text-left underline hover:text-gold-drop-400" [routerLink]="productDetail('P596')">Evie F1</a></div>
      <div><a title="Primula Ambie F1" class="text-forest-green-700 text-left underline hover:text-gold-drop-400" [routerLink]="productDetail('M077')">Ambie F1</a></div>
      <div class="justify-self-end"><a title="Primula Charlie F1" class="text-forest-green-700 text-right underline hover:text-gold-drop-400" [routerLink]="productDetail('P608')">Charlie F1</a></div>
      <div class="col-span-2 text-center"><a title="Primula Cabaret F1" class="text-forest-green-700 underline hover:text-gold-drop-400" [routerLink]="productDetail('E204')">Cabaret F1</a></div>
    </div>
    <div class="grid grid-cols-3 gap-4 justify-items-stretch">
      <div class="bg-forest-green-500 rounded h-2">&nbsp;</div>
      <div class="bg-forest-green-500 rounded h-2">&nbsp;</div>
      <div class="bg-forest-green-500 rounded h-2">&nbsp;</div>
    </div>
    <div class="grid grid-cols-3 gap-4 mb-5 mt-2 justify-items-center">
      <div class="bg-forest-green-500 rounded-xl hover:bg-gold-drop-400 hover:text-forest-green-700 text-white cursor-pointer"><a class="px-3 uppercase" (click)="navigate(goToPeriod('EARLY'))">Early</a></div>
      <div class="bg-forest-green-500 rounded-xl hover:bg-gold-drop-400 hover:text-forest-green-700 text-white cursor-pointer"><a class="px-3 uppercase" (click)="navigate(goToPeriod('MID_LATE'))">Mid</a></div>
      <div class="bg-forest-green-500 rounded-xl hover:bg-gold-drop-400 hover:text-forest-green-700 text-white cursor-pointer"><a class="px-3 uppercase" (click)="navigate(goToPeriod('LATE'))">Late</a></div>
    </div>
    <div class="mt-10">
      <dl class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
        <div class="relative">
          <dt>
            <div class="absolute flex items-center justify-center h-12 w-12 rounded-md text-black">
              <!-- Heroicon name: outline/globe-alt -->
              <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
            <p class="ml-16 text-lg leading-6 font-medium text-gray-900">{{'genetics.justintime.topics.first.title' | transloco }}</p>
          </dt>
          <dd class="mt-2 ml-16 text-base text-gray-500">{{'genetics.justintime.topics.first.description' | transloco }}</dd>
        </div>

        <div class="relative">
          <dt>
            <div class="absolute flex items-center justify-center h-12 w-12 rounded-md text-white">
              <!-- Heroicon name: outline/scale -->
              <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                   viewBox="0 0 471.434 471.434" style="enable-background:new 0 0 471.434 471.434;" xml:space="preserve">
<g>
	<path d="M81.245,212.419c-9.902,13.127-19.256,25.526-8.273,47.079c4.957,9.729,18.132,11.771,28.311,11.771
		c20.904,0,45.125-8.057,63.033-19.182c-2.994-3.719-6.111-8.237-6.111-13.949c0-6.576,4.131-11.571,7.451-15.585
		c1.601-1.936,3.415-4.129,3.882-5.569c0.523-1.607,0.357-4.439,0.197-7.178c-0.299-5.108-0.67-11.466,3.069-16.605
		c2.653-3.644,6.503-5.517,10.286-6.759c-3.608-8.125-8.398-16.488-14.204-24.48c-14.902-20.512-32.983-33.767-46.06-33.767
		c-2.845,0-5.39,0.592-7.78,1.81c-21.868,11.143-21.404,26.636-20.912,43.039c0.22,7.359,0.429,14.31-1.603,20.561
		C90.333,200.372,85.919,206.224,81.245,212.419z"/>
  <path d="M464.076,203.816c-7.567-14.789-26.952-17.893-41.88-17.893c-9.41,0-19.295,1.161-29.221,3.252
		c1.645,2.602,3.775,5.369,5.992,8.235c10.528,13.614,24.948,32.259,9.778,62.032c-5.735,11.255-17.472,17.797-34.132,19.144
		c4.004,3.046,7.514,6.264,10.369,9.608c7.716,9.035,10.98,18.948,9.44,28.668c-5.153,32.537-27.667,39.408-44.107,44.425
		c-2.622,0.8-5.172,1.586-7.547,2.43c17.125,17.508,34.971,27.756,49.438,27.756c4.384,0,8.483-0.96,12.185-2.855
		c32.102-16.428,31.354-39.981,30.631-62.76c-0.318-10.034-0.619-19.512,2.054-27.791c2.912-9.018,8.93-17.024,15.301-25.501
		C466.721,253.483,480.269,235.458,464.076,203.816z"/>
  <path d="M270.444,394.702c2.878,0.456,5.642,0.687,8.214,0.687c18.176,0,25.863-11.274,34.001-23.211
		c4.148-6.084,8.066-11.83,13.384-15.693c5.755-4.182,12.766-6.321,20.188-8.587c15.727-4.8,30.581-9.333,34.364-33.225
		c0.893-5.63-1.213-11.479-6.258-17.386c-8.141-9.532-23.127-18.049-39.956-23.652c-1.862-0.538-3.721-1.116-5.575-1.729
		c-9.632-2.772-19.668-4.561-29.237-5.027c0.292,5.036,0.549,11.19-3.092,16.193c-3.771,5.18-9.963,6.789-14.938,8.081
		c-2.629,0.683-5.348,1.389-6.67,2.351c-1.28,0.933-2.769,3.273-4.207,5.537c-2.776,4.368-6.232,9.804-12.397,11.806
		c-1.511,0.491-3.127,0.739-4.804,0.739c-1.835,0-3.626-0.305-5.359-0.754c-3.383,17.09-3.525,36.966,0.635,54.293
		C251.173,375.273,257.234,392.61,270.444,394.702z"/>
  <path d="M126.439,366.003c-16.52-4.826-39.144-11.435-44.37-44.438c-1.539-9.719,1.725-19.632,9.44-28.667
		c2.257-2.643,4.924-5.206,7.915-7.669c-19.207-0.406-32.657-7.071-38.927-19.376c-14.955-29.353-0.779-48.145,9.572-61.866
		c1.457-1.931,2.882-3.822,4.177-5.669c-8.438-1.537-16.798-2.384-24.81-2.384c-15.076,0-34.636,3.13-42.2,18.042
		c-16.315,32.161-1.866,50.776,12.108,68.778c6.156,7.931,11.971,15.422,14.674,23.692c2.944,9.006,2.782,19.021,2.61,29.623
		c-0.386,23.87-0.751,46.416,30.949,62.497c3.678,1.866,7.743,2.811,12.085,2.811c15.828,0,35.7-12.34,54.219-33.068
		C131.569,367.509,129.042,366.763,126.439,366.003z"/>
  <path d="M276.172,62.189c12.786,0,24.223,5.22,34.962,15.96c9.115,9.115,11.603,22.92,7.36,39.616
		c9.602-6.462,19.178-9.98,27.922-9.98c5.036,0,9.792,1.123,14.137,3.337c29.352,14.955,28.93,38.491,28.62,55.676
		c-0.036,2.032-0.067,4.02-0.056,5.943c13.727-6.889,25.322-15.114,32.853-23.947c7.611-8.928,10.762-17.875,9.364-26.592
		c-5.704-35.608-28.337-42.175-50.224-48.526c-9.642-2.797-18.749-5.44-25.796-10.541c-7.678-5.557-13.433-13.755-19.525-22.434
		c-11.52-16.41-23.431-33.378-49.824-33.378c-3.748,0-7.768,0.339-11.947,1.009c-20.054,3.212-28.987,28.625-32.527,43.488
		c-1.487,6.242-2.569,12.945-3.271,19.898c3.292-0.724,7.018-2.048,10.899-3.439C257.084,65.424,266.114,62.189,276.172,62.189z"/>
  <path d="M80.254,180.726c0.028-2.296-0.039-4.745-0.114-7.261c-0.516-17.203-1.222-40.762,28.551-55.932
		c4.345-2.214,9.101-3.336,14.136-3.336c8.349,0,17.457,3.203,26.621,9.117c-6.331-19.186-4.373-35.058,5.734-45.165
		c10.691-10.692,22.104-15.89,34.887-15.89c9.877,0,18.926,3.121,26.909,5.875c1.419,0.49,2.817,0.97,4.191,1.424
		c-0.685-4.98-1.561-9.809-2.666-14.377c-3.594-14.851-12.618-40.235-32.683-43.376c-4.124-0.646-8.094-0.973-11.801-0.973
		c-26.95,0-38.601,17.153-49.868,33.742c-5.641,8.306-10.969,16.151-17.999,21.277c-7.655,5.583-17.23,8.524-27.367,11.638
		c-22.821,7.009-44.376,13.629-49.874,48.746c-1.361,8.696,1.798,17.605,9.392,26.48C47.432,163.382,62.575,173.151,80.254,180.726z
		"/>
  <path d="M328.161,374.443c-1.295,1.753-2.6,3.664-3.935,5.621c-8.438,12.375-19.993,29.324-45.568,29.324
		c-3.304,0-6.804-0.289-10.403-0.859c-12.671-2.006-22.761-11.63-29.146-27.494c-6.21,18.666-16.992,30-30.871,32.198
		c-3.581,0.567-7.063,0.854-10.349,0.854c-25.198,0-36.474-16.121-45.533-29.075c-1.561-2.232-3.085-4.399-4.611-6.387
		c-2.519,4.654-4.752,9.299-6.633,13.862c-5.821,14.126-13.439,39.965,0.946,54.3c11.86,11.818,23.598,17.323,36.938,17.323
		c11.172,0,21.87-3.855,32.217-7.584c9.445-3.404,18.367-6.619,27.066-6.634c9.527,0,18.977,3.24,28.98,6.671
		c10.371,3.556,21.096,7.234,32.052,7.234h0.005c13.408-0.002,25.147-5.516,36.943-17.354c14.313-14.363,6.645-40.121,0.8-54.197
		C334.627,386.394,331.62,380.41,328.161,374.443z"/>
  <path d="M190.069,76.259c-9.005,0-16.944,3.746-24.987,11.789c-9.459,9.458-4.171,27.047-0.177,36.69
		c7.686,18.555,21.693,36.525,36.309,48.605c2.38-3.296,5.398-6.483,9.806-7.914c1.511-0.491,3.127-0.739,4.805-0.739
		c4.22,0,8.227,1.567,11.763,2.949c2.6,1.016,5.287,2.067,7.057,2.067s4.457-1.051,7.057-2.067
		c3.535-1.382,7.543-2.948,11.762-2.948c1.678,0,3.294,0.249,4.805,0.739c3.715,1.206,6.442,3.662,8.633,6.377
		c13.957-12.04,27.143-29.278,34.512-47.07c3.994-9.643,9.282-27.231-0.176-36.69c-8.092-8.091-16.055-11.859-25.063-11.859
		c-7.626,0-15.102,2.679-22.331,5.27c-6.931,2.484-13.477,4.83-20.049,4.83c-7.115,0-14.044-2.39-21.379-4.92
		C205.13,78.856,197.6,76.259,190.069,76.259z"/>
  <path d="M396.271,253.086c11.144-21.87,1.661-34.13-8.379-47.111c-4.504-5.824-8.758-11.324-10.789-17.574
		c-2.199-6.768-2.067-14.096-1.928-21.855c0.296-16.44,0.575-31.969-20.978-42.951c-2.392-1.218-4.936-1.811-7.781-1.811
		c-13.076,0-31.153,13.253-46.055,33.763c-7.177,9.878-12.801,20.322-16.549,30.183c4.48,1.242,9.45,3.059,12.663,7.474
		c3.74,5.139,3.368,11.496,3.069,16.604c-0.16,2.739-0.326,5.571,0.196,7.178c0.467,1.44,2.281,3.633,3.882,5.568
		c3.319,4.014,7.45,9.008,7.45,15.583c0,3.601-1.241,6.724-2.907,9.475c6.998,4.038,14.811,7.587,22.921,10.41
		c3.011,0.836,5.989,1.759,8.917,2.766c9.424,2.568,18.994,4.068,27.956,4.068C378.14,264.857,391.315,262.815,396.271,253.086z"/>
  <path d="M95.897,319.376c3.839,24.242,18.717,28.588,34.468,33.189c7.066,2.064,13.742,4.015,19.059,7.877
		c5.757,4.182,9.957,10.189,14.404,16.547c8.308,11.878,16.155,23.098,34.061,23.098c2.555,0,5.299-0.229,8.159-0.683
		c13.211-2.092,19.271-19.429,21.708-29.577c4.71-19.62,3.901-42.504-0.881-60.914c-3.359,1.307-7.109,2.672-11.051,2.672
		c-1.678,0-3.294-0.249-4.805-0.739c-6.165-2.001-9.621-7.438-12.398-11.805c-1.439-2.264-2.928-4.605-4.208-5.538
		c-1.322-0.962-4.041-1.668-6.67-2.351c-4.976-1.292-11.168-2.9-14.938-8.08c-2.434-3.342-3.123-7.199-3.229-10.893
		c-26.569,3.035-54.982,15.244-67.422,29.811C97.111,307.898,95.005,313.747,95.897,319.376z"/>
  <path d="M246.796,295.599c2.499,0.978,5.084,1.988,6.664,1.988c0.204,0,0.37-0.019,0.48-0.055c1.376-0.447,3.416-3.657,4.906-6.001
		c2.04-3.21,4.354-6.849,7.784-9.346c3.469-2.525,7.677-3.618,11.388-4.583c2.666-0.692,6.317-1.641,7.139-2.768
		c0.79-1.086,0.571-4.821,0.411-7.548c-0.225-3.845-0.48-8.204,0.856-12.32c1.292-3.98,4.011-7.267,6.41-10.168
		c1.889-2.283,4.238-5.124,4.238-6.661c0-1.536-2.351-4.378-4.238-6.661c-2.399-2.9-5.118-6.188-6.41-10.168
		c-1.336-4.115-1.081-8.474-0.856-12.319c0.16-2.728,0.379-6.462-0.412-7.549c-0.82-1.127-4.471-2.075-7.137-2.768
		c-3.712-0.964-7.918-2.057-11.388-4.583c-3.432-2.497-5.745-6.136-7.786-9.347c-1.489-2.344-3.529-5.553-4.904-5.999
		c-0.11-0.036-0.276-0.055-0.48-0.055c-1.58,0-4.165,1.01-6.665,1.988c-3.63,1.419-7.745,3.027-12.153,3.027
		s-8.523-1.609-12.154-3.028c-2.5-0.978-5.085-1.988-6.665-1.988c-0.204,0-0.371,0.019-0.482,0.055
		c-1.375,0.446-3.415,3.656-4.904,5.999c-2.041,3.211-4.355,6.85-7.787,9.348c-3.47,2.525-7.677,3.617-11.389,4.581
		c-2.666,0.692-6.317,1.64-7.139,2.768c-0.79,1.086-0.572,4.822-0.413,7.549c0.225,3.845,0.48,8.203-0.856,12.319
		c-1.293,3.98-4.012,7.267-6.41,10.167c-1.889,2.284-4.239,5.126-4.239,6.662c0,1.537,2.351,4.378,4.239,6.662
		c2.398,2.9,5.117,6.187,6.41,10.167c1.336,4.115,1.081,8.473,0.856,12.318c-0.159,2.728-0.377,6.463,0.414,7.55
		c0.821,1.128,4.472,2.076,7.138,2.768c3.712,0.964,7.918,2.056,11.389,4.581c3.432,2.497,5.745,6.136,7.786,9.347
		c1.489,2.344,3.53,5.554,4.905,6.001c0.111,0.036,0.277,0.055,0.482,0.055c1.58,0,4.165-1.011,6.666-1.988
		c3.63-1.419,7.745-3.028,12.153-3.028S243.166,294.18,246.796,295.599z"/>
</g>
</svg>

            </div>
            <p class="ml-16 text-lg leading-6 font-medium text-gray-900">{{'genetics.justintime.topics.second.title' | transloco }}</p>
          </dt>
          <dd class="mt-2 ml-16 text-base text-gray-500">{{'genetics.justintime.topics.second.description' | transloco }}</dd>
        </div>

        <div class="relative">
          <dt>
            <div class="absolute flex items-center justify-center h-12 w-12 rounded-md text-black">
              <!-- Heroicon name: outline/lightning-bolt -->
              <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                   viewBox="0 0 469.567 469.567" style="enable-background:new 0 0 469.567 469.567;" xml:space="preserve">
	<g>
		<rect x="43.341" y="34.327" width="16" height="16"/>
	</g>
                <g>
		<rect x="75.341" y="34.327" width="16" height="16"/>
	</g>
                <g>
		<rect x="107.341" y="34.327" width="16" height="16"/>
	</g>
                <g>
		<path d="M395.341,106.327h-16c0,13.232-10.768,24-24,24v16c13.232,0,24,10.768,24,24h16c0-13.232,10.768-24,24-24v-16
			C406.109,130.327,395.341,119.559,395.341,106.327z M387.341,146.575c-2.336-3.144-5.104-5.912-8.248-8.248
			c3.144-2.336,5.912-5.104,8.248-8.248c2.336,3.144,5.104,5.912,8.248,8.248C392.445,140.663,389.677,143.431,387.341,146.575z"/>
	</g>
                <g>
		<path d="M443.341,18.327h-16c0,13.232-10.768,24-24,24v16c13.232,0,24,10.768,24,24h16c0-13.232,10.768-24,24-24v-16
			C454.109,42.327,443.341,31.559,443.341,18.327z M435.341,58.575c-2.336-3.144-5.104-5.912-8.248-8.248
			c3.144-2.336,5.912-5.104,8.248-8.248c2.336,3.144,5.104,5.912,8.248,8.248C440.445,52.663,437.677,55.431,435.341,58.575z"/>
	</g>
                <g>
		<path d="M286.693,452.759c-80.384-6.8-172.288-36.936-245.848-80.64l-21.816-12.952c3.248-7.6,5.128-15.544,6.96-23.28
			c0.544-2.32,1.096-4.632,1.672-6.92c1.688-6.616,3.56-17.4,5.728-29.872c3.12-17.96,7-40.312,10.504-49.296l47.92-122.816
			c1.048-2.68,2-5.568,2.976-8.52c2.592-7.888,5.28-16.056,10.304-20.472c2.832-2.496,6.52-4.352,10.08-5.992
			c0.416-0.184,0.808-0.408,1.168-0.664l94.824-72.616c4.752-3.656,11.344-3.608,16.032,0.16l47.552,38.04
			c3.008,2.408,4.784,6,4.864,9.856c0.08,3.856-1.528,7.52-4.432,10.064l-30.336,26.536c-5.152,4.504-12.928,4.224-17.752-0.584
			c-2.768-2.768-4.128-6.576-3.744-10.472c0.392-3.888,2.48-7.368,5.736-9.536l2.688-1.8l-8.888-13.296l-2.68,1.792
			c-7.256,4.832-11.912,12.584-12.776,21.264c-0.864,8.68,2.184,17.192,8.352,23.36c10.76,10.768,28.16,11.344,39.592,1.32
			l25.512-22.32l7.848,11.776l-47.928,41.088l-57.256-7.16c0.872-4.096,1.872-8.168,3.04-12.2l-15.36-4.456
			c-5.368,18.472-7.848,37.584-7.408,56.824c0.24,10.328,1.344,20.616,2.416,30.568c1.248,11.696,2.432,22.744,2.36,33.936
			c-0.216,33.512-13.2,64.8-34.728,83.672l10.544,12.032c15.24-13.36,26.528-31.384,33.216-52c2.416-0.808,5.488-0.752,8.504-0.496
			c10.504,0.936,20.704,3.816,30.28,8.128c-3.904,6.272-7.088,12.968-9.456,20.024l15.168,5.096
			c2.064-6.152,4.896-11.976,8.376-17.424c0.192,0.128,0.4,0.224,0.584,0.352l7.672-11.192c15.072-16.88,37.28-28.648,63.112-32.512
			c38.68-5.8,74.64,7.464,94.008,34.536l13.016-9.312c-8.032-11.224-18.448-20.4-30.464-27.36
			c23.176-19.288,56.72-26.232,85.32-16.296l5.248-15.112c-36.064-12.56-78.752-2.4-106.168,23.952
			c-19.04-7.256-40.848-9.608-63.32-6.232c-29.912,4.464-55.688,18.336-73.232,38.296c-12.112-5.928-25.256-9.664-38.744-10.872
			c-1.768-0.16-3.664-0.232-5.6-0.216c1.664-8.696,2.6-17.704,2.656-26.952c0.08-12.112-1.208-24.128-2.456-35.752
			c-1.032-9.624-2.096-19.576-2.32-29.24c-0.192-8.032,0.256-16.04,1.184-23.984l61.328,7.664c0.352,0.024,0.688,0.048,1.016,0.048
			c1.896,0,3.752-0.68,5.2-1.928l56-48c3.08-2.632,3.696-7.144,1.456-10.504l-14.032-21.056c2.392-4.384,3.768-9.288,3.656-14.424
			c-0.184-8.608-4.16-16.632-10.872-22.008l-47.544-38.04c-10.464-8.368-25.184-8.496-35.792-0.336l-90.584,69.696l-2.96,2.024
			c-4.28,1.992-9.144,4.536-13.328,8.216c-8.288,7.288-11.832,18.016-14.944,27.488c-0.872,2.664-1.736,5.28-2.68,7.712
			l-47.92,122.816c-4.088,10.48-7.792,31.784-11.368,52.376c-2.024,11.64-3.928,22.64-5.464,28.664
			c-0.608,2.376-1.176,4.776-1.744,7.176c-2.24,9.488-4.36,18.44-8.776,26.056c-2.192,3.792-0.928,8.648,2.832,10.88l28.184,16.744
			c75.544,44.872,170,75.832,252.68,82.824c6.408,0.544,14.48,0.864,23.696,0.864c45.992,0,120.184-8.048,155.384-37.064
			l-10.168-12.344C417.901,450.127,327.173,456.167,286.693,452.759z"/>
	</g>
</svg>
            </div>
            <p class="ml-16 text-lg leading-6 font-medium text-gray-900">{{'genetics.justintime.topics.thirth.title' | transloco }}</p>
          </dt>
          <dd class="mt-2 ml-16 text-base text-gray-500">{{'genetics.justintime.topics.thirth.description' | transloco }}</dd>
        </div>

        <div class="relative">
          <dt>
            <div class="absolute flex items-center justify-center h-12 w-12 rounded-md text-black">
              <!-- Heroicon name: outline/annotation -->
              <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                   viewBox="0 0 480 480" style="enable-background:new 0 0 480 480;" xml:space="preserve">
<g>
	<g>
		<path d="M88,136c-26.51,0-48,21.49-48,48c0.029,26.498,21.502,47.971,48,48c26.51,0,48-21.49,48-48C136,157.49,114.51,136,88,136z
			 M88,216c-17.673,0-32-14.327-32-32c0.019-17.665,14.335-31.981,32-32c17.673,0,32,14.327,32,32S105.673,216,88,216z"/>
	</g>
</g>
                <g>
	<g>
		<path d="M216,48c-35.346,0-64,28.654-64,64c0.04,35.329,28.671,63.96,64,64c35.346,0,64-28.654,64-64
			C280,76.654,251.346,48,216,48z M216,160c-26.51,0-48-21.49-48-48c0.029-26.498,21.502-47.971,48-48c26.51,0,48,21.49,48,48
			S242.51,160,216,160z"/>
	</g>
</g>
                <g>
	<g>
		<path d="M280,288c-22.091,0-40,17.909-40,40c0,22.091,17.909,40,40,40c22.091,0,40-17.909,40-40
			C319.974,305.919,302.081,288.026,280,288z M280,352c-13.255,0-24-10.745-24-24s10.745-24,24-24s24,10.745,24,24
			C303.986,341.249,293.249,351.986,280,352z"/>
	</g>
</g>
                <g>
	<g>
		<path d="M160,280c-39.764,0-72,32.235-72,72c0,39.764,32.236,72,72,72c39.765,0,72-32.236,72-72
			C231.955,312.254,199.746,280.045,160,280z M160,408c-30.928,0-56-25.072-56-56c0-30.928,25.072-56,56-56
			c30.928,0,56,25.072,56,56C215.964,382.913,190.913,407.964,160,408z"/>
	</g>
</g>
                <g>
	<g>
		<path d="M256,392c-17.673,0-32,14.327-32,32c0,17.673,14.327,32,32,32c17.673,0,32-14.327,32-32
			C287.981,406.335,273.665,392.019,256,392z M256,440c-8.837,0-16-7.163-16-16s7.163-16,16-16s16,7.163,16,16
			C271.99,432.832,264.832,439.99,256,440z"/>
	</g>
</g>
                <g>
	<g>
		<path d="M376,272c-26.51,0-48,21.49-48,48c0,26.51,21.49,48,48,48c26.51,0,48-21.49,48-48
			C423.971,293.502,402.498,272.029,376,272z M376,352c-17.673,0-32-14.327-32-32c0-17.673,14.327-32,32-32
			c17.673,0,32,14.327,32,32C407.981,337.665,393.665,351.981,376,352z"/>
	</g>
</g>
                <g>
	<g>
		<path d="M240,0C107.452,0,0,107.452,0,240s107.452,240,240,240s240-107.452,240-240C479.85,107.514,372.486,0.15,240,0z M344,64
			c0,13.255-10.745,24-24,24s-24-10.745-24-24s10.745-24,24-24C333.249,40.014,343.986,50.751,344,64z M112,72
			c8.837,0,16,7.163,16,16s-7.163,16-16,16s-16-7.163-16-16C96.01,79.168,103.168,72.01,112,72z M240,464
			C116.365,464.077,16.077,363.913,16,240.278c-0.037-58.903,23.143-115.446,64.515-157.374C80.206,84.587,80.034,86.29,80,88
			c-0.021,17.673,14.288,32.017,31.961,32.039S143.978,105.751,144,88.078c0.021-17.529-14.063-31.812-31.591-32.036
			c56.817-39.525,128.736-50.507,194.76-29.74c-20.912,7.095-32.112,29.8-25.016,50.711c7.095,20.912,29.8,32.112,50.711,25.016
			c20.912-7.095,32.112-29.8,25.016-50.711c-0.252-0.743-0.526-1.478-0.821-2.204c105.457,64.617,138.564,202.489,73.946,307.946
			C390.285,423.516,317.94,464.021,240,464z"/>
	</g>
</g>
                <g>
	<g>
		<path d="M72,264c-13.255,0-24,10.745-24,24s10.745,24,24,24c13.249-0.014,23.986-10.751,24-24C96,274.745,85.255,264,72,264z
			 M72,296c-4.418,0-8-3.582-8-8s3.582-8,8-8s8,3.582,8,8C79.996,292.416,76.416,295.996,72,296z"/>
	</g>
</g>
                <g>
	<g>
		<path d="M176,192c-22.091,0-40,17.909-40,40c0.026,22.081,17.919,39.974,40,40c22.091,0,40-17.909,40-40S198.091,192,176,192z
			 M176,256c-13.255,0-24-10.745-24-24c0.014-13.249,10.751-23.986,24-24c13.255,0,24,10.745,24,24S189.255,256,176,256z"/>
	</g>
</g>
                <g>
	<g>
		<path d="M280,176c-26.498,0.029-47.971,21.502-48,48c0,26.51,21.49,48,48,48c26.51,0,48-21.49,48-48
			C328,197.49,306.51,176,280,176z M280,256c-17.673,0-32-14.327-32-32s14.327-32,32-32c17.665,0.019,31.981,14.335,32,32
			C312,241.673,297.673,256,280,256z"/>
	</g>
</g>
                <g>
	<g>
		<path d="M392,152c-30.913,0.036-55.964,25.087-56,56c0,30.928,25.072,56,56,56c30.928,0,56-25.072,56-56
			C448,177.072,422.928,152,392,152z M392,248c-22.091,0-40-17.909-40-40s17.909-40,40-40c22.081,0.026,39.974,17.919,40,40
			C432,230.091,414.091,248,392,248z"/>
	</g>
</g>
                <g>
		<path d="M384,96c-13.249,0.014-23.986,10.751-24,24c0,13.255,10.745,24,24,24s24-10.745,24-24S397.255,96,384,96z M384,128
			c-4.418,0-8-3.582-8-8s3.582-8,8-8c4.416,0.004,7.996,3.584,8,8C392,124.418,388.418,128,384,128z"/>
	</g>
</svg>

            </div>
            <p class="ml-16 text-lg leading-6 font-medium text-gray-900">{{'genetics.justintime.topics.fourth.title' | transloco }}</p>
          </dt>
          <dd class="mt-2 ml-16 text-base text-gray-500">{{'genetics.justintime.topics.fourth.description' | transloco }}</dd>
        </div>
      </dl>
    </div>
  </div>

  <h2 class="mt-10">{{ 'genetics.justintime.subtitle3' | transloco}}</h2>

  <div class="flex flex-col px-4">
    <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-4 inline-block min-w-full sm:px-6 lg:px-8">
        <div class="overflow-hidden">
          <table class="min-w-full text-center">
            <thead class="border-b bg-gray-800">
            <tr>
              <th scope="col" class="text-sm font-medium text-white px-6 py-4">
                {{'genetics.justintime.table.timing' | transloco }}
              </th>
              <th scope="col" class="text-sm font-medium text-white px-6 py-4">
                {{'genetics.justintime.table.variety' | transloco }}
              </th>
              <th scope="col" class="text-sm font-medium text-white px-6 py-4">
                {{'genetics.justintime.table.growth.title' | transloco }}
              </th>
              <th scope="col" class="text-sm font-medium text-white px-6 py-4">
                {{'genetics.justintime.table.flowersize.title' | transloco }}
              </th>
              <th scope="col" class="text-sm font-medium text-white px-6 py-4">
                {{'genetics.justintime.table.potsize' | transloco }}
              </th>
              <th scope="col" class="text-sm font-medium text-white px-6 py-4">
                {{'genetics.justintime.table.weeks.title' | transloco }}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr class="bg-white border-b">
              <th (click)="navigate(goToPeriod('VERY_EARLY'))" class="hover:bg-forest-green-400 hover:text-my-sin-500 cursor-pointer px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 bg-gold-drop-300">VERY EARLY</th>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                <a title="Primula Primus F1" class="text-forest-green-700 text-left underline hover:text-gold-drop-400" [routerLink]="productDetail('M034')">Primus</a>
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {{'genetics.justintime.table.growth.mc' | transloco }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {{'genetics.justintime.table.flowersize.m' | transloco }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                10,5
              </td>
              <td>
                <a class="hover:cursor-pointer"
                   (click)="initWeekCalendars(primusVariety)">
                  <button type="button"
                          class="flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center text-red-600 hover:bg-gold-drop-50 hover:text-my-sin-400 focus:outline-none focus:bg-indigo-500 focus:text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                  </button>
                </a>
              </td>
            </tr>
            <tr class="bg-white border-b">
              <th (click)="navigate(goToPeriod('EARLY'))" class="hover:bg-forest-green-400 hover:text-my-sin-500 cursor-pointer px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 bg-gold-drop-500">EARLY</th>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                <a title="Primula Evie F1" class="text-forest-green-700 text-left underline hover:text-gold-drop-400" [routerLink]="productDetail('P596')">Evie</a>
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {{'genetics.justintime.table.growth.c' | transloco }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {{'genetics.justintime.table.flowersize.l' | transloco }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                9 - 10,5
              </td>
              <td>
                <a class="hover:cursor-pointer"
                   (click)="initWeekCalendars(evieVariety)">
                  <button type="button"
                          class="flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center text-red-600 hover:bg-gold-drop-50 hover:text-my-sin-400 focus:outline-none focus:bg-indigo-500 focus:text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                  </button>
                </a>
              </td>
            </tr>
            <tr class="bg-white border-b">
              <th (click)="navigate(goToPeriod('MID_EARLY'))" rowspan="2" class="hover:bg-forest-green-400 hover:text-my-sin-500 cursor-pointer px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 bg-gold-drop-600">MID EARLY</th>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                <a title="Primula Ambie F1" class="text-forest-green-700 text-left underline hover:text-gold-drop-400" [routerLink]="productDetail('M077')">Ambie</a>
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {{'genetics.justintime.table.growth.c' | transloco }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {{'genetics.justintime.table.flowersize.m' | transloco }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                9 - 10,5
              </td>
              <td>
                <a class="hover:cursor-pointer"
                   (click)="initWeekCalendars(ambieVariety)">
                  <button type="button"
                          class="flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center text-red-600 hover:bg-gold-drop-50 hover:text-my-sin-400 focus:outline-none focus:bg-indigo-500 focus:text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                  </button>
                </a>
              </td>
            </tr>
            <tr class="bg-white border-b">
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                <a title="Primula Rambo Mid" class="text-forest-green-700 underline hover:text-gold-drop-400" [routerLink]="productDetail('F158')">Rambo Mid</a>
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {{'genetics.justintime.table.growth.xv' | transloco }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {{'genetics.justintime.table.flowersize.xl' | transloco }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                12 - 14
              </td>
              <td>
                <a class="hover:cursor-pointer"
                   (click)="initWeekCalendars(rambomidVariety)">
                  <button type="button"
                          class="flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center text-red-600 hover:bg-gold-drop-50 hover:text-my-sin-400 focus:outline-none focus:bg-indigo-500 focus:text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                  </button>
                </a>
              </td>
            </tr>
            <tr class="bg-white border-b">
              <th (click)="navigate(goToPeriod('MID_LATE'))" rowspan="3" class="hover:bg-forest-green-400 hover:text-my-sin-500 cursor-pointer px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 bg-gold-drop-400 bg-gold-drop-700">MID LATE</th>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                <a title="Primula Tobie" class="text-forest-green-700 underline hover:text-gold-drop-400" [routerLink]="productDetail('E998')">Tobie</a>
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {{'genetics.justintime.table.growth.xc' | transloco }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {{'genetics.justintime.table.flowersize.m' | transloco }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                9
              </td>
              <td>
                <a class="hover:cursor-pointer"
                   (click)="initWeekCalendars(tobieVariety)">
                  <button type="button"
                          class="flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center text-red-600 hover:bg-gold-drop-50 hover:text-my-sin-400 focus:outline-none focus:bg-indigo-500 focus:text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                  </button>
                </a>
              </td>
            </tr>
            <tr class="bg-white border-b">
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                <a title="Primula Charlie F1" class="text-forest-green-700 text-right underline hover:text-gold-drop-400" [routerLink]="productDetail('P608')">Charlie</a>
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {{'genetics.justintime.table.growth.c' | transloco }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {{'genetics.justintime.table.flowersize.m' | transloco }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                9 - 10,5
              </td>
              <td>
                <a class="hover:cursor-pointer"
                   (click)="initWeekCalendars(charlieVariety)">
                  <button type="button"
                          class="flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center text-red-600 hover:bg-gold-drop-50 hover:text-my-sin-400 focus:outline-none focus:bg-indigo-500 focus:text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                  </button>
                </a>
              </td>
            </tr>
            <tr class="bg-white border-b">
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                <a title="Primula Rambo Late" class="text-forest-green-700 underline hover:text-gold-drop-400" [routerLink]="productDetail('P593')">Rambo Late</a>
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {{'genetics.justintime.table.growth.xv' | transloco }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {{'genetics.justintime.table.flowersize.xl' | transloco }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                12 - 14
              </td>
              <td>
                <a class="hover:cursor-pointer"
                   (click)="initWeekCalendars(rambolateVariety)">
                  <button type="button"
                          class="flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center text-red-600 hover:bg-gold-drop-50 hover:text-my-sin-400 focus:outline-none focus:bg-indigo-500 focus:text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                  </button>
                </a>
              </td>
            </tr>
            <tr class="bg-white border-b">
              <th (click)="navigate(goToPeriod('LATE'))" class="hover:bg-forest-green-400 hover:text-my-sin-500 cursor-pointer px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 bg-gold-drop-800">LATE</th>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-center">
                <a title="Primula Cabaret F1" class="text-forest-green-700 underline hover:text-gold-drop-400" [routerLink]="productDetail('E204')">Cabaret</a>
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {{'genetics.justintime.table.growth.mc' | transloco }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {{'genetics.justintime.table.flowersize.l' | transloco }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                10,5
              </td>
              <td>
                <a class="hover:cursor-pointer"
                   (click)="initWeekCalendars(cabaretVariety)">
                  <button type="button"
                          class="flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center text-red-600 hover:bg-gold-drop-50 hover:text-my-sin-400 focus:outline-none focus:bg-indigo-500 focus:text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                  </button>
                </a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="card flex justify-content-center">
  <p-dialog
    header="Header"
    [(visible)]="visible"
    [modal]="true"
    [style]="{ width: '25rem' }">
    <ng-template pTemplate="header">
      <div class="inline-flex align-items-center justify-content-center gap-2">
        <span class="font-bold white-space-nowrap">
                        Week info
                    </span>
      </div>
    </ng-template>
    <div class="flex text-base text-left transform transition w-full md:inline-block md:max-w-2xl md:px-4 md:my-8 md:align-middle lg:max-w-4xl">

      <div class="w-full relative flex items-center bg-white">
        <div class="w-full relative flex items-center bg-white px-4 pt-14 pb-8 overflow-hidden shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8">
          <section aria-labelledby="options-heading" class="mt-6">
            <h2 class="mx-0 px-0 text-2xl font-extrabold text-gray-900">{{ currentSerie.variety }}</h2>
            <div class="flex flex-col align-middle">
              <h3 class="mx-0 px-0 text-gold-drop-800">{{ 'genetics.justintime.popup.pottingweeks' | transloco }}</h3>
              <div class="flex flex-row">
                <div class="w-6 lg:w-12 bg-christi-500 border-2 gap-4 text-center" *ngFor="let week of currentSerie.potweeks">{{ week }}</div>
              </div>
              <h3 class="mx-0 px-0 text-gold-drop-800">{{ 'genetics.justintime.popup.floweringweeks' | transloco }}</h3>
              <div class="flex flex-row">
                <div class="w-6 lg:w-12 bg-gold-drop-600 border-2 gap-4 text-center" *ngFor="let week of currentSerie.floweringweeks">{{ week }}</div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
    <ng-template pTemplate="footer">
      <p-button
        label="Close"
        [text]="true"
        severity="secondary"
        (click)="visible = false" />
    </ng-template>
  </p-dialog>
</div>
