export enum ProductType {
  SEEDS = 'SEEDS',
  SEEDLINGS = 'SEEDLINGS',
  CUTTINGS = 'CUTTINGS',
  TISSUECULTURE = 'TISSUECULTURE'
}

export namespace ProductType {
  export function map(value: any): ProductType | null {
    if (value === null || value === undefined) {
      return null;
    }

    switch (value) {
      case 'SEEDS':
        return ProductType.SEEDS;
      case 'SEEDLINGS':
        return ProductType.SEEDLINGS;
      case 'CUTTINGS':
        return ProductType.CUTTINGS;
      case 'TISSUECULTURE':
        return ProductType.TISSUECULTURE;
      default:
        return null;
    }
  }
}
