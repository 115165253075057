import {Injectable} from '@angular/core';
import {CustomInjectorService} from "../util/custom-injector.service";
import {firstValueFrom} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  private appConfig: any;

  constructor(private customInjector: CustomInjectorService
  ) {
  }

  public async loadConfig(): Promise<any> {
    const config$ = this.customInjector.getHttpClient().get('./assets/config/config.json');
    this.appConfig = await firstValueFrom(config$);
  }

  get config(): any {
    return this.appConfig;
  }
}
