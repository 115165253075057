<p-carousel
  [value]="plants"
  [numVisible]="3"
  [numScroll]="3"
  [circular]="false"
  [responsiveOptions]="responsiveOptions">
  <ng-template let-plant #item>
    <a [routerLink]="appRoutesLinks.PLANT_DETAIL_PAGE(plant.plantCode)">
    <div class="border border-surface rounded-border m-2 p-4">
      <div class="mb-4">
        <div class="relative mx-auto">
          <img
            src="{{plant.publicImage}}"
            [alt]="plant.fullnameEn"
            class="w-full rounded-border" />
        </div>
      </div>
      <div class="mb-4 font-medium">
        {{ plant.fullnameEn }}
      </div>
    </div>
    </a>
  </ng-template>
</p-carousel>
