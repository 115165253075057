<ng-container *transloco="let t; prefix: 'contact'">

<div class="flex flex-col h-full w-full bg-white rounded overflow-hidden shadow-lg">
  <!-- Header -->
  <div class="text-black text-lg px-6 py-4">
    <h3 *ngIf="contact?.firstName && !(contact?.firstName === '')"
        class="mt-6 text-gray-900 text-sm font-medium">{{ contact?.firstName }} {{ contact?.lastName }}</h3>
    <h3 *ngIf="!contact?.firstName || contact?.firstName === ''" class="text-gray-900 text-sm font-medium">{{ contact?.company }}</h3>  </div>

  <!-- Content -->
  <div class="px-6 py-4 flex-grow overflow-auto">
    <dl>
    <ng-container *ngIf="contact?.phone">
      <a href="tel:{{contact?.phone}}">
        <dt><span class="sr-only">Phone number</span></dt>
        <dd class="flex text-base">
          <svg class="flex-shrink-0 w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
               stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"/>
          </svg>
          <span class="ml-3">{{ contact?.phone }}</span>
        </dd>
      </a>
    </ng-container>
    <ng-container *ngIf="contact?.gsm">
      <a href="tel:{{contact?.gsm}}">
        <dt><span class="sr-only">Cellphone number</span></dt>
        <dd class="flex text-base">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"/>
          </svg>
          <span class="ml-3">{{ contact?.gsm }}</span>
        </dd>
      </a>
    </ng-container>
    <ng-container *ngIf="contact?.fax;">
      <dt><span class="sr-only">Fax number</span></dt>
      <dd class="flex text-base">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"/>
        </svg>
        <span class="ml-3">{{ contact?.fax }}</span>
      </dd>
    </ng-container>
      <dt *ngIf="contact?.email"><span class="sr-only">Email</span></dt>
      <dd *ngIf="contact?.email" class="flex text-base">
        <svg class="flex-shrink-0 w-6 h-6 text-xs" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
             stroke="currentColor" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"/>
        </svg>
        <span class="ml-3">{{ contact?.email }}</span>
      </dd>
      <dt *ngIf="contact?.website"><span class="sr-only">Website</span></dt>
      <dd *ngIf="contact?.website" class="flex text-base text-white">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
        </svg>
        <span class="ml-3">{{ contact?.website }}</span>
      </dd>
      <dt *ngIf="contact?.city"><span class="sr-only">Cellphone number</span></dt>
      <dd *ngIf="contact?.city" class="flex text-base">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"/>
        </svg>
        <span class="ml-3 text-left">{{ contact?.street }} {{ contact?.houseNumber }}, {{ contact?.city }}
          , {{ 'enum.country.' + contact?.country | transloco}}</span>
      </dd>
    </dl>
  </div>

  <!-- Footer -->
  <div class="border-t-2 text-white text-sm px-6 py-4">
    <div class="mt-auto">
      <div class="-mt-px flex divide-x divide-gray-200">
        <div class="flex w-0 flex-1">
          <a href="mailto:{{contact?.email}}" class="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900 hover:text-christi-500">
            <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path d="M3 4a2 2 0 00-2 2v1.161l8.441 4.221a1.25 1.25 0 001.118 0L19 7.162V6a2 2 0 00-2-2H3z" />
              <path d="M19 8.839l-7.77 3.885a2.75 2.75 0 01-2.46 0L1 8.839V14a2 2 0 002 2h14a2 2 0 002-2V8.839z" />
            </svg>
            {{ t('cta.email') }}
          </a>
        </div>
        <div class="-ml-px flex w-0 flex-1">
          <a href="tel:{{contact?.phone}}" class="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900 hover:text-christi-500">
            <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M2 3.5A1.5 1.5 0 013.5 2h1.148a1.5 1.5 0 011.465 1.175l.716 3.223a1.5 1.5 0 01-1.052 1.767l-.933.267c-.41.117-.643.555-.48.95a11.542 11.542 0 006.254 6.254c.395.163.833-.07.95-.48l.267-.933a1.5 1.5 0 011.767-1.052l3.223.716A1.5 1.5 0 0118 15.352V16.5a1.5 1.5 0 01-1.5 1.5H15c-1.149 0-2.263-.15-3.326-.43A13.022 13.022 0 012.43 8.326 13.019 13.019 0 012 5V3.5z" clip-rule="evenodd" />
            </svg>
            {{ t('cta.call') }}
          </a>
        </div>
    </div>
  </div>
</div>
</div>
</ng-container>
