<section class="text-green-900 relative z-0">
  <div class="hero-image bg-cover justify-center md:justify-start md:pl-5 flex sm:bg-none bg-center"
       style="background-image: url(https://d3coqd91sswgmf.cloudfront.net/header/news_header.jpg);">
    <div class="relative container justify-center md:justify-start flex items-end z-10 shadow-sm">
      <div class="content float-left py-4 px-5 my-5 bg-christi-700 rounded-md bg-opacity-90">
        <h1 class="heading text-gray-100 mb-3 text-2xl">{{'news.title' | transloco }}</h1>
        <h2 class="text text-base text-gray-300 leading-normal hidden sm:block">{{'news.subtitle' | transloco }}</h2>
      </div>
    </div>
  </div>
</section>

<section>
  <div class="bg-sapphire-900 text-center py-4 lg:px-4">
    <div class="p-2 bg-sapphire-800 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex hover:text-indigo-700 hover:bg-indigo-100"
         role="alert">
      <span class="flex rounded-full bg-indigo-500 uppercase px-2 py-1 text-xs font-bold mr-3">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
        d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"/>
</svg>
      </span>
      <a href="https://www.facebook.com/raesbloemzaden"><span class="font-semibold mr-2 text-left flex-auto">{{'cta.gotoFacebook' | transloco }}</span></a>
      <svg class="fill-current w-6 h-6 text-brand-light-green" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M13.422 20.008v-7.29h2.489l.356-2.843h-2.845V8.097c0-.8.267-1.422 1.422-1.422h1.511V4.097c-.355 0-1.244-.09-2.222-.09a3.431 3.431 0 0 0-3.644 3.734v2.134H8v2.844h2.489v7.289h2.933z"/>
      </svg>
    </div>
  </div>
</section>


<section>
  <app-news-list></app-news-list>
</section>

<section>
  <app-news-history></app-news-history>
</section>
