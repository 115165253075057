import {AvailabilityType} from './enum/availabilityType';
import {AvailabilityWeek} from './availabilityWeek';

export class Availability {
  constructor(public id: number = 0,
              public raescode: string = '',
              public productCode: string = '',
              public plantCode: string = '',
              public productName: string = '',
              public plantsPerTray: string = '',
              public noImageAvailable: boolean = false,
              public noProductAvailable: boolean = false,
              public availabilityType: AvailabilityType = AvailabilityType.CUTTINGS,
              public availabilityWeeks: AvailabilityWeek[]) {
  }

  static adapt(item: any): Availability {
    return new Availability(
      item.id,
      item.raescode,
      item.productCode,
      item.plantCode,
      item.productName,
      item.plantsPerTray,
      item.noImageAvailable,
      item.noProductAvailable,
      item.availabilityType,
      !!item.weeks ? item.weeks.map(AvailabilityWeek.adapt) : []
    );
  }

  getAvailableAmountForWeek(weekNumber: number): string {
    const availabilityWeek = this.availabilityWeeks.find(week => week.weekNumber === weekNumber);

    if (availabilityWeek === undefined || availabilityWeek === null) {
      return '';
    } else {
      if (availabilityWeek.amountAvailable === undefined || availabilityWeek.amountAvailable === null) {
        return '';
      } else {
        return String(availabilityWeek.amountAvailable);
      }
    }
  }

  getAvailableAmountForWeekAsNumber(weekNumber: number): number {
    const availabilityWeek = this.availabilityWeeks.find(week => week.weekNumber === weekNumber);

    if (availabilityWeek === undefined || availabilityWeek === null) {
      return 0;
    } else {
      if (availabilityWeek.amountAvailable === undefined || availabilityWeek.amountAvailable === null) {
        return 0;
      } else {
        return availabilityWeek.amountAvailable;
      }
    }
  }

  getPartOfImagePath(): string {
    if (this.availabilityType === AvailabilityType.SEEDLINGS) {
      return 'seeds';
    } else if (this.availabilityType === AvailabilityType.CUTTINGS) {
      return 'cuttings';
    } else {
      return 'tissueculture';
    }
  }

}
