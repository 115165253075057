import { Component } from '@angular/core';
import {NgClass} from "@angular/common";
import {TranslocoPipe} from "@jsverse/transloco";

@Component({
  selector: 'app-genetics-technical-info',
  standalone: true,
  imports: [
    NgClass,
    TranslocoPipe
  ],
  templateUrl: './genetics-technical-info.component.html',
  styles: ``
})
export class GeneticsTechnicalInfoComponent {
  private currentItem: any;

  isActive(item: string): any{
    return this.currentItem === item;
  }

  toggleItem(item: string): any {
    if (this.currentItem === item) {
      this.currentItem = false;
    } else {
      this.currentItem = item;
    }
  }
}
