<app-input
  [label]="label"
  [control]="control"
  [labelFor]="'input_' + id"
>
  <textarea [id]="'input_' + id"
            [name]="'input_' + id"
            [rows]="rows"
            class="py-3 px-4 block w-full shadow-sm focus:ring-christi-500 focus:border-christi-500 border-christi-300 rounded-md"
            [class.border]="control.dirty && !control.valid"
            [class.border-red-500]="control.dirty && !control.valid"
            [class.is-invalid]="isInvalid"
            [class.is-valid]="isValid"
            [formControl]="control">
  </textarea>
</app-input>
