import {Component, inject} from '@angular/core';
import {RouterLink} from "@angular/router";
import {NgForOf, NgIf, NgOptimizedImage} from "@angular/common";
import {AppConfigService} from "../../../core/providers/app-config.service";
import {ImagesliderService} from "../../../core/repository/imageslider.service";
import {Slide} from "../../../shared/model/slide";
import {CarouselModule} from "primeng/carousel";
import {TranslocoDirective, TranslocoPipe} from "@jsverse/transloco";
import {toSignal} from "@angular/core/rxjs-interop";
import {appRoutesLinks} from "../../../app.routes.links";

@Component({
  selector: 'app-landing-slider',
  standalone: true,
  imports: [
    RouterLink,
    NgForOf,
    CarouselModule,
    TranslocoDirective,
    NgOptimizedImage,
    TranslocoPipe,
    NgIf
  ],
  templateUrl: './landing-slider.component.html'
})


export class LandingSliderComponent {
  private slideService = inject(ImagesliderService);


  protected readonly slides = toSignal(this.slideService.getActive(), {
    initialValue: [] as Slide[]
  });

  getImageUrl(imagePath: string): string {
    return `https://s3.eu-west-3.amazonaws.com/raes.cdn/banner/${imagePath}`;
  }

  private readonly externalCDN: string;
  responsiveOptions: any[] = [
    {
      breakpoint: '1024px',
      numVisible: 1,
      numScroll: 1
    }
  ];

  public options: any = {
    wrapAround: true,
    autoPlay: 4500,
    lazyLoad: true,
    prevNextButtons: false,
    pageDots: true
  };

  constructor(private appConfig: AppConfigService) {
    this.externalCDN = `${appConfig.config.cdnBase}/banner/`;
    console.log('this.slides', this.slides());
  }

  protected readonly appRoutesLinks = appRoutesLinks;
}
