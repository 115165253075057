<div class="relative z-1 bg-white overflow-hidden">
  <div class="relative max-w-7xl z-5 mx-auto">
    <div class="relative pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
      <svg class="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2" fill="currentColor" viewBox="0 0 100 100" preserveAspectRatio="none" aria-hidden="true">
        <polygon points="50,0 100,0 50,100 0,100" />
      </svg>

      <main class="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
        <div class="sm:text-center lg:text-left">
          <h1 class="text-4xl tracking-tight font-extrabold text-my-sin-500 sm:text-5xl md:text-6xl">
            <span class="block xl:inline">{{ this.serietitle }}</span>
          </h1>
          <h2 class="text-2xl tracking-tight font-extrabold text-my-sin-500 sm:text-3xl md:text-3xl">
            <span class="block xl:inline">{{ this.seriesubtitle }}</span>
          </h2>
          <p class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">{{ 'genetics.type.desc.standard' | transloco }}</p>
          <div class="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
            <div class="rounded-md shadow">
              <a [routerLink]="seriesLink" class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gold-drop-700 hover:bg-gold-drop-800 md:py-4 md:text-lg md:px-10"> {{ 'genetics.type.goto-others' | transloco }} </a>
            </div>
            <div class="mt-3 sm:mt-0 sm:ml-3">
              <a [routerLink]="productCatalogLink"  class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gold-drop-600 hover:bg-gold-drop-800 md:py-4 md:text-lg md:px-10"> {{ 'genetics.type.goto-catalog' | transloco }}</a>
            </div>
          </div>
          <div class="pl-5 pt-5">
            <ng-container *ngIf="this.type === 'SPECIALS'">
              <ul class="list-disc">
                <li class="m-2">{{ 'genetics.type.arguments.standard.1' | transloco }}</li>
                <li class="m-2">{{ 'genetics.type.arguments.standard.2' | transloco }}</li>
                <li class="m-2">{{ 'genetics.type.arguments.standard.3' | transloco }}</li>
                <li class="m-2">{{ 'genetics.type.arguments.standard.4' | transloco }}</li>
                <li class="m-2">{{ 'genetics.type.arguments.standard.5' | transloco }}</li>
                <li class="m-2">{{ 'genetics.type.arguments.standard.6' | transloco }}</li>
                <li class="m-2">{{ 'genetics.type.arguments.standard.7' | transloco }}</li>
              </ul>
            </ng-container>
            <ng-container *ngIf="this.type === 'GARDENTYPE'">
              <ul class="list-disc">
                <li class="m-2">{{ 'genetics.type.arguments.gardentype.1' | transloco }}</li>
                <li class="m-2">{{ 'genetics.type.arguments.gardentype.2' | transloco }}</li>
                <li class="m-2">{{ 'genetics.type.arguments.gardentype.3' | transloco }}</li>
                <li class="m-2">{{ 'genetics.type.arguments.gardentype.4' | transloco }}</li>
              </ul>
            </ng-container>
            <ng-container *ngIf="this.type === 'STANDARD'">
              <ul class="list-disc">
                <li class="m-2">{{ 'genetics.type.arguments.standard.1' | transloco }}</li>
                <li class="m-2">{{ 'genetics.type.arguments.standard.2' | transloco }}</li>
                <li class="m-2">{{ 'genetics.type.arguments.standard.3' | transloco }}</li>
                <li class="m-2">{{ 'genetics.type.arguments.standard.4' | transloco }}</li>
              </ul>
            </ng-container>
          </div>
        </div>
        <div>
          <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2">
            <a *ngIf="this.type !== 'SPECIALS'" (click)="redirect(goToSerie('SPECIALS'))" class="cursor-pointer">
              <div  class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6 hover:bg-gold-drop-700">
                <dt class="text-sm font-medium text-gray-500 truncate">{{'genetics.type.switch' | transloco }}</dt>
                <dd class="mt-1 text-3xl font-semibold text-gray-900">Specials</dd>
              </div>
            </a>

            <a *ngIf="this.type !== 'GARDENTYPE'" (click)="redirect(goToSerie('GARDENTYPE'))" class="cursor-pointer">
              <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6 hover:bg-gold-drop-700">
                <dt class="text-sm font-medium text-gray-500 truncate">{{'genetics.type.switch' | transloco }}</dt>
                <dd class="mt-1 text-3xl font-semibold text-gray-900">Garden Types</dd>
              </div>
            </a>

            <a (click)="redirect(goToSerie('STANDARD'))" *ngIf="this.type !== 'STANDARD'" class="cursor-pointer">
              <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6 hover:bg-gold-drop-700">
                <dt class="text-sm font-medium text-gray-500 truncate">{{'genetics.type.switch' | transloco }}</dt>
                <dd class="mt-1 text-3xl font-semibold text-gray-900">Standard Series</dd>
              </div>
            </a>
          </dl>
        </div>
      </main>
    </div>
  </div>
  <div class="lg:absolute relative -z-4 lg:inset-y-0 lg:right-0 lg:w-1/2">
    <img class="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full" src="https://s3.eu-west-3.amazonaws.com/raes.cdn/genetics/genetics_1.jpg" alt="">
  </div>
</div>


<div class="bg-white">
  <div class="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
    <h2 class="sr-only">Products</h2>

    <div class="grid grid-cols-1 gap-y-10 sm:grid-cols-2 gap-x-6 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">

      <a [routerLink]="productDetail(product?.slug)" class="group" *ngFor="let product of seriePlants">
        <div class="w-full aspect-w-1 aspect-h-1 bg-gray-200 rounded-lg overflow-hidden xl:aspect-w-7 xl:aspect-h-8">
          <img src="{{product.publicImage}}" alt="{{product.fullnameEn}}" class="w-full h-full object-center object-cover group-hover:opacity-75">
        </div>
        <h3 class="mt-4 text-sm text-gray-700">{{product.fullnameEn}}</h3>
      </a>

    </div>
  </div>
</div>

<h2 class="mt-10">{{ this.typeTableTitle }}</h2>

<ng-container *ngIf="this.type === 'SPECIALS'">
  <div class="flex flex-col px-4">
    <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-4 inline-block min-w-full sm:px-6 lg:px-8">
        <div class="overflow-hidden">
          <table class="min-w-full text-center">
            <thead class="border-b bg-gray-800">
            <tr>
              <th scope="col" class="text-sm font-medium text-white px-6 py-4">
                {{'genetics.justintime.table.timing' | transloco }}
              </th>
              <th scope="col" class="text-sm font-medium text-white px-6 py-4">
                {{'genetics.justintime.table.variety' | transloco }}
              </th>
              <th scope="col" class="text-sm font-medium text-white px-6 py-4">
                {{'genetics.justintime.table.growth.title' | transloco }}
              </th>
              <th scope="col" class="text-sm font-medium text-white px-6 py-4">
                {{'genetics.justintime.table.flowersize.title' | transloco }}
              </th>
              <th scope="col" class="text-sm font-medium text-white px-6 py-4">
                {{'genetics.justintime.table.potsize' | transloco }}
              </th>
              <th scope="col" class="text-sm font-medium text-white px-6 py-4">
                {{'genetics.justintime.table.weeks.title' | transloco }}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr class="bg-white border-b">
              <th (click)="navigate(goToPeriod('MID_EARLY'))" rowspan="2" class="hover:bg-forest-green-400 hover:text-my-sin-500 cursor-pointer px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 bg-gold-drop-600">MID EARLY</th>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                <a title="Tinkerbells F1" class="text-forest-green-700 text-left underline hover:text-gold-drop-400" [routerLink]="productDetail('P739')  ">Tinkerbells</a>
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {{'genetics.justintime.table.growth.xc' | transloco }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {{'genetics.justintime.table.flowersize.m' | transloco }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                9
              </td>
              <td>
                <a class="hover:cursor-pointer"
                   (click)="initWeekCalendars(tinkerbellsVariety)">
                  <button type="button"
                          class="flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center text-red-600 hover:bg-gold-drop-50 hover:text-my-sin-400 focus:outline-none focus:bg-indigo-500 focus:text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                  </button>
                </a>
              </td>
            </tr>
            <tr class="bg-white border-b">
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                <a title="Zebra" class="text-forest-green-700 underline hover:text-gold-drop-400" [routerLink]="productDetail('F158')  ">Candy</a>
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {{'genetics.justintime.table.growth.xv' | transloco }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {{'genetics.justintime.table.flowersize.xl' | transloco }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                12 - 14
              </td>
              <td>
                <a class="hover:cursor-pointer"
                   (click)="initWeekCalendars(rambomidVariety)">
                  <button type="button"
                          class="flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center text-red-600 hover:bg-gold-drop-50 hover:text-my-sin-400 focus:outline-none focus:bg-indigo-500 focus:text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                  </button>
                </a>
              </td>
            </tr>
            <tr class="bg-white border-b">
              <th (click)="navigate(goToPeriod('MID_LATE'))" rowspan="3" class="hover:bg-forest-green-400 hover:text-my-sin-500 cursor-pointer px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 bg-gold-drop-400 bg-gold-drop-700">MID LATE</th>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                <a title="Double Rubens" class="text-forest-green-700 underline hover:text-gold-drop-400" [routerLink]="productDetail('X993') ">Double Rubens</a>
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {{'genetics.justintime.table.growth.xv' | transloco }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {{'genetics.justintime.table.flowersize.m' | transloco }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                12 - 14
              </td>
              <td>
                <a class="hover:cursor-pointer"
                   (click)="initWeekCalendars(doublerubensVariety)">
                  <button type="button"
                          class="flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center text-red-600 hover:bg-gold-drop-50 hover:text-my-sin-400 focus:outline-none focus:bg-indigo-500 focus:text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                  </button>
                </a>
              </td>
            </tr>
            <tr class="bg-white border-b">
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                <a title="Primula Zebra F1" class="text-forest-green-700 underline hover:text-gold-drop-400" [routerLink]="productDetail('P772') ">Zebra</a>
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {{'genetics.justintime.table.growth.c' | transloco }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {{'genetics.justintime.table.flowersize.m' | transloco }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                9 - 10,5
              </td>
              <td>
                <a class="hover:cursor-pointer"
                   (click)="initWeekCalendars(rambolateVariety)">
                  <button type="button"
                          class="flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center text-red-600 hover:bg-gold-drop-50 hover:text-my-sin-400 focus:outline-none focus:bg-indigo-500 focus:text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                  </button>
                </a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="this.type === 'STANDARD'">
  <div class="flex flex-col px-4">
    <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-4 inline-block min-w-full sm:px-6 lg:px-8">
        <div class="overflow-hidden">
          <table class="min-w-full text-center">
            <thead class="border-b bg-gray-800">
            <tr>
              <th scope="col" class="text-sm font-medium text-white px-6 py-4">
                {{'genetics.justintime.table.timing' | transloco }}
              </th>
              <th scope="col" class="text-sm font-medium text-white px-6 py-4">
                {{'genetics.justintime.table.variety' | transloco }}
              </th>
              <th scope="col" class="text-sm font-medium text-white px-6 py-4">
                {{'genetics.justintime.table.growth.title' | transloco }}
              </th>
              <th scope="col" class="text-sm font-medium text-white px-6 py-4">
                {{'genetics.justintime.table.flowersize.title' | transloco }}
              </th>
              <th scope="col" class="text-sm font-medium text-white px-6 py-4">
                {{'genetics.justintime.table.potsize' | transloco }}
              </th>
              <th scope="col" class="text-sm font-medium text-white px-6 py-4">
                {{'genetics.justintime.table.weeks.title' | transloco }}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr class="bg-white border-b">
              <th (click)="navigate(goToPeriod('VERY_EARLY'))" class="hover:bg-forest-green-400 hover:text-my-sin-500 cursor-pointer px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 bg-gold-drop-300">VERY EARLY</th>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                <a title="Primula Primus F1" class="text-forest-green-700 text-left underline hover:text-gold-drop-400" [routerLink]="productDetail('M034') ">Primus</a>
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {{'genetics.justintime.table.growth.mc' | transloco }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {{'genetics.justintime.table.flowersize.m' | transloco }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                10,5
              </td>
              <td>
                <a class="hover:cursor-pointer"
                   (click)="initWeekCalendars(primusVariety)">
                  <button type="button"
                          class="flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center text-red-600 hover:bg-gold-drop-50 hover:text-my-sin-400 focus:outline-none focus:bg-indigo-500 focus:text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                  </button>
                </a>
              </td>
            </tr>
            <tr class="bg-white border-b">
              <th (click)="navigate(goToPeriod('EARLY'))" class="hover:bg-forest-green-400 hover:text-my-sin-500 cursor-pointer px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 bg-gold-drop-500">EARLY</th>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                <a title="Primula Evie F1" class="text-forest-green-700 text-left underline hover:text-gold-drop-400" [routerLink]="productDetail('P596') ">Evie</a>
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {{'genetics.justintime.table.growth.c' | transloco }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {{'genetics.justintime.table.flowersize.l' | transloco }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                9 - 10,5
              </td>
              <td>
                <a class="hover:cursor-pointer"
                   (click)="initWeekCalendars(evieVariety)">
                  <button type="button"
                          class="flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center text-red-600 hover:bg-gold-drop-50 hover:text-my-sin-400 focus:outline-none focus:bg-indigo-500 focus:text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                  </button>
                </a>
              </td>
            </tr>
            <tr class="bg-white border-b">
              <th (click)="navigate(goToPeriod('MID_EARLY'))" rowspan="2" class="hover:bg-forest-green-400 hover:text-my-sin-500 cursor-pointer px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 bg-gold-drop-600">MID EARLY</th>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                <a title="Primula Ambie F1" class="text-forest-green-700 text-left underline hover:text-gold-drop-400" [routerLink]="productDetail('M077') ">Ambie</a>
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {{'genetics.justintime.table.growth.c' | transloco }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {{'genetics.justintime.table.flowersize.m' | transloco }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                9 - 10,5
              </td>
              <td>
                <a class="hover:cursor-pointer"
                   (click)="initWeekCalendars(tinkerbellsVariety)">
                  <button type="button"
                          class="flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center text-red-600 hover:bg-gold-drop-50 hover:text-my-sin-400 focus:outline-none focus:bg-indigo-500 focus:text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                  </button>
                </a>
              </td>
            </tr>
            <tr class="bg-white border-b">
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                <a title="Primula Rambo Mid" class="text-forest-green-700 underline hover:text-gold-drop-400" [routerLink]="productDetail('F158') ">Rambo Mid</a>
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {{'genetics.justintime.table.growth.xv' | transloco }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {{'genetics.justintime.table.flowersize.xl' | transloco }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                12 - 14
              </td>
              <td>
                <a class="hover:cursor-pointer"
                   (click)="initWeekCalendars(rambomidVariety)">
                  <button type="button"
                          class="flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center text-red-600 hover:bg-gold-drop-50 hover:text-my-sin-400 focus:outline-none focus:bg-indigo-500 focus:text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                  </button>
                </a>
              </td>
            </tr>
            <tr class="bg-white border-b">
              <th (click)="navigate(goToPeriod('MID_LATE'))" rowspan="3" class="hover:bg-forest-green-400 hover:text-my-sin-500 cursor-pointer px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 bg-gold-drop-400 bg-gold-drop-700">MID LATE</th>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                <a title="Primula Tobie" class="text-forest-green-700 underline hover:text-gold-drop-400" [routerLink]="productDetail('E998') ">Tobie</a>
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {{'genetics.justintime.table.growth.xc' | transloco }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {{'genetics.justintime.table.flowersize.m' | transloco }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                9
              </td>
              <td>
                <a class="hover:cursor-pointer"
                   (click)="initWeekCalendars(doublerubensVariety)">
                  <button type="button"
                          class="flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center text-red-600 hover:bg-gold-drop-50 hover:text-my-sin-400 focus:outline-none focus:bg-indigo-500 focus:text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                  </button>
                </a>
              </td>
            </tr>
            <tr class="bg-white border-b">
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                <a title="Primula Charlie F1" class="text-forest-green-700 text-right underline hover:text-gold-drop-400" [routerLink]="productDetail('P608') ">Charlie</a>
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {{'genetics.justintime.table.growth.c' | transloco }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {{'genetics.justintime.table.flowersize.m' | transloco }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                9 - 10,5
              </td>
              <td>
                <a class="hover:cursor-pointer"
                   (click)="initWeekCalendars(charlieVariety)">
                  <button type="button"
                          class="flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center text-red-600 hover:bg-gold-drop-50 hover:text-my-sin-400 focus:outline-none focus:bg-indigo-500 focus:text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                  </button>
                </a>
              </td>
            </tr>
            <tr class="bg-white border-b">
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                <a title="Primula Rambo Late" class="text-forest-green-700 underline hover:text-gold-drop-400" [routerLink]="productDetail('P593') ">Rambo Late</a>
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {{'genetics.justintime.table.growth.xv' | transloco }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {{'genetics.justintime.table.flowersize.xl' | transloco }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                12 - 14
              </td>
              <td>
                <a class="hover:cursor-pointer"
                   (click)="initWeekCalendars(rambolateVariety)">
                  <button type="button"
                          class="flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center text-red-600 hover:bg-gold-drop-50 hover:text-my-sin-400 focus:outline-none focus:bg-indigo-500 focus:text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                  </button>
                </a>
              </td>
            </tr>
            <tr class="bg-white border-b">
              <th (click)="navigate(goToPeriod('LATE'))" class="hover:bg-forest-green-400 hover:text-my-sin-500 cursor-pointer px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 bg-gold-drop-800">LATE</th>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-center">
                <a title="Primula Cabaret F1" class="text-forest-green-700 underline hover:text-gold-drop-400" [routerLink]="productDetail('E204') ">Cabaret</a>
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {{'genetics.justintime.table.growth.mc' | transloco }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {{'genetics.justintime.table.flowersize.l' | transloco }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                10,5
              </td>
              <td>
                <a class="hover:cursor-pointer"
                   (click)="initWeekCalendars(cabaretVariety)">
                  <button type="button"
                          class="flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center text-red-600 hover:bg-gold-drop-50 hover:text-my-sin-400 focus:outline-none focus:bg-indigo-500 focus:text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                  </button>
                </a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="this.type === 'GARDENTYPE'">
  <div class="flex flex-col px-4">
    <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-4 inline-block min-w-full sm:px-6 lg:px-8">
        <div class="overflow-hidden">
          <table class="min-w-full text-center">
            <thead class="border-b bg-gray-800">
            <tr>
              <th scope="col" class="text-sm font-medium text-white px-6 py-4">
                {{'genetics.justintime.table.timing' | transloco }}
              </th>
              <th scope="col" class="text-sm font-medium text-white px-6 py-4">
                {{'genetics.justintime.table.variety' | transloco }}
              </th>
              <th scope="col" class="text-sm font-medium text-white px-6 py-4">
                {{'genetics.justintime.table.growth.title' | transloco }}
              </th>
              <th scope="col" class="text-sm font-medium text-white px-6 py-4">
                {{'genetics.justintime.table.flowersize.title' | transloco }}
              </th>
              <th scope="col" class="text-sm font-medium text-white px-6 py-4">
                {{'genetics.justintime.table.potsize' | transloco }}
              </th>
              <th scope="col" class="text-sm font-medium text-white px-6 py-4">
                {{'genetics.justintime.table.weeks.title' | transloco }}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr class="bg-white border-b">
              <th (click)="navigate(goToPeriod('VERY_EARLY'))" class="hover:bg-forest-green-400 hover:text-my-sin-500 cursor-pointer px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 bg-gold-drop-300">VERY EARLY</th>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                <a title="Primula Primus F1" class="text-forest-green-700 text-left underline hover:text-gold-drop-400" [routerLink]="productDetail('M034') ">Primus</a>
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {{'genetics.justintime.table.growth.mc' | transloco }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {{'genetics.justintime.table.flowersize.m' | transloco }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                10,5
              </td>
              <td>
                <a class="hover:cursor-pointer"
                   (click)="initWeekCalendars(primusVariety)">
                  <button type="button"
                          class="flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center text-red-600 hover:bg-gold-drop-50 hover:text-my-sin-400 focus:outline-none focus:bg-indigo-500 focus:text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                  </button>
                </a>
              </td>
            </tr>
            <tr class="bg-white border-b">
              <th (click)="navigate(goToPeriod('EARLY'))" class="hover:bg-forest-green-400 hover:text-my-sin-500 cursor-pointer px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 bg-gold-drop-500">EARLY</th>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                <a title="Primula Evie F1" class="text-forest-green-700 text-left underline hover:text-gold-drop-400" [routerLink]="productDetail('P596') ">Evie</a>
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {{'genetics.justintime.table.growth.c' | transloco }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {{'genetics.justintime.table.flowersize.l' | transloco }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                9 - 10,5
              </td>
              <td>
                <a class="hover:cursor-pointer"
                   (click)="initWeekCalendars(evieVariety)">
                  <button type="button"
                          class="flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center text-red-600 hover:bg-gold-drop-50 hover:text-my-sin-400 focus:outline-none focus:bg-indigo-500 focus:text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                  </button>
                </a>
              </td>
            </tr>
            <tr class="bg-white border-b">
              <th (click)="navigate(goToPeriod('MID_EARLY'))" rowspan="2" class="hover:bg-forest-green-400 hover:text-my-sin-500 cursor-pointer px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 bg-gold-drop-600">MID EARLY</th>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                <a title="Primula Ambie F1" class="text-forest-green-700 text-left underline hover:text-gold-drop-400" [routerLink]="productDetail('M077') ">Ambie</a>
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {{'genetics.justintime.table.growth.c' | transloco }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {{'genetics.justintime.table.flowersize.m' | transloco }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                9 - 10,5
              </td>
              <td>
                <a class="hover:cursor-pointer"
                   (click)="initWeekCalendars(tinkerbellsVariety)">
                  <button type="button"
                          class="flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center text-red-600 hover:bg-gold-drop-50 hover:text-my-sin-400 focus:outline-none focus:bg-indigo-500 focus:text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                  </button>
                </a>
              </td>
            </tr>
            <tr class="bg-white border-b">
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                <a title="Primula Rambo Mid" class="text-forest-green-700 underline hover:text-gold-drop-400" [routerLink]="productDetail('F158') ">Rambo Mid</a>
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {{'genetics.justintime.table.growth.xv' | transloco }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {{'genetics.justintime.table.flowersize.xl' | transloco }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                12 - 14
              </td>
              <td>
                <a class="hover:cursor-pointer"
                   (click)="initWeekCalendars(rambomidVariety)">
                  <button type="button"
                          class="flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center text-red-600 hover:bg-gold-drop-50 hover:text-my-sin-400 focus:outline-none focus:bg-indigo-500 focus:text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                  </button>
                </a>
              </td>
            </tr>
            <tr class="bg-white border-b">
              <th (click)="navigate(goToPeriod('MID_LATE'))" rowspan="3" class="hover:bg-forest-green-400 hover:text-my-sin-500 cursor-pointer px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 bg-gold-drop-400 bg-gold-drop-700">MID LATE</th>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                <a title="Primula Tobie" class="text-forest-green-700 underline hover:text-gold-drop-400" [routerLink]="productDetail('E998') ">Tobie</a>
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {{'genetics.justintime.table.growth.xc' | transloco }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {{'genetics.justintime.table.flowersize.m' | transloco }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                9
              </td>
              <td>
                <a class="hover:cursor-pointer"
                   (click)="initWeekCalendars(doublerubensVariety)">
                  <button type="button"
                          class="flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center text-red-600 hover:bg-gold-drop-50 hover:text-my-sin-400 focus:outline-none focus:bg-indigo-500 focus:text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                  </button>
                </a>
              </td>
            </tr>
            <tr class="bg-white border-b">
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                <a title="Primula Charlie F1" class="text-forest-green-700 text-right underline hover:text-gold-drop-400" [routerLink]="productDetail('P608') ">Charlie</a>
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {{'genetics.justintime.table.growth.c' | transloco }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {{'genetics.justintime.table.flowersize.m' | transloco }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                9 - 10,5
              </td>
              <td>
                <a class="hover:cursor-pointer"
                   (click)="initWeekCalendars(charlieVariety)">
                  <button type="button"
                          class="flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center text-red-600 hover:bg-gold-drop-50 hover:text-my-sin-400 focus:outline-none focus:bg-indigo-500 focus:text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                  </button>
                </a>
              </td>
            </tr>
            <tr class="bg-white border-b">
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                <a title="Primula Rambo Late" class="text-forest-green-700 underline hover:text-gold-drop-400" [routerLink]="productDetail('P593') ">Rambo Late</a>
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {{'genetics.justintime.table.growth.xv' | transloco }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {{'genetics.justintime.table.flowersize.xl' | transloco }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                12 - 14
              </td>
              <td>
                <a class="hover:cursor-pointer"
                   (click)="initWeekCalendars(rambolateVariety)">
                  <button type="button"
                          class="flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center text-red-600 hover:bg-gold-drop-50 hover:text-my-sin-400 focus:outline-none focus:bg-indigo-500 focus:text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                  </button>
                </a>
              </td>
            </tr>
            <tr class="bg-white border-b">
              <th (click)="navigate(goToPeriod('LATE'))" class="hover:bg-forest-green-400 hover:text-my-sin-500 cursor-pointer px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 bg-gold-drop-800">LATE</th>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-center">
                <a title="Primula Cabaret F1" class="text-forest-green-700 underline hover:text-gold-drop-400" [routerLink]="productDetail('E204')">Cabaret</a>
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {{'genetics.justintime.table.growth.mc' | transloco }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {{'genetics.justintime.table.flowersize.l' | transloco }}
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                10,5
              </td>
              <td>
                <a class="hover:cursor-pointer"
                   (click)="initWeekCalendars(cabaretVariety)">
                  <button type="button"
                          class="flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center text-red-600 hover:bg-gold-drop-50 hover:text-my-sin-400 focus:outline-none focus:bg-indigo-500 focus:text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                  </button>
                </a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</ng-container>



<p-dialog
  header="Header"
  [(visible)]="visible"
  [modal]="true"
  [style]="{ width: '25rem' }">
  <ng-template pTemplate="header">
    <div class="inline-flex align-items-center justify-content-center gap-2">
        <span class="font-bold white-space-nowrap">
                        Week info
                    </span>
    </div>
  </ng-template>
  <div class="flex text-base text-left transform transition w-full md:inline-block md:max-w-2xl md:px-4 md:my-8 md:align-middle lg:max-w-4xl">

    <div class="w-full relative flex items-center bg-white">
      <div class="w-full relative flex items-center bg-white px-4 pt-14 pb-8 overflow-hidden shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8">
        <section aria-labelledby="options-heading" class="mt-6">
          <h2 class="mx-0 px-0 text-2xl font-extrabold text-gray-900">{{ currentSerie.variety }}</h2>
          <div class="flex flex-col align-middle">
            <h3 class="mx-0 px-0 text-gold-drop-800">{{ 'genetics.justintime.popup.pottingweeks' | transloco }}</h3>
            <div class="flex flex-row">
              <div class="w-6 lg:w-12 bg-christi-500 border-2 gap-4 text-center" *ngFor="let week of currentSerie.potweeks">{{ week }}</div>
            </div>
            <h3 class="mx-0 px-0 text-gold-drop-800">{{ 'genetics.justintime.popup.floweringweeks' | transloco }}</h3>
            <div class="flex flex-row">
              <div class="w-6 lg:w-12 bg-gold-drop-600 border-2 gap-4 text-center" *ngFor="let week of currentSerie.floweringweeks">{{ week }}</div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <p-button
      label="Close"
      [text]="true"
      severity="secondary"
      (click)="visible = false" />
  </ng-template>
</p-dialog>
