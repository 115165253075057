import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Job} from "../../../../shared/model/job";
import {JobService} from "../../../../core/repository/job.service";
import {NgIf} from "@angular/common";
import {TranslocoDirective} from "@jsverse/transloco";

@Component({
  selector: 'app-job-detail',
  standalone: true,
  imports: [
    NgIf,
    TranslocoDirective
  ],
  templateUrl: './job-detail.component.html',
  styles: ``
})
export class JobDetailComponent implements OnInit, OnChanges {
  @Input() jobId: number | undefined;

  public job: Job | undefined;

  constructor(private jobService: JobService) {
  }

  ngOnInit(): void {
    if (this.jobId !== undefined) {
      this.fetchJob(this.jobId);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.jobId !== undefined) {
      this.fetchJob(this.jobId);
    }
  }

  private fetchJob(jobId: number): void {
    this.jobService.getJob(jobId).subscribe((job: Job) => {
      this.job = job;
    });
  }
}
