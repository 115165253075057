<div class="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
  <div class="absolute inset-0">
    <div class="bg-white h-1/3 sm:h-2/3"></div>
  </div>

  <div class="relative max-w-7xl mx-auto">
    <div class="m-5 flex flex-row flex-wrap content-center justify-around">
      <app-cta-button buttonText="{{'cta.gotoPlants' | transloco }}" [link]="appRoutesLinks.PLANT_OVERVIEW_PAGE"></app-cta-button>
    </div>
    <!-- Modified grid layout -->
    <div class="mt-12 mx-auto grid gap-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 justify-items-center">
      <div class="category-card h-64 w-64 cursor-pointer" pTooltip="{{ 'dashboard.landing-categories.growEat' | transloco }}"
           [routerLink]="appRoutesLinks.PRODUCTS_PAGE" [queryParams]="{ categories: 'GROWEAT' }">
        <img src="/assets/images/grow-and-eat_1.jpg" alt="">
        <img class="back" src="/assets/images/grow-and-eat_2.jpg" alt="">
      </div>

      <div class="category-card h-64 w-64 cursor-pointer" pTooltip="{{ 'dashboard.landing-categories.fabulousFoliage' | transloco }}"
           [routerLink]="appRoutesLinks.PRODUCTS_PAGE" [queryParams]="{ categories: 'FOLIAGE' }">
        <img src="/assets/images/fabulous-foliage_1.jpg" alt="Fabulous Foliage">
        <img class="back" src="/assets/images/fabulous-foliage_2.jpg" alt="Fabulous Foliage">
      </div>

      <div class="category-card h-64 w-64 cursor-pointer" pTooltip="{{ 'dashboard.landing-categories.general' | transloco }}"
           [routerLink]="appRoutesLinks.PRODUCTS_PAGE" [queryParams]="{ categories: 'GENERAL' }">
        <img src="/assets/images/general_1.jpg" alt="General">
        <img class="back" src="/assets/images/general_2.jpg" alt="General">
      </div>

      <div class="category-card h-64 w-64 cursor-pointer" pTooltip="{{ 'dashboard.landing-categories.moreThanGrasses' | transloco }}"
           [routerLink]="appRoutesLinks.PRODUCTS_PAGE" [queryParams]="{ categories: 'GRASSES' }">
        <img src="/assets/images/more-than-grasses_1.jpg" alt="Grasses">
        <img class="back" src="/assets/images/more-than-grasses_2.jpg" alt="Grasses">
      </div>

      <div class="category-card h-64 w-64 cursor-pointer" pTooltip="{{ 'dashboard.landing-categories.trio' | transloco }}"
           [routerLink]="appRoutesLinks.PRODUCTS_PAGE" [queryParams]="{ categories: 'TRIO' }">
        <img src="/assets/images/trio_1.jpg" alt="Trio">
        <img class="back" src="/assets/images/trio_2.jpg" alt="Trio">
      </div>

      <!-- temporary disable because no image yet -->
      <!--
      <div class="category-card h-64 w-64 cursor-pointer" pTooltip="{{ 'dashboard.landing-categories.sassySucculents' | transloco }}"
           [routerLink]="appRoutesLinks.PRODUCTS_PAGE" [queryParams]="{ categories: 'SASSY' }">
        <img src="/assets/images/sassy_1.jpg" alt="Sassy Succulents">
        <img class="back" src="/assets/images/sassy_2.jpg" alt="Sassy Succulents">
      </div>
      -->
    </div>
  </div>
</div>
