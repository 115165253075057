<form *ngIf="form" [formGroup]="form">

  <!-- Name -->
  <fieldset class="mt-6">
    <div class="card flex">
      <p-floatlabel class="w-full">
        <input pInputText id="name" formControlName="name" class="w-full text-base font-medium text-gray-700"/>
        <label for="name" class="text-gray-700">{{ 'products.overview.filter.searchPlant' | transloco }}</label>
      </p-floatlabel>
    </div>
  </fieldset>

  <fieldset class="mt-6">
    <div class="card flex">
      <p-multiselect
        [options]="categoryOptions"
        formControlName="categories"
        variant="filled"
        optionValue="value"
        optionLabel="label"
        resetFilterOnHide="true"
        [filter]="false"
        [showClear]="true"
        [showHeader]="false"
        placeholder="{{ 'products.overview.filter.category' | transloco }}"
        class="w-full"></p-multiselect>
    </div>
  </fieldset>

  <!-- Product Types -->
  <fieldset class="mt-6">
    <div class="card flex">
      <p-multiselect
        [options]="productTypeOptions"
        formControlName="productTypes"
        variant="filled"
        optionValue="value"
        optionLabel="label"
        resetFilterOnHide="true"
        [filter]="false"
        [showClear]="true"
        [showHeader]="false"
        placeholder="{{ 'products.overview.filter.productType' | transloco }}"
        class="w-full"></p-multiselect>
    </div>
  </fieldset>


  <!-- Lifetime -->

  <fieldset class="mt-6">
    <div class="card flex">
      <p-multiselect
        [options]="lifetimeOptions"
        formControlName="lifetimes"
        variant="filled"
        optionValue="value"
        optionLabel="label"
        resetFilterOnHide="true"
        [filter]="false"
        [showClear]="true"
        [showHeader]="false"
        placeholder="{{ 'products.overview.filter.lifetime' | transloco }}"
        class="w-full">
        <ng-template let-lifetime pTemplate="item">
          <div class="flex items-center gap-2">
            <img
              src="assets/icon/{{lifetime.value.toLowerCase()}}.svg"
              style="width: 18px"
            />
            <div>{{ lifetime.label }}</div>
          </div>
        </ng-template>
      </p-multiselect>
    </div>
  </fieldset>

  <!-- Stand -->

  <fieldset class="mt-6">
    <div class="card flex">
      <p-multiselect
        [options]="standOptions"
        formControlName="stands"
        variant="filled"
        optionValue="value"
        optionLabel="label"
        [showClear]="true"
        resetFilterOnHide="true"
        [filter]="false"
        [showHeader]="false"
        placeholder="{{ 'products.overview.filter.stand' | transloco }}"
        class="w-full">
        <ng-template let-stand pTemplate="item">
          <div class="flex items-center gap-2">
            <img
              src="assets/icon/{{stand.value.toLowerCase()}}.svg"
              style="width: 18px"
            />
            <div>{{ stand.label }}</div>
          </div>
        </ng-template>
      </p-multiselect>
    </div>
  </fieldset>


  <!-- Usage -->

  <fieldset class="mt-6">
    <div class="card flex">
      <p-multiselect
        [options]="usageOptions"
        formControlName="usages"
        variant="filled"
        optionValue="value"
        optionLabel="label"
        [showClear]="true"
        resetFilterOnHide="true"
        [filter]="true"
        [maxSelectedLabels]="2"
        placeholder="{{ 'products.overview.filter.usage' | transloco }}"
        class="w-full">
        <ng-template let-usage pTemplate="item">
          <div class="flex items-center gap-2">
            <img
              src="assets/icon/{{usage.value.toLowerCase()}}.svg"
              style="width: 18px"
            />
            <div>{{ usage.label }}</div>
          </div>
        </ng-template>
      </p-multiselect>
    </div>
  </fieldset>


  <!-- Genus -->

  <fieldset class="mt-6">
    <div class="card flex">
      <p-multiselect
        [options]="genusOptions"
        formControlName="geni"
        variant="filled"
        optionValue="value"
        optionLabel="label"
        resetFilterOnHide="true"
        [showClear]="true"
        [filter]="true"
        [maxSelectedLabels]="2"
        placeholder="{{ 'products.overview.filter.genus' | transloco }}"
        class="w-full">
      </p-multiselect>
    </div>
  </fieldset>

  <!-- Highlights -->
  <fieldset class="mt-6">
    <div>
      <legend
        class="block text-sm font-medium text-gray-700">{{ 'products.overview.filter.others' | transloco }}
      </legend>
    </div>
    <div class="mt-4 space-y-4">
      <div class="flex items-center">
        <p-checkbox
          formControlName="special"
          [binary]="true"
          id="spl"
          inputId="spl"/>
        <label for="spl" class="ml-3 block text-sm font-medium text-gray-700">
          {{ 'products.overview.filter.specials' | transloco }}
        </label>
      </div>
      <div class="flex items-center">
        <p-checkbox
          formControlName="improved"
          [binary]="true"
          id="improved"
          inputId="improved"/>
        <label for="improved" class="ml-3 block text-sm font-medium text-gray-700">
          {{ 'products.overview.filter.improved' | transloco }}
        </label>
      </div>
      <div class="flex items-center">
        <p-checkbox
          formControlName="newPlant"
          [binary]="true"
          id="newPlant"
          inputId="newPlant"/>
        <label for="newPlant" class="ml-3 block text-sm font-medium text-gray-700">
          {{ 'products.overview.filter.newPlant' | transloco }}
        </label>
      </div>
      <div class="flex items-center">
        <p-checkbox
          formControlName="beeFriendly"
          [binary]="true"
          id="beeFriendly"
          inputId="beeFriendly"/>
        <label for="beeFriendly" class="ml-3 block text-sm font-medium text-gray-700">
          <div class="flex items-center gap-2">
            <i>
              <img src="assets/icon/bee-friendly.svg" alt="Scented" class="w-4 h-4"/>
            </i>
            {{ 'products.overview.filter.beeFriendly' | transloco }}
          </div>
        </label>
      </div>
      <div class="flex items-center">
        <p-checkbox
          formControlName="spiciness"
          [binary]="true"
          id="spiciness"
          inputId="spiciness"/>
        <label for="spiciness" class="ml-3 block text-sm font-medium text-gray-700">
          <div class="flex items-center gap-2">
            <i>
              <img src="assets/icon/hot.svg" alt="Hot" class="w-4 h-4"/>
            </i>
            {{ 'products.overview.filter.spiciness' | transloco }}
          </div>
        </label>
      </div>
      <div class="flex items-center">
        <p-checkbox
          formControlName="raesGenetics"
          [binary]="true"
          id="raesGenetics"
          inputId="raesGenetics"/>
        <label for="raesGenetics" class="ml-3 block text-sm font-medium text-gray-700">
          <div class="flex items-center gap-2">
          <i>
            <img src="assets/icon/raes-breeding.svg" alt="Scented" class="w-4 h-4"/>
          </i>
          {{ 'products.overview.filter.raesGenetics' | transloco }}
          </div>
        </label>
      </div>
      <div class="flex items-center">
        <p-checkbox
          formControlName="scented"
          [binary]="true"
          id="sctd"
          inputId="sctd">
          <ng-template pTemplate="icon">
            <img src="assets/icon/scented.svg" alt="Scented" class="inline-block h-4 w-4"/>
          </ng-template>
        </p-checkbox>
        <label for="sctd" class="ml-3 block text-sm font-medium text-gray-700 flex items-center gap-2">
          <div class="flex items-center gap-2">
          <i>
            <img src="assets/icon/scented.svg" alt="Scented" class="w-4 h-4"/>
          </i>
          {{ 'products.overview.filter.scented' | transloco }}
          </div>
        </label>
      </div>
    </div>
  </fieldset>

  <fieldset>
    <div class="my-5">
      <button (click)="reset.emit($event)"
              class="inline-flex items-center px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
        {{ 'products.overview.filter.clear' | transloco }}
      </button>
    </div>
  </fieldset>

</form>
