import {Component} from '@angular/core';
import {AboutHistoryItemComponent} from "../about-history-item/about-history-item.component";
import {NgForOf} from "@angular/common";
import {TranslocoDirective, TranslocoService} from "@jsverse/transloco";

@Component({
  selector: 'app-about-history-slider',
  standalone: true,
  imports: [
    AboutHistoryItemComponent,
    NgForOf,
    TranslocoDirective
  ],
  templateUrl: './about-history-slider.component.html'
})
export class AboutHistorySliderComponent {
  public history = [{
    image: 'https://d3coqd91sswgmf.cloudfront.net/about/founders_raes_bloemzaden.jpg',
    name: '1927',
    designation: this.translocoService.translate('about.history.1927.title'),
    item1: this.translocoService.translate('about.history.1927.item1'),
    item2: this.translocoService.translate('about.history.1927.item2'),
    item3: this.translocoService.translate('about.history.1927.item3')
  }, {
    image: 'https://d3coqd91sswgmf.cloudfront.net/about/greenhouses.jpg',
    name: '1952',
    designation: this.translocoService.translate('about.history.1952.title'),
    item1: this.translocoService.translate('about.history.1952.item1'),
    item2: this.translocoService.translate('about.history.1952.item2'),
    item3: this.translocoService.translate('about.history.1952.item3'),
    item4: this.translocoService.translate('about.history.1952.item4')
  }, {
    image: 'https://d3coqd91sswgmf.cloudfront.net/about/powerplugs.jpg',
    name: '1977',
    designation: this.translocoService.translate('about.history.1977.title'),
    item1: this.translocoService.translate('about.history.1977.item1'),
    item2: this.translocoService.translate('about.history.1977.item2'),
    item3: this.translocoService.translate('about.history.1977.item3'),
    item4: this.translocoService.translate('about.history.1977.item4'),
    item5: this.translocoService.translate('about.history.1977.item5'),
    item6: this.translocoService.translate('about.history.1977.item6'),
    item7: this.translocoService.translate('about.history.1977.item7')
  }, {
    image: 'https://d3coqd91sswgmf.cloudfront.net/about/new_greenhouses.jpg',
    name: '2000',
    designation: this.translocoService.translate('about.history.2020.title'),
    item1: this.translocoService.translate('about.history.2020.item1'),
    item2: this.translocoService.translate('about.history.2020.item2'),
    item3: this.translocoService.translate('about.history.2020.item3'),
    item4: this.translocoService.translate('about.history.2020.item4'),
    item5: this.translocoService.translate('about.history.2020.item5'),
    item6: this.translocoService.translate('about.history.2020.item6')
  }];

  constructor(public translocoService: TranslocoService) {
  }

}
