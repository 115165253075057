<ng-container *transloco="let t; prefix: 'about'">

  <section class="text-green-900 relative z-0">
    <div class="hero-image bg-right-bottom justify-center md:justify-start md:pl-5 bg-cover flex sm:bg-none"
         style="background-image: url(https://d3coqd91sswgmf.cloudfront.net/header/about_header.jpg);">
      <div class="container justify-center md:justify-start flex items-end z-10">
        <div class="content float-left py-4 px-5 my-5 bg-christi-700 rounded-md bg-opacity-90">
          <h1 class="heading text-gray-100 mb-3 text-2xl">{{ t('title') }}</h1>
          <h2 class="text text-base text-gray-300 leading-normal hidden sm:block">{{ t('subtitle') }}</h2>
        </div>
      </div>
    </div>
  </section>

  <section>

    <div class="py-16 xl:py-20 px-4 sm:px-6 lg:px-8 bg-white overflow-hidden">
      <div class="max-w-max lg:max-w-7xl mx-auto relative"> <!-- Added relative here -->
        <div class="z-[5] mb-8 md:mb-2 md:px-6 relative"> <!-- Added relative here -->
          <div class="text-base max-w-prose lg:max-w-none">
            <h2 class="leading-8 text-sapphire-600 font-semibold tracking-wide uppercase">{{ t('intro.title') }}</h2>
          </div>
        </div>
        <div class="relative">
          <svg class="hidden md:block absolute top-0 right-0 -mt-20 -mr-20 z-[1]" width="404" height="384" fill="none"
               viewBox="0 0 404 384" aria-hidden="true">
            <defs>
              <pattern id="95e8f2de-6d30-4b7e-8159-f791729db21b" x="0" y="0" width="20" height="20"
                       patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor"/>
              </pattern>
            </defs>
            <rect width="404" height="384" fill="url(#95e8f2de-6d30-4b7e-8159-f791729db21b)"/>
          </svg>
          <svg class="hidden md:block absolute bottom-0 left-0 -mb-20 -ml-20" width="404" height="384" fill="none"
               viewBox="0 0 404 384" aria-hidden="true">
            <defs>
              <pattern id="7a00fe67-0343-4a3c-8e81-c145097a3ce0" x="0" y="0" width="20" height="20"
                       patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor"/>
              </pattern>
            </defs>
            <rect width="404" height="384" fill="url(#7a00fe67-0343-4a3c-8e81-c145097a3ce0)"/>
          </svg>
          <div class="relative md:bg-white md:p-6">
            <div class="lg:grid lg:grid-cols-2 lg:gap-6">
              <div class="text-gray-800 lg:max-w-none">
                {{ t('intro.p1') }}
              </div>
              <div class="mt-6 text-gray-500 lg:mt-0 z-900">
                {{ t('intro.p2') }}
              </div>
            </div>
            <div class="mt-8 inline-flex rounded-md shadow">
              <app-cta-button buttonText="{{ t('cta.gotoSales') }}"
                              [link]="appRoutesLinks.CONTACT_PAGE"></app-cta-button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </section>

  <section>
    <app-about-history-slider></app-about-history-slider>
    <app-about-testimonials></app-about-testimonials>
  </section>

  <section>
    <app-unique-selling-points></app-unique-selling-points>
  </section>

</ng-container>
