<ng-container *transloco="let t; prefix: 'about.history'">

  <div class="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
    <div class="absolute inset-0">
      <div class="bg-white h-1/3 sm:h-2/3"></div>
    </div>
    <div class="relative max-w-7xl mx-auto">
      <div class="text-center">
        <h2>{{ t('title') }}</h2>
      </div>
      <div class="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-4 lg:max-w-none">
        <app-about-history-item [history]="historyItem" *ngFor="let historyItem of history"></app-about-history-item>
      </div>
    </div>
  </div>

</ng-container>
