import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AppConfigService} from '../providers/app-config.service';
import {Orderform} from "../../shared/model/orderform";

@Injectable({
  providedIn: 'root'
})
export class OrderFormService {
  private readonly publicApi: string;

  constructor(private http: HttpClient, private appConfig: AppConfigService) {
    this.publicApi = this.appConfig.config.apiBase;
  }

  getActive(): Observable<Orderform[]> {
    const orderFormsUrl = `${this.publicApi}/orderforms`;
    return this.http.get<Orderform[]>(orderFormsUrl).pipe(
      map(data => data.map(Orderform.adapt))
    );
  }


}
