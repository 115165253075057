import { Routes } from '@angular/router';
import {DashboardComponent} from "./features/dashboard/dashboard.component";
import {WrapperComponent} from "./shared/layout/wrapper/wrapper.component";
import {ContactComponent} from "./features/contact/contact/contact.component";
import {PrivacyComponent} from "./features/company/privacy/privacy.component";
import {DisclaimerComponent} from "./features/company/disclaimer/disclaimer.component";
import {AboutUsComponent} from "./features/company/about-us/about-us.component";
import {JobsComponent} from "./features/company/jobs/jobs.component";
import {NewsComponent} from "./features/company/news/news.component";
import {NewsDetailComponent} from "./features/company/news/news-detail.component";
import {OrderFormsComponent} from "./features/orderforms/orderForms/orderForms.component";
import {CatalogComponent} from "./features/catalog/catalog.component";
import {AvailabilityComponent} from "./features/availability/availability.component";
import {GeneticsIntroComponent} from "./features/genetics/genetics-intro/genetics-intro.component";
import {PlantsComponent} from "./features/plants/plants/plants.component";
import {JobDetailComponent} from "./features/company/jobs/job-detail/job-detail.component";
import {GeneticsCultureComponent} from "./features/genetics/genetics-culture/genetics-culture.component";
import {GeneticsHistoryComponent} from "./features/genetics/genetics-history/genetics-history.component";
import {GeneticsJustInTimeComponent} from "./features/genetics/genetics-just-in-time/genetics-just-in-time.component";
import {GeneticsTypesComponent} from "./features/genetics/genetics-types/genetics-types.component";
import {FloweringperiodsComponent} from "./features/genetics/floweringperiods/floweringperiods.component";
import {GeneticsNewComponent} from "./features/genetics/genetics-new/genetics-new.component";
import {PlantComponent} from "./features/plant/plant.component";
import {JobApplyComponent} from "./features/company/jobs/job-apply/job-apply.component";
import {GeneticsTypeComponent} from "./features/genetics/genetics-type/genetics-type.component";
import {FlowerperiodComponent} from "./features/genetics/floweringperiod/floweringperiod.component";

export const routes: Routes = [
  { path: '',
    component: WrapperComponent,
    children: [
      {path: '', component: DashboardComponent},
      {path: 'about', component: AboutUsComponent},
      {path: 'availability', component: AvailabilityComponent},
      {path: 'contact', component: ContactComponent, title:'Contact'},
      {path: 'catalogs', component: CatalogComponent},
      {path: 'disclaimer', component: DisclaimerComponent},
      {path: 'genetics', component: GeneticsIntroComponent},
      {path: 'genetics/culture', component: GeneticsCultureComponent},
      {path: 'genetics/history', component: GeneticsHistoryComponent},
      {path: 'genetics/justintime', component: GeneticsJustInTimeComponent},
      {path: 'genetics/primula/period/:floweringperiod', component: FlowerperiodComponent},
      {path: 'genetics/primula/floweringperiods', component: FloweringperiodsComponent},
      {path: 'genetics/primula/type/:type', component: GeneticsTypeComponent},
      {path: 'genetics/primula/types', component: GeneticsTypesComponent},
      {path: 'genetics/primula/new', component: GeneticsNewComponent},
      {path: 'genetics/primula/overview', component: GeneticsNewComponent},
      {path: 'products', component: PlantsComponent},
      {path: 'plants', component: PlantsComponent},
      {path: 'products/:slugOrRaesCode', component: PlantComponent},
      {path: 'plants/:slugOrRaesCode', component: PlantsComponent},
      {path: 'privacy', component: PrivacyComponent},
      {path: 'jobs', component: JobsComponent},
      {path: 'jobs/apply/:id', component: JobApplyComponent},
      {path: 'jobs/:id', component: JobDetailComponent},
      {path: 'news', component: NewsComponent},
      {path: 'news/:id', component: NewsDetailComponent},
      {path: 'orderforms', component: OrderFormsComponent},
      {path: 'news:id', component: NewsDetailComponent},
      { path: '**', component: DashboardComponent }
    ] },
];
