import {Component} from '@angular/core';
import {GeneticsHistoryComponent} from "../genetics-history/genetics-history.component";
import {GeneticsNewsComponent} from "../genetics-news/genetics-news.component";
import {RouterLink} from "@angular/router";
import {NgIf} from "@angular/common";
import {SafePipe} from "../../../shared/pipes/safe.pipe";
import {appRoutesLinks} from "../../../app.routes.links";
import {TranslocoDirective, TranslocoPipe, TranslocoService} from "@jsverse/transloco";
import {Meta, Title} from "@angular/platform-browser";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-genetics-intro',
  standalone: true,
    imports: [
        GeneticsHistoryComponent,
        GeneticsNewsComponent,
        RouterLink,
        NgIf,
        SafePipe,
        TranslocoPipe,
        TranslocoDirective
    ],
  templateUrl: './genetics-intro.component.html',
  styles: ``
})
export class GeneticsIntroComponent {
  productsLink = appRoutesLinks.PLANT_OVERVIEW_PAGE;

  constructor(protected translocoService: TranslocoService,
              private titleService: Title,
              private metaService: Meta
  ) {
    let lang: string = this.translocoService.getActiveLang();

    let title: string;
    let description: string;
    let keywords: string;
    let translationSubscription: Subscription;

    // Use selectTranslate for the first department option
    translationSubscription = this.translocoService.selectTranslate('genetics.intro.title').subscribe(value => {
      title = value;
      this.titleService.setTitle(title);
    });
    translationSubscription = this.translocoService.selectTranslate('genetics.intro.description').subscribe(value => {
      description = value;
      this.metaService.updateTag({name: 'description', content: description});
    });
    translationSubscription = this.translocoService.selectTranslate('genetics.intro.keywords').subscribe(value => {
      keywords = value;
      if (typeof keywords !== 'undefined') {
        this.metaService.updateTag({name: 'keywords', content: keywords});
      }    });
    translationSubscription.unsubscribe();
  }
}
