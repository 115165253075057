<section class="text-green-900 relative z-0">
  <div class="hero-image bg-right-bottom bg-cover flex sm:bg-none"
       style="background-image: url(https://d3coqd91sswgmf.cloudfront.net/header/orderforms_header.jpg);">
    <div class="relative container justify-center md:justify-start md:pl-5 flex items-end z-10">
      <div>
        <div
          class="content float-left justify-center md:justify-start py-4 px-5 my-5 bg-christi-700 rounded-md bg-opacity-90">
          <h1 class="heading text-gray-100 mb-3 text-2xl">{{ 'orders.title' | transloco }}</h1>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="bg-gray-200 m-5 p-5 rounded-md">
  <form *ngIf="form" [formGroup]="form" novalidate class="m-5">
    <div class="sm:hidden">
      <label for="tabs" class="sr-only">Select a tab</label>
      <select id="tabs" name="tabs" formControlName="selectedTab"
              class="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md">
        <option value="belgium">{{ 'orders.belgium.title' | transloco }}</option>
        <option value="netherlands">{{ 'orders.netherlands' | transloco }}</option>
        <option value="germany">{{ 'orders.germany.title' | transloco }}</option>
        <option value="france">{{ 'orders.france' | transloco }}</option>
        <option value="uk">{{ 'orders.uk' | transloco }}</option>
        <option value="ireland">{{ 'orders.ireland' | transloco }}</option>
      </select>
    </div>
    <div class="hidden sm:block">
      <nav class="flex space-x-4" aria-label="Tabs">
        <h3 (click)="toggle('belgium')"
            [ngClass]="{ 'bg-indigo-100 text-indigo-700': isActive('belgium'), 'text-gray-500 hover:text-gray-700 cursor-pointer': !isActive('belgium') }"
            class="px-3 py-2 font-medium text-sm rounded-md">
          {{ 'orders.belgium.title' | transloco }}
        </h3>

        <h3 (click)="toggle('netherlands')"
            [ngClass]="{ 'bg-indigo-100 text-indigo-700': isActive('netherlands'), 'text-gray-500 hover:text-gray-700 cursor-pointer': !isActive('netherlands') }"
            class="px-3 py-2 font-medium text-sm rounded-md">
          {{ 'orders.netherlands' | transloco }}
        </h3>

        <!--
        <h3 (click)="toggle('germany')"
            [ngClass]="{ 'bg-indigo-100 text-indigo-700': isActive('germany'), 'text-gray-500 hover:text-gray-700 cursor-pointer': !isActive('germany') }"
            class="px-3 py-2 font-medium text-sm rounded-md">
          {{ 'orders.germany.title' | transloco }}
        </h3>
        -->
        <h3 (click)="toggle('france')"
            [ngClass]="{ 'bg-indigo-100 text-indigo-700': isActive('france'), 'text-gray-500 hover:text-gray-700 cursor-pointer': !isActive('france') }"
            class="px-3 py-2 font-medium text-sm rounded-md">
          {{ 'orders.france' | transloco }}
        </h3>

        <h3 (click)="toggle('uk')"
            [ngClass]="{ 'bg-indigo-100 text-indigo-700': isActive('uk'), 'text-gray-500 hover:text-gray-700 cursor-pointer': !isActive('uk') }"
            class="px-3 py-2 font-medium text-sm rounded-md">
          {{ 'orders.uk' | transloco }}
        </h3>

        <h3 (click)="toggle('ireland')"
            [ngClass]="{ 'bg-indigo-100 text-indigo-700': isActive('ireland'), 'text-gray-500 hover:text-gray-700 cursor-pointer': !isActive('ireland') }"
            class="px-3 py-2 font-medium text-sm rounded-md">
          {{ 'orders.ireland' | transloco }}
        </h3>
      </nav>
    </div>
  </form>

  <div>
    <!-- belgium -->
    <div [class.hidden]="!isActive('belgium')">
      <div class="bg-gray-100 p-5 rounded-md">
        <p class="mb-2">
          {{ 'orders.belgium.info-p1' | transloco }}
        </p>
        <p class="mb-2">
          {{ 'orders.belgium.info-p1-2' | transloco }}
        </p>
        <ul class="list-disc pl-5 pb-5">
          <li><span class="font-bold capitalize pr-1">{{ 'orders.belgium-north' | transloco }}:</span>{{ 'orders.belgium-north-info' | transloco }}</li>
          <li><span class="font-bold capitalize pr-1">{{ 'orders.belgium-south' | transloco }}:</span>{{ 'orders.belgium-south-info' | transloco }}</li>
        </ul>
        <p class="mb-2">
          {{ 'orders.belgium.info-p2' | transloco }}
        </p>
        <p class="mb-2">
          {{ 'orders.belgium.info-p3' | transloco }}
        </p>
        <p>
          {{ 'orders.belgium.info-p4' | transloco }}
        </p>
      </div>
      <p class="text-gold-drop-800 m-5"><a [routerLink]="contactLink">{{ 'orders.contactus' | transloco }}</a>
      </p>

      <h4>{{ 'orders.spring' | transloco }}</h4>

      <ng-container *ngIf="orderForms$ | async as orderForms">
        <ng-container *ngIf="(orderForms | orderFormFilter:'belgium_north|belgium':'SPRING').length > 0">
          <h5 class="m-5 font-bold capitalize">{{ 'orders.belgium-north' | transloco }}</h5>
          <app-orderFormList
            *ngIf="orderForms$ | async as orderForms"
            [orderForms]="(orderForms | orderFormFilter:'belgium_north|belgium':'SPRING')">
          </app-orderFormList>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="orderForms$ | async as orderForms">
        <ng-container *ngIf="(orderForms | orderFormFilter:'belgium_south|belgium':'SPRING').length > 0">
          <h5 class="m-5 font-bold capitalize">{{ 'orders.belgium-south' | transloco }}</h5>
          <app-orderFormList
            *ngIf="orderForms$ | async as orderForms"
            [orderForms]="orderForms | orderFormFilter:'belgium_south|belgium':'SPRING'"></app-orderFormList>
        </ng-container>
      </ng-container>

      <h4>{{ 'orders.autumn' | transloco }}</h4>

      <ng-container *ngIf="orderForms$ | async as orderForms">
        <ng-container *ngIf="(orderForms | orderFormFilter:'belgium_north|belgium':'AUTUMN').length > 0">
          <h5 class="m-5 font-bold capitalize">{{ 'orders.belgium-north' | transloco }}</h5>
          <app-orderFormList
            [orderForms]="orderForms | orderFormFilter:'belgium_north|belgium':'AUTUMN'"></app-orderFormList>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="orderForms$ | async as orderForms">
        <ng-container *ngIf="(orderForms | orderFormFilter:'belgium_south|belgium':'AUTUMN').length > 0">
          <h5 class="m-5 font-bold capitalize">{{ 'orders.belgium-south' | transloco }}</h5>
          <app-orderFormList
            *ngIf="orderForms$ | async as orderForms"
            [orderForms]="orderForms | orderFormFilter:'belgium_south|belgium':'AUTUMN'"></app-orderFormList>
        </ng-container>
      </ng-container>
    </div>

    <!-- netherlands -->
    <div [class.hidden]="!isActive('netherlands')">
      <div class="bg-gray-100 p-5 rounded-md">
        <p class="mb-2">
          {{ 'orders.belgium.info-p1' | transloco }}
        </p>
        <p class="mb-2">
          {{ 'orders.belgium.info-p2' | transloco }}
        </p>
        <p>
          {{ 'orders.belgium.info-p4' | transloco }}
        </p>
      </div>

      <ng-container *ngIf="orderForms$ | async as orderForms">
        <ng-container *ngIf="(orderForms | orderFormFilter:'netherlands':'SPRING').length > 0">
          <h4>{{ 'orders.spring' | transloco }}</h4>
          <app-orderFormList
            *ngIf="orderForms$ | async as orderForms"
            [orderForms]="orderForms | orderFormFilter:'netherlands':'SPRING'"></app-orderFormList>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="orderForms$ | async as orderForms">
        <ng-container *ngIf="(orderForms | orderFormFilter:'netherlands':'AUTUMN').length > 0">
          <h4>{{ 'orders.autumn' | transloco }}</h4>
          <app-orderFormList
            [orderForms]="orderForms | orderFormFilter:'netherlands':'AUTUMN'"></app-orderFormList>
        </ng-container>
      </ng-container>
    </div>

    <!-- germany -->
    <div [class.hidden]="!isActive('germany')">
      <div class="bg-gray-100 p-5 rounded-md">
        <p class="mb-2">
          {{ 'orders.belgium.info-p1' | transloco }}
        </p>
        <p class="mb-2">
          {{ 'orders.belgium.info-p2' | transloco }}
        </p>
        <p class="mb-2">
          {{ 'orders.belgium.info-p4' | transloco }}
        </p>
        <p>
          {{ 'orders.germany.seedlings' | transloco }}<a class="underline text-gold-drop-600" target="_blank"
                                                         href="https://kloer-gartenbau-gb.chayns.net/">Kloer
          Gartenbau</a>
        </p>
      </div>

      <ng-container *ngIf="orderForms$ | async as orderForms">
        <ng-container *ngIf="(orderForms | orderFormFilter:'germany':'SPRING').length > 0">
          <h4>{{ 'orders.spring' | transloco }}</h4>
          <app-orderFormList
            *ngIf="orderForms$ | async as orderForms"
            [orderForms]="orderForms | orderFormFilter:'germany':'SPRING'"></app-orderFormList>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="orderForms$ | async as orderForms">
        <ng-container *ngIf="(orderForms | orderFormFilter:'germany':'AUTUMN').length > 0">
          <h4>{{ 'orders.autumn' | transloco }}</h4>
          <app-orderFormList
            *ngIf="orderForms$ | async as orderForms"
            [orderForms]="orderForms | orderFormFilter:'germany':'AUTUMN'"></app-orderFormList>
        </ng-container>
      </ng-container>


    </div>

    <!-- france -->
    <div [class.hidden]="!isActive('france')">
      <div class="bg-gray-100 p-5 rounded-md">
        <p class="mb-2">
          {{ 'orders.belgium.info-p1' | transloco }}
        </p>
        <p class="mb-2">
          {{ 'orders.belgium.info-p2' | transloco }}
        </p>
        <p>
          {{ 'orders.belgium.info-p4' | transloco }}
        </p>
      </div>
      <ng-container *ngIf="orderForms$ | async as orderForms">
        <ng-container *ngIf="(orderForms | orderFormFilter:'france':'SPRING').length > 0">
          <h4>{{ 'orders.spring' | transloco }}</h4>
          <app-orderFormList
            *ngIf="orderForms$ | async as orderForms"
            [orderForms]="orderForms | orderFormFilter:'france':'SPRING'"></app-orderFormList>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="orderForms$ | async as orderForms">
        <ng-container *ngIf="(orderForms | orderFormFilter:'france':'AUTUMN').length > 0">
          <h4>{{ 'orders.autumn' | transloco }}</h4>
          <app-orderFormList
            *ngIf="orderForms$ | async as orderForms"
            [orderForms]="orderForms | orderFormFilter:'france':'AUTUMN'"></app-orderFormList>
        </ng-container>
      </ng-container>

    </div>

    <!-- uk -->
    <div [class.hidden]="!isActive('uk')">
      <div class="bg-gray-100 p-5 rounded-md">
        <p class="mb-2">
          {{ 'orders.belgium.info-p1' | transloco }}
        </p>
        <p>
          {{ 'orders.belgium.info-p4' | transloco }}
        </p>
      </div>

      <ng-container *ngIf="orderForms$ | async as orderForms">
        <ng-container *ngIf="(orderForms | orderFormFilter:'uk':'SPRING').length > 0">
          <h4>{{ 'orders.spring' | transloco }}</h4>
          <app-orderFormList
            [orderForms]="orderForms | orderFormFilter:'uk':'SPRING'"></app-orderFormList>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="orderForms$ | async as orderForms">
        <ng-container *ngIf="(orderForms | orderFormFilter:'uk':'AUTUMN').length > 0">
          <h4>{{ 'orders.autumn' | transloco }}</h4>
          <app-orderFormList
            [orderForms]="orderForms | orderFormFilter:'uk':'AUTUMN'"></app-orderFormList>
        </ng-container>
      </ng-container>

    </div>

    <!-- ireland -->
    <div [class.hidden]="!isActive('ireland')">
      <div class="bg-gray-100 p-5 rounded-md">
        <p class="mb-2">
          {{ 'orders.belgium.info-p1' | transloco }}
        </p>
        <p>
          {{ 'orders.belgium.info-p4' | transloco }}
        </p>
      </div>

      <ng-container *ngIf="orderForms$ | async as orderForms">
        <ng-container *ngIf="(orderForms | orderFormFilter:'ireland':'SPRING').length > 0">
          <h4>{{ 'orders.spring' | transloco }}</h4>
          <app-orderFormList
            [orderForms]="orderForms | orderFormFilter:'ireland':'SPRING'"></app-orderFormList>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="orderForms$ | async as orderForms">
        <ng-container *ngIf="(orderForms | orderFormFilter:'ireland':'AUTUMN').length > 0">
          <h4>{{ 'orders.autumn' | transloco }}</h4>
          <app-orderFormList
            [orderForms]="orderForms | orderFormFilter:'ireland':'AUTUMN'"></app-orderFormList>
        </ng-container>
      </ng-container>

    </div>

  </div>

</section>
