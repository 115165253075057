<div class="relative max-w-7xl mx-auto my-10">
  <div class="text-center">
    <h2>{{'genetics.history.title' | transloco }}</h2>
  </div>
</div>
<!-- component -->
<div class="hidden sm:block container bg-gray-200 mx-auto m-10 shadow-b-md">
  <div class="relative overflow-hidden p-10">
    <div class="border-2-2 absolute border-opacity-20 border-gray-700 h-full border" style="left: 50%"></div>
    <!-- right timeline -->
    <div class="mb-8 flex justify-between items-center w-full right-timeline">
      <div class="order-1 w-5/12"></div>
      <div class="z-20 flex items-center order-1 bg-gray-800 shadow-xl w-12 h-12 rounded-full">
        <h1 class="mx-auto font-semibold text-lg text-white">1927</h1>
      </div>
      <div class="order-1 bg-gray-400 rounded-lg shadow-xl w-5/12 px-6 py-4">
        <h3 class="mb-3 font-bold text-gray-800 text-xl">Knolbegonia's</h3>
        <p class="text-sm leading-snug tracking-wide text-gray-900 text-opacity-100 text-justify">
          {{'genetics.history.p-1' | transloco }}
        </p>
      </div>
    </div>

    <!-- left timeline -->
    <div class="mb-8 flex justify-between flex-row-reverse items-center w-full left-timeline">
      <div class="order-1 w-5/12"></div>
      <div class="z-20 flex items-center order-1 bg-gray-800 shadow-xl w-12 h-12 rounded-full">
        <h1 class="mx-auto text-white font-semibold text-lg">1950</h1>
      </div>
      <div class="order-1 bg-red-400 rounded-lg shadow-xl w-5/12 px-6 py-4">
        <h3 class="mb-3 font-bold text-white text-xl">Mister Begonia</h3>
        <p class="text-sm font-medium leading-snug tracking-wide text-white text-opacity-100 text-justify">
          {{'genetics.history.p-2' | transloco }}
        </p>
        <p class="text-sm mt-5 font-medium leading-snug tracking-wide text-white text-opacity-100 text-justify">
          {{'genetics.history.p-3' | transloco }}
        </p>
      </div>
    </div>

    <!-- right timeline -->
    <div class="mb-8 flex justify-between items-center w-full right-timeline">
      <div class="order-1 w-5/12"></div>
      <div class="z-20 flex items-center order-1 bg-gray-800 shadow-xl w-12 h-12 rounded-full">
        <h1 class="mx-auto font-semibold text-lg text-white">1970</h1>
      </div>
      <div class="order-1 bg-gray-400 rounded-lg shadow-xl w-5/12 px-6 py-4">
        <h3 class="mb-3 font-bold text-gray-800 text-xl">Raes Series</h3>
        <p class="text-sm font-medium leading-snug tracking-wide text-white text-opacity-100 text-justify">
          {{'genetics.history.p-4' | transloco }}
        </p>
        <p class="mt-5 text-sm font-medium leading-snug tracking-wide text-white text-opacity-100 text-justify">
          {{'genetics.history.p-5' | transloco }}
        </p>
        <p class="text-sm mt-5 font-medium leading-snug tracking-wide text-white text-opacity-100 text-justify">
          {{'genetics.history.p-6' | transloco }}
        </p>
        <p class="text-sm mt-5 font-medium leading-snug tracking-wide text-white text-opacity-100 text-justify">
          {{'genetics.history.p-7' | transloco }}
        </p>
      </div>
    </div>

    <!-- left timeline -->
    <div class="mb-8 flex justify-between flex-row-reverse items-center w-full left-timeline">
      <div class="order-1 w-5/12"></div>
      <div class="z-20 flex items-center order-1 bg-gray-800 shadow-xl w-12 h-12 rounded-full">
        <h1 class="mx-auto text-white font-semibold text-lg">2000</h1>
      </div>
      <div class="order-1 bg-red-400 rounded-lg shadow-xl w-5/12 px-6 py-4">
        <h3 class="mb-3 font-bold text-white text-xl">Raes Genetics</h3>
        <p class="m-5 text-sm font-medium leading-snug tracking-wide text-white text-opacity-100 text-justify">
          {{'genetics.history.p-8' | transloco }}
        </p>
        <p class="text-sm m-5 font-medium leading-snug tracking-wide text-white text-opacity-100 text-justify">
          {{'genetics.history.p-9' | transloco }}
        </p>
      </div>
    </div>

  </div>
</div>

<div class="sm:hidden">
  <form [formGroup]="form" novalidate class="m-5">
    <div class="sm:hidden">
      <label for="tabs" class="sr-only">Select a tab</label>
      <select id="tabs" name="tabs" formControlName="selectedTab"
              class="block w-full focus:ring-sapphire-500 focus:border-sapphire-500 border-gray-300 rounded-md">
        <option value="knolbegonia">1927 - knolbegonia's</option>
        <option value="begonia">1950 Mister Begonia</option>
        <option value="series">1970 Raes Series</option>
        <option value="genetics">2000 Raes Generics</option>
      </select>
    </div>
  </form>

  <div [class.hidden]="!isActive('knolbegonia')">
    <h3 class="mb-3 font-bold text-gray-800 text-xl">1927 - Knolbegonia's</h3>
    <p class="m-5 text-sm leading-snug tracking-wide text-gray-900 text-opacity-100 text-justify">
      {{'genetics.history.p-1' | transloco }}
    </p>
  </div>
  <div [class.hidden]="!isActive('begonia')">
    <h3 class="mb-3 font-bold text-gray-800 text-xl">1950 - Mister Begonia</h3>
    <p class="text-sm font-medium leading-snug tracking-wide text-gray-900 text-opacity-100 text-justify m-5">
      {{'genetics.history.p-2' | transloco }}
    </p>
    <p class="m-5 text-sm mt-5 font-medium leading-snug tracking-wide text-gray-900 text-opacity-100 text-justify">
      {{'genetics.history.p-3' | transloco }}
    </p>
  </div>
  <div [class.hidden]="!isActive('series')">
    <h3 class="mb-3 font-bold text-gray-800 text-xl">1970 - Raes Series</h3>
    <p class="text-sm font-medium leading-snug tracking-wide text-gray-900 text-opacity-100 m-5 text-justify">
      {{'genetics.history.p-4' | transloco }}
    </p>
    <p class="m-5 text-sm font-medium leading-snug tracking-wide text-gray-900 text-opacity-100 text-justify">
      {{'genetics.history.p-5' | transloco }}
    </p>
    <p class="text-sm m-5 font-medium leading-snug tracking-wide text-gray-900 text-opacity-100 text-justify">
      {{'genetics.history.p-6' | transloco }}
    </p>
    <p class="text-sm m-5 font-medium leading-snug tracking-wide text-gray-900 text-opacity-100 tex">
      {{'genetics.history.p-7' | transloco }}
    </p>
  </div>
  <div [class.hidden]="!isActive('genetics')">
    <h3 class="mb-3 font-bold text-gray-800 text-xl">2000 - Raes Genetics</h3>
    <p class="m-5 text-sm font-medium leading-snug tracking-wide text-gray-900 text-opacity-100 text-justify">
      {{'genetics.history.p-8' | transloco }}
    </p>
    <p class="text-sm m-5 font-medium leading-snug tracking-wide text-gray-900 text-opacity-100 text-justify">
      {{'genetics.history.p-9' | transloco }}
    </p>
  </div>
</div>
