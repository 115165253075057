// scroll-top.directive.ts
import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[routerLink]', // This will apply to all routerLink elements
  standalone: true
})
export class ScrollTopDirective {
  @HostListener('click')
  onClick(): void {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'instant'
      });
    }, 0);
  }
}
