import {Language} from './enum/language';

export class Digitalcatalog {
  constructor(public id: number = 0,
              public name: string = '',
              public shortName: string = '',
              public digitalMagazineCode: string = '',
              public borderColor: string = '',
              public priority: number = 9999,
              public active: boolean = false,
              public frontPage: boolean = false,
              public mainCatalog: boolean = false,
              public pdfPath: string = '',
              public catalogPaths: Map<string, string> = new Map<string, string>(),
              public createdBy: string = '',
              public createdOn: string = '',
              public lastModifiedBy: string = '',
              public lastModifiedOn: string = '',
              public languages: Language[] = []) {}
  static adapt(item: any): Digitalcatalog {
    return new Digitalcatalog(
      item.id,
      item.name,
      item.shortName,
      item.digitalMagazineCode,
      item.borderColor,
      item.priority,
      item.active,
      item.frontPage,
      item.mainCatalog,
      item.pdfPath,
      item.catalogPaths,
      item.createdBy,
      item.createdOn,
      item.lastModifiedBy,
      item.lastModifiedOn,
      item.languages
    );
  }

  getIssuuPath(): string {
    return 'https://e.issuu.com/embed.html?d=' + this.digitalMagazineCode + '&u=yrr81';
  }
}
