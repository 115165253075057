export class AvailabilityUpload {
  constructor(public fileName: string = '',
              public dateUploaded: Date = new Date()) {
  }

  static adapt(item: any): AvailabilityUpload {
    return new AvailabilityUpload(
      item.fileName,
      item.dateUploaded
    );
  }

  get file(): string {
    return `https://flowerweb.s3.eu-west-1.amazonaws.com/availability/${this.fileName}`;
  }
}
