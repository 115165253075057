import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AppConfigService} from '../providers/app-config.service';
import {Genus} from "../../shared/model/genus";

@Injectable({
  providedIn: 'root'
})
export class GenusService {
  private readonly publicApi: string;

  constructor(private http: HttpClient, private appConfig: AppConfigService) {
    this.publicApi = this.appConfig.config.apiBase;
  }

  show(id: number): Observable<Genus> {
    const genusUrl = `${this.publicApi}/geni/${id}`;
    return this.http.get<Genus>(genusUrl).pipe(
      map(Genus.adapt)
    );
  }

  autocomplete(search: string): Observable<Genus[]> {
    const genusUrl = `${this.publicApi}/geni?search=${search}`;
    return this.http.get<Genus[]>(genusUrl).pipe(
      map(data => data.map(Genus.adapt))
    );
  }
}
