<div class="card flex justify-center">
  <p-confirmPopup>
    <ng-template pTemplate="content" let-message>
      <div
        class="flex flex-col items-center w-full gap-4 border-b border-surface-200 dark:border-surface-700 p-4 mb-4 pb-0"
      >
        <i [class]="message.icon" class="text-6xl text-primary-500"></i>
        <img [src]="image" alt="{{ productName }}" class="w-full object-cover object-center rounded-lg shadow-md">
        <p>{{ productName }}</p>
      </div>
    </ng-template>
  </p-confirmPopup>
</div>
