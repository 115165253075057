import {Component, Input, numberAttribute, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Job} from "../../../../shared/model/job";
import {TruncateTextPipe} from "../../../../shared/pipes/truncatetext.pipe";
import {TranslocoPipe} from "@jsverse/transloco";

@Component({
  selector: 'app-job-intro',
  standalone: true,
  imports: [
    TruncateTextPipe, TranslocoPipe, TruncateTextPipe
  ],
  templateUrl: './job-intro.component.html',
  styles: ``
})
export class JobIntroComponent implements OnInit, OnChanges {
  @Input() job!: Job;
  @Input({transform: numberAttribute}) activeJobId: number | undefined;
  active = false;

  constructor() {
  }

  ngOnInit(): void {
    this.makeItemActiveIfSelected();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.active = false;
    this.makeItemActiveIfSelected();
  }

  makeItemActiveIfSelected(): void {
    if (this.job && this.job.id === this.activeJobId) {
      this.active = true;
    }
  }
}
