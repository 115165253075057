
export const appRoutesLinks = {
  ABOUT_PAGE: [`/about`],
  ACCESSIBILITY_PAGE: [`/accessibility`],
  CATALOG_PAGE: [`/catalogs`],
  CONTACT_PAGE: [`/contact`],
  DISCLAIMER_PAGE: [`/disclaimer`],
  LANDING_PAGE: ['/'],
  PRIVACY_PAGE: [`/privacy`],
  ORDERFORM_PAGE: [`/orderforms`],
  PLANT_DETAIL_PAGE: (slugOrRaesCode:any) => [`/products/${slugOrRaesCode}`],
  PLANT_OVERVIEW_PAGE: [`/products`],
  JOBS_PAGE: [`/jobs`],
  NEWS_PAGE: [`/news`],
  NEWS_DETAIL_PAGE: (id: any) => [`/news/${id}`],

  ACCOUNT_AUTH_GATE_PAGE: [`/account/gate`],

  GENETICS_PAGE: [`/genetics`],
  GENETICS_CULTURE_PAGE: [`/genetics/culture`],
  GENETICS_PRIMULA_PAGE: [`/genetics/primula/types`],
  GENETICS_TYPE_PAGE: (type: any) => [`/genetics/primula/type/${type}`],
  GENETICS_NEWPRIMULA_PAGE: [`/genetics/primula/new`],
  GENETICS_FLOWERINGPERIODS_PAGE: [`/genetics/primula/floweringperiods`],
  GENETICS_JUSTINTIME_PAGE: [`/genetics/justintime`],
  GENETICS_FLOWERINGPERIOD_PAGE: (floweringperiod: any) => [`/genetics/primula/period/${floweringperiod}`],
  COMPANY_PAGE: [`/about`],
  PRODUCTS_PAGE: [`/products`],
  AVAILABILITY_PAGE: [`/availability`],
  JOBS_APPLY_PAGE: (id: any) => [`/jobs/apply/${id}`]
};
