<div *ngIf="(jobsSubscription | async)?.length" class="bg-gold-drop-700 p-10 my-5">
  <div class="relative mx-auto">
    <div class="text-center">
      <h3>{{'dashboard.jobs.title' | transloco }}</h3>
      <h4>{{'dashboard.jobs.subtitle' | transloco }}</h4>
    </div>
  </div>

  <div class="relative max-w-7xl mx-auto px-auto">
    <div class="mt-12 grid items grid-cols-1 lg:grid-cols-3 lg:grid-rows-1 gap-4 justify-center">
      <app-landing-job-item  *ngFor="let jobItem of jobsSubscription | async;" class="mx-5 flex flex-col justify-center rounded-lg shadow-lg overflow-hidden"
                             [jobitem]="jobItem">
      </app-landing-job-item >
    </div>
  </div>
</div>
