import { trigger, state, style, transition, animate } from '@angular/animations';
import * as i1 from '@angular/common';
import { isPlatformBrowser, CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Injectable, inject, numberAttribute, Component, ChangeDetectionStrategy, ViewEncapsulation, Input, ContentChild, NgModule } from '@angular/core';
import { getWindowScrollTop } from '@primeuix/utils';
import { SharedModule } from 'primeng/api';
import { BaseComponent } from 'primeng/basecomponent';
import { Button } from 'primeng/button';
import { ChevronUpIcon } from 'primeng/icons';
import { ZIndexUtils } from 'primeng/utils';
import { BaseStyle } from 'primeng/base';
const theme = ({
  dt
}) => `
.p-scrolltop.p-button {
    position: fixed;
    bottom: 20px;
    inset-inline-end: 20px;
}

.p-scrolltop-sticky.p-button {
    position: sticky;
    display: flex;
    margin-left: auto;
}

.p-scrolltop-sticky.p-button:dir(rtl) {
    margin-left: 0;
    margin-right: auto;
}

.p-scrolltop-enter-from {
    opacity: 0;
}

.p-scrolltop-enter-active {
    transition: opacity 0.15s;
}

.p-scrolltop.p-scrolltop-leave-to {
    opacity: 0;
}

.p-scrolltop-leave-active {
    transition: opacity 0.15s;
}

/* For PrimeNG */
.p-scrolltop-sticky.p-link {
    margin-left: auto;
}
`;
const classes = {
  root: ({
    props
  }) => ['p-scrolltop', {
    'p-scrolltop-sticky': props.target !== 'window'
  }],
  icon: 'p-scrolltop-icon'
};
class ScrollTopStyle extends BaseStyle {
  name = 'scrolltop';
  theme = theme;
  classes = classes;
  static ɵfac = /*@__PURE__*/(() => {
    let ɵScrollTopStyle_BaseFactory;
    return function ScrollTopStyle_Factory(__ngFactoryType__) {
      return (ɵScrollTopStyle_BaseFactory || (ɵScrollTopStyle_BaseFactory = i0.ɵɵgetInheritedFactory(ScrollTopStyle)))(__ngFactoryType__ || ScrollTopStyle);
    };
  })();
  static ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
    token: ScrollTopStyle,
    factory: ScrollTopStyle.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ScrollTopStyle, [{
    type: Injectable
  }], null, null);
})();
/**
 *
 * ScrollTop gets displayed after a certain scroll position and used to navigates to the top of the page quickly.
 *
 * [Live Demo](https://www.primeng.org/scrolltop/)
 *
 * @module scrolltopstyle
 *
 */
var ScrollTopClasses;
(function (ScrollTopClasses) {
  /**
   * Class name of the root element
   */
  ScrollTopClasses["root"] = "p-scrolltop";
  /**
   * Class name of the icon element
   */
  ScrollTopClasses["icon"] = "p-scrolltop-icon";
})(ScrollTopClasses || (ScrollTopClasses = {}));
const _c0 = ["icon"];
const _c1 = (a0, a1) => ({
  showTransitionParams: a0,
  hideTransitionParams: a1
});
const _c2 = a0 => ({
  value: "open",
  params: a0
});
const _c3 = () => ({
  styleClass: "p-scrolltop-icon"
});
const _c4 = () => ({
  "font-size": "1rem",
  scale: "1.5"
});
function ScrollTop_p_button_0_ng_template_1_ng_container_0_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 7);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(4);
    i0.ɵɵclassMap(ctx_r1._icon);
    i0.ɵɵproperty("ngClass", "p-scrolltop-icon");
  }
}
function ScrollTop_p_button_0_ng_template_1_ng_container_0_ChevronUpIcon_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "ChevronUpIcon", 8);
  }
  if (rf & 2) {
    i0.ɵɵproperty("styleClass", "p-scrolltop-icon")("ngStyle", i0.ɵɵpureFunction0(2, _c4));
  }
}
function ScrollTop_p_button_0_ng_template_1_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, ScrollTop_p_button_0_ng_template_1_ng_container_0_span_1_Template, 1, 3, "span", 5)(2, ScrollTop_p_button_0_ng_template_1_ng_container_0_ChevronUpIcon_2_Template, 1, 3, "ChevronUpIcon", 6);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1._icon);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1._icon);
  }
}
function ScrollTop_p_button_0_ng_template_1_1_ng_template_0_Template(rf, ctx) {}
function ScrollTop_p_button_0_ng_template_1_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, ScrollTop_p_button_0_ng_template_1_1_ng_template_0_Template, 0, 0, "ng-template", 9);
  }
  if (rf & 2) {
    i0.ɵɵnextContext(2);
    const icon_r3 = i0.ɵɵreference(2);
    i0.ɵɵproperty("ngIf", !icon_r3);
  }
}
function ScrollTop_p_button_0_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, ScrollTop_p_button_0_ng_template_1_ng_container_0_Template, 3, 2, "ng-container", 3)(1, ScrollTop_p_button_0_ng_template_1_1_Template, 1, 1, null, 4);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngIf", !ctx_r1.iconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.iconTemplate)("ngTemplateOutletContext", i0.ɵɵpureFunction0(3, _c3));
  }
}
function ScrollTop_p_button_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "p-button", 2);
    i0.ɵɵlistener("@animation.start", function ScrollTop_p_button_0_Template_p_button_animation_animation_start_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onEnter($event));
    })("@animation.done", function ScrollTop_p_button_0_Template_p_button_animation_animation_done_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onLeave($event));
    })("click", function ScrollTop_p_button_0_Template_p_button_click_0_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onClick());
    });
    i0.ɵɵtemplate(1, ScrollTop_p_button_0_ng_template_1_Template, 2, 4, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("@animation", i0.ɵɵpureFunction1(8, _c2, i0.ɵɵpureFunction2(5, _c1, ctx_r1.showTransitionOptions, ctx_r1.hideTransitionOptions)))("styleClass", ctx_r1.getStyleClass())("ngStyle", ctx_r1.style)("buttonProps", ctx_r1.buttonProps);
    i0.ɵɵattribute("aria-label", ctx_r1.buttonAriaLabel);
  }
}
/**
 * ScrollTop gets displayed after a certain scroll position and used to navigates to the top of the page quickly.
 * @group Components
 */
class ScrollTop extends BaseComponent {
  /**
   * Class of the element.
   * @group Props
   */
  styleClass;
  /**
   * Inline style of the element.
   * @group Props
   */
  style;
  /**
   * Target of the ScrollTop.
   * @group Props
   */
  target = 'window';
  /**
   * Defines the threshold value of the vertical scroll position of the target to toggle the visibility.
   * @group Props
   */
  threshold = 400;
  /**
   * Name of the icon or JSX.Element for icon.
   * @group Props
   */
  get icon() {
    return this._icon;
  }
  /**
   * Defines the scrolling behavior, "smooth" adds an animation and "auto" scrolls with a jump.
   * @group Props
   */
  behavior = 'smooth';
  /**
   * A string value used to determine the display transition options.
   * @group Props
   */
  showTransitionOptions = '.15s';
  /**
   * A string value used to determine the hiding transition options.
   * @group Props
   */
  hideTransitionOptions = '.15s';
  /**
   * Establishes a string value that labels the scroll-top button.
   * @group Props
   */
  buttonAriaLabel;
  /**
   * Used to pass all properties of the ButtonProps to the Button component.
   * @group Props
   */
  buttonProps = {
    rounded: true
  };
  /**
   * Template of the icon.
   * @group Templates
   */
  iconTemplate;
  _icon;
  set icon(value) {
    this._icon = value;
  }
  documentScrollListener;
  parentScrollListener;
  visible = false;
  overlay;
  _componentStyle = inject(ScrollTopStyle);
  ngOnInit() {
    super.ngOnInit();
    if (this.target === 'window') this.bindDocumentScrollListener();else if (this.target === 'parent') this.bindParentScrollListener();
  }
  ngAfterContentInit() {
    this.templates.forEach(item => {
      switch (item.getType()) {
        case 'icon':
          this.iconTemplate = item.template;
          break;
      }
    });
  }
  onClick() {
    let scrollElement = this.target === 'window' ? this.document.defaultView : this.el.nativeElement.parentElement;
    scrollElement.scroll({
      top: 0,
      behavior: this.behavior
    });
  }
  onEnter(event) {
    switch (event.toState) {
      case 'open':
        this.overlay = event.element;
        ZIndexUtils.set('overlay', this.overlay, this.config.zIndex.overlay);
        break;
      case 'void':
        this.overlay = null;
        break;
    }
  }
  onLeave(event) {
    switch (event.toState) {
      case 'void':
        ZIndexUtils.clear(event.element);
        break;
    }
  }
  checkVisibility(scrollY) {
    if (scrollY > this.threshold) this.visible = true;else this.visible = false;
    this.cd.markForCheck();
  }
  bindParentScrollListener() {
    if (isPlatformBrowser(this.platformId)) {
      this.parentScrollListener = this.renderer.listen(this.el.nativeElement.parentElement, 'scroll', () => {
        this.checkVisibility(this.el.nativeElement.parentElement.scrollTop);
      });
    }
  }
  bindDocumentScrollListener() {
    if (isPlatformBrowser(this.platformId)) {
      this.documentScrollListener = this.renderer.listen(this.document.defaultView, 'scroll', () => {
        this.checkVisibility(getWindowScrollTop());
      });
    }
  }
  unbindParentScrollListener() {
    if (this.parentScrollListener) {
      this.parentScrollListener();
      this.parentScrollListener = null;
    }
  }
  unbindDocumentScrollListener() {
    if (this.documentScrollListener) {
      this.documentScrollListener();
      this.documentScrollListener = null;
    }
  }
  getStyleClass() {
    return `p-scrolltop p-button${this.styleClass ? ` ${this.styleClass}` : ''}${this.target !== 'window' ? ' p-scrolltop-sticky' : ''}`;
  }
  ngOnDestroy() {
    if (this.target === 'window') this.unbindDocumentScrollListener();else if (this.target === 'parent') this.unbindParentScrollListener();
    if (this.overlay) {
      ZIndexUtils.clear(this.overlay);
      this.overlay = null;
    }
    super.ngOnDestroy();
  }
  static ɵfac = /*@__PURE__*/(() => {
    let ɵScrollTop_BaseFactory;
    return function ScrollTop_Factory(__ngFactoryType__) {
      return (ɵScrollTop_BaseFactory || (ɵScrollTop_BaseFactory = i0.ɵɵgetInheritedFactory(ScrollTop)))(__ngFactoryType__ || ScrollTop);
    };
  })();
  static ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
    type: ScrollTop,
    selectors: [["p-scrollTop"], ["p-scrolltop"], ["p-scroll-top"]],
    contentQueries: function ScrollTop_ContentQueries(rf, ctx, dirIndex) {
      if (rf & 1) {
        i0.ɵɵcontentQuery(dirIndex, _c0, 5);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.iconTemplate = _t.first);
      }
    },
    inputs: {
      styleClass: "styleClass",
      style: "style",
      target: "target",
      threshold: [2, "threshold", "threshold", numberAttribute],
      icon: "icon",
      behavior: "behavior",
      showTransitionOptions: "showTransitionOptions",
      hideTransitionOptions: "hideTransitionOptions",
      buttonAriaLabel: "buttonAriaLabel",
      buttonProps: "buttonProps"
    },
    standalone: true,
    features: [i0.ɵɵProvidersFeature([ScrollTopStyle]), i0.ɵɵInputTransformsFeature, i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
    decls: 1,
    vars: 1,
    consts: [["icon", ""], ["type", "button", 3, "styleClass", "ngStyle", "buttonProps", "click", 4, "ngIf"], ["type", "button", 3, "click", "styleClass", "ngStyle", "buttonProps"], [4, "ngIf"], [4, "ngTemplateOutlet", "ngTemplateOutletContext"], [3, "class", "ngClass", 4, "ngIf"], [3, "styleClass", "ngStyle", 4, "ngIf"], [3, "ngClass"], [3, "styleClass", "ngStyle"], [3, "ngIf"]],
    template: function ScrollTop_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵtemplate(0, ScrollTop_p_button_0_Template, 3, 10, "p-button", 1);
      }
      if (rf & 2) {
        i0.ɵɵproperty("ngIf", ctx.visible);
      }
    },
    dependencies: [CommonModule, i1.NgClass, i1.NgIf, i1.NgTemplateOutlet, i1.NgStyle, ChevronUpIcon, Button, SharedModule],
    encapsulation: 2,
    data: {
      animation: [trigger('animation', [state('void', style({
        opacity: 0
      })), state('open', style({
        opacity: 1
      })), transition('void => open', animate('{{showTransitionParams}}')), transition('open => void', animate('{{hideTransitionParams}}'))])]
    },
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ScrollTop, [{
    type: Component,
    args: [{
      selector: 'p-scrollTop, p-scrolltop, p-scroll-top',
      standalone: true,
      imports: [CommonModule, ChevronUpIcon, Button, SharedModule],
      template: `
        <p-button
            *ngIf="visible"
            [@animation]="{
                value: 'open',
                params: { showTransitionParams: showTransitionOptions, hideTransitionParams: hideTransitionOptions }
            }"
            (@animation.start)="onEnter($event)"
            (@animation.done)="onLeave($event)"
            [attr.aria-label]="buttonAriaLabel"
            (click)="onClick()"
            [styleClass]="getStyleClass()"
            [ngStyle]="style"
            type="button"
            [buttonProps]="buttonProps"
        >
            <ng-template #icon>
                <ng-container *ngIf="!iconTemplate">
                    <span *ngIf="_icon" [class]="_icon" [ngClass]="'p-scrolltop-icon'"></span>
                    <ChevronUpIcon *ngIf="!_icon" [styleClass]="'p-scrolltop-icon'" [ngStyle]="{ 'font-size': '1rem', scale: '1.5' }" />
                </ng-container>
                <ng-template [ngIf]="!icon" *ngTemplateOutlet="iconTemplate; context: { styleClass: 'p-scrolltop-icon' }"></ng-template>
            </ng-template>
        </p-button>
    `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      animations: [trigger('animation', [state('void', style({
        opacity: 0
      })), state('open', style({
        opacity: 1
      })), transition('void => open', animate('{{showTransitionParams}}')), transition('open => void', animate('{{hideTransitionParams}}'))])],
      providers: [ScrollTopStyle]
    }]
  }], null, {
    styleClass: [{
      type: Input
    }],
    style: [{
      type: Input
    }],
    target: [{
      type: Input
    }],
    threshold: [{
      type: Input,
      args: [{
        transform: numberAttribute
      }]
    }],
    icon: [{
      type: Input
    }],
    behavior: [{
      type: Input
    }],
    showTransitionOptions: [{
      type: Input
    }],
    hideTransitionOptions: [{
      type: Input
    }],
    buttonAriaLabel: [{
      type: Input
    }],
    buttonProps: [{
      type: Input
    }],
    iconTemplate: [{
      type: ContentChild,
      args: ['icon']
    }]
  });
})();
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassDebugInfo(ScrollTop, {
    className: "ScrollTop"
  });
})();
class ScrollTopModule {
  static ɵfac = function ScrollTopModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ScrollTopModule)();
  };
  static ɵmod = /*@__PURE__*/i0.ɵɵdefineNgModule({
    type: ScrollTopModule
  });
  static ɵinj = /*@__PURE__*/i0.ɵɵdefineInjector({
    imports: [ScrollTop, SharedModule, SharedModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ScrollTopModule, [{
    type: NgModule,
    args: [{
      imports: [ScrollTop, SharedModule],
      exports: [ScrollTop, SharedModule]
    }]
  }], null, null);
})();
(function () {
  (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(ScrollTopModule, {
    imports: [ScrollTop, SharedModule],
    exports: [ScrollTop, SharedModule]
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { ScrollTop, ScrollTopClasses, ScrollTopModule, ScrollTopStyle };
