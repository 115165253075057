<app-job-intro
  *ngFor="let job of jobs"
  [job]="job" (click)="onClick(job)" [activeJobId]="activeJobId">
</app-job-intro>

<li class="sm:py-8" [routerLink]="applyLink(0)">
  <div class="space-y-4 my-5 sm:grid sm:grid-cols-3 sm:items-start sm:gap-6 sm:space-y-0 bg-gold-drop-500 shadow-md rounded-md" >
    <div class="h-48 sm:h-full sm:aspect-w-3 sm:aspect-h-4">
      <img class="object-cover w-full h-48 shadow-lg rounded-t-lg" src="https://s3.eu-west-3.amazonaws.com/raes.cdn/open_sollicitations.jpg" alt="Solliciteer bij Raes Bloemzaden nv">
    </div>
    <div class="sm:col-span-2">
      <div class="space-y-4">
        <div class="text-lg leading-6 font-medium space-y-1">
          <h3 class="text-black">{{'job.openApplication.title' | transloco}}</h3>
        </div>
        <div class="text-md p-5">
          <p class="text-gray-800 text-sm">{{'job.openApplication.description' | transloco}}</p>
          <button type="button" class="inline-flex items-center m-3 p-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-sapphire-600 hover:bg-sapphire-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sapphire-500">
            {{'job.applyOpenApplication' | transloco }}
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</li>
