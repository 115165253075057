import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ApplyForJobInfo} from "../../../../shared/model/applyForJobInfo";
import {ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {Job} from "../../../../shared/model/job";
import {Subscription, take} from "rxjs";
import {appRoutesLinks} from "../../../../app.routes.links";
import {JobService} from "../../../../core/repository/job.service";
import {FormUtils} from "../../../../core/util/form.utils";
import {NotificationService} from "../../../../core/util/notification.service";
import {RouterLink} from "@angular/router";
import {TextComponent} from "../../../../shared/form/text/text.component";
import {TextAreaComponent} from "../../../../shared/form/text-area/text-area.component";
import {TranslocoDirective, TranslocoPipe, TranslocoService} from "@jsverse/transloco";
import {CheckboxComponent} from "../../../../shared/form/checkbox/checkbox.component";
import {RecaptchaFormsModule, RecaptchaModule} from "ng-recaptcha-2";
import {NgIf} from "@angular/common";
import {Toast} from "primeng/toast";
import {MessageService} from "primeng/api";

@Component({
  selector: 'app-job-apply-form',
  standalone: true,
  imports: [
    RouterLink,
    RecaptchaModule,
    ReactiveFormsModule,
    TextComponent,
    TextAreaComponent,
    TranslocoDirective,
    TranslocoPipe,
    CheckboxComponent,
    RecaptchaFormsModule,
    NgIf,
    Toast
  ],
  templateUrl: './job-apply-form.component.html',
  styles: [`
    .file-name {
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .file-error {
      color: #dc2626;
      font-size: 0.875rem;
      margin-top: 0.5rem;
    }
  `]
})
export class JobApplyFormComponent implements OnDestroy {
  applyForJobInfo = new ApplyForJobInfo();
  jobForm: UntypedFormGroup;
  selectedFile: File | null = null;
  selectedFileName: string = '';
  fileError: string = '';
  @Input() job?: Job;
  private subscriptions = new Subscription();
  disclaimerLink = appRoutesLinks.DISCLAIMER_PAGE;
  isSubmitting = false;

  // Define allowed file types
  private allowedFileTypes = [
    'application/pdf',                                                    // PDF
    'application/msword',                                                // DOC
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document' // DOCX
  ];

  // Maximum file size in bytes (e.g., 5MB)
  private maxFileSize = 5 * 1024 * 1024;

  constructor(
    private applyForJobService: JobService,
    private formBuilder: UntypedFormBuilder,
    private formUtils: FormUtils,
    public translocoService: TranslocoService,
    private messageService: MessageService,
    private notificationService: NotificationService
  ) {
    this.jobForm = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      motivation: ['', [Validators.required]],
      reference: ['', [Validators.required]],
      captcha: [null, [Validators.required]],
      termsAccepted: [false, [Validators.requiredTrue]]
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onFileSelect(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];

      // Reset previous errors
      this.fileError = '';

      // Validate file type
      if (!this.allowedFileTypes.includes(file.type)) {
        this.fileError = this.translocoService.translate('validation.file.type_error');
        this.resetFileInput();
        return;
      }

      // Validate file size
      if (file.size > this.maxFileSize) {
        this.fileError = this.translocoService.translate('validation.file.size_error');
        this.resetFileInput();
        return;
      }

      this.selectedFile = file;
      this.selectedFileName = file.name;
    } else {
      this.resetFileInput();
    }
  }

  private resetFileInput(): void {
    this.selectedFile = null;
    this.selectedFileName = '';
    // Reset the file input element
    const fileInput = document.querySelector('input[type="file"]') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
  }

  public beforeSubmittingForm(): void {
    if (this.isSubmitting) {
      return;
    }

    if (this.jobForm.valid) {
      this.isSubmitting = true;
      this.sendJobApplyRequest();
    } else {
      // Get all invalid fields
      const invalidFields = this.getInvalidFields();

      // Show specific error message with invalid field names
      this.notificationService.error(
        this.translocoService.translate('validation.toast.required_title'),
        this.translocoService.translate('validation.toast.required_fields', {
          fields: invalidFields.join(', ')
        })
      );

      this.formUtils.validateFormFields(this.jobForm);
    }
  }

  private getInvalidFields(): string[] {
    const invalidFields: string[] = [];

    Object.keys(this.jobForm.controls).forEach(key => {
      const control = this.jobForm.get(key);
      if (control?.invalid) {
        // Translate the field name
        const fieldName = this.translocoService.translate(`job.form.${key}`);
        invalidFields.push(fieldName);
      }
    });

    return invalidFields;
  }
  sendJobApplyRequest(): void {
    if (!this.selectedFile) {
      this.isSubmitting = false;
      this.messageService.add({
        severity: 'error',
        summary: this.translocoService.translate('validation.toast.required_title'),
        detail: this.translocoService.translate('validation.toast.cv_required'),
        closable: false,
        life: 4000
      });
      return;
    }

    const formValues = this.jobForm.value;
    this.applyForJobInfo = {
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      phone: formValues.phone,
      email: formValues.email,
      motivation: formValues.motivation,
      reference: formValues.reference,
      jobId: this.job?.id ?? 0,
      captcha: formValues.captcha
    };

    this.subscriptions.add(
      this.applyForJobService.applyForJobRequest(this.applyForJobInfo, this.selectedFile)
        .subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success',
              summary: 'Bericht Verstuurd',
              detail: 'We hebben uw aanvraag verstuurd!',
              closable: false,
              life: 4000
            });
            this.resetForm();
          },
          error: (error) => {
            console.error('Error submitting job application:', error);
            this.notificationService.error(
              this.translocoService.translate('validation.toast.error_title'),
              this.translocoService.translate('validation.toast.error_message')
            );
            this.isSubmitting = false;
          },
          complete: () => {
            this.isSubmitting = false;
          }
        })
    );
  }

  private resetForm(): void {
    this.jobForm.reset();
    this.resetFileInput();
    this.isSubmitting = false;
  }
}
