<ng-container *transloco="let t; prefix: 'header'">
<header>
  <div class="hidden sm:block bg-christi-700 border-b-2 border-christi-900 border-opacity-75">
    <div class="w-full px-2 sm:px-4 lg:px-8">
      <div class="py-2 lg:flex lg:items-center lg:justify-between" aria-label="Global">
        <div class="hidden lg:block lg:flex-1">
          <h1 class="text-xl font-bold text-white">Rudy Raes Bloemzaden nv</h1>
        </div>
        <div class="flex space-x-2">
        <a (click)="switchLanguage('nl')" [ngClass]="{ 'bg-gold-drop-500 text-white': isCurrentLanguage('nl'), 'text-gray-100 hover:bg-christi-700 hover:text-white hover:underline': !isCurrentLanguage('nl') }" class="rounded-md py-1 px-2 inline-flex items-center text-sm font-medium cursor-pointer">
          NL
        </a>

        <a (click)="switchLanguage('fr')" [ngClass]="{ 'bg-gold-drop-500 text-white': isCurrentLanguage('fr'), 'text-gray-100 hover:bg-christi-700 hover:text-white hover:underline': !isCurrentLanguage('fr') }" class="rounded-md py-1 px-2 inline-flex items-center text-sm font-medium  cursor-pointer">
          FR
        </a>

        <a (click)="switchLanguage('en')" [ngClass]="{ 'bg-gold-drop-500 text-white': isCurrentLanguage('en'), 'text-gray-100 hover:bg-christi-700 hover:text-white hover:underline': !isCurrentLanguage('en') }" class="rounded-md py-1 px-2 inline-flex items-center text-sm font-medium  cursor-pointer">
          EN
        </a>
        <a (click)="switchLanguage('de')" [ngClass]="{ 'bg-gold-drop-500 text-white': isCurrentLanguage('de'), 'text-gray-100 hover:bg-christi-700 hover:text-white hover:underline': !isCurrentLanguage('de') }" class="rounded-md py-1 px-2 inline-flex items-center text-sm font-medium  cursor-pointer">
          DE
        </a>
        <a (click)="switchLanguage('pl')" [ngClass]="{ 'bg-gold-drop-500 text-white': isCurrentLanguage('pl'), 'text-gray-100 hover:bg-christi-700 hover:text-white hover:underline': !isCurrentLanguage('pl') }" class="rounded-md py-1 px-2 inline-flex items-center text-sm font-medium  cursor-pointer">
          PL
        </a>
        <a (click)="switchLanguage('hu')" [ngClass]="{ 'bg-gold-drop-500 text-white': isCurrentLanguage('hu'), 'text-gray-100 hover:bg-christi-700 hover:text-white hover:underline': !isCurrentLanguage('hu') }" class="rounded-md py-1 px-2 inline-flex items-center text-sm font-medium  cursor-pointer">
          HU
        </a>
        </div>

        </div>
    </div>
  </div>

  <header class="relative isolate z-10 bg-white">
    <nav class="flex w-full items-center justify-between p-6 lg:px-8" aria-label="Global">
      <div class="flex lg:flex-1">
        <a href="#" class="-m-1.5 p-1.5">
          <span class="sr-only">Rudy Raes Bloemzaden nv</span>
          <img class="h-8 w-auto" src="https://d3coqd91sswgmf.cloudfront.net/logo/raes_logo.jpg" alt="Rudy Raes Bloemzaden nv" >
        </a>
      </div>

      <div class="flex-1 text-center lg:hidden">
        <h1 class="font-bold text-green-800 text-xs">Rudy Raes Bloemzaden nv</h1>
      </div>

      <div class="flex lg:hidden">
        <button type="button" (click)="toggleMobileMenu()" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700">
          <span class="sr-only">Open main menu</span>
          <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
          </svg>
        </button>
      </div>

      <div class="hidden lg:flex lg:gap-x-12">
        <app-products-menu></app-products-menu>
        <a [routerLink]="ordersPage" class="capitalize text-sm font-semibold leading-6 text-gray-900">{{ t('orders.title') }}</a>
        <a [routerLink]="availabilityPage" class="capitalize text-sm font-semibold leading-6 text-gray-900">{{ t('availability.title') }}</a>
        <app-genetics-menu></app-genetics-menu>
        <a [routerLink]="catalogPage" class="capitalize text-sm font-semibold leading-6 text-gray-900">{{ t('catalog.title') }}</a>
        <app-company-menu></app-company-menu>
        <a [routerLink]="contactPage" class="capitalize text-sm font-semibold leading-6 text-gray-900">{{ t('contact.title') }}</a>
      </div>
      <div class="hidden lg:flex lg:flex-1 lg:justify-end">
        <!-- <a href="#" class="text-sm font-semibold leading-6 text-gray-900">Log in <span aria-hidden="true">&rarr;</span></a> -->
      </div>
    </nav>
      <app-mobile-menu [isMenuOpen]="isMenuOpen"></app-mobile-menu>
  </header>
</header>
</ng-container>
