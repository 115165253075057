import {Component} from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';
import {AsyncPipe, NgClass, NgIf} from "@angular/common";
import {TranslocoDirective} from "@jsverse/transloco";
import {appRoutesLinks} from "../../../../app.routes.links";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {NavigationUtils} from "../../../../core/util/navigation.utils";
import {FlyoutMenuService} from "../../../../core/util/flyout-menu.service";
import {Button} from "primeng/button";

@Component({
  selector: 'app-products-menu',
  templateUrl: './products-menu.component.html',
  standalone: true,
  imports: [
    NgIf,
    TranslocoDirective,
    RouterLink,
    NgClass,
    AsyncPipe,
    Button
  ],
  animations: [
    trigger('AnimationTrigger0', [
      transition(':enter', [
        style({opacity: 0, transform: 'translateY(0.25rem)'}),
        animate('200ms ease-out', style({opacity: 1, transform: 'translateY(0)'}))
      ]),
      transition(':leave', [
        style({opacity: 1, transform: 'translateY(0)'}),
        animate('150ms ease-in', style({opacity: 0, transform: 'translateY(0.25rem)'}))
      ])
    ])
  ]
})
export class ProductsMenuComponent {
  protected readonly appRoutesLinks = appRoutesLinks;

  constructor(private navigationUtils: NavigationUtils, protected flyoutMenuService: FlyoutMenuService, private route: ActivatedRoute,     private router: Router) {
    this.route.queryParams.subscribe(params => {
      // Handle query parameter changes
      this.handleQueryParams(params);

    });
  }

  private handleQueryParams(params: any): void {
    // Update component state based on query parameters
    // Add your logic here to handle query parameter changes
  }

  navigateAndClose(route: string[], queryParams: {} = {}): void {
    // Check if we're already on the current route
    const currentUrl = this.router.url.split('?')[0]; // Get base URL without query params
    const targetUrl = '/' + route.join('/'); // Construct target URL from route array

    if (currentUrl === targetUrl && Object.keys(queryParams).length > 0) {
      // We're on the same route, need to force a refresh
      // First navigate to a dummy route
      this.navigationUtils.navigate(['/', 'reload'], { skipLocationChange: true })
        .then(() => {
          // Then navigate back to the target route with new query params
          this.navigationUtils.navigate(route, {
            queryParams
          });
        });
    } else {
      // Normal navigation for different routes
      if (Object.keys(queryParams).length > 0) {
        this.navigationUtils.navigate(route, {
          queryParams
        });
      } else {
        this.navigationUtils.navigate(route);
      }
    }

    this.resetMenu();
  }

  toggleFlyoutMenu() {
    this.flyoutMenuService.toggleFlyoutMenu('products');
  }

  private resetMenu(): void {
    this.flyoutMenuService.closeCurrentFlyout();
  }

}
