import {Component, Input} from '@angular/core';
import {NgIf} from "@angular/common";
import {Plant} from "../../../../shared/model/plant";
import {PlantPeriodsComponent} from "../plant-periods/plant-periods.component";
import {TranslocoService} from "@jsverse/transloco";
import {FlowerPeriodsComponent} from "../flower-periods/flower-periods.component";

@Component({
  selector: 'app-plant-period-grid',
  standalone: true,
  imports: [
    NgIf,
    PlantPeriodsComponent,
    FlowerPeriodsComponent
  ],
  templateUrl: './plant-period-grid.component.html',
  styles: ``
})
export class PlantPeriodGridComponent {
  @Input() plant: Plant = new Plant() ;
  constructor(public translateService: TranslocoService) { }

  periodDataAvailable(): boolean {
    return !!(this.plant?.fromPottingMonth
      || this.plant?.fromSowingMonth
      || this.plant?.fromSalesMonth);
  }
}

