<div [routerLink]="appRoutesLinks.JOBS_APPLY_PAGE(jobitem?.id)" class="cursor-pointer">
  <img class="h-48 w-full object-cover" src="{{jobitem?.imageUrl}}" alt="">
</div>
<div class="flex-1 bg-white p-6 flex flex-col justify-between">
  <div class="flex-1">
    <p class="text-sm font-medium text-indigo-600">
      <a [routerLink]="appRoutesLinks.JOBS_APPLY_PAGE(jobitem?.id)" class="hover:underline">
        {{ jobitem?.title }}
      </a>
    </p>
    <a [routerLink]="appRoutesLinks.JOBS_APPLY_PAGE(jobitem?.id)" class="block mt-2">
      <p class="text-base text-gray-900">
        {{ jobitem?.intro }}
      </p>
    </a>
  </div>
</div>
