import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {NotificationType, Notification} from "../../shared/model/notification";
import {TranslocoService} from "@jsverse/transloco";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private subject = new Subject<Notification>();
  private idx = 0;

  constructor(private translocoService: TranslocoService) { }

  getObservable(): Observable<Notification> {
    return this.subject.asObservable();
  }

  info(title: string, message: string, timeout = 3000): void {
    message = this.translocoService.translate(message);
    title = this.translocoService.translate(title);
    this.subject.next(new Notification(this.idx++, NotificationType.info, title, message, timeout, false));
  }

  success(title: string, message: string, timeout = 3000): void {
    message = this.translocoService.translate(message);
    title = this.translocoService.translate(title);
    this.subject.next(new Notification(this.idx++, NotificationType.success, title, message, timeout, false));
  }

  warning(title: string, message: string, timeout = 3000): void {
    message = this.translocoService.translate(message);
    title = this.translocoService.translate(title);
    this.subject.next(new Notification(this.idx++, NotificationType.warning, title, message, timeout, false));
  }

  error(title: string, message: string, timeout = 0): void {
    message = this.translocoService.translate(message);
    title = this.translocoService.translate(title);
    this.subject.next(new Notification(this.idx++, NotificationType.error, title, message, timeout, false));
  }
}
