import {APP_INITIALIZER, ApplicationConfig, isDevMode, LOCALE_ID} from '@angular/core';
import { environment } from '../environments/environment';

import {
  InMemoryScrollingFeature,
  InMemoryScrollingOptions,
  provideRouter,
  withInMemoryScrolling,
  withViewTransitions,
  Router,
  NavigationEnd
} from '@angular/router';

import {routes} from './app.routes';
import {provideClientHydration} from '@angular/platform-browser';
import {provideHttpClient, withFetch} from '@angular/common/http';
import {TranslocoHttpLoader} from './transloco-loader';
import {provideTransloco, TranslocoService} from '@jsverse/transloco';
import {provideAnimations} from "@angular/platform-browser/animations";
import {AppConfigService} from "./core/providers/app-config.service";
import {RECAPTCHA_LOADER_OPTIONS} from "ng-recaptcha-2";
import {providePrimeNG} from "primeng/config";
import Aura from '@primeng/themes/aura';
import {MessageService} from "primeng/api";
import { Injectable } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { filter } from 'rxjs/operators';
import {NgcCookieConsentConfig, provideNgcCookieConsent} from "ngx-cookieconsent";

@Injectable({
  providedIn: 'root'
})
export class ScrollService {
  constructor(private router: Router,private appConfig: AppConfigService) {
    // Listen to NavigationEnd events
    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe(() => {
      // Force scroll to top immediately
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: 'instant'  // Changed to instant for more reliable behavior
        });
      }, 0);
    });
  }
}

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain:  'raes.be', // it is recommended to set your domain, for cookies to work properly
  },
  palette: {
    popup: {
      background: '#000',
    },
    button: {
      background: '#59891f',
    },
  },
  theme: 'edgeless',
  type: 'opt-out',
};

export function initConfig(appConfig: AppConfigService): () => Promise<any> {
  return () => appConfig.loadConfig();
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes,
      withViewTransitions()
    ),
    ScrollService,  // Make sure ScrollService is provided
    provideAnimations(),
    provideClientHydration(),
    providePrimeNG({
      theme: {
        preset: Aura,
        options: {
          prefix: 'p',
          darkModeSelector:'.dark',
          cssLayer: false
        }
      }
    }),
    provideHttpClient(withFetch()),
    {
      provide: LOCALE_ID,
      deps: [TranslocoService],
      useFactory: (translocoService: TranslocoService) => translocoService.getActiveLang(),
    },
    {
      provide: RECAPTCHA_LOADER_OPTIONS,
      useFactory: (locale: string) => {
        console.log('Locale:', locale);
        return {
          onBeforeLoad(url: any): any {
            url.searchParams.set("hl", locale);
            console.log('in the recaptcha config')
            return {url};
          },
        };
      },
      deps: [LOCALE_ID],
    },
    MessageService,
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [AppConfigService],
      multi: true
    },
    provideNgcCookieConsent(cookieConfig),
    provideTransloco({
      config: {
        availableLangs: ['en', 'fr', 'nl', 'de', 'pl', 'hu'],
        defaultLang: 'nl',
        fallbackLang: ['en', 'fr'],
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader
    })
  ]
};
