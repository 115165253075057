import {Component, OnDestroy, OnInit} from '@angular/core';
import {PlantService} from "../../../core/repository/plant.service";
import {Subscription} from "rxjs";
import {Plant} from "../../../shared/model/plant";
import {appRoutesLinks} from "../../../app.routes.links";
import {RouterLink} from "@angular/router";
import {NgForOf} from "@angular/common";
import {TranslocoPipe, TranslocoService} from "@jsverse/transloco";
import {Meta, Title} from "@angular/platform-browser";
import {NavigationUtils} from "../../../core/util/navigation.utils";

@Component({
  selector: 'app-genetics-new',
  standalone: true,
  imports: [
    RouterLink,
    NgForOf,
    TranslocoPipe
  ],
  templateUrl: './genetics-new.component.html',
  styles: ``
})
export class GeneticsNewComponent implements OnInit, OnDestroy {

  constructor(
    private plantService: PlantService,
    private titleService: Title,
    private navigationUtils: NavigationUtils,
    private translocoService: TranslocoService,
    private metaService: Meta
  ) {
    let lang: string = this.translocoService.getActiveLang();

    let title: string;
    let description: string;
    let keywords: string;
    let translationSubscription: Subscription;

    // Use selectTranslate for the first department option
    translationSubscription = this.translocoService.selectTranslate('genetics.floweringperiods.title').subscribe(value => {
      title = value;
      this.titleService.setTitle(title);
    });
    translationSubscription = this.translocoService.selectTranslate('genetics.floweringperiods.description').subscribe(value => {
      description = value;
      this.metaService.updateTag({name: 'description', content: description});
    });
    translationSubscription = this.translocoService.selectTranslate('genetics.floweringperiods.keywords').subscribe(value => {
      keywords = value;
      if (typeof keywords !== 'undefined') {
        this.metaService.updateTag({name: 'keywords', content: keywords});
      }
    });
    translationSubscription.unsubscribe();
  }

  private subscriptions: Subscription[] = [];
  public newPlants: Plant[] = [];
  plantDetail = appRoutesLinks.PLANT_DETAIL_PAGE;

  ngOnInit(): void {
    this.subscriptions.push(
      this.plantService.fetchNewGeneticsList().subscribe(result => {
        this.newPlants = result;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
