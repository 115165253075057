import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FlyoutMenuService {

  private currentOpenFlyoutSubject = new BehaviorSubject<string | null>(null);
  currentOpenFlyout$ = this.currentOpenFlyoutSubject.asObservable();

  private isGeneticsFlyoutMenuSubject = new BehaviorSubject<boolean>(false);
  isGeneticsFlyoutMenu$ = this.isGeneticsFlyoutMenuSubject.asObservable();

  private isProductsFlyoutMenuSubject = new BehaviorSubject<boolean>(false);
  isProductsFlyoutMenu$ = this.isProductsFlyoutMenuSubject.asObservable();

  private isCompanyFlyoutMenuSubject = new BehaviorSubject<boolean>(false);
  isCompanyFlyoutMenu$ = this.isCompanyFlyoutMenuSubject.asObservable();

  setCurrentOpenFlyout(flyout: string | null) {
    this.currentOpenFlyoutSubject.next(flyout);
  }

  getCurrentOpenFlyout() {
    return this.currentOpenFlyoutSubject.value;
  }

  closeCurrentFlyout() {
    this.currentOpenFlyoutSubject.next(null);
    this.isGeneticsFlyoutMenuSubject.next(false);
    this.isProductsFlyoutMenuSubject.next(false);
    this.isCompanyFlyoutMenuSubject.next(false);
  }

  toggleFlyoutMenu(flyout: string) {
    const currentOpenFlyout = this.getCurrentOpenFlyout();
    this.closeCurrentFlyout(); // Close any currently open flyout
    if (currentOpenFlyout !== flyout) {
      this.setCurrentOpenFlyout(flyout);
      this.isGeneticsFlyoutMenuSubject.next(flyout === 'genetics');
      this.isProductsFlyoutMenuSubject.next(flyout === 'products');
      this.isCompanyFlyoutMenuSubject.next(flyout === 'company');
    }
  }

}
