import {ProductType} from './enum/product-type';

export class Product {
  constructor(public raescode: string = '',
              public fullnameEn: string = '',
              public unitQuantity: string = '',
              public productType: ProductType) {}

  static adapt(item: any): Product {
    return new Product(
      item.raescode,
      item.fullNameEn,
      item.unitQuantity,
      item.productType);
  }
}
