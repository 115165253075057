import {Component} from '@angular/core';
import {NewsListComponent} from "./news-list/news-list.component";
import {NewsHistoryComponent} from "./news-history/news-history.component";
import {TranslocoPipe, TranslocoService} from "@jsverse/transloco";
import {JobService} from "../../../core/repository/job.service";
import {ActivatedRoute} from "@angular/router";
import {Meta, Title} from "@angular/platform-browser";
import {Subscription} from "rxjs";
import {NewsService} from "../../../core/repository/news.service";

@Component({
  selector: 'app-news',
  standalone: true,
  imports: [
    NewsListComponent,
    NewsHistoryComponent,
    TranslocoPipe
  ],
  templateUrl: './news.component.html'
})
export class NewsComponent {

  constructor(private newsService: NewsService,
              private translocoService: TranslocoService,
              private titleService: Title,
              private metaService: Meta
  ) {
    let title: string;
    let description: string;
    let keywords: string;
    let translationSubscription: Subscription;

    // Use selectTranslate for the first department option
    translationSubscription = this.translocoService.selectTranslate('news.title').subscribe(value => {
      title = value;
      this.titleService.setTitle(title);
    });
    translationSubscription = this.translocoService.selectTranslate('news.description').subscribe(value => {
      description = value;
      this.metaService.updateTag({name: 'description', content: description});
    });
    translationSubscription = this.translocoService.selectTranslate('news.keywords').subscribe(value => {
      keywords = value;
      if (typeof keywords !== 'undefined') {
        this.metaService.updateTag({name: 'keywords', content: keywords});
      }    });
    translationSubscription.unsubscribe();
  }
}
