import {Component, OnInit} from '@angular/core';
import {NavigationUtils} from "../../../core/util/navigation.utils";
import {appRoutesLinks} from "../../../app.routes.links";
import {TranslocoPipe, TranslocoService} from "@jsverse/transloco";
import {Meta, Title} from "@angular/platform-browser";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-floweringperiods',
  standalone: true,
  imports: [
    TranslocoPipe
  ],
  templateUrl: './floweringperiods.component.html',
  styles: ``
})
export class FloweringperiodsComponent implements OnInit {

  constructor(
    private titleService: Title,
    private navigationUtils: NavigationUtils,
    private translocoService: TranslocoService,
    private metaService: Meta
  ) {
    let lang: string = this.translocoService.getActiveLang();

    let title: string;
    let description: string;
    let keywords: string;
    let translationSubscription: Subscription;

    // Use selectTranslate for the first department option
    translationSubscription = this.translocoService.selectTranslate('genetics.floweringperiods.title').subscribe(value => {
      title = value;
      this.titleService.setTitle(title);
    });
    translationSubscription = this.translocoService.selectTranslate('genetics.floweringperiods.description').subscribe(value => {
      description = value;
      this.metaService.updateTag({name: 'description', content: description});
    });
    translationSubscription = this.translocoService.selectTranslate('genetics.floweringperiods.keywords').subscribe(value => {
      keywords = value;
      if (typeof keywords !== 'undefined') {
        this.metaService.updateTag({name: 'keywords', content: keywords});
      }
    });
    translationSubscription.unsubscribe();
  }

  goToPeriod = appRoutesLinks.GENETICS_FLOWERINGPERIOD_PAGE ;

  ngOnInit(): void {
  }

  public navigate(route: string[], queryParams: {} = {}): void {
    if (Object.keys(queryParams).length !== 0) {
      this.navigationUtils.navigate(route, {
        queryParams
      });
    } else {
      this.navigationUtils.navigate(route);
    }
  }
}
