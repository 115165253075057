import {Component, Input} from '@angular/core';
import {RouterLink} from "@angular/router";
import {Job} from "../../../shared/model/job";
import {appRoutesLinks} from "../../../app.routes.links";

@Component({
  selector: 'app-landing-job-item',
  standalone: true,
  imports: [
    RouterLink
  ],
  templateUrl: './landing-job-item.component.html',
  styles: ``
})
export class LandingJobItemComponent {
  @Input() jobitem: Job | undefined;
  protected readonly appRoutesLinks = appRoutesLinks;
}
