import {Component, OnInit} from '@angular/core';
import {RouterLink} from "@angular/router";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";
import {AsyncPipe, NgForOf, NgIf} from "@angular/common";
import {TranslocoPipe, TranslocoService} from "@jsverse/transloco";
import {CtaButtonComponent} from "../../../shared/components/cta-button/cta-button.component";
import {Digitalcatalog} from "../../../shared/model/digitalcatalog";
import {CatalogService} from "../../../core/repository/catalog.service";
import {appRoutesLinks} from "../../../app.routes.links";
import {IssuuItemComponent} from "../../../shared/components/issuu-item/issuu-item.component";

@Component({
  selector: 'app-landing-catalogs',
  standalone: true,
  imports: [
    CtaButtonComponent,
    RouterLink,
    AsyncPipe,
    NgForOf,
    TranslocoPipe,
    CtaButtonComponent,
    IssuuItemComponent,
    NgIf
  ],
  templateUrl: './landing-catalogs.component.html'
})
export class LandingCatalogsComponent implements OnInit {

  identifyDigitalcatalog(index: number, digitalcatalog: Digitalcatalog): number {
    return digitalcatalog.id;
  }

  constructor(private digitalcatalogsService: CatalogService,
              private translateService: TranslocoService,
  ) {
    this.translateService.langChanges$.subscribe(lang => {
      this.catalogsSubscription = this.digitalcatalogsService.getHighlighted().pipe(
        map(catalogs =>
          catalogs.filter(c => c.languages.find(langg =>
            langg.toLowerCase() === lang.toLowerCase()
          ))
        )
      );
    });
    console.log('LandingCatalogsComponent constructor');
  }

  catalogsSubscription: Observable<Digitalcatalog[]> | undefined;

  digitalCatalogLink = appRoutesLinks.CATALOG_PAGE;

  ngOnInit(): void {
    console.log(this.translateService.getActiveLang());
    this.catalogsSubscription = this.digitalcatalogsService.getHighlighted().pipe(map(catalogs =>
      catalogs.filter(c => c.languages.find(lang => lang.toLowerCase() === this.translateService.getActiveLang()))
    ));
  }

  protected readonly appRoutesLinks = appRoutesLinks;
}
