<div *ngIf="filtersOpen" class="fixed inset-0 overflow-hidden z-50" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
  <div class="absolute inset-0 overflow-hidden">
    <!-- Background overlay, show/hide based on slide-over state. -->
    <div class="absolute inset-0" aria-hidden="true"></div>

    <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
      <!--
        Slide-over panel, show/hide based on slide-over state.

        Entering: "transform transition ease-in-out duration-500 sm:duration-700"
          From: "translate-x-full"
          To: "translate-x-0"
        Leaving: "transform transition ease-in-out duration-500 sm:duration-700"
          From: "translate-x-0"
          To: "translate-x-full"
      -->
      <div *ngIf="filtersOpen"
           appTransition="filtersOpen"
           appTransitionEntering="transform transition ease-in-out duration-500 sm:duration-700"
           appTransitionEnteringFrom="translate-x-full"
           appTransitionEnteringTo="translate-x-0"
           appTransitionLeaving="transform transition ease-in-out duration-500 sm:duration-700"
           appTransitionLeavingFrom="translate-x-0"
           appTransitionLeavingTo="translate-x-full"
           class="w-screen max-w-md">
        <div class="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
          <div class="px-4 sm:px-6">
            <div class="flex items-start justify-between">
              <!-- todo: translate -->
              <h2 class="text-lg font-medium text-gray-900" id="slide-over-title">
                Filters
              </h2>
              <div class="ml-3 h-7 flex items-center">
                <button (click)="closeFilters()" class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  <span class="sr-only">Close panel</span>
                  <!-- Heroicon name: outline/x -->
                  <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div class="mt-6 relative flex-1 px-4 sm:px-6">
            <app-plant-filters class="mb-4" [form]="form"></app-plant-filters>
          </div>
          <div class="flex justify-end mt-6 px-4 sm:px-6">
            <button (click)="closeFilters()" type="button" class="bg-christi-700 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white hover:bg-christi-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-christi-600">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

