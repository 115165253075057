<div class="flex flex-col">
  <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
    <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
      <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
        <table class="min-w-full divide-y divide-gray-200">
          <tbody class="bg-white divide-y divide-gray-200">
          <tr *ngFor="let orderForm of orderForms" >
            <td (click)="openInNewTab(orderForm?.url)"
                class="px-6 py-3 whitespace-nowrap cursor-pointer text-sm font-medium text-gray-900 text-left">
              <p class="m-0 overflow-ellipsis">{{ orderForm?.titleNl }}</p>
            </td>
            <td (click)="openInNewTab(orderForm?.url)"
                class="px-6 py-3 lg:table-cell whitespace-nowrap text-sm cursor-pointer font-medium text-gray-900 text-right">
              {{ 'orders.download' | transloco }}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
