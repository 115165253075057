
export class AvailabilityWeek {
  constructor(public amountAvailable: number = 0,
              public weekNumber: number = 0) {}

  static adapt(item: any): AvailabilityWeek {
    return new AvailabilityWeek(
      item.amountAvailable,
      item.weekNumber
    );
  }
}
