<ng-container *transloco="let t; prefix: 'header.aboutUs'">

  <div class="relative w-fit">
    <button type="button" (click)="toggleFlyoutMenu()"
            class="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900" [class.text-green-600]="flyoutMenuService.isCompanyFlyoutMenu$ | async"
            aria-expanded="false">
      <span class="capitalize">{{ t('title') }}</span>
      <svg class="h-5 w-5" [ngClass]="{'rotate-180': !(flyoutMenuService.isCompanyFlyoutMenu$ | async)}" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        <path fill-rule="evenodd"
              d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
              clip-rule="evenodd"/>
      </svg>
    </button>

    <div @opacityTranslateXY *ngIf="(flyoutMenuService.isCompanyFlyoutMenu$ | async)"  [class.hidden]="!(flyoutMenuService.isCompanyFlyoutMenu$ | async)"
         class="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4">
      <div
        class="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
        <div class="p-4">
          <div class="group relative flex gap-x-6 rounded-lg p-4 hover:bg-christi-600">
            <div
              class="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                   stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M7.864 4.243A7.5 7.5 0 0 1 19.5 10.5c0 2.92-.556 5.709-1.568 8.268M5.742 6.364A7.465 7.465 0 0 0 4.5 10.5a7.464 7.464 0 0 1-1.15 3.993m1.989 3.559A11.209 11.209 0 0 0 8.25 10.5a3.75 3.75 0 1 1 7.5 0c0 .527-.021 1.049-.064 1.565M12 10.5a14.94 14.94 0 0 1-3.6 9.75m6.633-4.596a18.666 18.666 0 0 1-2.485 5.33"/>
              </svg>
            </div>
            <div>
              <a (click)="navigateAndClose(appRoutesLinks.ABOUT_PAGE)"
                 (keyup)="navigateAndClose(appRoutesLinks.ABOUT_PAGE)" class="font-semibold text-gray-900">
                {{ t('family.title') }}
                <span class="absolute inset-0"></span>
              </a>
              <p class="mt-1 text-gray-600">{{ t('family.description') }}</p>
            </div>
          </div>

          <div class="group relative flex gap-x-6 rounded-lg p-4 hover:bg-christi-600">
            <div
              class="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                   stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z"/>
              </svg>
            </div>
            <div>
              <a (click)="navigateAndClose(appRoutesLinks.JOBS_PAGE)"
                 (keyup)="navigateAndClose(appRoutesLinks.JOBS_PAGE)" class="font-semibold text-gray-900">
                {{ t('jobs.title') }}
                <span class="absolute inset-0"></span>
              </a>
              <p class="mt-1 text-gray-600">{{ t('jobs.description') }}</p>
            </div>
          </div>

          <div class="group relative flex gap-x-6 rounded-lg p-4 hover:bg-christi-600">
            <div
              class="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                   stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 0 1-2.25 2.25M16.5 7.5V18a2.25 2.25 0 0 0 2.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 0 0 2.25 2.25h13.5M6 7.5h3v3H6v-3Z"/>
              </svg>
            </div>
            <div>
              <a (click)="navigateAndClose(appRoutesLinks.NEWS_PAGE)"
                 (keyup)="navigateAndClose(appRoutesLinks.NEWS_PAGE)" class="font-semibold text-gray-900">
                {{ t('news.title') }}
                <span class="absolute inset-0"></span>
              </a>
              <p class="mt-1 text-gray-600">{{ t('news.description') }}</p>
            </div>
          </div>

        </div>
        <div class="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
          <a (click)="navigateAndClose(appRoutesLinks.PLANT_OVERVIEW_PAGE)"
             (keyup)="navigateAndClose(appRoutesLinks.PLANT_OVERVIEW_PAGE)"
             class="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-christi-600">
            <svg class="h-5 w-5 flex-none text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                    d="M2 10a8 8 0 1116 0 8 8 0 01-16 0zm6.39-2.908a.75.75 0 01.766.027l3.5 2.25a.75.75 0 010 1.262l-3.5 2.25A.75.75 0 018 12.25v-4.5a.75.75 0 01.39-.658z"
                    clip-rule="evenodd"/>
            </svg>
            {{ t('callToAction.viewAllProducts') }}
          </a>

          <a (click)="navigateAndClose(appRoutesLinks.CONTACT_PAGE)"
             (keyup)="navigateAndClose(appRoutesLinks.CONTACT_PAGE)"
             class="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-christi-600">
            <svg class="h-5 w-5 flex-none text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                    d="M2 3.5A1.5 1.5 0 013.5 2h1.148a1.5 1.5 0 011.465 1.175l.716 3.223a1.5 1.5 0 01-1.052 1.767l-.933.267c-.41.117-.643.555-.48.95a11.542 11.542 0 006.254 6.254c.395.163.833-.07.95-.48l.267-.933a1.5 1.5 0 011.767-1.052l3.223.716A1.5 1.5 0 0118 15.352V16.5a1.5 1.5 0 01-1.5 1.5H15c-1.149 0-2.263-.15-3.326-.43A13.022 13.022 0 012.43 8.326 13.019 13.019 0 012 5V3.5z"
                    clip-rule="evenodd"/>
            </svg>
            {{ t('callToAction.contactSales') }}
          </a>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="(flyoutMenuService.isCompanyFlyoutMenu$ | async)" (click)="toggleFlyoutMenu()" class="fixed inset-0"></div>

</ng-container>
