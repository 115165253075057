import {Component, Input} from '@angular/core';
import {TranslocoPipe} from "@jsverse/transloco";
import {NgClass, NgForOf} from "@angular/common";
import {FlowerMonth} from "../../../../shared/model/enum/flowerMonth";
import {Month} from "../../../../shared/model/enum/month";
import {convertMonthToFlowerMonth} from "../../../../shared/model/enum/convertMonth";

@Component({
  selector: 'app-flower-periods',
  standalone: true,
  imports: [
    TranslocoPipe,
    NgClass,
    NgForOf
  ],
  templateUrl: './flower-periods.component.html',
  styleUrl: './flower-periods.component.css'
})
export class FlowerPeriodsComponent {
  @Input() flowerMonths: FlowerMonth[] | undefined;
  @Input() label: string | undefined;

  calculateClasses(month: string): string {
    const monthIndex = Object.keys(Month).indexOf(month) + 1;

    // Convert current month number to Month enum string
    const monthName = Month[month as keyof typeof Month];

    if (this.flowerMonths?.includes(monthName as any)) {
      return 'bg-gold-drop-600';
    }
    return '';
  }
}
