import {Component, OnInit} from '@angular/core';
import {RouterLink} from "@angular/router";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";
import {AsyncPipe, NgForOf, NgIf} from "@angular/common";
import {TranslocoPipe, TranslocoService} from "@jsverse/transloco";
import {CtaButtonComponent} from "../../../shared/components/cta-button/cta-button.component";
import {Digitalcatalog} from "../../../shared/model/digitalcatalog";
import {CatalogService} from "../../../core/repository/catalog.service";
import {appRoutesLinks} from "../../../app.routes.links";
import {IssuuItemComponent} from "../../../shared/components/issuu-item/issuu-item.component";

@Component({
  selector: 'app-landing-catalogs',
  standalone: true,
  imports: [
    CtaButtonComponent,
    RouterLink,
    AsyncPipe,
    NgForOf,
    TranslocoPipe,
    CtaButtonComponent,
    IssuuItemComponent,
    NgIf
  ],
  templateUrl: './landing-catalogs.component.html'
})
export class LandingCatalogsComponent implements OnInit {

  identifyDigitalcatalog(index: number, digitalcatalog: Digitalcatalog): number {
    return digitalcatalog.id;
  }

  constructor(private digitalcatalogsService: CatalogService,
              private translateService: TranslocoService,
  ) {
  }

  catalogsSubscription: Observable<Digitalcatalog[]> | undefined;

  catalogClasses = 'col-md-12';
  digitalCatalogLink = appRoutesLinks.CATALOG_PAGE;

  public catalogsSlideConfig: any = {
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [{
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  ngOnInit(): void {
    this.catalogsSubscription = this.digitalcatalogsService.getHighlighted().pipe(map(catalogs =>
      catalogs.filter(c => c.languages.find(lang => lang.toLowerCase() === this.translateService.getActiveLang()))
    ));
  }

  protected readonly appRoutesLinks = appRoutesLinks;
}
