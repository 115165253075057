import {Component, OnInit} from '@angular/core';
import {TranslocoPipe, TranslocoService} from "@jsverse/transloco";
import {News} from "../../../shared/model/news";
import {Subscription} from "rxjs";
import {NavigationUtils} from "../../../core/util/navigation.utils";
import {NewsService} from "../../../core/repository/news.service";
import {ActivatedRoute, RouterLink} from "@angular/router";
import {SafePipe} from "../../../shared/pipes/safe.pipe";
import {appRoutesLinks} from "../../../app.routes.links";
import {NgIf} from "@angular/common";
@Component({
  selector: 'app-news',
  standalone: true,
  imports: [
    TranslocoPipe,
    RouterLink,
    SafePipe,
    NgIf
  ],
  templateUrl: './news-detail.component.html'
})
export class NewsDetailComponent implements OnInit {
  newsItem: News | undefined;
  data: any;
  private subscription: Subscription | undefined;

  constructor(private navigationUtils: NavigationUtils,
              private route: ActivatedRoute,
              protected translateService: TranslocoService,
              private newsService: NewsService) {
  }

  ngOnInit(): void {
    this.subscription = this.newsService.show(this.route.snapshot.params['id'])
      .subscribe(newsItem => this.newsItem = newsItem);
  }

  protected readonly appRoutesLinks = appRoutesLinks;
}
