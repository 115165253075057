import {Component} from '@angular/core';
import {appRoutesLinks} from "../../../app.routes.links";
import {RouterModule} from "@angular/router";
import {TranslocoDirective} from "@jsverse/transloco";

@Component({
  selector: 'app-disclaimer',
  standalone: true,
  imports: [
    RouterModule,
    TranslocoDirective
  ],
  templateUrl: './disclaimer.component.html'
})
export class DisclaimerComponent {
  privacyLink = appRoutesLinks.PRIVACY_PAGE;

}
