import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Digitalcatalog} from "../../model/digitalcatalog";
import {NgIf, SlicePipe} from "@angular/common";
import {SafePipe} from "../../pipes/safe.pipe";
import {PlantIconListComponent} from "../../../features/catalog/plant-icon-list/plant-icon-list.component";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";

@Component({
  selector: 'app-issuu-item',
  templateUrl: './issuu-item.component.html',
  standalone: true,
  imports: [
    SlicePipe,
    SafePipe,
    NgIf,
    PlantIconListComponent
  ],
  styleUrls: ['./issuu-item.component.scss']
})
export class IssuuItemComponent {

  @Input() digitalcatalog: Digitalcatalog = new Digitalcatalog();
  @Input() languageIconActivated = false;
  @Output() clicked = new EventEmitter<Digitalcatalog>();

  constructor(private sanitizer: DomSanitizer) {}

  getSafeUrl(): SafeResourceUrl {
    const url = `https://e.issuu.com/embed.html?d=${this.digitalcatalog.digitalMagazineCode}&u=yrr81`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
