export class Genus {
  constructor(
    public id: number = 0,
    public botanicName: string = '',
    public nameNl: string = '',
    public nameFr: string = '',
    public nameEn: string = '',
    public nameDe: string = ''
  ) {
  }

  static adapt(item: any): Genus {
    return new Genus(
      item.id,
      item.botanicName,
      item.nameNl,
      item.nameFr,
      item.nameEn,
      item.nameDe
    );
  }

  getName(language: string): string {
    if (language === null || language === undefined) {
      return this.nameNl;
    }

    switch (language) {
      default:
      case 'nl':
        return this.nameNl;
      case 'fr':
        return this.nameFr;
      case 'en':
        return this.nameEn;
      case 'de':
        return this.nameDe;
    }
  }
}
