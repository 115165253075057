import {Component, Input} from '@angular/core';
import {TranslocoPipe} from "@jsverse/transloco";

@Component({
  selector: 'app-plant-potsize-icon',
  standalone: true,
  imports: [
    TranslocoPipe
  ],
  templateUrl: './plant-potsize-icon.component.html',
  styles: ``
})
export class PlantPotsizeIconComponent {

  @Input() fromSize: any;
  @Input() tillSize: any;

  getSize(): string {
    if (this.tillSize == null || this.tillSize === 0) {
      return this.fromSize + ' cm';
    } else {
      return this.fromSize + ' - ' + this.tillSize + ' cm';
    }
  }

}
