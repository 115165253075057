<li class="py-1 m-5 px-2 bg-gold-drop-500 text-center rounded-lg xl:px-1 xl:text-left flex-1 shadow-md">
  <div class="bg-white px-2 py-5 border-b border-gray-200 sm:px-6 rounded-t-md">
    <div class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
      <div class="ml-4 mt-2">
        <a href="{{digitalcatalog.pdfPath}}" target="_blank">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          {{digitalcatalog.shortName | slice:0:25}}
        </h3>
        </a>
      </div>
      <a href="{{digitalcatalog.pdfPath}}" target="_blank">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 self-start" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
      </svg>
      </a>
      <div *ngIf="languageIconActivated" class="ml-4 mt-2 flex-shrink-0">
          <app-plant-icon-list  [productIcons]="digitalcatalog.languages" category="language" extension="png"></app-plant-icon-list>
      </div>
    </div>
  </div>
  <div class="embed-container rounded-b-md" data-page-width="453" data-page-height="640" id="ypembedcontainer">
    <iframe
      [src]="getSafeUrl()"
      allow="clipboard-write"
      sandbox="allow-top-navigation allow-top-navigation-by-user-activation allow-downloads allow-scripts allow-same-origin allow-popups allow-modals allow-popups-to-escape-sandbox allow-forms"
      allowfullscreen="true"
      style="position:absolute;border:none;width:100%;height:100%;left:0;right:0;top:0;bottom:0;">
    </iframe>
  </div>
</li>
