import {Component, OnInit} from '@angular/core';
import {Job} from "../../../../shared/model/job";
import {ActivatedRoute, Router} from "@angular/router";
import {SeoService} from "../../../../core/util/seo.service";
import {JobService} from "../../../../core/repository/job.service";
import {JobApplyFormComponent} from "../job-apply-form/job-apply-form.component";
import {TranslocoPipe, TranslocoService} from "@jsverse/transloco";

@Component({
  selector: 'app-job-apply',
  standalone: true,
  imports: [
    JobApplyFormComponent,
    TranslocoPipe
  ],
  templateUrl: './job-apply.component.html',
  styles: ``
})
export class JobApplyComponent implements OnInit {
  public job: Job | undefined;
  public title: string | undefined;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translocoService: TranslocoService,
    private seoService: SeoService,
    private jobService: JobService) {
  }

  ngOnInit(): void {
    const title = this.translocoService.translate('job.title');
    const description = this.translocoService.translate('availability.subtitle');

    if (this.route.snapshot.params['id'] === 0) {
      this.title = this.translocoService.translate('job.openApplication');
    } else {
      this.jobService.getJob(this.route.snapshot.params['id']).subscribe(job => {
          this.job = job;
          this.title = this.job.title;
        },
        (error) => console.log(error));
    }
    this.seoService.setSEOData(title, description);
  }

}
