import {Component, computed, inject, signal} from '@angular/core';
import {Contact} from "../../../shared/model/contact";
import {ContactItemComponent} from "../contact-item/contact-item.component";
import {NgForOf} from "@angular/common";
import {TranslocoDirective, TranslocoPipe} from "@jsverse/transloco";
import {ContactService} from "../../../core/repository/contact.service";
import {toSignal} from '@angular/core/rxjs-interop';

type CountryOption = 'all' | 'belgium' | 'france' | 'uk' | 'germany' | 'austria' |
  'switzerland' | 'poland' | 'hungary' | 'czech' | 'scandinavia' | 'ireland';

@Component({
  selector: 'app-contact-list',
  standalone: true,
  imports: [
    ContactItemComponent,
    NgForOf,
    TranslocoDirective,
    TranslocoPipe
  ],
  templateUrl: './contact-list.component.html'
})
export class ContactListComponent {
  private contactService = inject(ContactService);

  // Signals
  protected readonly selectedCountry = signal<CountryOption>('all');
  protected readonly isLoading = signal<boolean>(true);
  protected readonly error = signal<string | null>(null);

  protected readonly contacts = toSignal(this.contactService.getActive(), {
    initialValue: [] as Contact[]
  });

  // Computed signal for filtered contacts
  protected readonly filteredContacts = computed(() => {
    const currentFilter = this.selectedCountry();
    const allContacts = this.contacts();

    if (currentFilter === 'all') {
      return allContacts;
    }

    return allContacts.filter(
      contact => contact.country.toLowerCase() === currentFilter.toLowerCase()
    );
  });

  // Countries array for type safety
  protected readonly countries: CountryOption[] = [
    'all', 'belgium', 'france', 'uk', 'germany', 'austria',
    'switzerland', 'poland', 'hungary', 'czech', 'scandinavia','ireland'
  ];

  filterContactPersons(event: Event): void {
    const filterVal = (event.target as HTMLSelectElement).value as CountryOption;
    this.selectedCountry.set(filterVal);
  }
}
