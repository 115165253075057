export class ProductSortHelper {
  public static translateFromAlias(alias: string): string {
    switch (alias) {
      case 'name_asc':
        return 'fullnameEn,asc';
      case 'name_desc':
        return 'fullnameEn,desc';
    }
    return '';
  }
}
