export class Soil {
  constructor(public id: string = '',
              public nameEn: string = '',
              public nameFr: string = '',
              public nameNl: string = '',
              public nameDe: string = '',
              public namePl: string = '',
              public nameHu: string = '') {}
  static adapt(item: any): Soil {
    return new Soil(
      item.id,
      item.nameEn,
      item.nameFr,
      item.nameNl,
      item.nameDe,
      item.namePl,
      item.nameHu
    );
  }

  getName(language: string): string {
    switch (language.toLowerCase()) {
      default:
      case 'nl':
        return this.nameNl;
      case 'fr':
        return this.nameFr;
      case 'en':
        return this.nameEn;
      case 'de':
        return this.nameDe;
      case 'pl':
        return this.namePl;
      case 'hu':
        return this.nameHu;
    }
  }
}
