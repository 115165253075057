export class Disease {
  constructor(public nameEn: string = '',
              public nameFr: string = '',
              public nameNl: string = '',
              public nameDe: string = '',
              public namePl: string = '',
              public nameHu: string = '') {}
  static adapt(item: any): Disease {
    return new Disease(
      item.nameEn,
      item.nameFr,
      item.nameNl,
      item.nameDe,
      item.namePl,
      item.nameHu
    );
  }

  getName(language: string): string {
    switch (language.toLowerCase()) {
      default:
      case 'nl':
        return this.nameNl;
      case 'fr':
        return this.nameFr;
      case 'en':
        return this.nameEn;
      case 'de':
        return this.nameEn;
      case 'pl':
        return this.nameEn;
      case 'hu':
        return this.nameEn;
    }
  }

}
