import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root' // Provide globally for standalone components
})
export class CustomInjectorService {
  private httpClient: HttpClient; // Renamed to `httpClient`

  constructor(http: HttpClient) { // Use constructor argument directly
    this.httpClient = http;
  }

  getHttpClient(): HttpClient {
    return this.httpClient;
  }
}
