import {Component, OnInit} from '@angular/core';
import {Serie} from "../../../shared/model/serie";
import {appRoutesLinks} from "../../../app.routes.links";
import {NavigationUtils} from "../../../core/util/navigation.utils";
import {SeoService} from "../../../core/util/seo.service";
import {RouterLink} from "@angular/router";
import {NgForOf} from "@angular/common";
import {TranslocoPipe, TranslocoService} from "@jsverse/transloco";
import {Meta, Title} from "@angular/platform-browser";
import {Subscription} from "rxjs";
import {ButtonModule} from "primeng/button";
import {DialogModule} from "primeng/dialog";

@Component({
  selector: 'app-genetics-just-in-time',
  standalone: true,
  imports: [
    RouterLink,
    NgForOf,
    TranslocoPipe,
    ButtonModule,
    DialogModule
  ],
  templateUrl: './genetics-just-in-time.component.html',
  styles: ``
})
export class GeneticsJustInTimeComponent implements OnInit {
  productDetail = appRoutesLinks.PLANT_DETAIL_PAGE;
  goToPeriod = appRoutesLinks.GENETICS_FLOWERINGPERIOD_PAGE ;
  public isSelectedWeekDropdownOpen = false;
  primusVariety: Serie = new Serie();
  evieVariety: Serie = new Serie();
  ambieVariety: Serie = new Serie();
  rambomidVariety: Serie = new Serie();
  tobieVariety: Serie = new Serie();
  charlieVariety: Serie = new Serie();
  rambolateVariety: Serie = new Serie();
  cabaretVariety: Serie = new Serie();
  public currentSerie: Serie = new Serie();

  visible: boolean = false;

  showDialog() {
    this.visible = true;
  }

  constructor(private navigationUtils: NavigationUtils,
              private readonly seoService: SeoService,
              public translocoService: TranslocoService,
              private titleService: Title,
              private metaService: Meta
  ) {
    let lang: string = this.translocoService.getActiveLang();

    let title: string;
    let description: string;
    let keywords: string;
    let translationSubscription: Subscription;

    // Use selectTranslate for the first department option
    translationSubscription = this.translocoService.selectTranslate('genetics.justintime.title').subscribe(value => {
      title = value;
      this.titleService.setTitle(title);
    });
    translationSubscription = this.translocoService.selectTranslate('genetics.justintime.description').subscribe(value => {
      description = value;
      this.metaService.updateTag({name: 'description', content: description});
    });
    translationSubscription = this.translocoService.selectTranslate('genetics.justintime.keywords').subscribe(value => {
      keywords = value;
      if (typeof keywords !== 'undefined') {
        this.metaService.updateTag({name: 'keywords', content: keywords});
      }    });
    translationSubscription.unsubscribe();
  }


  ngOnInit(): void {
    this.initVarieties();
  }

  private initVarieties(): void{
    this.primusVariety = new Serie();
    this.primusVariety.potweeks = ['30', '32', '32', '33', '34', '35'];
    this.primusVariety.floweringweeks = ['39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '1'];
    this.primusVariety.variety = 'Primus F1';
    this.primusVariety.growth = 'mc';
    this.primusVariety.potsize = 'm';
    this.primusVariety.flowersize = '10,5';
    this.primusVariety.image = 'https://d2r5uf6odu3th4.cloudfront.net/seeds/public/M034.jpg';

    this.evieVariety = new Serie();
    this.evieVariety.potweeks = ['34', '35', '36', '37', '38', '39', '40'];
    this.evieVariety.floweringweeks = ['48', '49', '50', '51', '52', '1', '2', '3', '4', '5', '6'];
    this.evieVariety.variety = 'Evie F1';
    this.evieVariety.growth = 'c';
    this.evieVariety.potsize = 'l';
    this.evieVariety.flowersize = '9-10,5';
    this.evieVariety.image = 'https://d2r5uf6odu3th4.cloudfront.net/seeds/public/P596.jpg';

    this.ambieVariety = new Serie();
    this.ambieVariety.potweeks = ['36', '37', '38', '39', '40'];
    this.ambieVariety.floweringweeks = ['2', '3', '4', '5', '6', '7', '8', '9'];
    this.ambieVariety.variety = 'Ambie F1';
    this.ambieVariety.growth = 'c';
    this.ambieVariety.potsize = 'm';
    this.ambieVariety.flowersize = '9-10,5';
    this.ambieVariety.image = 'https://d2r5uf6odu3th4.cloudfront.net/seeds/public/M077.jpg';

    this.rambomidVariety = new Serie();
    this.rambomidVariety.potweeks = ['36', '37', '38', '39', '40'];
    this.rambomidVariety.floweringweeks = ['2', '3', '4', '5', '6', '7', '8', '9'];
    this.rambomidVariety.variety = 'Rambo Mid F1';
    this.rambomidVariety.growth = 'xv';
    this.rambomidVariety.potsize = 'xl';
    this.rambomidVariety.flowersize = '12-14';
    this.rambomidVariety.image = 'https://d2r5uf6odu3th4.cloudfront.net/seeds/public/F158.jpg';

    this.tobieVariety = new Serie();
    this.tobieVariety.potweeks = ['37', '38', '39'];
    this.tobieVariety.floweringweeks = ['6', '7', '8', '9', '10', '11'];
    this.tobieVariety.variety = 'Tobie F1';
    this.tobieVariety.growth = 'xc';
    this.tobieVariety.potsize = 'm';
    this.tobieVariety.flowersize = '9';
    this.tobieVariety.image = 'https://d2r5uf6odu3th4.cloudfront.net/seeds/public/E998.jpg';

    this.charlieVariety = new Serie();
    this.charlieVariety.potweeks = ['37', '38', '39', '40'];
    this.charlieVariety.floweringweeks = ['6', '7', '8', '9', '10', '11'];
    this.charlieVariety.variety = 'Charlie F1';
    this.charlieVariety.growth = 'c';
    this.charlieVariety.potsize = 'm';
    this.charlieVariety.flowersize = '9-10,5';
    this.charlieVariety.image = 'https://d2r5uf6odu3th4.cloudfront.net/seeds/public/P608.jpg';

    this.rambolateVariety = new Serie();
    this.rambolateVariety.potweeks = ['37', '38', '39', '40'];
    this.rambolateVariety.floweringweeks = ['6', '7', '8', '9', '10', '11', '12'];
    this.rambolateVariety.variety = 'Rambo Late F1';
    this.rambolateVariety.growth = 'xv';
    this.rambolateVariety.potsize = 'xl';
    this.rambolateVariety.flowersize = '12-14';
    this.rambolateVariety.image = 'https://d2r5uf6odu3th4.cloudfront.net/seeds/public/P593.jpg';

    this.cabaretVariety = new Serie();
    this.cabaretVariety.potweeks = ['37', '38', '39', '40'];
    this.cabaretVariety.floweringweeks = ['9', '10', '11', '12', '13', '14'];
    this.cabaretVariety.variety = 'Cabaret F1';
    this.cabaretVariety.growth = 'mc';
    this.cabaretVariety.potsize = '10,5';
    this.cabaretVariety.flowersize = 'l';
    this.cabaretVariety.image = 'https://d2r5uf6odu3th4.cloudfront.net/seeds/public/E204.jpg';

  }
  public navigate(route: string[], queryParams: {} = {}): void {
    if (Object.keys(queryParams).length !== 0) {
      this.navigationUtils.navigate(route, {
        queryParams
      });
    } else {
      this.navigationUtils.navigate(route);
    }
  }

  initWeekCalendars(serie: Serie): void {
    this.visible = true;
    this.currentSerie = serie;
  }
}
