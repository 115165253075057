import {Component, OnDestroy, OnInit} from '@angular/core';
import {ContactInfo} from "../../../shared/model/contactInfo";
import {FormGroup, ReactiveFormsModule, UntypedFormBuilder, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {appRoutesLinks} from "../../../app.routes.links";
import {ContactService} from "../../../core/repository/contact.service";
import {OptionsService} from "../../../core/util/options.service";
import {FormUtils} from "../../../core/util/form.utils";
import {RouterModule} from "@angular/router";
import {TextComponent} from "../../../shared/form/text/text.component";
import {TextAreaComponent} from "../../../shared/form/text-area/text-area.component";
import {DropdownComponent} from "../../../shared/form/dropdown/dropdown.component";
import {RecaptchaFormsModule, RecaptchaModule} from "ng-recaptcha-2";
import {TranslocoDirective, TranslocoPipe} from "@jsverse/transloco";
import {MessageService} from "primeng/api";
import {Toast} from "primeng/toast";
import {Message} from "primeng/message";

@Component({
  selector: 'app-contact-form',
  standalone: true,
  imports: [
    RouterModule,
    ReactiveFormsModule,
    TextComponent,
    TextAreaComponent,
    DropdownComponent,
    RecaptchaFormsModule,
    RecaptchaModule,
    TranslocoDirective,
    TranslocoPipe,
    Toast,
    Message
  ],
  templateUrl: './contact-form.component.html'
})
export class ContactFormComponent implements OnInit, OnDestroy {

  contactInfo = new ContactInfo();
  contactForm!: FormGroup;
  errors: any;

  departments: any[] = [];
  subscriptions: Subscription[] = [];
  disclaimerLink = appRoutesLinks.DISCLAIMER_PAGE;

  constructor(private contactInfoService: ContactService,
              private formBuilder: UntypedFormBuilder,
              private formUtils: FormUtils,
              private messageService: MessageService,
              private optionService: OptionsService) {

  }

  ngOnInit(): void {
    this.contactForm = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      company: ['', [Validators.required]],
      street: ['', [Validators.required]],
      streetNumber: ['', [Validators.required]],
      city: ['', [Validators.required]],
      country: ['', [Validators.required]],
      department: ['INFO_DEPARTMENT', [Validators.required]],
      subject: ['', [Validators.required]],
      message: ['', [Validators.required]],
      termsAccepted: [false, [Validators.requiredTrue]],
      captcha: [null, [Validators.required]]
    });

    this.subscriptions.push(
      this.optionService.getDepartments().subscribe(departments => this.departments = departments)
    );

  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  public beforeSubmittingForm(): void {
    this.sendContactRequest();  // This sends the user data to the backend
  }

  sendContactRequest(): void {
    if (this.contactForm && this.contactForm.valid) {

      const formValues = this.contactForm.value;
      this.contactInfo.firstName = formValues.firstName;
      this.contactInfo.lastName = formValues.lastName;
      this.contactInfo.phone = formValues.phone;
      this.contactInfo.message = formValues.message;
      this.contactInfo.email = formValues.email;
      this.contactInfo.company = formValues.company;
      this.contactInfo.street = formValues.street;
      this.contactInfo.streetNumber = formValues.streetNumber;
      this.contactInfo.city = formValues.city;
      this.contactInfo.department = formValues.department;
      this.contactInfo.country = formValues.country;
      this.contactInfo.subject = formValues.subject;
      this.contactInfo.captcha = formValues.captcha;

      this.contactInfoService.createContactInfoRequest(this.contactInfo).subscribe(() => {
        this.messageService.add({
          severity: 'success',
          summary: 'Bericht Verstuurd',
          detail: 'We hebben uw aanvraag verstuurd!',
          closable: false,
          life: 4000
        });
      }, (response) => {
        if (response === undefined) {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Error',
            closable: false,
            life: 4000
          });
        } else {
        }
      });
    } else {
      if (this.contactForm) {
        this.formUtils.validateFormFields(this.contactForm);
      }
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Error',
        closable: false,
        life: 4000
      });
    }
  }
}
