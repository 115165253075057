import {Component, Input} from '@angular/core';
import {Plant} from "../../../shared/model/plant";
import {appRoutesLinks} from "../../../app.routes.links";
import {RouterLink} from "@angular/router";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-plant-grid-item',
  standalone: true,
  imports: [
    RouterLink,
    NgIf
  ],
  templateUrl: './plant-grid-item.component.html',
  styles: ``
})
export class PlantGridItemComponent {
  @Input() plant: Plant | undefined;

  productDetail = appRoutesLinks.PLANT_DETAIL_PAGE;
  @Input()
  isNew = false;
  @Input()
  isSpecial = false;

  public addToCart(): void {
    console.log('Add product to the cart!');
  }

  public addToCompare(): void {
    console.log('Add product to the compare.');
  }

  public addToWishlist(): void {
    console.log('Add product to the wishlist.');
  }
}
