import {Component} from '@angular/core';
import {appRoutesLinks} from "../../../app.routes.links";
import {RouterLink} from "@angular/router";
import {TranslocoDirective, TranslocoPipe} from "@jsverse/transloco";

@Component({
  selector: 'app-privacy',
  standalone: true,
  imports: [
    RouterLink,
    TranslocoPipe,
    TranslocoDirective
  ],
  templateUrl: './privacy.component.html'
})
export class PrivacyComponent {
  contactLink = appRoutesLinks.CONTACT_PAGE;

}
