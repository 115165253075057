import {Component, OnInit} from '@angular/core';
import {PlantService} from '../../../core/repository/plant.service';
import {TranslocoDirective} from "@jsverse/transloco";

@Component({
  selector: 'app-unique-selling-points',
  templateUrl: './unique-selling-points.component.html',
  standalone: true,
  imports: [
    TranslocoDirective
  ],
  styleUrls: ['./unique-selling-points.component.scss']
})
export class UniqueSellingPointsComponent implements OnInit {

  public newActiveProductCount: number | undefined;
  public yearsActive: number | undefined;

  constructor(private plantService: PlantService) {
  }

  ngOnInit(): void {
    const currentYear = new Date().getFullYear() - 2000;
    this.yearsActive = currentYear + 73;
    this.plantService.fetchNewPlantsCount().subscribe(count => this.newActiveProductCount = count);
  }

}
