<div class="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
  <div class="absolute inset-0">
    <div class="bg-white h-1/3 sm:h-2/3"></div>
  </div>
  <div class="relative max-w-7xl mx-auto">
    <div class="text-center">
      <h2>
        {{'genetics.news.title' | transloco }} {{'genetics.news.subtitle' | transloco }}
      </h2>
    </div>
    <div class="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
      <div class="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div class="flex-shrink-0">
          <img class="h-48 w-full object-cover" src="https://d3coqd91sswgmf.cloudfront.net/genetics/genetics_1.jpg" alt="">
        </div>
        <div class="flex-1 bg-white p-6 flex flex-col justify-between">
          <div class="flex-1">
            <p class="text-xl font-semibold text-gray-900">
              {{'genetics.landing.intro.description1' | transloco }}
            </p>
          </div>
        </div>
      </div>
      <div class="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div class="flex-shrink-0">
          <img class="h-48 w-full object-cover" src="https://d3coqd91sswgmf.cloudfront.net/genetics/genetics_3.jpg" alt="">
        </div>
        <div class="flex-1 bg-white p-6 flex flex-col justify-between">
          <div class="flex-1">
            <p class="text-xl font-semibold text-gray-900">
              {{'genetics.landing.intro.description2' | transloco }}
            </p>
          </div>
        </div>
      </div>
      <div class="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div class="flex-shrink-0">
          <img class="h-48 w-full object-cover" src="https://d3coqd91sswgmf.cloudfront.net/genetics/genetics_4.jpg" alt="">
        </div>
        <div class="flex-1 bg-white p-6 flex flex-col justify-between">
          <div class="flex-1">
            <p class="text-xl font-semibold text-gray-900">
              {{'genetics.landing.intro.description3' | transloco }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
