import {Component, Input} from '@angular/core';
import {Trademark} from "../../../../shared/model/enum/trademark";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-plant-trademark',
  standalone: true,
  imports: [
    NgIf
  ],
  templateUrl: './plant-trademark.component.html',
  styles: ``
})
export class PlantTrademarkComponent {
  @Input() trademark: Trademark = Trademark.UNREGISTERED;

  isUnregistered(trademark: Trademark): boolean {
    return trademark === Trademark.UNREGISTERED;
  }

  isRegistered(trademark: Trademark): boolean {
    return trademark === Trademark.REGISTERED;
  }
}
