<div class="flex flex-row items-center justify-center">
  <div class="w-36 inline-block align-middle text-xs truncate sm:text-sm">
    {{ ('plant.taxonomy.product.periodgrid.' + label | transloco) }}
  </div>
  <ng-container *ngIf="showMonths">
    <div *ngFor="let number of [1,2,3,4,5,6,7,8,9,10,11,12]"
         class="w-8 h-8 bg-white text-white flex items-center justify-center text-xs font-light m-1 p-0">
      <div class="grid grid-cols-1 p-0 m-0 h-full w-full">
        <div
          class="p-0 m-0 text-center text-black">{{ ('plant.taxonomy.product.periodgrid.' + number | transloco | slice:0:3) }}
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!showMonths">

    <div *ngFor="let number of [1,2,3,4,5,6,7,8,9,10,11,12]"
         class="w-8 h-8 bg-white text-white flex items-center justify-center text-xs font-light m-1 p-0">
      <div class="grid grid-cols-1 p-0 m-0 h-full w-full">
        <div class="p-0 m-0 text-center" [ngClass]="calculateClasses(number)"></div>
      </div>
    </div>
  </ng-container>
</div>





