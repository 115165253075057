import {Category} from './enum/category';
import {Usage} from './enum/usage';
import {Stand} from './enum/stand';
import {Lifetime} from './enum/lifetime';
import {FlowerMonth} from './enum/flowerMonth';
import {FruitMaturity} from './enum/fruit-maturity';
import {FruitColor} from './enum/fruit-color';
import {FruitShape} from './enum/fruit-shape';
import {FlowerPeriod} from './enum/flower-period';
import {FlowerPattern} from './enum/flower-pattern';
import {PrimulaType} from './enum/primula-type';
import {LeafType} from './enum/leaf-type';
import {Fertilization} from './enum/fertilization';
import {Spicyness} from './enum/spicyness';
import {Trademark} from './enum/trademark';
import {Soil} from './enum/soil';
import {ProductType} from './enum/product-type';
import {Disease} from './disease';
import {Color} from './color';

export class Plant {
  constructor(
              public approvedNovelty: boolean = false,
              public autumnSelection: boolean = false,
              public category: Category = Category.FOLIAGE,
              public colors: Color[] = [],
              public descriptionEn: string = '',
              public descriptionFr: string = '',
              public descriptionNl: string = '',
              public descriptionDe: string = '',
              public descriptionPl: string = '',
              public descriptionHu: string = '',
              public diseases: Disease[] = [],
              public earlyFlowering: boolean = false,
              public familyBotanicName: string = '',
              public fertilization: Fertilization = Fertilization.HIGH,
              public fleuroselect: boolean = false,
              public floralAward: boolean = false,
              public flowerPattern: FlowerPattern = FlowerPattern.DICHROMATIC,
              public flowerPeriod: FlowerPeriod = FlowerPeriod.EARLY,
              public flowerMonths: FlowerMonth[] = [],
              public fromHeight: number = 0,
              public fromPotSize: number = 0,
              public fromPottingMonth: FlowerMonth = FlowerMonth.APRIL,
              public fromPottingTemperature: number = 0,
              public fromSalesMonth: FlowerMonth = FlowerMonth.APRIL,
              public fromSecondPottingMonth: FlowerMonth = FlowerMonth.APRIL,
              public fromSecondSalesMonth: FlowerMonth = FlowerMonth.APRIL,
              public fromSecondSowingMonth: FlowerMonth = FlowerMonth.APRIL,
              public fromSowingMonth: FlowerMonth = FlowerMonth.APRIL,
              public fromSowingTemperature: number = 0,
              public fruitColor: FruitColor = FruitColor.BI_COLOR,
              public fruitMaturity: FruitMaturity = FruitMaturity.MATURE,
              public fruitShape: FruitShape = FruitShape.BLOCKY,
              public fullnameEn: string = '',
              public fullnameFr: string = '',
              public fullnameNl: string = '',
              public fullnameDe: string = '',
              public fullnamePl: string = '',
              public fullnameHu: string = '',
              public genusBotanicName: string = '',
              public genusNameEn: string = '',
              public genusNameFr: string = '',
              public genusNameNl: string = '',
              public genusNameDe: string = '',
              public genusNamePl: string = '',
              public genusNameHu: string = '',
              public genusDescriptionEn: string = '',
              public genusDescriptionFr: string = '',
              public genusDescriptionNl: string = '',
              public genusDescriptionDe: string = '',
              public genusDescriptionPl: string = '',
              public genusDescriptionHu: string = '',
              public geneticallyCompact: boolean = false,
              public hasSelectedCultivar: boolean = false,
              public height: number = 0,
              public hybrid: boolean = false,
              public improvedPlant: boolean = false,
              public innovertOr: boolean = false,
              public innovertBronze: boolean = false,
              public ipmAward: boolean = false,
              public leafType: LeafType = LeafType.DARK_LEAVED,
              public lifetime: Lifetime = Lifetime.ANNUAL,
              public nameEn: string = '',
              public nameFr: string = '',
              public nameNl: string = '',
              public nameDe: string = '',
              public namePl: string = '',
              public nameHu: string = '',
              public newPlant: boolean = false,
              public noImageAvailable: boolean =  false,
              public primulaType: PrimulaType = PrimulaType.GENERAL,
              public plantCode: string = '',
              public potSize: string = '',
              public productTypes: ProductType[] = [],
              public pottingTemperature: string = '',
              public pottingPeriod: string = '',
              public salesPeriod: string = '',
              public secondPottingPeriod: string = '',
              public secondSalesPeriod: string = '',
              public secondSowingPeriod: string = '',
              public selectedCultivarDescriptionEn: string = '',
              public selectedCultivarDescriptionFr: string = '',
              public selectedCultivarDescriptionNl: string = '',
              public selectedCultivarDescriptionDe: string = '',
              public selectedCultivarDescriptionPl: string = '',
              public selectedCultivarDescriptionHu: string = '',
              public selectedCultivarCuttingsDescriptionEn: string = '',
              public selectedCultivarCuttingsDescriptionFr: string = '',
              public selectedCultivarCuttingsDescriptionNl: string = '',
              public selectedCultivarCuttingsDescriptionDe: string = '',
              public selectedCultivarCuttingsDescriptionPl: string = '',
              public selectedCultivarCuttingsDescriptionHu: string = '',
              public selectedCultivarSeedsDescriptionEn: string = '',
              public selectedCultivarSeedsDescriptionFr: string = '',
              public selectedCultivarSeedsDescriptionNl: string = '',
              public selectedCultivarSeedsDescriptionDe: string = '',
              public selectedCultivarSeedsDescriptionPl: string = '',
              public selectedCultivarSeedsDescriptionHu: string = '',
              public selectedCultivarNameEn: string = '',
              public slug: string = '',
              public soils: Soil[] = [],
              public sowingPeriod: string = '',
              public sowingTemperature: string = '',
              public specialPlant: boolean = false,
              public specialSlug: string = '',
              public specieBotanicName: string = '',
              public specieDescriptionEn: string = '',
              public specieDescriptionFr: string = '',
              public specieDescriptionNl: string = '',
              public specieDescriptionDe: string = '',
              public specieDescriptionPl: string = '',
              public specieDescriptionHu: string = '',
              public specieNameEn: string = '',
              public specieNameFr: string = '',
              public specieNameNl: string = '',
              public specieNameDe: string = '',
              public specieNamePl: string = '',
              public specieNameHu: string = '',
              public spicyness: Spicyness = Spicyness.EXTRA_HOT,
              public stands: Stand[] = [],
              public tillHeight: number = 0,
              public tillPotSize: number = 0,
              public tillPottingMonth: FlowerMonth = FlowerMonth.APRIL,
              public tillPottingTemperature: number = 0,
              public tillSalesMonth: FlowerMonth = FlowerMonth.APRIL,
              public tillSecondPottingMonth: FlowerMonth = FlowerMonth.APRIL,
              public tillSecondSalesMonth: FlowerMonth = FlowerMonth.APRIL,
              public tillSecondSowingMonth: FlowerMonth = FlowerMonth.APRIL,
              public tillSowingMonth: FlowerMonth = FlowerMonth.APRIL,
              public tillSowingTemperature: number = 0,
              public timing: string = '',
              public trademark: Trademark = Trademark.UNREGISTERED,
              public usages: Usage[] = [],
              public image: string = '',
              public seedsAvailable: boolean = false,
              public seedlingsAvailable: boolean = false,
              public cuttingsAvailable: boolean = false,
              public raesBreeding: boolean = false
              ) {}


  static adapt(item: any): Plant {
    return new Plant(
      item.approvedNovelty,
      item.autumnSelection,
      item.category,
      item.colors,
      item.descriptionEn,
      item.descriptionFr,
      item.descriptionNl,
      item.descriptionDe,
      item.descriptionPl,
      item.descriptionHu,
      !!item.diseases ? item.diseases.map(Disease.adapt) : [],
      item.earlyFlowering,
      item.familyBotanicName,
      item.fertilization,
      item.fleuroselect,
      item.floralAward,
      item.flowerPattern,
      item.flowerPeriod,
      item.flowerMonths,
      item.fromHeight,
      item.fromPotSize,
      item.fromPottingMonth,
      item.fromPottingTemperature,
      item.fromSalesMonth,
      item.fromSecondPottingMonth,
      item.fromSecondSalesMonth,
      item.fromSecondSowingMonth,
      item.fromSowingMonth,
      item.fromSowingTemperature,
      item.fruitColor,
      item.fruitMaturity,
      item.fruitShape,
      item.fullnameEn,
      item.fullnameFr,
      item.fullnameNl,
      item.fullnameDe,
      item.fullnamePl,
      item.fullnameHu,
      item.genusBotanicName,
      item.genusNameEn,
      item.genusNameFr,
      item.genusNameNl,
      item.genusNameDe,
      item.genusNamePl,
      item.genusNameHu,
      item.genusDescriptionEn,
      item.genusDescriptionFr,
      item.genusDescriptionNl,
      item.genusDescriptionDe,
      item.genusDescriptionPl,
      item.genusDescriptionHu,
      item.geneticallyCompact,
      item.hasSelectedCultivar,
      item.height,
      item.hybrid,
      item.improvedPlant,
      item.innovertOr,
      item.innovertBronze,
      item.ipmAward,
      item.leafType,
      item.lifetime,
      item.nameEn,
      item.nameFr,
      item.nameNl,
      item.nameDe,
      item.namePl,
      item.nameHu,
      item.newPlant,
      item.noImageAvailable,
      item.primulaType,
      item.plantCode,
      item.potSize,
      item.productTypes,
      item.pottingTemperature,
      item.pottingPeriod,
      item.salesPeriod,
      item.secondPottingPeriod,
      item.secondSalesPeriod,
      item.secondSowingPeriod,
      item.selectedCultivarDescriptionEn,
      item.selectedCultivarDescriptionFr,
      item.selectedCultivarDescriptionNl,
      item.selectedCultivarDescriptionDe,
      item.selectedCultivarDescriptionPl,
      item.selectedCultivarDescriptionHu,
      item.selectedCultivarCuttingsDescriptionEn,
      item.selectedCultivarCuttingsDescriptionFr,
      item.selectedCultivarCuttingsDescriptionNl,
      item.selectedCultivarCuttingsDescriptionDe,
      item.selectedCultivarCuttingsDescriptionPl,
      item.selectedCultivarCuttingsDescriptionHu,
      item.selectedCultivarSeedsDescriptionEn,
      item.selectedCultivarSeedsDescriptionFr,
      item.selectedCultivarSeedsDescriptionNl,
      item.selectedCultivarSeedsDescriptionDe,
      item.selectedCultivarSeedsDescriptionPl,
      item.selectedCultivarSeedsDescriptionHu,
      item.selectedCultivarNameEn,
      item.slug,
      !!item.soils ? item.soils.map(Soil.adapt) : [],
      item.sowingPeriod,
      item.sowingTemperature,
      item.specialPlant,
      item.specialSlug,
      item.specieBotanicName,
      item.specieDescriptionEn,
      item.specieDescriptionFr,
      item.specieDescriptionNl,
      item.specieDescriptionDe,
      item.specieDescriptionPl,
      item.specieDescriptionHu,
      item.specieNameEn,
      item.specieNameFr,
      item.specieNameNl,
      item.specieNameDe,
      item.specieNamePl,
      item.specieNameHu,
      item.spicyness,
      item.stands,
      item.tillHeight,
      item.tillPotSize,
      item.tillPottingMonth,
      item.tillPottingTemperature,
      item.tillSalesMonth,
      item.tillSecondPottingMonth,
      item.tillSecondSalesMonth,
      item.tillSecondSowingMonth,
      item.tillSowingMonth,
      item.tillSowingTemperature,
      item.timing,
      item.trademark,
      item.usages,
      item.image,
      item.seedsAvailable,
      item.seedlingsAvailable,
      item.cuttingsAvailable,
      item.raesBreeding
    );
  }

  get lifetimeIcon(): string {
    let iconUrl = '';
    if (this.lifetime != null) {
      iconUrl = 'https://d3coqd91sswgmf.cloudfront.net/icon/' + this.lifetime + '.png';
    }
    return iconUrl;
  }

  get lifetimeIconSmall(): string {
    let iconUrl = '';
    if (this.lifetime != null) {
      iconUrl = 'https://d3coqd91sswgmf.cloudfront.net/icon/' + this.lifetime + '-small.png';
    }
    return iconUrl;
  }

  get standIcon(): string {
    let iconUrl = '';
    if (this.stands != null) {
      iconUrl = 'https://d3coqd91sswgmf.cloudfront.net/icon/' + this.stands[0] + '.png';
    }
    return iconUrl;
  }

  get usageIcon(): string {
    let iconUrl = '';
    if (this.usages != null) {
      iconUrl = 'https://d3coqd91sswgmf.cloudfront.net/icon/' + this.usages[0] + '.png';
    }
    return iconUrl;
  }

  get localPottingTemperature(): string {
    if (this.fromPottingTemperature === null && this.tillPottingTemperature === null) {
      return '';
    } else if (this.fromPottingTemperature === null) {
      return `${this.tillPottingTemperature} °C`;
    } else if (this.tillPottingTemperature === null) {
      return `${this.fromPottingTemperature} °C`;
    } else if (this.fromPottingTemperature === this.tillPottingTemperature) {
      return `${this.fromPottingTemperature} °C`;
    } else {
      return `${this.fromPottingTemperature} - ${this.tillPottingTemperature} °C`;
    }
  }

  get localSowingTemperature(): string {
    if (this.fromSowingTemperature === null && this.tillSowingTemperature === null) {
      return '';
    } else if (this.fromSowingTemperature === null) {
      return `${this.tillSowingTemperature} °C`;
    } else if (this.tillSowingTemperature === null) {
      return `${this.fromSowingTemperature} °C`;
    } else if (this.tillSowingTemperature === this.fromSowingTemperature) {
      return `${this.tillSowingTemperature} °C`;
    } else {
      return `${this.fromSowingTemperature} - ${this.tillSowingTemperature} °C`;
    }
  }

  get localPotSize(): string {
    if (this.fromPotSize === null && this.tillPotSize === null) {
      return '';
    } else if (this.fromPotSize === null) {
      return `${this.tillPotSize} cm`;
    } else if (this.tillPotSize === null) {
      return `${this.fromPotSize} cm`;
    } else {
      return `${this.fromPotSize}-${this.tillPotSize} cm`;
    }
  }

  get localHeight(): string {
    if (this.fromHeight === null && this.tillHeight === null) {
      return '';
    } else if (this.fromHeight === null) {
      return `${this.tillHeight} cm`;
    } else if (this.tillHeight === null) {
      return `${this.fromHeight} cm`;
    } else {
      return `${this.fromHeight}-${this.tillHeight} cm`;
    }
  }

  getName(language: string): string {
    switch (language.toLowerCase()) {
      default:
      case 'nl':
        return this.nameNl;
      case 'fr':
        return this.nameFr;
      case 'en':
        return this.nameEn;
      case 'de':
        return this.nameDe;
      case 'pl':
        return this.namePl;
      case 'hu':
        return this.nameHu;
    }
  }

  getFullName(language: string): string {
    switch (language.toLowerCase()) {
      default:
      case 'nl':
        return this.fullnameNl;
      case 'fr':
        return this.fullnameFr;
      case 'en':
        return this.fullnameEn;
      case 'de':
        return this.fullnameDe;
      case 'pl':
        return this.fullnamePl;
      case 'hu':
        return this.fullnameHu;
    }
  }

  getDescription(language: string): string {
    switch (language.toLowerCase()) {
      default:
      case 'nl':
        return this.descriptionNl;
      case 'fr':
        return this.descriptionFr;
      case 'en':
        return this.descriptionEn;
      case 'de':
        return this.descriptionDe;
      case 'pl':
        return this.descriptionPl;
      case 'hu':
        return this.descriptionHu;
    }
  }

  get publicImage(): string {
    if (this.noImageAvailable) {
      return `https://d2r5uf6odu3th4.cloudfront.net/seeds/public/noimage.jpg`;
    } else {
      return this.image;
    }
  }

  getGenusName(language: string): string {
    if (language === null || language === undefined) {
      return this.nameEn;
    }

    switch (language) {
      default:
      case 'nl':
        return this.genusNameNl;
      case 'fr':
        return this.genusNameFr;
      case 'en':
        return this.genusNameEn;
      case 'de':
        return this.genusNameDe;
      case 'pl':
        return this.genusNamePl;
      case 'hu':
        return this.genusNameHu;
    }
  }

  getGenusDescription(language: string): string {
    if (language === null || language === undefined) {
      return this.nameNl;
    }

    switch (language) {
      default:
      case 'nl':
        return this.genusDescriptionNl;
      case 'fr':
        return this.genusDescriptionFr;
      case 'en':
        return this.genusDescriptionEn;
      case 'de':
        return this.genusDescriptionDe;
      case 'pl':
        return this.genusDescriptionPl;
      case 'hu':
        return this.genusDescriptionHu;
    }
  }

  getSpecieDescription(language: string): string {
    if (language === null || language === undefined) {
      return this.nameNl;
    }

    switch (language) {
      default:
      case 'nl':
        return this.specieDescriptionNl;
      case 'fr':
        return this.specieDescriptionFr;
      case 'en':
        return this.specieDescriptionEn;
      case 'de':
        return this.specieDescriptionDe;
      case 'pl':
        return this.specieDescriptionPl;
      case 'hu':
        return this.specieDescriptionHu;
    }
  }

  getSelectedCultivarDescription(language: string): string {
    if (language === null || language === undefined) {
      return this.nameEn;
    }

    switch (language) {
      default:
      case 'nl':
        return this.selectedCultivarDescriptionNl;
      case 'fr':
        return this.selectedCultivarDescriptionFr;
      case 'en':
        return this.selectedCultivarDescriptionEn;
      case 'de':
        return this.selectedCultivarDescriptionDe;
      case 'pl':
        return this.selectedCultivarDescriptionPl;
      case 'hu':
        return this.selectedCultivarDescriptionHu;
    }
  }

  getSelectedCultivarCuttingsDescription(language: string): string {
    if (language === null || language === undefined) {
      return this.selectedCultivarCuttingsDescriptionNl;
    }

    switch (language) {
      default:
      case 'nl':
        return this.selectedCultivarCuttingsDescriptionNl;
      case 'fr':
        return this.selectedCultivarCuttingsDescriptionFr;
      case 'en':
        return this.selectedCultivarCuttingsDescriptionEn;
      case 'de':
        return this.selectedCultivarCuttingsDescriptionDe;
      case 'pl':
        return this.selectedCultivarCuttingsDescriptionPl;
      case 'hu':
        return this.selectedCultivarCuttingsDescriptionHu;
    }
  }

  getSelectedCultivarSeedsDescription(language: string): string {
    if (language === null || language === undefined) {
      return this.selectedCultivarSeedsDescriptionNl;
    }

    switch (language) {
      default:
      case 'nl':
        return this.selectedCultivarSeedsDescriptionNl;
      case 'fr':
        return this.selectedCultivarSeedsDescriptionFr;
      case 'en':
        return this.selectedCultivarSeedsDescriptionEn;
      case 'de':
        return this.selectedCultivarSeedsDescriptionDe;
      case 'pl':
        return this.selectedCultivarSeedsDescriptionPl;
      case 'hu':
        return this.selectedCultivarSeedsDescriptionHu;
    }
  }

  hasCuttings(): boolean {
    return (this.productTypes.includes(ProductType.CUTTINGS) || this.productTypes.includes(ProductType.TISSUECULTURE) );
  }
  hasSeeds(): boolean {
    return this.productTypes.includes(ProductType.SEEDS);
  }
  hasSeedlings(): boolean {
    return this.productTypes.includes(ProductType.SEEDLINGS);
  }
}
