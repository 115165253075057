<ng-container *transloco="let t; prefix: 'contact'">
  <div class="mx-auto xl:px-0 px-4">
    <div class="py-5 px-5 mx-5">
      <select
        id="countryList"
        (change)="filterContactPersons($event)"
        class="block py-3 pl-3 pr-10 w-full focus:ring-indigo-500 focus:border-indigo-500 shadow-sm sm:text-sm border-gray-300 rounded-md"
        [value]="selectedCountry()"
      >
        <option value="all">{{ 'enum.country.title' | transloco }}</option>
        <option value="belgium">{{ 'enum.country.belgium' | transloco }}</option>
        <option value="france">{{ 'enum.country.france' | transloco }}</option>
        <option value="uk">{{ 'enum.country.uk' | transloco }}</option>
        <option value="germany">{{ 'enum.country.germany' | transloco }}</option>
        <option value="austria">{{ 'enum.country.austria' | transloco }}</option>
        <option value="switzerland">{{ 'enum.country.switzerland' | transloco }}</option>
        <option value="poland">{{ 'enum.country.poland' | transloco }}</option>
        <option value="hungary">{{ 'enum.country.hungary' | transloco }}</option>
        <option value="czech">{{ 'enum.country.czech' | transloco }}</option>
        <option value="scandinavia">{{ 'enum.country.scandinavia' | transloco }}</option>
        <option value="ireland">{{ 'enum.country.ireland' | transloco }}</option>
      </select>
    </div>

    <ul role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
      <li *ngFor="let contact of filteredContacts()"
          class="col-span-1 h-full flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow">
        <app-contact-item class="h-full" [contact]="contact"></app-contact-item>
      </li>
    </ul>
  </div>
</ng-container>
