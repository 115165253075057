import {Component, Input, OnInit} from '@angular/core';
import {appRoutesLinks} from '../../../app.routes.links';
import {RouterLink} from "@angular/router";

@Component({
  selector: 'app-cta-button',
  templateUrl: './cta-button.component.html',
  imports: [
    RouterLink
  ],
  standalone: true
})
export class CtaButtonComponent implements OnInit {
  @Input() buttonText: string | undefined;
  @Input() link = appRoutesLinks.CONTACT_PAGE;
  @Input() icon = 'id-card';

  constructor() { }

  ngOnInit(): void {
  }

}
