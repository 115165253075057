import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {isPlatformServer} from '@angular/common';
import {ShoppingCart} from "../../shared/model/shoppingCart";
import {Availability} from "../../shared/model/availability";

@Injectable({
  providedIn: 'root'
})
export class CartService {
  private readonly SHOPPING_CART_IDENTIFIER = 'raes_shopping_cart';

  private cart: ShoppingCart = new ShoppingCart();
  private cartSubject: BehaviorSubject<ShoppingCart> = new BehaviorSubject<ShoppingCart>(this.cart);
  public cart$: Observable<ShoppingCart> = this.cartSubject.asObservable();

  constructor(@Inject(PLATFORM_ID) private platformId: any) {
    this.initializeShoppingCart();
    this.listenOnCartChanges();
  }

  public updateCart(availability: Availability, week: number, quantity: number): void {
    this.cart.updateCart(availability, week, quantity);

    localStorage.setItem(this.SHOPPING_CART_IDENTIFIER, JSON.stringify(this.cart));
    this.cartSubject.next(this.cart);
  }

  public removeFromCart(availability: Availability): void {
    this.cart.removeFromCart(availability);

    localStorage.setItem(this.SHOPPING_CART_IDENTIFIER, JSON.stringify(this.cart));
    this.cartSubject.next(this.cart);
  }

  public getCart(): ShoppingCart {
    return this.cart;
  }

  public clear(): void {
    this.cart = new ShoppingCart();

    localStorage.setItem(this.SHOPPING_CART_IDENTIFIER, JSON.stringify(this.cart));
    this.cartSubject.next(this.cart);
  }

  private initializeShoppingCart(): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    const stringifiedCart = localStorage.getItem(this.SHOPPING_CART_IDENTIFIER);

    if (stringifiedCart) {
      this.cart = ShoppingCart.adapt(JSON.parse(stringifiedCart));
    } else {
      this.cart = new ShoppingCart();
    }

    this.cartSubject.next(this.cart);
  }

  private listenOnCartChanges(): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    window.addEventListener('storage', (event) => {
      // The `key` is `null` if the event was caused by `.clear()`
      if (event.key !== this.SHOPPING_CART_IDENTIFIER && event.key !== null) {
        console.log('Invoked in other tab.', event);
        return;
      }

      console.warn('Shopping Cart changes detected', event);
      this.initializeShoppingCart();
    });
  }
}
