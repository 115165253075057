<ng-container *transloco="let t; prefix: 'job'">

  <div>
    <h2 class="m-0 sm:m-2">{{ job?.title }}</h2>
    <div class="m-2 prose">
      <ng-container *ngIf="job?.tasks">
        <h3>{{ t('detail.tasks') }}</h3>
        <div class="pl-2 text-sm list-disc list-inside" [innerHTML]="job?.tasks"></div>
      </ng-container>
      <ng-container *ngIf="job?.jobFunction">
        <h3>{{ t('detail.function') }}</h3>
        <div class="pl-2 text-sm list-disc list-inside" [innerHTML]="job?.jobFunction"></div>
      </ng-container>
      <ng-container *ngIf="job?.profile">
        <h3>{{ t('detail.profile') }}</h3>
        <div class="pl-2 text-sm list-disc list-inside" [innerHTML]="job?.profile"></div>
      </ng-container>
      <ng-container *ngIf="job?.attitudes">
        <h3>{{ t('detail.attitudes') }}</h3>
        <div class="pl-2 text-sm list-disc list-inside" [innerHTML]="job?.attitudes"></div>
      </ng-container>
      <ng-container *ngIf="job?.offer">
        <h3>{{ t('detail.offer') }}</h3>
        <div class="pl-2 text-sm list-disc list-inside" [innerHTML]="job?.offer"></div>
      </ng-container>
    </div>
  </div>

</ng-container>
