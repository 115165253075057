import {Component} from '@angular/core';
import {GoogleMap, MapMarker} from "@angular/google-maps";
import {ContactListComponent} from "../contact-list/contact-list.component";
import {ContactFormComponent} from "../contact-form/contact-form.component";
import {TranslocoDirective, TranslocoService} from "@jsverse/transloco";
import {Meta, Title} from "@angular/platform-browser";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-contact',
  standalone: true,
  imports: [
    GoogleMap,
    MapMarker,
    ContactListComponent,
    ContactFormComponent,
    TranslocoDirective
  ],
  templateUrl: './contact.component.html'
})
export class ContactComponent {

  protected options: google.maps.MapOptions = {
    center: {
      lat: 51.072510,
      lng: 3.836140
    },
    mapTypeId: 'roadmap',
    zoomControl: false,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    maxZoom: 15,
    minZoom: 8
  };

  constructor(private translocoService: TranslocoService,
              private titleService: Title,
              private metaService: Meta
  ) {
    let lang: string = this.translocoService.getActiveLang();

    let title: string;
    let description: string;
    let keywords: string;
    let translationSubscription: Subscription;

    // Use selectTranslate for the first department option
    translationSubscription = this.translocoService.selectTranslate('contact.title').subscribe(value => {
      title = value;
      this.titleService.setTitle(title);
    });
    translationSubscription = this.translocoService.selectTranslate('contact.description').subscribe(value => {
      description = value;
      this.metaService.updateTag({name: 'description', content: description});
    });
    translationSubscription = this.translocoService.selectTranslate('contact.keywords').subscribe(value => {
      keywords = value;
      if (typeof keywords !== 'undefined') {
        this.metaService.updateTag({name: 'keywords', content: keywords});
      }
    });
    translationSubscription.unsubscribe();
  }


}
