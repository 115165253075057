import {Injectable} from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AppConfigService} from '../providers/app-config.service';
import {Page} from '../../shared/model/page';
import {Type} from '../../shared/model/enum/type';
import {Timing} from '../../shared/model/enum/timing';
import {ProductSortHelper} from '../../shared/helpers/product-sort-helper';
import {Product} from '../../shared/model/product';
import {Plant} from '../../shared/model/plant';
import {isArray} from "node:util";

@Injectable({
  providedIn: 'root'
})
export class PlantService {
  private readonly publicApi: string;

  constructor(private http: HttpClient, private appConfig: AppConfigService) {
    this.publicApi = this.appConfig.config.apiBase;
  }

  public fetchPagedPlantList(searchParameters: SearchParameters): Observable<Page<Plant>> {
    return this.http.get<Page<Plant>>(`${this.publicApi}/plants/paged`, {params: searchParameters.build()}).pipe(
      map(page => Page.adapt(page, page.content.map(Plant.adapt)))
    );
  }

  public fetchLandingPlantList(): Observable<Plant[]> {
    return this.http.get<any>(`${this.publicApi}/plants?highlighted=true`).pipe(
      map(data => data.content.map(Plant.adapt))
    );
  }

  public fetchAvailableProducts(plantCode: string): Observable<Product[]> {
    return this.http.get<Product[]>(`${this.publicApi}/plants/${plantCode}/products`).pipe(
      map(data => data.map(Product.adapt))
    );
  }

  public fetchNewPlantsCount(): Observable<number> {
    return this.http.get<any>(`${this.publicApi}/plants/new/count`).pipe(map(content => content.count));
  }

  public fetchSpecialPlantList(): Observable<Plant[]> {
    return this.http.get<Plant[]>(`${this.publicApi}/plants?special=true`).pipe(
      map(data => data.map(Plant.adapt))
    );
  }

  public fetchRelatedPlants(raesCode: string): Observable<Plant[]> {
    return this.http.get<any>(`${this.publicApi}/plants/${raesCode}/related`).pipe(
      map(data => data.content.map(Plant.adapt))
    );
  }

  public findBySlugOrRaesCode(slugOrRaesCode: string | null): Observable<Plant> {
    return this.http.get<Plant>(`${this.publicApi}/plants/${slugOrRaesCode}`).pipe(
      map(Plant.adapt)
    );
  }

  public fetchNewGeneticsList(): Observable<Plant[]> {
    return this.http.get<any>(`${this.publicApi}/plants/genetics?newPlant=true`).pipe(
      map(data => data.content.map(Plant.adapt))
    );
  }

  public fetchSpecialGeneticsList(): Observable<Plant[]> {
    return this.http.get<any>(`${this.publicApi}/plants/genetics?special=true`).pipe(
      map(data => data.content.map(Plant.adapt))
    );
  }

  public fetchByTypeGeneticsList(type: Type): Observable<Plant[]> {
    return this.http.get<any>(`${this.publicApi}/plants/genetics?primulaType=${type}`).pipe(
      map(data => data.content.map(Plant.adapt))
    );
  }

  public fetchDefaultsByTypeGeneticsList(type: Type): Observable<Plant[]> {
    return this.http.get<any>(`${this.publicApi}/plants/genetics?primulaType=${type}&defaultPlant=true`).pipe(
      map(data => data.content.map(Plant.adapt))
    );
  }

  public fetchByFloweringPeriodGeneticsList(flowerPeriod: Timing): Observable<Plant[]> {
    return this.http.get<any>(`${this.publicApi}/plants/genetics?timing=${flowerPeriod}`).pipe(
      map(data => data.content.map(Plant.adapt))
    );
  }
}

export class SearchParameters {
  constructor(
    public name: string = '',
    public genusIds: number[] = [],
    public colorIds: number[] = [],
    public categories: string[] = [],
    public lifetimes: string[] = [],
    public stands: string[] = [],
    public usages: string[] = [],
    public plantType: string = '',
    public flowerMonths: string[] = [],
    public productTypes: string[] = [],
    public special: boolean = false,
    public improved: boolean = false,
    public newPlant: boolean = false,
    public provenWinners: boolean = false,
    public beeFriendly: boolean = false,
    public scented: boolean = false,
    public customersFavorite: boolean = false,
    public summerOnly: boolean = false,
    public raesGenetics: boolean = false,
    public geneticallyCompact: boolean = false,
    public earlyFlowering: boolean = false,
    public spiciness: boolean = false,
    public autumnSelection: boolean = false,
    public page: number = 0,
    public size: number = 0,
    public sortAlias: string = '',
    public gridLayout: number = 0
  ) {
  }

  build(): HttpParams {
    let params = new HttpParams();

    if (this.name) {
      params = params.append('name', this.name);
    }
    if (Array.isArray(this.genusIds) && this.genusIds.length != 0) {
      params = params.append('geni', this.genusIds.join(','));
    }

    if (Array.isArray(this.colorIds) && this.colorIds.length != 0) {
      params = params.append('colors', this.colorIds.join(','));
    }

    if (this.categories && this.categories.length !== 0) {
      params = params.append('categories', this.categories.join(','));
    }

    if (this.lifetimes && this.lifetimes.length !== 0) {
      params = params.append('lifetimes', this.lifetimes.join(','));
    }

    if (this.flowerMonths && this.flowerMonths.length !== 0) {
      params = params.append('flowerMonths', this.flowerMonths.join(','));
    }
    if (this.productTypes && this.productTypes.length !== 0) {
      params = params.append('productTypes', this.productTypes.join(','));
    }
    if (this.plantType) {
      params = params.append('PlantType', this.plantType);
    }

    if (this.stands && this.stands.length !== 0) {
      params = params.append('stands', this.stands.join(','));
    }

    if (this.usages && this.usages.length !== 0) {
      params = params.append('usages', this.usages.join(','));
    }

    if (this.special !== false && this.special !== null) {
      params = params.append('specialPlant', String(true));
    }
    if (this.improved !== false && this.improved !== null) {
      params = params.append('improvedPlant', String(true));
    }
    if (this.newPlant !== false && this.newPlant !== null) {
      params = params.append('newPlant', String(true));
    }
    if (this.provenWinners !== false && this.provenWinners !== null) { params = params.append('provenWinners' , String(true)); }
    if (this.beeFriendly !== false && this.beeFriendly !== null) { params = params.append('beeFriendly' , String(true)); }
    if (this.scented !== false && this.scented !== null) { params = params.append('scented' , String(true)); }
    if (this.customersFavorite !== false && this.customersFavorite !== null) { params = params.append('customersFavorite' , String(true)); }
    if (this.summerOnly !== false && this.summerOnly !== null) { params = params.append('summerOnly' , String(true)); }
    if (this.raesGenetics !== false && this.raesGenetics !== null) { params = params.append('raesGenetics' , String(true)); }
    if (this.geneticallyCompact !== false && this.geneticallyCompact !== null) { params = params.append('geneticallyCompact' , String(true)); }
    if (this.earlyFlowering !== false && this.earlyFlowering !== null) { params = params.append('earlyFlowering' , String(true)); }
    if (this.spiciness !== false && this.spiciness !== null) { params = params.append('spiciness' , String(true)); }
    if (this.autumnSelection !== false && this.autumnSelection !== null) { params = params.append('autumnSelection' , String(true)); }
    if (this.page) {
      params = params.append('page', String(this.page - 1));
    }
    if (this.size) {
      params = params.append('size', String(this.size));
    }
    if (this.sortAlias) {
      params = params.append('sort', ProductSortHelper.translateFromAlias(this.sortAlias));
    }

    return params;
  }
}
