import {ChangeDetectorRef, Component} from '@angular/core';
import {RouterLink} from "@angular/router";
import {CommonModule, NgOptimizedImage} from "@angular/common";
import {MobileMenuComponent} from "./mobile-menu/mobile-menu.component";
import {CompanyMenuComponent} from "./company-menu/company-menu.component";
import {GeneticsMenuComponent} from "./genetics-menu/genetics-menu.component";
import {ProductsMenuComponent} from "./products-menu/products-menu.component";
import {appRoutesLinks} from "../../../app.routes.links";
import {TranslocoDirective, TranslocoPipe, TranslocoService} from "@jsverse/transloco";
@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    RouterLink,
    CommonModule,
    NgOptimizedImage,
    MobileMenuComponent,
    CompanyMenuComponent,
    GeneticsMenuComponent,
    ProductsMenuComponent,
    TranslocoPipe,
    TranslocoDirective,
  ],
  templateUrl: './header.component.html'
})

export class HeaderComponent {
  ordersPage  = appRoutesLinks.ORDERFORM_PAGE;
  availabilityPage  = appRoutesLinks.AVAILABILITY_PAGE;
  catalogPage  = appRoutesLinks.CATALOG_PAGE;
  contactPage  = appRoutesLinks.CONTACT_PAGE;
  isSlideOver = false;
  isMenuOpen: any = false;

  constructor(private translocoService: TranslocoService,private changeDetector: ChangeDetectorRef) {
  }

  switchLanguage(lang: string) {
    this.translocoService.setActiveLang(lang);
    this.changeDetector.detectChanges();
    console.log('switchLanguage - changedetector', lang);
  }

  isCurrentLanguage(lang: string) {
    return this.translocoService.getActiveLang() === lang;
  }

  toggleMobileMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }
}
