<div>
  <label for="{{labelFor}}" class="block text-sm font-medium text-gray-700" >
    {{ label }}<span *ngIf="required">*</span>
  </label>
  <ng-content></ng-content>
  <div *ngIf="hasErrors">
    <small class="text-red-700">
      {{ error }}
    </small>
  </div>
</div>
