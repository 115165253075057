import {Component} from '@angular/core';
import {TranslocoDirective} from "@jsverse/transloco";
import {LandingCatalogsComponent} from "./landing-catalogs/landing-catalogs.component";
import {LandingNewsComponent} from "./landing-news/landing-news.component";
import {
  UniqueSellingPointsComponent
} from "../../shared/components/unique-selling-points/unique-selling-points.component";
import {LandingCategoriesComponent} from "./landing-categories/landing-categories.component";
import {LandingJobsComponent} from "./landing-jobs/landing-jobs.component";
import {LandingPlantsComponent} from "./landing-plants/landing-plants.component";
import {LandingSliderComponent} from "./landing-slider/landing-slider.component";

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    TranslocoDirective,
    LandingCatalogsComponent,
    LandingNewsComponent,
    UniqueSellingPointsComponent,
    LandingCategoriesComponent,
    LandingJobsComponent,
    LandingPlantsComponent,
    LandingSliderComponent
  ],
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent {

}
