<ng-container *transloco="let t; prefix: 'genetics'">

<section class="text-green-900 relative z-0">
  <div class="hero-image bg-right-bottom bg-cover flex sm:bg-none"
       style="background-image: url(https://d3coqd91sswgmf.cloudfront.net/header/genetics_header.jpg);">
    <div class="relative container  justify-center md:justify-start md:pl-5 flex items-end z-10">
      <div>
        <div
          class="content float-left  justify-center md:justify-start py-4 px-5 my-5 bg-christi-700 rounded-md bg-opacity-90">
          <h1 class="heading text-gray-100 mb-3 text-2xl md:text-4xl">{{ 'genetics.intro.title' | transloco }}</h1>
        </div>
      </div>
    </div>
  </div>
</section>

<section>
  <main class="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6 lg:mt-32">
    <div class="lg:grid lg:grid-cols-12 lg:gap-8">
      <div
        class="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
        <div class="relative mx-auto w-full rounded-lg shadow-lg lg:max-w-md">
          <div
            class="w-full max-w-6xl rounded bg-white shadow-xl p-2 lg:p-2 mx-auto text-gray-800 relative md:text-left">
            <div class="embed-container rounded-b-md" data-page-width="453" data-page-height="640"
                 id="yp2embedcontainer">
              <ng-container *ngIf="this.translocoService.getActiveLang() === 'nl'">
                <iframe
                  [src]="'https://www.youtube.com/embed/OGJ2waaGL7Y' | safe"
                  allow="fullscreen">
                </iframe>
              </ng-container>
              <ng-container *ngIf="this.translocoService.getActiveLang()  === 'fr'">
                <iframe
                  [src]="'https://www.youtube.com/embed/WQA-ebo8yMM' | safe"
                  allow="fullscreen">
                </iframe>
              </ng-container>
              <ng-container
                *ngIf="this.translocoService.getActiveLang() !== 'nl' && this.translocoService.getActiveLang() !== 'fr'">
                <iframe
                  [src]="'https://www.youtube.com/embed/WQA-ebo8yMM' | safe"
                  allow="fullscreen">
                </iframe>
              </ng-container>

            </div>
          </div>
        </div>
      </div>
      <div class="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
        <h1>
          <span class="mt-1 block text-3xl tracking-tight font-extrabold sm:text-2xl xl:text-4xl">
              <span class="block text-gold-drop-500">{{ 'genetics.movie.early.title' | transloco }}</span>
              <span class="block text-eminence-600">{{ 'genetics.movie.early.subtitle' | transloco }}</span>
            </span>
        </h1>
        <h2 class="text-base font-medium text-gold-drop-400">{{ 'genetics.movie.early.highlight' | transloco }}</h2>
        <!--<p class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo. Elit sunt amet fugiat veniam occaecat fugiat aliqua ad ad non deserunt sunt.</p>-->
        <div class="mt-8 sm:max-w-lg sm:mx-auto xs:text-center lg:text-left lg:mx-0">
          <a [routerLink]="productsLink" [queryParams]="{ genusId: 110, name:'primus' }"
             class="my-5 w-full px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 shadow-sm hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:flex-shrink-0 sm:inline-flex sm:items-center sm:w-auto">{{ 'genetics.movie.early.button' | transloco }}</a>
        </div>
      </div>
    </div>
  </main>
</section>

<section>
  <main class="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6 lg:mt-32">
    <div class="lg:grid lg:grid-cols-12 lg:gap-8">
      <div class="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
        <h1>
          <span class="mt-1 block text-3xl tracking-tight font-extrabold sm:text-2xl xl:text-4xl">
              <span class="block text-gold-drop-500">{{ 'genetics.movie.midearly.title' | transloco }}</span>
              <span class="block text-eminence-600">{{ 'genetics.movie.midearly.subtitle' | transloco }}</span>
            </span>
        </h1>
        <h2 class="text-base font-medium text-gold-drop-400">{{ 'genetics.movie.early.highlight' | transloco }}</h2>
        <!--<p class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo. Elit sunt amet fugiat veniam occaecat fugiat aliqua ad ad non deserunt sunt.</p>-->
        <div class="mt-8 sm:max-w-lg sm:mx-auto xs:text-center lg:text-left lg:mx-0">
          <a [routerLink]="productsLink" [queryParams]="{ genusId: 110, name:'primus' }"
             class="my-5 w-full px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 shadow-sm hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:flex-shrink-0 sm:inline-flex sm:items-center sm:w-auto">{{ 'genetics.movie.early.button' | transloco }}</a>
        </div>
      </div>
      <div
        class="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
        <div class="relative mx-auto w-full rounded-lg shadow-lg lg:max-w-md">
          <div
            class="w-full max-w-6xl rounded bg-white shadow-xl p-2 lg:p-2 mx-auto text-gray-800 relative md:text-left">
            <div class="embed-container rounded-b-md" data-page-width="453" data-page-height="640"
                 id="yp3embedcontainer">
              <ng-container *ngIf="this.translocoService.getActiveLang() === 'nl'">
                <iframe
                  [src]="'https://www.youtube.com/embed/_-cL0MbwYMc' | safe"
                  allow="fullscreen">
                </iframe>
              </ng-container>
              <ng-container *ngIf="this.translocoService.getActiveLang() === 'fr'">
                <iframe
                  [src]="'https://www.youtube.com/embed/McRp70KiHGU' | safe"
                  allow="fullscreen">
                </iframe>
              </ng-container>
              <ng-container
                *ngIf="this.translocoService.getActiveLang() !== 'nl' && this.translocoService.getActiveLang() !== 'fr'">
                <iframe
                  [src]="'https://www.youtube.com/embed/McRp70KiHGU' | safe"
                  allow="fullscreen">
                </iframe>
              </ng-container>

            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</section>

<section>
  <main class="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6 lg:mt-32">
    <div class="lg:grid lg:grid-cols-12 lg:gap-8">
      <div
        class="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
        <div class="relative mx-auto w-full rounded-lg shadow-lg lg:max-w-md">
          <div
            class="w-full max-w-6xl rounded bg-white shadow-xl p-2 lg:p-2 mx-auto text-gray-800 relative md:text-left">
            <div class="embed-container rounded-b-md" data-page-width="453" data-page-height="640"
                 id="yp4embedcontainer">
              <ng-container *ngIf="this.translocoService.getActiveLang() === 'nl'">
                <iframe
                  [src]="'https://www.youtube.com/embed/ZMehWxXl8vQ' | safe"
                  allow="fullscreen">
                </iframe>
              </ng-container>
              <ng-container *ngIf="this.translocoService.getActiveLang() === 'fr'">
                <iframe
                  [src]="'https://www.youtube.com/embed/VgwADK62nfk' | safe"
                  allow="fullscreen">
                </iframe>
              </ng-container>
              <ng-container
                *ngIf="this.translocoService.getActiveLang() !== 'nl' && this.translocoService.getActiveLang() !== 'fr'">
                <iframe
                  [src]="'https://www.youtube.com/embed/VgwADK62nfk' | safe"
                  allow="fullscreen">
                </iframe>
              </ng-container>

            </div>
          </div>
        </div>
      </div>
      <div class="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
        <h1>
          <span class="mt-1 block text-3xl tracking-tight font-extrabold sm:text-2xl xl:text-4xl">
              <span class="block text-gold-drop-500">{{ 'genetics.movie.late.title' | transloco }}</span>
              <span class="block text-eminence-600">{{ 'genetics.movie.late.subtitle' | transloco }}</span>
            </span>
        </h1>
        <h2 class="text-base font-medium text-gold-drop-400">{{ 'genetics.movie.late.highlight' | transloco }}</h2>
        <div class="mt-8 sm:max-w-lg sm:mx-auto xs:text-center lg:text-left lg:mx-0">
          <a [routerLink]="productsLink" [queryParams]="{ genusId: 110, name:'primus' }"
             class="my-5 w-full px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 shadow-sm hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:flex-shrink-0 sm:inline-flex sm:items-center sm:w-auto">{{ 'genetics.movie.early.button' | transloco }}</a>
        </div>
      </div>
    </div>
  </main>
</section>

<section>
  <app-genetics-news></app-genetics-news>
</section>

<section>
  <app-genetics-history></app-genetics-history>
</section>

</ng-container>
