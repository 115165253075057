export enum Trademark {
  REGISTERED = 'R',
  UNREGISTERED = 'TM'
}

// tslint:disable-next-line:no-namespace
export namespace Trademark {
  export function map(value: any): Trademark | null {
    if (value === null || value === undefined) {
      return null;
    }

    switch (value) {
      case 'R':
        return Trademark.REGISTERED;
      case 'TM':
        return Trademark.UNREGISTERED;
      default:
        return null;
    }
  }
}
