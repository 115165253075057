export class ApplyForJobInfo {
  constructor(public firstName: string = '',
              public lastName: string = '',
              public phone: string = '',
              public email: string = '',
              public motivation: string = '',
              public reference: string = '',
              public jobId: number = 1,
              public captcha: string = '') {}
}
