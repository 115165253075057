import {Component} from '@angular/core';
import {CtaButtonComponent} from "../../../shared/components/cta-button/cta-button.component";
import {Observable, Subscription} from "rxjs";
import {Job} from "../../../shared/model/job";
import {JobDetailComponent} from "./job-detail/job-detail.component";
import {JobListComponent} from "./job-list/job-list.component";
import {AsyncPipe, NgIf} from "@angular/common";
import {appRoutesLinks} from "../../../app.routes.links";
import {JobService} from "../../../core/repository/job.service";
import {ActivatedRoute, RouterLink} from "@angular/router";
import {SeoService} from "../../../core/util/seo.service";
import {TranslocoPipe, TranslocoService} from "@jsverse/transloco";
import {Meta, Title} from "@angular/platform-browser";
import {MetadataDtsModuleScopeResolver} from "@angular/compiler-cli/src/ngtsc/scope";

@Component({
  selector: 'app-jobs',
  standalone: true,
  imports: [
    CtaButtonComponent,
    JobDetailComponent,
    JobListComponent,
    AsyncPipe,
    NgIf,
    TranslocoPipe,
    RouterLink
  ],
  templateUrl: './jobs.component.html',
  styles: ``
})
export class JobsComponent {
  jobs$!: Observable<Job[]>;
  public selectedJobId: number | undefined;

  constructor(private jobService: JobService,
              private route: ActivatedRoute,
              private translocoService: TranslocoService,
              private titleService: Title,
              private metaService: Meta
  ) {
    let title: string;
    let description: string;
    let keywords: string;
    let translationSubscription: Subscription;

    // Use selectTranslate for the first department option
    translationSubscription = this.translocoService.selectTranslate('job.title').subscribe(value => {
      title = value;
      this.titleService.setTitle(title);
    });
    translationSubscription = this.translocoService.selectTranslate('job.description').subscribe(value => {
      description = value;
      this.metaService.updateTag({name: 'description', content: description});
    });
    translationSubscription = this.translocoService.selectTranslate('job.keywords').subscribe(value => {
      keywords = value;
      if (typeof keywords !== 'undefined') {
        this.metaService.updateTag({name: 'keywords', content: keywords});
      }    });
    translationSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.translocoService.selectTranslate('job.title')
      .subscribe((text) => {
        let title = text + ' | Raes Bloemzaden nv';
        this.titleService.setTitle(title);
      });

    this.jobs$ = this.jobService.getActive();
    const possibleJobId: number = this.route.snapshot.params['id'];
    if (possibleJobId !== undefined) {
      this.selectedJobId = possibleJobId;
    } else {
      this.jobs$.subscribe(d => this.selectedJobId = d[0].id);
    }

  }

  onSelect(selectedJob: Job): void {
    this.selectedJobId = selectedJob.id;
  }

  protected readonly appRoutesLinks = appRoutesLinks;
}
