export class Contact {
  constructor(public firstName: string = '',
              public lastName: string  = '',
              public phone: string  = '',
              public gsm: string  = '',
              public fax: string  = '',
              public email: string  = '',
              public street: string  = '',
              public houseNumber: string  = '',
              public city: string  = '',
              public country: string  = '',
              public company: string  = '',
              public website: string  = '',
              public bigImageUrl: string = '',
              public smallImageUrl: string = '',
              public contact: string = '',
              public title: string = '') {}

  static adapt(item: any): Contact {
    return new Contact(
      item.firstName,
      item.lastName,
      item.phone,
      item.gsm,
      item.fax,
      item.email,
      item.street,
      item.houseNumber,
      item.city,
      item.country,
      item.company,
      item.website,
      item.bigImageUrl,
      item.smallImageUrl,
      item.contact,
      item.title
    );
  }

  get fullName(): string {
    return this.firstName + ' ' + this.lastName;
  }
}
