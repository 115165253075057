import { Component } from '@angular/core';
import {RouterOutlet} from "@angular/router";
import {FooterComponent} from "../footer/footer.component";
import {HeaderComponent} from "../header/header.component";
import {ScrollTop} from "primeng/scrolltop";

@Component({
  selector: 'app-wrapper',
  standalone: true,
    imports: [
        RouterOutlet,
        FooterComponent,
        HeaderComponent,
        ScrollTop
    ],
  templateUrl: './wrapper.component.html'
})
export class WrapperComponent {

}
