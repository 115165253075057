export enum FlowerMonth {
  JANUARY = 1,
  FEBRUARY,
  MARCH ,
  APRIL ,
  MAY ,
  JUNE ,
  JULY ,
  AUGUST,
  SEPTEMBER,
  OCTOBER ,
  NOVEMBER ,
  DECEMBER
}
