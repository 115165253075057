<div *ngIf="(newsSubscription | async)?.length"  class="relative mx-auto mb-5">
  <div class="text-center">
    <h3>{{ 'dashboard.news.subtitle' | transloco }}</h3>
    <h4>{{ 'dashboard.news.title' | transloco }}</h4>
  </div>
</div>

<div class="relative max-w-7xl mx-auto px-auto">
  <div class="mt-12 grid items grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 justify-center">
    <app-landing-news-item *ngFor="let newsItem of newsSubscription | async;"
                           class="mx-5 flex flex-col justify-center rounded-lg shadow-lg overflow-hidden"
                           [newsItem]="newsItem">
    </app-landing-news-item>
  </div>
</div>
