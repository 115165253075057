import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AppConfigService} from '../providers/app-config.service';
import {Digitalcatalog} from "../../shared/model/digitalcatalog";

@Injectable({
  providedIn: 'root'
})

export class CatalogService {
  private readonly publicApi: string;

  constructor(private http: HttpClient, private appConfig: AppConfigService) {
    this.publicApi = this.appConfig.config.apiBase;
  }

  getHighlighted(): Observable<Digitalcatalog[]> {
    const digitalCatalogUrl = `${this.publicApi}/digitalcatalogs?highlighted=true`;
    return this.http.get<Digitalcatalog[]>(digitalCatalogUrl).pipe(
      map(data => data.map(Digitalcatalog.adapt))
    );
  }

  getActive(): Observable<Digitalcatalog[]> {
    const digitalCatalogUrl = `${this.publicApi}/digitalcatalogs?active=true`;
    return this.http.get<Digitalcatalog[]>(digitalCatalogUrl).pipe(
      map(data => data.map(Digitalcatalog.adapt))
    );
  }

}
