import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import {TranslocoDirective} from "@jsverse/transloco";
import {Toast} from "primeng/toast";
import {MessageService} from "primeng/api";
import {Subscription} from "rxjs";
import {NgcCookieConsentService} from "ngx-cookieconsent";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, TranslocoDirective, Toast],
  templateUrl: './app.component.html',
  providers: [MessageService]
})
export class AppComponent {
  title: string = 'nextgenFlower';
  private popupOpenSubscription!: Subscription;

  constructor(private ccService: NgcCookieConsentService) {
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
        console.log('popupOpen');
      });
  }
}
