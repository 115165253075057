import {APP_INITIALIZER, Component, Input} from '@angular/core';
import {RouterLink} from "@angular/router";
import {News} from "../../../shared/model/news";
import {appRoutesLinks} from "../../../app.routes.links";
import {TranslocoService} from "@jsverse/transloco";

@Component({
  selector: 'app-landing-news-item',
  standalone: true,
  imports: [
    RouterLink,
  ],
  templateUrl: './landing-news-item.component.html',
  styles: ``
})
export class LandingNewsItemComponent {
  @Input() newsItem: News | undefined;

  protected readonly APP_INITIALIZER = APP_INITIALIZER;
  protected readonly appRoutesLinks = appRoutesLinks;

  constructor(protected translocoService: TranslocoService) {
  }
}
