import {Availability} from './availability';

export class ShoppingCart {
  constructor(public items: ShoppingCartItem[] = [],
              public updatedAt: Date = new Date()) {
  }

  static adapt(item: any): ShoppingCart {
    return new ShoppingCart(
      !!item.items ? item.items.map(ShoppingCartItem.adapt) : [],
      item.updatedAt);
  }

  public updateCart(availability: Availability, week: number, quantity: number): void {
    const shoppingItem = this.items.find(item => item?.availability.raescode === availability.raescode);
    if (shoppingItem) {
      const foundStockOrder = shoppingItem.ordersPerWeek.find(stockOrder => stockOrder.week === week);

      if (quantity <= 0) {
        if (foundStockOrder instanceof StockOrder) {
          const stockOrderIndex = shoppingItem.ordersPerWeek.indexOf(foundStockOrder, 0);
          shoppingItem.ordersPerWeek.splice(stockOrderIndex, 1);
        }

      } else if (foundStockOrder) {
        foundStockOrder.quantity = quantity;
      } else {
        shoppingItem.ordersPerWeek.push(new StockOrder(week, quantity));
      }

      if (shoppingItem.ordersPerWeek.length === 0) {
        const shoppingItemIndex = this.items.indexOf(shoppingItem, 0);
        this.items.splice(shoppingItemIndex, 1);
      }
    } else {
      this.items.push(new ShoppingCartItem(availability, [new StockOrder(week, quantity)]));
    }

    this.updatedAt = new Date();
  }

  public removeFromCart(availability: Availability): void {
    const shoppingItem = this.items.find(item => item?.availability.raescode === availability.raescode);
    if (shoppingItem) {
      const shoppingItemIndex = this.items.indexOf(shoppingItem, 0);
      this.items.splice(shoppingItemIndex, 1);
    } else {
      // The availability is already removed.
    }

    this.updatedAt = new Date();
  }
}

export class ShoppingCartItem {
  constructor(public availability: Availability,
              public ordersPerWeek: StockOrder[]) {
  }

  static adapt(item: any): ShoppingCartItem {
    return new ShoppingCartItem(
      Availability.adapt(item.availability),
      !!item.ordersPerWeek ? item.ordersPerWeek.map(StockOrder.adapt) : []);
  }
}

export class StockOrder {
  constructor(public week: number,
              public quantity: number) {
  }

  static adapt(item: any): StockOrder {
    return new StockOrder(
      item.week,
      item.quantity);
  }
}
