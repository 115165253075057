import {Component} from '@angular/core';
import {TranslocoDirective} from "@jsverse/transloco";

@Component({
  selector: 'app-about-testimonials',
  standalone: true,
  imports: [
    TranslocoDirective
  ],
  templateUrl: './about-testimonials.component.html',
  styles: ``
})
export class AboutTestimonialsComponent {

}
