import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {Observable} from "rxjs";
import {LandingJobItemComponent} from "../landing-job-item/landing-job-item.component";
import {AsyncPipe, NgForOf, NgIf} from "@angular/common";
import {TranslocoPipe} from "@jsverse/transloco";
import {JobService} from "../../../core/repository/job.service";
import {Job} from "../../../shared/model/job";

@Component({
  selector: 'app-landing-jobs',
  standalone: true,
  imports: [
    LandingJobItemComponent,
    AsyncPipe,
    NgForOf,
    TranslocoPipe,
    NgIf
  ],
  templateUrl: './landing-jobs.component.html'
})
export class LandingJobsComponent implements OnInit {

  constructor(private jobsService: JobService) {
  }

  jobsSubscription: Observable<Job[]> | undefined;

  ngOnInit(): void {
    this.jobsSubscription = this.jobsService.getHighlighted();
  }
}
