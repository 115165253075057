export enum FruitColor {
  GREEN = 'GREEN',
  RED = 'RED',
  YELLOW = 'YELLOW',
  ORANGE = 'ORANGE',
  ROSE = 'ROSE',
  WHITE = 'WHITE',
  PURPLE = 'PURPLE',
  BLACK = 'BLACK',
  BI_COLOR = 'BI_COLOR',
  SPECIALS = 'SPECIALS'
}
