<ng-container *transloco="let t; prefix: 'disclaimer'">

<section class="text-green-900 relative z-0">
  <div class="hero-image bg-cover flex sm:bg-none bg-center"
       style="background-image: url(https://d3coqd91sswgmf.cloudfront.net/header/disclaimer_header.jpg);">
    <div class="relative container mx-auto p-4 flex items-end z-10">
      <div>
        <div class="content float-left py-4 px-5 my-5 bg-christi-700 rounded-md bg-opacity-90">
          <div class="heading text-gray-100 mb-3 text-2xl md:text-4xl">{{ t('title') }}</div>
          <div class="text text-gray-300 leading-normal hidden sm:block">{{ t('intro_p1') }}</div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="bg-gray-100 m-5 p-5 rounded-md">
  <h1 class="text-xl">{{ t('intro') }}</h1>
  <p  class="px-5 pb-5">
    {{ t('statement_1.description') }}
    <a class="underline" [routerLink]="privacyLink">{{ t('statement_1.privacy_link') }}</a>
  </p>

  <h2 class="text-lg">{{ t('statement_2.title') }}</h2>

  <p  class="px-5 pb-5">{{ t('statement_2.description_p1') }}</p>
  <p  class="px-5 pb-5">{{ t('statement_2.description_p2') }}</p>
  <p  class="px-5 pb-5">{{ t('statement_2.description_p3') }}</p>
  <p  class="px-5 pb-5">{{ t('statement_2.description_p4') }}</p>
  <p  class="px-5 pb-5">{{ t('statement_2.description_p5') }}</p>
  <p  class="px-5 pb-5">{{ t('statement_2.description_p6') }}</p>
  <p  class="px-5 pb-5">{{ t('statement_2.description_p7') }}</p>
  <p  class="px-5 pb-5">{{ t('statement_2.description_p8') }}</p>

  <h2 class="text-lg">{{ t('statement_3.title') }}</h2>

  <p  class="px-5 pb-5">{{ t('statement_3.description_p1') }}</p>
  <p  class="px-5 pb-5">{{ t('statement_3.description_p2') }}</p>

</section>

</ng-container>
