import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnChanges,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  SimpleChanges
} from '@angular/core';
import {appRoutesLinks} from "../../../app.routes.links";
import {Plant} from "../../../shared/model/plant";
import {Subscription} from "rxjs";
import {PlantService} from "../../../core/repository/plant.service";
import {ActivatedRoute, RouterLink} from "@angular/router";
import {NavigationUtils} from "../../../core/util/navigation.utils";
import {isPlatformBrowser, NgForOf, NgIf} from "@angular/common";
import {TranslocoPipe, TranslocoService} from "@jsverse/transloco";

@Component({
  selector: 'app-floweringperiod',
  standalone: true,
  imports: [
    RouterLink,
    NgIf,
    NgForOf,
    TranslocoPipe
  ],
  templateUrl: './floweringperiod.component.html',
  styles: ``
})

export class FlowerperiodComponent implements OnInit, OnDestroy {

  goToPeriod = appRoutesLinks.GENETICS_FLOWERINGPERIOD_PAGE;
  periodsLink = appRoutesLinks.GENETICS_FLOWERINGPERIODS_PAGE;
  productCatalogLink = appRoutesLinks.PLANT_OVERVIEW_PAGE;

  public seriePlants: Plant[] = [];
  productDetail = appRoutesLinks.PLANT_DETAIL_PAGE;
  public flowerPeriod: string | undefined;
  private subscriptions: Subscription[] = [];

  constructor(private plantService: PlantService,
              private readonly changeDetection: ChangeDetectorRef,
              private activatedRoute: ActivatedRoute,
              private navigationUtils: NavigationUtils,
              public translateService: TranslocoService,
              @Inject(PLATFORM_ID) private platform: any) {
  }

  public floweringPeriodTitle: string | undefined;

  ngOnInit(): void {
    this.floweringPeriodTitle = 'Primula\'s';
    this.setCurrentFloweringPeriod();
    this.fetchFlowerPeriodProducts();
  }

  private setCurrentFloweringPeriod() {
    this.flowerPeriod = this.activatedRoute.snapshot.params['floweringperiod'];

    if (this.activatedRoute.snapshot.params['floweringperiod'] === 'VERY_EARLY') {
      this.floweringPeriodTitle = 'Very Early Primula\'s';
    } else if (this.activatedRoute.snapshot.params['floweringperiod'] === 'EARLY') {
      this.floweringPeriodTitle = 'Early Primula\'s';
    } else if (this.activatedRoute.snapshot.params['floweringperiod'] === 'MID_EARLY') {
      this.floweringPeriodTitle = 'Mid Early Primula\'s';
    } else if (this.activatedRoute.snapshot.params['floweringperiod'] === 'MID_LATE') {
      this.floweringPeriodTitle = 'Mid Late Primula\'s';
    } else if (this.activatedRoute.snapshot.params['floweringperiod'] === 'LATE') {
      this.floweringPeriodTitle = 'Late Primula\'s';
    } else if (this.activatedRoute.snapshot.params['floweringperiod'] === 'VERY_LATE') {
      this.floweringPeriodTitle = 'Very Late Primula\'s';
    }
    if (this.activatedRoute.snapshot.params['floweringperiod'] === undefined) {
      this.activatedRoute.snapshot.params['floweringperiod'] = 'Primula\'s';
    }
  }

  private fetchFlowerPeriodProducts(): void {
    if (isPlatformBrowser(this.platform)) {
      this.subscriptions.push(
        this.plantService.fetchByFloweringPeriodGeneticsList(
          this.activatedRoute.snapshot.params['floweringperiod']).subscribe(result => {
          this.seriePlants = result;
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public redirect(route: string[], queryParams: {} = {}): void {
    if (Object.keys(queryParams).length !== 0) {
      this.navigationUtils.navigate(route, {
        queryParams
      });
    } else {
      this.navigationUtils.navigate(route);
    }
    this.setCurrentFloweringPeriod();
  }

}
