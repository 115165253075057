import {Component, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {Plant} from "../../../shared/model/plant";
import {PlantService} from "../../../core/repository/plant.service";
import {CarouselModule} from "primeng/carousel";
import {ButtonModule} from "primeng/button";
import {RouterLink} from "@angular/router";
import {appRoutesLinks} from "../../../app.routes.links";
import {Tag} from "primeng/tag";
import {NgStyle} from "@angular/common";

@Component({
  selector: 'app-landing-plants',
  standalone: true,
  imports: [
    CarouselModule,
    ButtonModule,
    RouterLink,
    Tag,
    NgStyle
  ],
  templateUrl: './landing-plants.component.html',
  styles: ``
})
export class LandingPlantsComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  public plants: Plant[] = [];
  responsiveOptions: any[] | undefined;

  constructor(private plantService: PlantService) {
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.plantService.fetchLandingPlantList().subscribe(result => {
        this.plants = result;
      })
    );
    this.responsiveOptions = [
      {
        breakpoint: '1400px',
        numVisible: 2,
        numScroll: 1,
      },
      {
        breakpoint: '1199px',
        numVisible: 3,
        numScroll: 1,
      },
      {
        breakpoint: '767px',
        numVisible: 2,
        numScroll: 1,
      },
      {
        breakpoint: '575px',
        numVisible: 1,
        numScroll: 1,
      },
    ];
  }

  protected readonly appRoutesLinks = appRoutesLinks;
}
