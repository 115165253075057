export class AvailabilityAmount {
  constructor(public numberOfCuttings: number,
              public numberOfSeedlings: number,
              public numberOfTissueCulture: number) {}

  static adapt(item: any): AvailabilityAmount {
    return new AvailabilityAmount(
      item.numberOfCuttings,
      item.numberOfSeedlings,
      item.numberOfTissueCulture
    );
  }
}
