import { Component } from '@angular/core';
import {TranslocoPipe, TranslocoService} from "@jsverse/transloco";
import {FormBuilder} from "@angular/forms";
import {Meta, Title} from "@angular/platform-browser";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-genetics-news',
  standalone: true,
  imports: [
    TranslocoPipe
  ],
  templateUrl: './genetics-news.component.html',
  styles: ``
})
export class GeneticsNewsComponent {
  constructor(
              private titleService: Title,
              private translocoService: TranslocoService,
              private metaService: Meta
  ) {
    let lang: string = this.translocoService.getActiveLang();


    let title: string;
    let description: string;
    let keywords: string;
    let translationSubscription: Subscription;

    // Use selectTranslate for the first department option
    translationSubscription = this.translocoService.selectTranslate('genetics.news.title').subscribe(value => {
      title = value;
      this.titleService.setTitle(title);
    });
    translationSubscription = this.translocoService.selectTranslate('genetics.news.description').subscribe(value => {
      description = value;
      this.metaService.updateTag({name: 'description', content: description});
    });
    translationSubscription = this.translocoService.selectTranslate('genetics.news.keywords').subscribe(value => {
      keywords = value;
      if (typeof keywords !== 'undefined') {
        this.metaService.updateTag({name: 'keywords', content: keywords});
      }
    });
    translationSubscription.unsubscribe();
  }
}
