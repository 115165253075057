<div *ngIf='isMenuOpen' [class.hidden]="!isMenuOpen" class="lg:hidden" role="dialog" aria-modal="true">
  <div *ngIf='isSlideOver' class="fixed inset-0 z-40"></div>
  <div class="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
    <!-- Header with Logo and Close Button -->
    <div class="flex items-center justify-between">
      <button type="button" (click)="navigateAndClose(appRoutesLinks.LANDING_PAGE)" class="-m-1.5 p-1.5">
        <span class="sr-only">Raes Bloemzaden nv</span>
        <img class="h-8 w-auto" src="https://d3coqd91sswgmf.cloudfront.net/logo/raes_logo.jpg" alt="Raes Bloemzaden nv">
      </button>
      <button type="button" (click)="toggleMenu()" class="-m-2.5 rounded-md p-2.5 text-gray-700">
        <span class="sr-only">Close menu</span>
        <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
    </div>

    <!-- Main Menu Content -->
    <div class="mt-6 flow-root">
      <div class="-my-6 divide-y divide-gray-500/10">
        <div class="space-y-2 py-6">
          <!-- Language Switcher -->
          <div class="pl-5 py-2">
            <button (click)="switchLanguage('nl')" type="button"
                    [ngClass]="{ 'bg-gold-drop-500 text-white': isCurrentLanguage('nl'), 'text-green-400 hover:bg-christi-700 hover:text-white hover:underline': !isCurrentLanguage('nl') }"
                    class="rounded-md py-1 px-2 inline-flex items-center text-sm font-medium cursor-pointer">NL</button>
            <button (click)="switchLanguage('fr')" type="button"
                    [ngClass]="{ 'bg-gold-drop-500 text-white': isCurrentLanguage('fr'), 'text-green-700 hover:bg-christi-700 hover:text-white hover:underline': !isCurrentLanguage('fr') }"
                    class="rounded-md py-1 px-2 inline-flex items-center text-sm font-medium cursor-pointer">FR</button>
            <button (click)="switchLanguage('en')" type="button"
                    [ngClass]="{ 'bg-gold-drop-500 text-white': isCurrentLanguage('en'), 'text-green-700 hover:bg-christi-700 hover:text-white hover:underline': !isCurrentLanguage('en') }"
                    class="rounded-md py-1 px-2 inline-flex items-center text-sm font-medium cursor-pointer">EN</button>
            <button (click)="switchLanguage('de')" type="button"
                    [ngClass]="{ 'bg-gold-drop-500 text-white': isCurrentLanguage('de'), 'text-green-700 hover:bg-christi-700 hover:text-white hover:underline': !isCurrentLanguage('de') }"
                    class="rounded-md py-1 px-2 inline-flex items-center text-sm font-medium cursor-pointer">DE</button>
            <button (click)="switchLanguage('pl')" type="button"
                    [ngClass]="{ 'bg-gold-drop-500 text-white': isCurrentLanguage('pl'), 'text-green-700 hover:bg-christi-700 hover:text-white hover:underline': !isCurrentLanguage('pl') }"
                    class="rounded-md py-1 px-2 inline-flex items-center text-sm font-medium cursor-pointer">PL</button>
            <button (click)="switchLanguage('hu')" type="button"
                    [ngClass]="{ 'bg-gold-drop-500 text-white': isCurrentLanguage('hu'), 'text-green-700 hover:bg-christi-700 hover:text-white hover:underline': !isCurrentLanguage('hu') }"
                    class="rounded-md py-1 px-2 inline-flex items-center text-sm font-medium cursor-pointer">HU</button>
          </div>
          <!-- Availability Link -->
          <button (click)="navigateAndClose(appRoutesLinks.AVAILABILITY_PAGE)"
                  class="-mx-3 block w-full text-left rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
            {{ 'header.menu.availability' | transloco }}
          </button>

          <!-- Regular Menu Items -->
          <button (click)="navigateAndClose(appRoutesLinks.ORDERFORM_PAGE)"
                  class="-mx-3 block w-full text-left rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
            {{ 'header.menu.order' | transloco }}
          </button>
          <button (click)="navigateAndClose(appRoutesLinks.CATALOG_PAGE)"
                  class="-mx-3 block w-full text-left rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
            {{ 'header.menu.catalogs' | transloco }}
          </button>
          <button (click)="navigateAndClose(appRoutesLinks.JOBS_PAGE)"
                  class="-mx-3 block w-full text-left rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
            {{ 'header.menu.jobs' | transloco }}
          </button>
          <button (click)="navigateAndClose(appRoutesLinks.NEWS_PAGE)"
                  class="-mx-3 block w-full text-left rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
            {{ 'header.menu.company.news' | transloco }}
          </button>
          <button (click)="navigateAndClose(appRoutesLinks.CONTACT_PAGE)"
                  class="-mx-3 block w-full text-left rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
            {{ 'header.menu.contact' | transloco }}
          </button>
          <button (click)="navigateAndClose(appRoutesLinks.COMPANY_PAGE)"
                  class="-mx-3 block w-full text-left rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
            {{ 'header.menu.company.company' | transloco }}
          </button>

          <!-- Products Menu -->
          <div class="-mx-3">
            <button type="button" (click)="toggleProductMenu()"
                    class="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 hover:bg-gray-50"
                    aria-controls="disclosure-1"
                    [attr.aria-expanded]="isProductMenu">
              {{ 'header.menu.products' | transloco }}
              <svg [class.rotate-180]="isProductMenu" class="h-5 w-5 flex-none transition-transform" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
              </svg>
            </button>
            <!-- Products Submenu -->
            <div [@toggleMenu]="isProductMenu ? 'open' : 'closed'" class="mt-2 space-y-2" id="disclosure-1">
              <button (click)="navigateAndClose(appRoutesLinks.PRODUCTS_PAGE, { categories: 'GENERAL' })"
                      class="block w-full text-left rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                General
              </button>
              <button (click)="navigateAndClose(appRoutesLinks.PRODUCTS_PAGE, { categories: 'GROWEAT' })"
                      class="block w-full text-left rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                Grow &amp; Eat
              </button>
              <button (click)="navigateAndClose(appRoutesLinks.PRODUCTS_PAGE, { categories: 'GRASSES' })"
                      class="block w-full text-left rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                More Than Grasses
              </button>
              <button (click)="navigateAndClose(appRoutesLinks.PRODUCTS_PAGE, { categories: 'FOLIAGE' })"
                      class="block w-full text-left rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                Fabulous Foliage
              </button>
              <button (click)="navigateAndClose(appRoutesLinks.CONTACT_PAGE)"
                      class="block w-full text-left rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                Contact sales
              </button>
              <button (click)="navigateAndClose(appRoutesLinks.PRODUCTS_PAGE)"
                      class="block w-full text-left rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                View all products
              </button>
            </div>
          </div>

          <!-- Genetics Menu -->
          <div class="-mx-3">
            <button type="button" (click)="toggleGeneticsMenu()"
                    class="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 hover:bg-gray-50"
                    aria-controls="genetics-1"
                    [attr.aria-expanded]="isGeneticsMenu">
              {{ 'header.menu.genetics.title' | transloco }}
              <svg [class.rotate-180]="isGeneticsMenu" class="h-5 w-5 flex-none transition-transform" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
              </svg>
            </button>
            <!-- Genetics Submenu -->
            <div [@toggleMenu]="isGeneticsMenu ? 'open' : 'closed'" class="mt-2 space-y-2" id="genetics-1">
              <button (click)="navigateAndClose(appRoutesLinks.GENETICS_PAGE)"
                      class="block w-full text-left rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                {{ 'header.menu.genetics.overview' | transloco }}
              </button>
              <button (click)="navigateAndClose(appRoutesLinks.GENETICS_CULTURE_PAGE)"
                      class="block w-full text-left rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                {{ 'header.menu.genetics.culture' | transloco }}
              </button>
              <button (click)="navigateAndClose(appRoutesLinks.GENETICS_JUSTINTIME_PAGE)"
                      class="block w-full text-left rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                {{ 'header.menu.genetics.justInTime' | transloco }}
              </button>
              <button (click)="navigateAndClose(appRoutesLinks.GENETICS_FLOWERINGPERIODS_PAGE)"
                      class="block w-full text-left rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                {{ 'header.menu.genetics.floweringperiods' | transloco }}
              </button>
              <button (click)="navigateAndClose(appRoutesLinks.GENETICS_NEWPRIMULA_PAGE)"
                      class="block w-full text-left rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                {{ 'header.menu.genetics.newprimula' | transloco }}
              </button>
              <button (click)="navigateAndClose(appRoutesLinks.GENETICS_PRIMULA_PAGE)"
                      class="block w-full text-left rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                {{ 'header.menu.genetics.primula' | transloco }}
              </button>
              <button (click)="navigateAndClose(appRoutesLinks.CONTACT_PAGE)"
                      class="block w-full text-left rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                {{ 'header.menu.genetics.contact' | transloco }}
              </button>
              <button (click)="navigateAndClose(appRoutesLinks.PRODUCTS_PAGE)"
                      class="block w-full text-left rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                {{ 'header.menu.genetics.allproducts' | transloco }}
              </button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
