import {Component, Input, OnInit} from '@angular/core';
import {NgForOf} from "@angular/common";
import {PlantIconComponent} from "../plant-icon/plant-icon.component";

@Component({
  selector: 'app-plant-icon-list',
  standalone: true,
  imports: [
    NgForOf,
    PlantIconComponent
  ],
  templateUrl: './plant-icon-list.component.html'
})
export class PlantIconListComponent implements OnInit {

  @Input() productIcons: string[] | undefined;
  @Input() category: any;
  @Input() extension: any;

  constructor() { }

  ngOnInit(): void {
  }

}
