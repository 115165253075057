import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {HttpClient, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {AppConfigService} from '../providers/app-config.service';
import {Job} from "../../shared/model/job";
import {ApplyForJobInfo} from "../../shared/model/applyForJobInfo";

@Injectable({
  providedIn: 'root'
})
export class JobService {
  private readonly publicApi: string;

  constructor(private http: HttpClient, private appConfig: AppConfigService) {
    this.publicApi = this.appConfig.config.apiBase;
  }

  getActive(): Observable<Job[]> {
    const jobUrl = `${this.publicApi}/jobs`;
    return this.http.get<Job[]>(jobUrl).pipe(
      map(data => data.map(Job.adapt))
    );
  }

  getHighlighted(): Observable<Job[]> {
    const jobUrl = `${this.publicApi}/jobs`;
    const searchParameters: JobSearchParameters = new JobSearchParameters();
    searchParameters.highlighted = true;
    return this.http.get<Job[]>(jobUrl, {params: searchParameters.build()}).pipe(
      map(data => data.map(Job.adapt))
    );
  }

  getJob(jobId: number): Observable<Job> {
    const jobUrl = `${this.publicApi}/jobs/${jobId}`;
    return this.http.get<Job>(jobUrl).pipe(
      map(Job.adapt)
    );
  }

  applyForJobRequest(jobInfo: ApplyForJobInfo, file: File): Observable<any> {
    const url = `${this.publicApi}/applyforjob`;
    const data = new FormData();
    data.append('firstName', jobInfo.firstName);
    data.append('lastName', jobInfo.lastName);
    data.append('phone', jobInfo.phone);
    data.append('email', jobInfo.email);
    data.append('motivation', jobInfo.motivation);
    data.append('reference', jobInfo.reference);
    data.append('jobId', jobInfo.jobId?.toString());
    data.append('captcha', jobInfo.captcha);
    data.append('cv', file);

    return this.http.post<any>(url, data).pipe(
      map(response => response)
    );
  }

}


export class JobSearchParameters {
  constructor(
    public highlighted: boolean = false
  ) {
  }

  build(): HttpParams {
    let params = new HttpParams();

    if (this.highlighted) {
      params = params.append('highlighted', this.highlighted);
    }
    return params;
  }
}
