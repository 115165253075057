export enum Lifetime {
  ANNUAL = 'ANNUAL',
  BIENNIAL = 'BIENNIAL',
  PERENNIAL = 'PERENNIAL'
}

// tslint:disable-next-line:no-namespace
export namespace Lifetime {
  export function map(value: any): Lifetime | null {
    if (value === null || value === undefined) {
      return null;
    }

    switch (value) {
      case 'ANNUAL':
        return Lifetime.ANNUAL;
      case 'BIENNIAL':
        return Lifetime.BIENNIAL;
      case 'PERENNIAL':
        return Lifetime.PERENNIAL;
      default:
        return null;
    }
  }
}
